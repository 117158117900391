// File: src/screens/Evidence.jsx
import React from "react";
import { useState } from 'react';
import "../styles/NewHome.css"; // Import the corresponding CSS file
import "../styles/Product.css";
import "../styles/Evidence.css";
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
//image imports
import { logosGreen } from '../config/logos';
import linkedinicon from '../assets/linkedinicon.png';
import mission from '../assets/PMR.png';
import vision from '../assets/balancingguy.png';
import values from '../assets/activitylog.png';

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";
const currentEnv = "production";

const Evidence = () => {
    const currentYear = new Date().getFullYear();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    
        const toggleMenu = () => {
          setMenuOpen(!menuOpen);
        };

const handleEmailChange = (e) => {
    setEmail(e.target.value);
};

const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch(`${api}/Waitlist/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
                //console.log("error");
            }

            alert('You successfully joined our mailing list! We will contact you soon.');
            setEmail('');
        } catch (error) {
            setError('There was a problem submitting your email. Please try again later.');
        }
    };

  const evidenceMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
          </Menu.Item>
        </Menu>
      );

  const aboutMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/About" style={{ textDecoration: 'none' }}>About</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
          </Menu.Item>
        </Menu>
      );

  const productMenu = (
          <Menu>
            <Menu.Item key="product">
              <a href="/Product" style={{ textDecoration: 'none' }}>Product</a>
            </Menu.Item>
            <Menu.Item key="startupguide">
              <a href="/StartUpGuide" style={{ textDecoration: 'none' }}>Getting Started</a>
            </Menu.Item>
          </Menu>
        );

  return (
    <>
    <head>
      <meta charSet='UTF-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <title>Yosa About</title>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
      </style>
    </head>

    <div className="new-home">

    {/* Header */}
    <header className="header">
        <a href="/" className="logo">
          <img src={logosGreen[currentEnv]} alt="Yosa Logo" />
        </a>
        
        {/* Hamburger Menu Icon */}
        <button
            className={`menu-toggle ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>

        <nav className={`navigation ${menuOpen ? "open" : ""}`}>
            <ul>
            <li><a href="/">Home</a></li>
            <li>
              <Dropdown overlay={productMenu} trigger={['hover']}>
                <a style={{ textDecoration: 'none' }} href="/Product">
                  Product <DownOutlined />
                </a>
              </Dropdown></li>
            <li>
              <Dropdown overlay={evidenceMenu} trigger={['hover']}>
                <a style={{ textDecoration: 'none' }} href="/Evidence">
                  Evidence <DownOutlined />
                </a>
              </Dropdown>
            </li>
            <li><a href="/Pricing">Pricing</a></li>
            <li>
              <Dropdown overlay={aboutMenu} trigger={['hover']}>
                <a style={{ textDecoration: 'none' }} href="/About">
                  About <DownOutlined />
                </a>
              </Dropdown>
            </li>            <li><a href="/LogIn">Log In</a></li>
            <li><a href="/SignUp">Sign Up</a></li>
            </ul>
        </nav>
    </header>

      {/* Hero Section */}
      <section className="hero-research">
      </section>

      {/* Mission */}
      <section className="limitations-section">
        <div className="limitations-text">
          <h2 style={{ fontSize: "2.5rem" }}>Our Mission</h2>
          <p style={{ fontSize: "1.8rem", color: "#375945"}}>
          To transform and <strong>enhance</strong> the therapeutic process by integrating <strong>innovative digital tools</strong> that <strong>improve patient outcomes</strong>, empower clients to apply therapeutic skills daily, and elevate provider workflows for more impactful and efficient care.
          </p>
        </div>
        <div className="limitations-image">
          <img src={mission} alt="Current Limitations Illustration" />
        </div>
      </section>

      {/* Vision */}
      <section className="limitations-section">
        <div className="limitations-text">
          <h2 style={{ fontSize: "2.5rem"}}>Our Vision</h2>
          <p style={{ fontSize: "1.8rem", color: "#375945"}}>
          <strong>Mental health is a daily journey,</strong> not limited to a few
          sessions a month. We aim to pioneer the future of therapy where the norm is using
          digital tools to bridge the gap between sessions, ensuring continuous
          skill development and <strong>empowering clients to apply lessons learned in therapy to their daily lives.</strong>
          </p>
        </div>
        <div className="limitations-image">
          <img src={vision} alt="Why Homework Matters Illustration" />
        </div>
      </section>

      {/* Values */}
      <section className="solution-section">
        <div className="solution-text">
            <h2 style={{ fontSize: "2.5rem" }}>Our Values</h2>
            <p style={{ fontSize: "1.8rem", color: "#375945" }}>
            At Yosa, <strong>our values guide every aspect of our work</strong>. We are committed to securely leveraging digital innovation to make mental health care more impactful, engaging, and effective:
            </p>
            <ul>
            <li><strong>Client Empowerment:</strong> Empowering individuals to take ownership of their mental health journey with accessible and personalized tools.</li>
            <li><strong>Innovation:</strong> Continuously improving therapy through cutting-edge technology that bridges the gaps between sessions.</li>
            <li><strong>Quality:</strong> Ensuring the highest standards of care and outcomes through evidence-informed practices and continuous improvement.</li>
            <li><strong>Privacy and Security:</strong> Upholding the highest standards of confidentiality and compliance to build trust and safety.</li>
            <li><strong>Continuous Growth:</strong> Supporting clients and providers in ongoing learning, reflection, and development for sustained well-being.</li>
            <li><strong>Compassion:</strong> Centering humanity, understanding, and care in everything we do.</li>
            </ul>
        </div>
        <div className="solution-image">
            <img src={values} alt="How Yosa is Better Illustration" />
        </div>
      </section>


      {/* Waitlist Signup */}
      <section className="newsletter">
        <h2 style={{marginBottom: '30px'}}>Get started today for free! No credit card required.</h2>
        <a href="/signup" className="free-trial-button">Sign Up</a>
      </section>

      <footer className="footer">
                <div className="footer-content">
                    <p>&copy; {currentYear} Yosa Inc. All rights reserved.</p>
                    <nav>
                    <ul>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                        <li><a href="/terms-of-service">Terms of Service</a></li>
                        <li><a href="/baa">Business Associate Agreement</a></li>
                    </ul>
                    </nav>
                    <div className="footer-bottom">
                    <p>
                        <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
                        <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
                        <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
                        </a>
                    </p>
                    </div>
                </div>
            </footer>
    </div>
    </>
  );
};

export default Evidence;
