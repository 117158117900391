import React, { useState, useEffect } from "react";
import "../styles/NewHome.css";
import "../styles/Product.css";
import "../styles/Evidence.css";
import { Tabs, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { logosGreen } from '../config/logos';
import linkedinicon from '../assets/linkedinicon.png';
import { Tab } from "bootstrap";

const { TabPane } = Tabs;
const currentEnv = "production";

const evidenceMenu = (
  <Menu>
    <Menu.Item key="evidence">
      <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
    </Menu.Item>
    <Menu.Item key="homework">
      <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
    </Menu.Item>
  </Menu>
);

const aboutMenu = (
  <Menu>
    <Menu.Item key="evidence">
      <a href="/About" style={{ textDecoration: 'none' }}>About</a>
    </Menu.Item>
    <Menu.Item key="homework">
      <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
    </Menu.Item>
  </Menu>
);

const productMenu = (
  <Menu>
    <Menu.Item key="product">
      <a href="/Product" style={{ textDecoration: 'none' }}>Product</a>
    </Menu.Item>
    <Menu.Item key="startupguide">
      <a href="/StartUpGuide" style={{ textDecoration: 'none' }}>Getting Started</a>
    </Menu.Item>
  </Menu>
);

const StartUpGuide = () => {
  const currentYear = new Date().getFullYear();
  const [menuOpen, setMenuOpen] = useState(false);
  
  // State to track if screen is mobile (under 600px wide)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  // Listen for window resize to update "isMobile"
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Adjust paddingBottom for the aspect ratio based on screen size
  // Desktop => 39.5% (the ratio you want)
  // Mobile => 56.25% (standard 16:9)
  const videoContainerStyle = {
    position: 'relative',
    paddingBottom: isMobile ? '56.25%' : '39.5%',
    height: 0,
    maxWidth: isMobile ? '100%' : '70%',
    margin: '0 auto 1rem',
    textAlign: 'center'
  };

  const iframeStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0
  };

  const evidenceMenu = (
    <Menu>
      <Menu.Item key="evidence">
        <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
      </Menu.Item>
      <Menu.Item key="homework">
        <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
      </Menu.Item>
    </Menu>
  );

const aboutMenu = (
    <Menu>
      <Menu.Item key="evidence">
        <a href="/About" style={{ textDecoration: 'none' }}>About</a>
      </Menu.Item>
      <Menu.Item key="homework">
        <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
      </Menu.Item>
    </Menu>
  );

const productMenu = (
  <Menu>
    <Menu.Item key="product">
      <a href="/Product" style={{ textDecoration: 'none' }}>Product</a>
    </Menu.Item>
    <Menu.Item key="startupguide">
      <a href="/StartUpGuide" style={{ textDecoration: 'none' }}>Getting Started</a>
    </Menu.Item>
  </Menu>
);

  return (
    <>
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Yosa Getting Started</title>
        <style>
          {`@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');`}
        </style>
      </head>
      <div className="new-home">
        {/* Header */}
        <header className="header">
          <a href="/" className="logo">
            <img src={logosGreen[currentEnv]} alt="Yosa Logo" />
          </a>
          <button
            className={`menu-toggle ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <nav className={`navigation ${menuOpen ? "open" : ""}`}>
          <ul>
              <li><a href="/">Home</a></li>
              <li>
                <Dropdown overlay={productMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/Product">
                    Product <DownOutlined />
                  </a>
                </Dropdown></li>
              <li>
                <Dropdown overlay={evidenceMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/Evidence">
                    Evidence <DownOutlined />
                  </a>
                </Dropdown>
              </li>
              <li><a href="/Pricing">Pricing</a></li>
              <li>
                <Dropdown overlay={aboutMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/About">
                    About <DownOutlined />
                  </a>
                </Dropdown>
              </li>
              <li><a href="/LogIn">Log In</a></li>
              <li><a href="/SignUp">Sign Up</a></li>
            </ul>
          </nav>
        </header>

        {/* Main Content */}
        <section className="startup-guide-section" style={{ padding: '2rem' }}>
          <h1 style={{ textAlign: 'center', color: '#375945' }}>Getting Started</h1>
          <Tabs defaultActiveKey="1" centered>
            <TabPane tab={<span style={{ color: '#375945' }}>How to Sign Up</span>} key="1">
              <div className="video-container" style={videoContainerStyle}>
                <iframe
                  style={iframeStyle}
                  src="https://www.youtube.com/embed/Vi_kBi1iyvA"
                  title="How to Sign Up and Get Started on Yosa"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div
                className="guide-description"
                style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
              >
                <h2 style={{color: '#375945'}}>Sign Up Process</h2>
                <ol>
                  <li>
                    Navigate to the <a href="https://yosaapp.com/SignUp" target="_blank" rel="noopener noreferrer">Sign Up</a> screen.
                  </li>
                  <li>
                    Enter your information and select a pricing plan. <strong>Note:</strong> Your therapist name is what your clients will see when invited.
                  </li>
                  <li>
                    Input the confirmation code sent to your email and submit.
                  </li>
                  <li>
                    Proceed to log in with your credentials.
                  </li>
                  <li>
                    On the dashboard, click <strong>Add Client</strong> on the left sidebar.
                  </li>
                  <li>
                    Ensure you correctly enter the email your client will use to register on their mobile device.
                  </li>
                  <li>
                    If your client hasn’t installed Yosa or logged in, they will receive an email guiding them through onboarding and a link to the app.
                  </li>
                  <li>
                    Once they sign up and log in for the first time, they’ll get a notification that you’ve invited them to connect.
                  </li>
                  <li>
                    If they accept, their registration status updates to “registered” in your dashboard. (You might need to click “Check Registration Status” in the clients table to refresh.)
                  </li>
                  <li>
                    Now you’re connected to your client and ready to send assignments and provide care!
                  </li>
                </ol>
              </div>
            </TabPane>
            <TabPane tab={<span style={{ color: '#375945' }}>Send Assignments and Monitor Clients</span>} key="2">
              <div className="video-container" style={videoContainerStyle}>
                <iframe
                  style={iframeStyle}
                  src="https://www.youtube.com/embed/K42gT4lky_4"
                  title="How to Send Assignments and Monitor Your Patients on Yosa"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <Tabs defaultActiveKey="3" centered>
                <TabPane tab={<span style={{ color: '#375945' }}>Sending Assignments</span>} key="3">
                  <div
                    className="guide-description"
                    style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                  >
                    <ol>
                      <li>
                        Log in to your provider dashboard.
                      </li>
                      <li>
                        Locate the client to whom you want to send an assignment.
                      </li>
                      <li>
                        Click the <strong>Send Assignment</strong> button next to the client's name.
                      </li>
                      <li>
                        Choose an assignment or multiple in the popup. Assignments include custom ones under "My Assignments", validated measures, and any of the Yosa features on the app. You may also choose to add instructions or send a link in the intructions field.
                      </li>
                      <li>
                        Click "Send" at the bottom of the popup to send to your client. Once sent, the assignment will appear in the client's mobile app.
                      </li>
                    </ol>
                  </div>
                </TabPane>
                <TabPane tab={<span style={{ color: '#375945' }}>Monitoring Client Progress</span>} key="4">
                  <div
                    className="guide-description"
                    style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                  >
                    <ol>
                      <li>
                        Log in to your provider dashboard.
                      </li>
                      <li>
                        Client responses and assignment statuses update in real-time. Note that besides measures like the PHQ-9, assignment responses and data are locked by default. Clients can choose to share responses or data with you by clicking "Sharing" in the settings on their mobile app. From there, they can toggle on or off the specific features they want to share.
                      </li>
                      <li>
                        To view responses, click the client's name in the "My Clients" table in the dashboard. 
                      </li>
                      <li>
                        You can view qualitative responses in the <b>"Activity"</b> tab (e.g. Thought Records, Goals, etc.). Here, the green check or alert icon will tell you if the assignment was completed or not. Click the assignment to view its content if shared (see step 2). 
                      </li>
                      <li>
                        You can view quantitative responses in the <b>"Charts"</b> tab (e.g. measures, symptom log, medication log, substance log).
                      </li>
                      <li>
                        You can also view client assignments directly from the dashboard by clicking the arrow directly to the left of the client's name in the "My Clients" table. This will display a list of all assignments sent to the client and their statuses, including date and time sent, completion status, sharing status, any provided instruction, and a link to view its data.
                      </li>
                    </ol>
                  </div>
                </TabPane>
                <TabPane tab={<span style={{ color: '#375945' }}>Creating Custom Assignments</span>} key="5">
                  <div
                    className="guide-description"
                    style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                  >
                    <ol>
                      <li>
                        Custom assignments you create or upload will appear in the "My Library" table on the dashboard. These include assignments created from the assignment builder, community assignments, and uploaded PDFs.
                      </li>
                      <li>
                        To create an interactive assignment from scratch, click the <strong>Assignment Builder</strong> button on the left sidebar. From here, you can create a new assignment, choosing the individual components and layout of the worksheet, including text fields, sliders, checkboxes and more.
                      </li>
                      <li>
                        To upload a PDF, click the <strong>Upload PDF</strong> button on the left sidebar.
                      </li>
                      <li>
                        Any custom assignments you create or upload can be sent to clients by clicking "Send Assignment" and selecting the desired custom assignment(s).
                      </li>
                    </ol>
                  </div>
                </TabPane>
                <TabPane tab={<span style={{ color: '#375945' }}>Choosing Client Features</span>} key="6">
                  <div
                    className="guide-description"
                    style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                  >
                    <ol>
                      <li>
                        Yosa is a one-stop-shop of tools you can utilize with your clients. However, not all will be applicable to every client and it may be overwhelming to see so many. 
                      </li>
                      <li>
                        Thus, you can choose which features to enable or disable for each client, tailoring their experience to their unique needs and goals.
                      </li>
                      <li>
                        Feature selection occurs first when adding the client. Whatever features you select will be the one's that client can view on their mobile app. Note that Journaling, the Therapy Journal, Worksheets, and Help resources are default features all users have access to. 
                      </li>
                      <li>
                        In addition to when initally adding a client, you can update their features at any time by clicking the client's name in the "My Clients" table on the dashboard and selecting the <strong>Features</strong> tab. From here choose the features you want to enable and click "Save", updating what your client can access.
                      </li>
                    </ol>
                  </div>
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab={<span style={{ color: '#375945' }}>Client Mobile App</span>} key="3">
              <div className="video-container" style={videoContainerStyle}>
                <iframe
                  style={iframeStyle}
                  src="https://www.youtube.com/embed/3qz0-zZravs?si=rawS7H5BIqaNZtHh"
                  title="How to Use the Client Mobile App on Yosa"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <Tabs defaultActiveKey="3" centered>
                <TabPane tab={<span style={{ color: '#375945' }}>General</span>} key="3">
                  <div
                    className="guide-description"
                    style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                  >
                    <ol>
                      <li>
                        The Yosa mobile app can be downloaded from the App Store (for Apple devices) or Google Play Store (for Android devices).
                      </li>
                      <li>
                        While the web portal (yosaapp.com) is for providers, the mobile app is for clients, where they will complete assignments, track progress, and engage with therapuetic activities.
                      </li>
                      <li>
                        Although the mobile app is for clients, we reccomend providers add themselves as a client and download it to understand the client experience and better support them. See the video in the "How to Sign Up" tab for more information.
                      </li>
                      <li>
                        The features clients are able to access are determined by the provider. See the video in the "Send Assignments and Monitor Clients" tab for information on customizing what features your individual clients can access.
                      </li>
                      <li>
                        Through their mobile app, clients choose what information they wish to share with their provider (e.g. assignment responses, symptom log data, etc.). They can do this by clicking "Sharing" in the settings on their mobile app. From there, they can toggle on or off the specific features they want to share. See the video in the "Send Assignments and Monitor Clients" tab for more information.
                      </li>
                    </ol>
                  </div>
                </TabPane>
                <TabPane tab={<span style={{ color: '#375945' }}>Home Screen</span>} key="4">
                  <div
                    className="guide-description"
                    style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                  >
                    <ol>
                      <li>
                        Clients can view and complete pending assignments you send under "Your Assignments" on the home screen.
                      </li>
                      <li>
                        The "Whiteboard" on the home screen is a place clients can store any readily available information, such as goals, coming appointments, or reminders.
                      </li>
                    </ol>
                  </div>
                </TabPane>
                <TabPane tab={<span style={{ color: '#375945' }}>Features</span>} key="5">
                  <div
                    className="guide-description"
                    style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                  >
                    <ol>
                      <li>
                        Yosa has several features clients can access on their mobile app, which are found on the library tab. These include:
                      </li>
                      <li>
                        <b>Wellness Toolkit</b> - A collection of active practices, including journaling, breathing exercises, meditation, and progressive muscle relaxation.
                      </li>
                      <li>
                        <b>Tracking</b> - The symptom log allows clients to track their mood, sleep, energy, stress and social interaction over time. The medication log allows clients to track medication usage and adherance. The substance log allows clients to track substance use.
                      </li>
                      <li>
                        <b>Therapy</b> - Custom worksheets (PDFs or from assignment builder) you send your clients will show here to respond to, as well as various other therapy specific activities, including thought records, goal setting, value sorting, behavioral activation, exposure therapy, and a therapy journal to prepare and reflect on sessions.
                      </li>
                      <li>
                        <b>Help</b> - A collection of resources for clients to access, including crisis lines specific to different needs.
                      </li>
                    </ol>
                  </div>
                </TabPane>
                <TabPane tab={<span style={{ color: '#375945' }}>Journey</span>} key="6">
                  <div
                    className="guide-description"
                    style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                  >
                    <ol>
                      <li>
                        A core goal of Yosa is boosting engagement in therapeutic activities between sessions. Thus, the "Journey" tab is all about making the app more fun and inspiring to use by tracking their mental health journey on Yosa.
                      </li>
                      <li>
                        Clients can work towards a number of "Yosa Challenges", each with a specific goal and reward. Rewards often include earning a meal donation on their behalf, an incentive that is both motivating and makes you feel good to accomplish.
                      </li>
                      <li>
                        As clients complete challenges, they also earn exp to level up, a fun way to track progress and engage with the app.
                      </li>
                      <li>
                        The "Journey" feature also includes a "Daily Quest", a small goal to complete each day, such as taking a 5 minute stretch break. Similar to completing challenges, after completing 15 "Daily Quests", clients earn a meal donation.
                      </li>
                    </ol>
                  </div>
                </TabPane>
              </Tabs>
            </TabPane>
          </Tabs>
        </section>

        {/* Footer */}
        <footer className="footer">
          <div className="footer-content">
            <p>&copy; {currentYear} Yosa Inc. All rights reserved.</p>
            <nav>
              <ul>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                <li><a href="/terms-of-service">Terms of Service</a></li>
                <li><a href="/baa">Business Associate Agreement</a></li>
              </ul>
            </nav>
            <div className="footer-bottom">
              <p>
                <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
                <a
                  href="https://www.linkedin.com/company/yosa-app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={linkedinicon}
                    alt="LinkedIn"
                    className="linkedin-icon"
                  />
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default StartUpGuide;
