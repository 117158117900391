// File: src/screens/Home.jsx
import React from "react";
import { useState } from 'react';
import "../styles/NewHome.css"; // Import the corresponding CSS file
import IconTicker from "../components/InfiniteTicker";
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Lottie from "lottie-react";
import naturebackground from "../assets/animations/naturebackground.json";
//image imports
import { logosGreen } from '../config/logos';
import web from '../assets/webapp.png';
import yellowshirtlady from '../assets/yellowshirtlady.png';
import securitycartoon from '../assets/securitycartoon.png';
import linkedinicon from '../assets/linkedinicon.png';
import { Navigate } from "react-router-dom";

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";
const currentEnv = process.env.REACT_APP_ENV || "development";

const Home = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch(`${api}/Waitlist/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
        //console.log("error");
      }

      alert('You successfully joined our mailing list! We will contact you soon.');
      setEmail('');
    } catch (error) {
      setError('There was a problem submitting your email. Please try again later.');
    }
  };

  const evidenceMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
          </Menu.Item>
        </Menu>
      );

  const aboutMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/About" style={{ textDecoration: 'none' }}>About</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
          </Menu.Item>
        </Menu>
      );

  const productMenu = (
        <Menu>
          <Menu.Item key="product">
            <a href="/Product" style={{ textDecoration: 'none' }}>Product</a>
          </Menu.Item>
          <Menu.Item key="startupguide">
            <a href="/StartUpGuide" style={{ textDecoration: 'none' }}>Getting Started</a>
          </Menu.Item>
        </Menu>
      );

  return (
    <>
      <head>
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>Yosa Home Screen</title>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
        </style>
      </head>

      <div className="new-home">

        {/* Header */}
        <header className="header">
          <a href="/" className="logo">
            <img src={logosGreen[currentEnv]} alt="Yosa Logo" />
          </a>

          {/* Hamburger Menu Icon */}
          <button
            className={`menu-toggle ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>

          <nav className={`navigation ${menuOpen ? "open" : ""}`}>
            <ul>
              <li><a className="bold-header-title" style={{ fontWeight: "900" }} href="/">Home</a></li>
              <li>
                <Dropdown overlay={productMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/Product">
                    Product <DownOutlined />
                  </a>
                </Dropdown></li>
              <li>
                <Dropdown overlay={evidenceMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/Evidence">
                    Evidence <DownOutlined />
                  </a>
                </Dropdown>
              </li>
              <li><a href="/Pricing">Pricing</a></li>
              {/* <li><a href="/Admin">Admin</a></li>*/}
              <li>
                <Dropdown overlay={aboutMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/About">
                    About <DownOutlined />
                  </a>
                </Dropdown>
              </li>
              <li><a href="/LogIn">Log In</a></li>
              <li><a href="/SignUp">Sign Up</a></li>
            </ul>
          </nav>
        </header>

        {/* Hero Section */}
        <section className="hero">
          <div className="hero-text">
            <h1>Bridging the Gap Between Therapy and Everyday Life</h1>
            <p>
              Seamlessly implement evidence-informed, HIPAA-compliant digital
              tools to boost client engagement and accelerate outcomes
            </p>
          </div>
          <IconTicker />
        </section>

        <section className="why-yosa">
          <div className="why-yosa-text">
            <h2>Why Yosa?</h2>
            <p>
              <strong>Going to therapy is a crucial first step, but real progress happens between sessions when you apply what you learned into daily life. <a className="link-to-evidence" href="/Evidence">Research</a> shows that actively engaging in therapeutic exercises outside of sessions leads to better outcomes.</strong>
            </p>
            <p>
              Although homework is a common practice often considered essential in therapy, engaging clients can often be challenging due to limitations with current methods (i.e. paper, email, verbal).
            </p>
            <p>
              Mental health apps offer a powerful way to keep clients engaged between sessions, but most lack HIPAA compliance and aren't designed for therapists' needs or use in clinical settings. Many are narrowly focused (e.g., just a meditation app), while therapists handle diverse caseloads with varied challenges and treatment goals.
            </p>
            <p>
              Yosa is an all-in-one, professional-grade solution to bridge the gap between sessions. Fully HIPAA-compliant, Yosa equips therapists with a <a className="link-to-product" href="/Product">customizable library of tools</a>—from interactive breathing exercises to validated measures like the PHQ-9 to thought records—empowering clients to apply therapy insights to daily life while giving providers actionable insights to accelerate outcomes.
            </p>
          </div>
        </section>

        {/* How Yosa Works */}
        <section className="how-it-works">
          <div className="home-title">
            <h2>How Yosa Works</h2>
          </div>
          <div className="sub-title">
            <h2>Simple as 1-2-3</h2>
          </div>
          <div className="steps">
            <div className="step">
              <h3 className="step-one">1</h3>
              <p>
                There’s a lot in our <a className="link-to-product" href="/Product">Assignment Toolbox</a>. You choose which features
                each client has access to, tailoring it to their unique needs.
              </p>
            </div>
            <div className="step">
              <h3 className="step-two">2</h3>
              <p>
                Upload, build from scratch, or take assignments from our library to
                assign to clients, including worksheets, tracking, measures,
                and practices.
              </p>
            </div>
            <div className="step">
              <h3 className="step-three">3</h3>
              <p>
                Monitor your clients’ progress as they complete assignments and
                share responses. View charts for data-driven insights.
              </p>
            </div>
          </div>
        </section>

        {/* Client App and Provider Portal */}
        <section className="client-provider">
          <div className="client-app">
            <div className="client-app-text">
              <h2>Client App</h2>
              <p>
                An interactive, engaging, and accessible alternative to old-school assignments—clients can complete personalized therapeutic tasks, track progress, and access tools like guided breathing exercises and journaling, all in a secure, user-friendly app.
              </p>
            </div>
            <div className="client-app-image">
              <img src={yellowshirtlady} alt="Yellow Shirt Lady" />
            </div>
          </div>
          <div className="provider-portal">
            <div className="provider-portal-image">
              <img src={web} alt="Web App Mockup" />
            </div>
            <div className="provider-portal-text">
              <h2>Provider Portal</h2>
              <p>
                Add and manage clients, send assignments, request and assess client
                responses and data, analyze client charts for data-driven insights.
              </p>
            </div>
          </div>
        </section>

        {/* Assignment Toolkit */}
        {/* <h2 className="toolbox-title">The Yosa Assignment Toolbox</h2>
        <AssignmentToolboxCarousel /> */}
        <h3 className="learn-more"><a href="/Product">Click here to learn more about our features!</a></h3>

        {/* Security Section */}
        <section className="security">
          <div className="security-header">
            <h1>Did you know the majority of mental health apps are not HIPAA-compliant?</h1>
          </div>
          <div className="security-content">
            <div className="security-text">
              <h2>Peace of Mind in Every Click</h2>
              <p>
                Unlike other apps, Yosa is a professional-grade tool and is proudly HIPAA-compliant, ensuring a confidential and private digital experience.
              </p>
              <ul>
                <li>End-to-end data encryption</li>
                <  li>Multi-factor authentication</li>
                <li>We do NOT sell your data for ANY reason</li>
              </ul>
            </div>
            <div className="security-image">
              <img src={securitycartoon} alt="Security Illustration" />
            </div>
          </div>
        </section>

        {/* Nature Animation 
        <div animation-container>
          <div className="nature-animation-container">
            <Lottie animationData={naturebackground} loop={true} className="nature-animation" />
          </div>
        </div> */}

        {/* Vision Statement */}
        <section className="vision">
          <div className="vision-container">
            <h2 className="vision-title">Our Vision</h2>
            <p className="vision-description">
              <strong>Mental health is a daily journey,</strong> not limited to a few
              sessions a month. We aim to pioneer the future of therapy by using
              digital tools to bridge the gap between sessions, ensuring continuous
              skill development and <strong>empowering clients to apply lessons learned in therapy to their daily lives.</strong>
            </p>
          </div>
        </section>

        {/* Waitlist Signup */}
        <section className="newsletter">
          <h2 style={{marginBottom: '30px'}}>Get started today for free! No credit card required.</h2>
          <a href="/signup" className="free-trial-button">Sign Up</a>
        </section>

        <footer className="footer">
          <div className="footer-content">
            <p>&copy; {currentYear} Yosa Inc. All rights reserved.</p>
            <nav>
              <ul>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                <li><a href="/terms-of-service">Terms of Service</a></li>
                <li><a href="/baa">Business Associate Agreement</a></li>
              </ul>
            </nav>
            <div className="footer-bottom">
              <p>
                <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
                <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
                  <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Home;