import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import linkedinicon from '../assets/linkedinicon.png';
import { logosGreen } from '../config/logos';
import '../styles/SignUp.css';

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";
const currentEnv = process.env.REACT_APP_ENV || "development";

const TermsOfService = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const currentYear = new Date().getFullYear();
        
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const goToSignUp = () => {
        navigate('/signup');
    };

    const goToLogIn = () => {
        navigate('/login');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch(`${api}/Waitlist/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
                //console.log("error");
            }

            alert('You successfully joined our mailing list!');
            setEmail('');
        } catch (error) {
            setError('There was a problem submitting your email. Please try again later.');
        }
    };

  const evidenceMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
          </Menu.Item>
        </Menu>
      );

  const aboutMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/About" style={{ textDecoration: 'none' }}>About</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
          </Menu.Item>
        </Menu>
      );

    return (
        <>
            <html>
            <head>
                <meta charSet='UTF-8' />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <title>Yosa Terms of Service</title>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
                    
                </style>
            </head>
            <body>

            <div className="new-home">


                {/* Header */}
                <header className="header">
                      <a href="/" className="logo">
                        <img src={logosGreen[currentEnv]} alt="Yosa Logo" />
                      </a>

                      {/* Hamburger Menu Icon */}
                      <button
                        className={`menu-toggle ${menuOpen ? "open" : ""}`}
                        onClick={toggleMenu}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>

                      <nav className={`navigation ${menuOpen ? "open" : ""}`}>
                        <ul>
                          <li><a className="bold-header-title" style={{ fontWeight: "900" }} href="/">Home</a></li>
                          <li><a href="/Product">Product</a></li>
                          <li>
                            <Dropdown overlay={evidenceMenu} trigger={['hover']}>
                              <a style={{ textDecoration: 'none' }} href="/Evidence">
                                Evidence <DownOutlined />
                              </a>
                            </Dropdown>
                          </li>
                          <li><a href="/Pricing">Pricing</a></li>
                          {/*<li><a href="/Admin">Admin</a></li>*/}
                          <li>
                            <Dropdown overlay={aboutMenu} trigger={['hover']}>
                              <a style={{ textDecoration: 'none' }} href="/About">
                                About <DownOutlined />
                              </a>
                            </Dropdown>
                          </li>
                          <li><a href="/LogIn">Log In</a></li>
                          <li><a href="/SignUp">Sign Up</a></li>
                        </ul>
                      </nav>
                </header>


                <main>
                    <h1 className="privacy-title">Terms of Service</h1>
                    <p className="last-updated">Last Updated: March 9, 2025</p>
                    <div className="content-container">
                    <p>Welcome to Yosa! These terms of service ("Terms") apply to your access and use of Yosa (the "Service") provided by Yosa Inc. (the "Company"). Please read them carefully.</p>
                    <p className="policy-header">Accepting these Terms</p>
                    <p>If you access or use the Service, it means you agree to be bound by all of the terms below. So, before you use the Service, please read all of the terms. If you do not agree to all of the terms below, please do not use the Service. Also, if a term does not make sense to you, please let us know by emailing support@yosaapp.com.</p>
                    <p className="policy-header">Changes to these Terms</p>
                        <p>We reserve the right to modify these Terms at any time. For instance, we may need to change these Terms if we release a new feature or for some other reason.</p>
                        <p>Whenever we make changes to these Terms, the changes are effective at the time we post such revised Terms (indicated by revising the date at the top of these Terms) or upon your acceptance if we provide a mechanism for your immediate acceptance of the revised Terms (such as a click-through confirmation or acceptance button). It is your responsibility to check the Service for changes to these Terms. If you continue to use the Service after the revised Terms go into effect, then you have accepted the changes to these Terms.</p>
                        <p className="policy-header">Not a Substitute for Professional Medical or Psychological Services</p>
                        <p>Yosa is designed as a supplementary tool to be used in conjunction with traditional therapy. It is intended to enhance and support the therapeutic process by providing digital tools and resources that therapists can utilize with their clients. Yosa does not replace therapy sessions but rather serves as an adjunct to these services.</p>
                        <p>You acknowledge and agree that Yosa is not a healthcare or medical provider and that the services provided through our platform do not constitute medical, psychological, or any other professional healthcare advice. The tools and features offered by Yosa are intended to support your engagement and progress in therapy as prescribed by your licensed practitioner.</p>
                        <p>Any content, tools, or materials provided through Yosa are for general purposes only and are not intended to be relied upon for making healthcare decisions. The platform is not a substitute for professional service from a healthcare provider.</p>
                        <p>You are solely responsible for how you choose to use the features and tools provided by Yosa. Decisions about your treatment and the application of Yosa's tools should be made in consultation with your healthcare provider. We are not liable or responsible for any actions taken due to your having used the Service. In particular, to the fullest extent permitted by law, we give no representation or warranties about the accuracy, completeness, or suitability for any purpose of the materials and information published on or through the Service.</p>
                        <p>Should you receive advice from a medical or psychological professional that conflicts with any content or tools provided by Yosa, the advice of your healthcare provider should take precedence. Always follow the guidance of your therapist or other qualified health professionals regarding your treatment.</p>
                        <p>Yosa is not designed to handle or address medical emergencies. If you believe you are experiencing an emergency, call emergency services immediately.</p>
                        <p className="policy-header">Privacy Policy</p>
                        <p>For information about how we collect and use information about users of the Service, please check out our privacy policy available at <a href="https://yosaapp.com/privacy-policy/">yosaapp.com/privacy-policy/</a>.</p>
                        <p className="policy-header">Third-Party Services</p>
                        <p>We may provide you with links to third party websites or services that we do not own or control. Your use of the Service may also include the use of applications that are developed or owned by a third party. Your use of such third party applications, websites, and services is governed by that party's own terms of service or privacy policies. We encourage you to read the terms and conditions and privacy policy of any third party application, website or service that you visit or use.</p>
                        <p className="policy-header">Creating Accounts</p>
                        <p>When you create an account or use another service to log in to the Service, you agree to maintain the security of your password and accept all risks of unauthorized access to any data or other information you provide to the Service. If you discover or suspect any Service security breaches, please let us know as soon as possible.</p>
                        <p className="policy-header">Your Content & Conduct</p>
                        <p>You, along with other authorized users, can input, store, and manage therapy-related data, including but not limited to journal entries, therapy assignments, and health tracking information. You are responsible for the accuracy and relevance of the data you provide.</p>
                        <p>You retain ownership of the data you input into Yosa. Yosa has the right to process this data as necessary for the functionality and operations of our Service, in accordance with our Privacy Policy and applicable regulations.</p>
                    <p>You can delete your data from the platform at any time, which will remove it from visibility within the Service.</p>
                        <p>It is prohibited to make available on or through the Service any of the following:</p>
                        <ul>
                            <li>Content that is illegal or unlawful, that would otherwise create liability</li>
                            <li>Content that may infringe or violate any patent, trademark, trade secret, copyright, right of privacy, right of publicity or other intellectual or other right of any party</li>
                            <li>Unauthorized private information of any third party (e.g., addresses, phone numbers, email addresses, Social Security numbers and credit card numbers)</li>
                            <li>Viruses, corrupted data or other harmful, disruptive or destructive files or code</li>
                        </ul>
                        <p>Also, you agree that you will not do any of the following in connection with the Service or other users:</p>
                        <ul>
                            <li>Use the Service in any manner that could interfere with, disrupt, negatively affect or inhibit other users from fully enjoying the Service or that could damage, disable, overburden or impair the functioning of the Service</li>
                            <li>Create an account if you are not over 13 years of age</li>
                            <li>Circumvent or attempt to circumvent any security measures designed to protect the Service, users of the Service, or third parties</li>
                        </ul>
                        <p className="policy-header">The Company Materials</p>
                        <p>We have invested significant effort into creating the Service including the logo and all designs, text, graphics, information, and other content (excluding your content). This property is owned by us and is protected by copyright laws. We grant you the right to use it.</p>
                        <p>However, unless we expressly state otherwise, you may not:</p>
                        <ul>
                            <li>Publicly perform or display the Service</li>
                            <li>Modify, adapt, translate, or create derivative works based upon the Service or any part thereof</li>
                            <li>Employ data mining, robots, or similar data gathering or extraction methods</li>
                            <li>Download (other than through page caching necessary for personal use), copy, archive, or capture any content from the Service without explicit written permission</li>
                            <li>Reverse engineer any part of the Service to build a competing product or service</li>
                            <li>Use the service beyond the scope of its intended use</li>
                        </ul>
                        <p>Violation of any of these restrictions may result in the termination of your access to the Service, and may subject you to legal action and damages.</p>
                        <p className="policy-header">Intellectual Property and Licensed Content</p>
                        <p>By using the Service, you agree that you will only upload, share, or distribute materials (including but not limited to therapy assignments, assessments, worksheets, and measures) for which you have the legal rights and necessary licenses to do so. Yosa does not review or verify the licensing status of uploaded content, and we are not responsible for any unauthorized use or distribution of copyrighted, proprietary, or licensed materials by users of the Service.</p>
                        <p>If you upload content that requires licensing (e.g., proprietary assessments, validated psychological measures, or copyrighted therapy assignments), you acknowledge that you have obtained the appropriate permissions or licenses from the copyright owner. You further agree to hold Yosa Inc. harmless from any claims, damages, liabilities, or legal actions arising from unauthorized uploads or use of such content.</p>
                        <p>Yosa reserves the right to remove or restrict access to any content that violates intellectual property laws or this Agreement and to suspend or terminate accounts of users who repeatedly infringe on such rights.</p>
                        <p className="policy-header">Provider Obligations</p>
                        <p>If you are a healthcare provider using Yosa’s Service to engage with clients, you are solely responsible for complying with all applicable state, federal, and ethical obligations, including but not limited to HIPAA, state telehealth laws, and professional licensing board regulations. You agree not to use Yosa in a way that violates patient confidentiality or any regulatory requirements.</p>
                        <p>Yosa stores and facilitates access to patient data, including completed assignments, text entries, and tracked health data, so that providers may review and utilize this information in their professional capacity. However, Yosa does not actively monitor, review, or analyze this data, nor does it provide clinical decision-making or oversight regarding its contents. Providers are solely responsible for ensuring they handle patient data in compliance with their legal and ethical obligations.</p>
                        <p>Any claims related to breaches of privacy, professional responsibility, or misuse of patient data are the sole responsibility of the provider and not Yosa Inc.</p>
                        <p className="policy-header">Hyperlinks and Third-Party Content</p>
                        <p>The Company makes no claim or representation regarding third party websites accessible by hyperlink from the Service or websites linking to the Service. When you leave the Service, you should be aware that these Terms and our policies no longer govern.</p>
                        <p>If there is any content on the Service from you and others, we do not review, verify or authenticate it, and it may include inaccuracies or false information. We make no representations, warranties, or guarantees relating to the quality, suitability, truth, accuracy or completeness of any content contained in the Service. You acknowledge sole responsibility for and assume all risk arising from your use of or reliance on any content.</p>
                        <p className="policy-header">Legal and Liability Terms</p>
                        <p>THE SERVICE IS PROVIDED TO YOU ON AN AS IS OR AS AVAILABLE BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND. WE DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR WRITTEN) WITH RESPECT TO THE SERVICE AND CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE WHETHER ALLEGED TO ARISE BY OPERATION OF LAW, BY REASON OF CUSTOM OR USAGE IN THE TRADE, BY COURSE OF DEALING OR OTHERWISE.</p>
                        <p>IN NO EVENT WILL THE COMPANY BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THE SERVICE OR ANY OTHER SERVICE AND/OR CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE, REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR ARE AWARE OF THE POSSIBILITY OF SUCH DAMAGES. OUR TOTAL LIABILITY FOR ALL CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY WILL BE LIMITED TO THE AMOUNT YOU PAID TO THE COMPANY. THIS SECTION WILL BE GIVEN FULL EFFECT EVEN IF ANY REMEDY SPECIFIED IN THIS AGREEMENT IS DEEMED TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</p>
                        <p className="policy-header">Governing Law</p>
                        <p>The validity of these Terms and the rights, obligations, and relations of the parties under these Terms will be construed and determined under and in accordance with the laws of the state of Delaware without regard to conflicts of law principles.</p>
                        <p className="policy-header">Jurisdiction</p>
                        <p>You expressly agree that exclusive jurisdiction for any dispute with the Service or relating to your use of it, resides in the courts of the state of Delaware and you further agree and expressly consent to the exercise of personal jurisdiction in the courts of the state of Delaware in connection with any such dispute including any claim involving Service. You further agree that you and Service will not commence against the other a class action, class arbitration or other representative action or proceeding.</p>
                        <p className="policy-header">Termination</p>
                        <p>If you breach any of these Terms, we have the right to suspend or disable your access to or use of the Service.</p>
                        <p className="policy-header">Entire Agreement</p>
                        <p>These Terms constitute the entire agreement between you and the Company regarding the use of the Service, superseding any prior agreements between you and the Company relating to your use of the Service.</p>
                        <p className="policy-header">Questions and Contact Information</p>
                        <p>Questions or comments about the Service may be directed to us at the email address support@yosaapp.com.</p>
                    </div>
                </main>

                <footer className="footer">
                  <div className="footer-content">
                    <p>&copy; {currentYear} Yosa Inc. All rights reserved.</p>
                    <nav>
                        <ul>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                        <li><a href="/terms-of-service">Terms of Service</a></li>
                        <li><a href="/baa">Business Associate Agreement</a></li>
                        </ul>
                    </nav>
                    <div className="footer-bottom">
                        <p>
                        <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
                        <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
                            <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
                        </a>
                        </p>
                    </div>
                  </div>
                </footer>
              </div>
            </body>
          </html>
        </>
    )
}

export default TermsOfService;