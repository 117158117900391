import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, useCallback } from 'react';
import { UploadOutlined, LogoutOutlined, UserAddOutlined, DeleteOutlined, AppstoreOutlined, InfoCircleOutlined, ShopOutlined, RightOutlined, EditOutlined, DollarOutlined, ExclamationCircleOutlined, FundViewOutlined, CheckCircleOutlined, LineChartOutlined, FilePdfOutlined, MessageOutlined, MobileOutlined } from '@ant-design/icons';
import { Dropdown, Space, Table, Button, Tooltip, Layout, theme, message, Modal, Form, Upload, Spin, Tabs, Select, Col, Card, Progress, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../styles/Dashboard.css';
import Sidebar from '../components/Sidebar';
import SendAssignmentModal from '../components/SendAssignmentModal';
import AssignmentPreviewModal from '../components/AssignmentPreviewModal';
import FeaturesTabInfoModal from '../components/FeaturesTabInfoModal';
import TourGuide from '../components/TourGuide';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth';
import axios from 'axios';
import { signOut } from 'aws-amplify/auth';
import { deleteUser } from 'aws-amplify/auth';
import dayjs from 'dayjs';
import pmr from '../assets/PMR.png';
import valuesort from '../assets/valuesort.png';
import breathing from '../assets/meditation.png';
import mood from '../assets/mood.png';
import medication from '../assets/medication.png';
import substance from '../assets/substance.png';
import safetyplan from '../assets/safety.png';
import thoughtrecord from '../assets/thoughtrecord.png';
import goals from '../assets/goals.png';
import activitylog from '../assets/activitylog.png';
import exposuretherapy from '../assets/exposuretherapy.png';
import mealdonations from '../assets/meal donations.png';
import '../styles/site.css';
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from 'recharts';

const { Option } = Select;
const { Title } = Typography;

const poolData = {
	UserPoolId: 'us-east-1_2Pbc3zfmr',
	ClientId: '7q641c75ljpv2snpitel88muip',
};

const InputComponent = forwardRef(({ label, required, value, onChange, placeholder, maxLength }, ref) => {
	const inputRef = useRef(null);

	const getValue = useCallback(() => inputRef.current.value, []);

	const setValue = useCallback((value) => {
		inputRef.current.value = value;
	}, []);

	const clearValue = useCallback(() => {
		inputRef.current.value = "";
	}, []);

	useImperativeHandle(ref, () => ({ getValue, setValue, clearValue }), []);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
			<label style={{ marginBottom: '10px', marginRight: '10px', paddingTop: '10px', paddingBottom: '0px' }}>
				{required && <span style={{ color: 'red' }}>*</span>} {label}
			</label>
			<input
				ref={inputRef}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				maxLength={maxLength}
				style={{
					padding: '3px 3px 3px 6px',
					border: '1px solid #d9d9d9',
					borderRadius: '4px',
					width: '100%',
					fontSize: '14px'
				}}
			/>
		</div>
	);
});

const userPool = new CognitoUserPool(poolData);

const authConfig = {
	Cognito: {
		userPoolId: 'us-east-1_2Pbc3zfmr',
		userPoolClientId: '7q641c75ljpv2snpitel88muip'
	}
};

Amplify.configure({
	Auth: authConfig
});

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const Dashboard = () => {
	const [clients, setClients] = useState([]);
	const [customWorksheets, setCustomWorksheets] = useState([]);
	const [savedWorksheets, setSavedWorksheets] = useState([]);
	const [worksheets, setWorksheets] = useState([]);
	const [clientWorksheets, setClientWorksheets] = useState([]);
	const [isWorksheetModalVisible, setIsWorksheetModalVisible] = useState(false);
	const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
	const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
	const emailInputRef = useRef(null);
	const nameInputRef = useRef(null);
	const [newWorksheet, setNewWorksheet] = useState({ name: '', file: null });
	const [newClient, setNewClient] = useState({ name: '', email: '' });
	const [loading, setLoading] = useState(true); // Add loading state
	const [selectedClientKey, setSelectedClientKey] = useState(null);
	const [selectedWorksheets, setSelectedWorksheets] = useState([]);
	//const [selectedWorksheetKey, setSelectedWorksheetKey] = useState([]);
	const [editableClientKey, setEditableClientKey] = useState(null);
	const [selectedOtherAssignments, setSelectedOtherAssignments] = useState([]);
	const [selectedClientEmail, setSelectedClientEmail] = useState(null);
	const [editableClientName, setEditableClientName] = useState('');
	const [isAssignmentModalVisible, setAssignmentModalVisible] = useState(false);
	const [assignmentData, setAssignmentData] = useState([]);
	const [isPdfModalVisible, setIsPdfModalVisible] = useState(false);
	const [pdfUrl, setPdfUrl] = useState('');
	const [isResponseModalVisible, setIsResponseModalVisible] = useState('');
	const [responseContent, setResponseContent] = useState('');
	const [isCombinedResponseModalVisible, setIsCombinedResponseModalVisible] = useState('');
	const [responseData, setResponseData] = useState([]);
	const [isInstructionsModalVisible, setIsInstructionsModalVisible] = useState('');
	const [instructionsData, setInstructionsData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [expandedRowKeys, setExpandedRowKeys] = useState([]);
	const [therapySheets, setTherapySheets] = useState([]);
	const [status, setstatus] = useState('');
	const [tasks, setTasks] = useState([]);
	const [activeTab, setActiveTab] = useState('customWorksheets');
	const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
	const [therapistName, setTherapistName] = useState('');
	const [username, setUsername] = useState('');
	const [isClientsOpen, setIsClientsOpen] = useState(true);
	const [isWorksheetsOpen, setIsWorksheetsOpen] = useState(true);
	const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(true);
	const [timeSelection, setTimeSelection] = useState('month');
	const [statusSelection, setStatusSelection] = useState('all');
	const [therapistEmail, setTherapistEmail] = useState('');
	const [trialExpired, setTrialExpired] = useState(false);

	// API CALLS

	const postWorkSheet = async (username, name, pdfFile) => {
		const formData = new FormData();
		formData.append('username', username);
		formData.append('name', name);
		formData.append('file', pdfFile);

		const axiosConfig = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Add the Authorization header
			},
		};

		const newWorksheet = {
			id: Date.now(), // Temporary ID, replace with actual ID from server
			username,
			name,
			file: pdfFile,
			status: 'Incomplete',
			response: 'Client has not completed the worksheet yet.',
		};

		setWorksheets((prevWorksheets) => [...prevWorksheets, newWorksheet]);

		try {
			await axios.post(`${api}/WorkSheets/`, formData, axiosConfig);
			setRefresh((prev) => !prev);
			window.location.reload();
		} catch (error) {
			console.error('Error posting worksheet:', error);
		}
	};

	const fetchUserDetails = async () => {
		try {
			// Fetch the current authenticated user
			const user = await getCurrentUser();
			if (!user) {
				//console.log('No user is signed in.');
				return null;
			}

			// Fetch user attributes
			const attributes = await fetchUserAttributes(user);


			// Extract username and the custom attribute (therapistName)
			setUsername(user.username);
			////console.log('username', user.username);
			const therapistName = attributes["custom:TherapistName"] || "You don't have a name";
			const therapistEmail = attributes["email"];

			setTherapistEmail(therapistEmail);

			// You can now set the therapist name or use it directly
			setTherapistName(therapistName);

			return {
				username,
				therapistName,
			};
		} catch (err) {
			console.error('Error getting current user:', err);
			throw err;
		}
	};

	const handleSetFeatures = async (username, features) => {
		try {
			const newFeatures = {
				id: Date.now(),
				username,
				features,
			};

			await postFeatures(newFeatures);
		} catch (error) {
			console.error('Error assigning features:', error);
			message.error('Failed to assign features. Please try again.');
		}
	}

	const postFeatures = async (features) => {
		try {
			// POST request to the API endpoint
			const response = await axios.post(`${api}/Features/`, features, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
					'Content-Type': 'application/json',
				},
			});

			// Return the server response
			return response.data;
		} catch (error) {
			console.error("Error posting features:", error);
			throw new Error("Failed to post features. Please try again.");
		}
	}

	const postNotification = async (clientUsername, email, type, name) => {
		const payload = {
			username: clientUsername,
			providerUsername: username,
			name: name,
			email: email,
			completed: false,
			type: type,
			accepted: false,
			rejected: false,
		};

		const axiosConfig = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
			},
		};

		try {
			// prevent duplicate invitations for client from the same provider
			if (type === 'invite') {
				// Fetch all notifications
				const getResponse = await fetch(`${api}/Notifications/`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
					},
				});

				if (getResponse.ok) {
					const notifications = await getResponse.json();

					// Filter notifications that match the specified criteria.
					const filteredNotifications = notifications.filter(notification =>
						notification.email === email &&
						notification.type === 'invite' &&
						notification.providerUsername === username
					);

					// Iterate over each matching notification and delete it individually.
					for (const notification of filteredNotifications) {
						const deleteUrl = `${api}/Notifications/${notification.id}/`;
						const deleteResponse = await fetch(deleteUrl, {
							method: 'DELETE',
							headers: {
								'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
							},
						});
						if (!deleteResponse.ok) {
							console.error(`Failed to delete notification with id ${notification.id}`);
						}
					}
				} else {
					console.error('Failed to fetch notifications for deletion');
				}
			}

			await axios.post(`${api}/Notifications/`, payload, axiosConfig);
		} catch (error) {
			if (error.response) {
				console.error('Error response:', error.response.data);
			} else if (error.request) {
				console.error('Error request:', error.request);
			} else {
				console.error('Error message:', error.message);
			}
		}
	};

	const deleteNotification = async (username, name, worksheetId) => {
		try {
			// Step 1: Fetch all notifications
			const response = await axios.get(`${api}/Notifications/`, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Add the Authorization header
				},
			});
			const notifications = response.data;

			// Step 3: Filter notifications to find the one matching rounded date and username
			const notificationToDelete = notifications.find(
				(notification) => notification.name === name && notification.worksheetId === worksheetId
			);

			if (!notificationToDelete) {

				return;
			}

			// Step 4: Delete the notification using its ID
			await axios.delete(`${api}/Notifications/${notificationToDelete.id}/`, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Add the Authorization header
				},
			});


		} catch (error) {
			console.error('Error deleting notification:', error);
		}
	};

	const findAndDeleteTherapySheet = async (url, name, clients) => {
		try {
			for (const client of clients) {
				// Load worksheets for the current client
				await LoadClientWorksheets(client.email);

				// Filter out the sheets with the specified URL and name
				const matchingSheets = clientWorksheets.filter(
					(sheet) => sheet.file === url && sheet.name === name
				);

				if (matchingSheets.length === 0) {

					continue;
				}

				// Loop through each matching sheet and call deleteTherapySheet
				for (const sheet of matchingSheets) {
					const therapySheetId = sheet.id; // Assuming each sheet has a unique ID
					await deleteTherapySheet(client.email, therapySheetId, name, sheet.worksheetId);
				}

				// Update state to remove the deleted sheets
				setClientWorksheets((prevSheets) =>
					prevSheets.filter((sheet) => !(sheet.file === url && sheet.name === name))
				);
			}
		} catch (error) {
			console.error('Error deleting therapy sheet:', error);
		}
	};

	const deleteTherapySheet = async (email, therapySheetId, name, worksheetId) => {
		try {
			setClientWorksheets((prevSheets) => prevSheets.filter((sheet) => sheet.id !== therapySheetId));
			//const username = await fetchUsernameByEmail(email);

			const url = `${api}/TherapySheets/${therapySheetId}/`;

			// Make the DELETE request
			const response = await axios.delete(url, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Add the Authorization header
				},
			});

			if (response.status === 204) {
				//console.log('Therapy sheet deleted successfully');
				await deleteNotification(username, name, worksheetId);
			} else {
				throw new Error('Failed to delete therapy sheet');
			}
		} catch (error) {
			console.error('Error deleting therapy sheet:', error);
		}
	};

	const fetchUsernameByEmail = async (email) => {
		try {
			// Fetch all users from a new endpoint
			const response = await fetch(`${api}/Users/`, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				},
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error(`Failed to fetch all users: ${response.status} ${response.statusText} - ${errorText}`);
			}

			const data = await response.json();
			// Assume the API returns an array directly or in a field (e.g. data.users)
			const users = Array.isArray(data) ? data : data.users || [];

			// Filter the list for a matching email (case-insensitive)
			const user = users.find(u => u.email.toLowerCase() === email.toLowerCase());

			if (user) {
				return user.username || null;
			} else {
				console.warn(`No username found for email: ${email}`);
				return null;
			}
		} catch (error) {
			console.error('Error fetching username:', error.message || error);
			return null;
		}
	};

	const deleteTherapySheets = async (email) => {
		try {
			// Fetch the username associated with the provided email
			//const username = await fetchUsernameByEmail(email);
			if (!username) {
				throw new Error('Username could not be fetched.');
			}

			// Fetch all therapy sheets associated with the username
			const therapySheetsResponse = await axios.get(`${api}/TherapySheets/`, {
				params: { username },
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Replace with actual auth
				},
			});

			const therapySheets = therapySheetsResponse.data;

			// Iterate through each therapy sheet and delete it
			for (const sheet of therapySheets) {
				const url = `${api}/TherapySheets/${sheet.id}/`;

				const deleteResponse = await axios.delete(url, {
					headers: {
						'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Replace with actual auth
					},
				});

				if (deleteResponse.status === 204) {
					//console.log(`Therapy sheet with ID ${sheet.id} deleted successfully`);
					await deleteNotification(username, sheet.name, sheet.worksheetId); // Ensure this is awaited if it returns a promise
				} else {
					console.error(`Failed to delete therapy sheet with ID ${sheet.id}: ${deleteResponse.statusText}`);
				}
			}

			// Remove the deleted sheets from the state
			setTherapySheets((prevSheets) => prevSheets.filter((sheet) => sheet.username !== username));
		} catch (error) {
			console.error('Error deleting therapy sheets:', error.message || error);
		}
	};

	const LoadCustomWorksheets = async () => {
		setLoading(true); // Assuming you have a loading state to set

		try {
			// Get the current authenticated user


			// Build the URL with the query parameter
			const url = new URL(`${api}/CustomWorksheets/`);
			url.searchParams.append('username', username);

			// Set up the request
			const request = new Request(url, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Set the Authorization header
				},
			});

			// Fetch the data
			const response = await fetch(request);
			if (!response.ok) {
				throw new Error('Failed to fetch custom worksheets');
			}

			// Parse the JSON response
			const data = await response.json();
			setCustomWorksheets(data);
		} catch (error) {
			console.error('Error loading custom worksheets:', error);
		} finally {
			setLoading(false);
		}
	};

	const LoadSavedWorksheets = async () => {
		setLoading(true); // Assuming you have a loading state to set

		try {
			// Get the current authenticated user


			// Build the URL with the query parameter
			const url = new URL(`${api}/SavedWorksheets/`);
			url.searchParams.append('username', username);

			// Set up the request
			const request = new Request(url, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Set the Authorization header
				},
			});

			// Fetch the data
			const response = await fetch(request);
			if (!response.ok) {
				throw new Error('Failed to fetch saved worksheets');
			}

			// Parse the JSON response
			const data = await response.json();
			setSavedWorksheets(data);
		} catch (error) {
			console.error('Error loading saved worksheets:', error);
		} finally {
			setLoading(false);
		}
	};

	const LoadWorksheets = async () => {
		setLoading(true); // Assuming you have a loading state to set

		try {
			// Get the current authenticated user


			// Build the URL with the query parameter
			const url = new URL(`${api}/WorkSheets/`);
			url.searchParams.append('username', username);

			// Set up the request
			const request = new Request(url, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Set the Authorization header
				},
			});

			// Fetch the data
			const response = await fetch(request);
			if (!response.ok) {
				throw new Error('Failed to fetch worksheets');
			}

			// Parse the JSON response
			const data = await response.json();
			setWorksheets(data); // Assuming you have a state setter for worksheets
		} catch (error) {
			console.error('Error loading worksheets:', error);
		} finally {
			setLoading(false);
		}
	};

	const LoadClientWorksheets = async (email) => {
		setLoading(true); // Show loading spinner
		try {
			const response = await fetch(`${api}/Tasks/?email=${email}`, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				},
			});

			if (!response.ok) throw new Error('Failed to fetch tasks');

			const data = await response.json();
			setClientWorksheets(data); // Update state
			return data; // Return the fetched data
		} catch (error) {
			console.error('Error loading tasks:', error);
			return []; // Return an empty array in case of an error
		} finally {
			setLoading(false); // Hide loading spinner
		}
	};

	const fetchClientUsername = async (email) => {
		try {
			// Fetch data from the Users API
			const response = await fetch(`${api}/Users?email=${encodeURIComponent(email)}`);
			if (!response.ok) {
				throw new Error(`Failed to fetch users: ${response.status}`);
			}

			const clientUsername = await fetchUsernameByEmail(email);

			// Parse the JSON response
			//const users = await response.json();

			// Find the user with the matching email
			//const user = users.find((u) => u.email === email);

			// Return the username or null if it doesn't exist
			return clientUsername || null;
		} catch (error) {
			console.error(`Error fetching username for email ${email}:`, error);
			return null;
		}
	};

	const checkRegistrationStatus = async () => {
		if (!clients.length) {
			//console.log("No clients to check registration status for.");
			return;
		}

		setLoading(true); // Show loading indicator

		try {
			const promises = clients.map(async (client) => {
				const clientUsername = await fetchUsernameByEmail(client.email);


				if (clientUsername && client.isRegistered && client.registrationStatus !== 'Registered') { // if disabled, isRegistered is set to false and this doesn't run
					// Change the registration status to registered
					//client.username = username;
					client.isRegistered = true;
					client.clientUsername = clientUsername;
					client.registrationStatus = 'Registered';

					// Update the client data in the API
					const response = await fetch(`${api}/Clients/${client.id}/`, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							...client,
							isRegistered: true,
							clientUsername: clientUsername,
							registrationStatus: 'Registered',
						}),
					});


					if (!response.ok) {
						throw new Error(`Failed to update client ${client.id}: ${response.status}`);

					}

					//console.log(`Updated client ${client.id} to registered.`);
				} else if (clientUsername == null && client.isRegistered) {
					// Change the registration status to unregistered
					client.isRegistered = false;
					client.clientUsername = null;

					// Update the client data in the API
					const response = await fetch(`${api}/Clients/${client.id}/`, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							...client,
							isRegistered: false,
							clientUsername: null,
						}),
					});

					if (!response.ok) {
						throw new Error(`Failed to update client ${client.id}: ${response.status}`);
					}

					//console.log(`Updated client ${client.id} to unregistered.`);
				}
			});

			await Promise.all(promises); // Wait for all promises to resolve
		} catch (error) {
			console.error('Error checking registration status:', error);
		} finally {
			setLoading(false); // Hide loading indicator
		}
	};



	// Set up interval to check registration statuses
	useEffect(() => {
		// Check on load
		checkRegistrationStatus();
		fetchUserDetails();

		if (therapistEmail) {
			checkTrialStatus();
		}
	}, [clients]);

	const checkTrialStatus = async () => {
		try {
			const response = await fetch(`${api}/user-payments?email=${therapistEmail}`);
			const data = await response.json();
			// Assuming one payment record per user
			const userPayment = data[0];
			if (userPayment.trial_expiration) {
				const trialExpiration = new Date(userPayment.trial_expiration);
				if (new Date() > trialExpiration) {
					setTrialExpired(true);
				}
			}
		} catch (error) {
			console.error("Error checking trial status", error);
		}
	};

	useEffect(() => {
		if (expandedRowKeys.length > 0) {
			const expandedKey = expandedRowKeys[0];
			const client = clients.find((c) => c.key === expandedKey);
			if (client) {
				LoadClientWorksheets(client.email);
			}
		}
	}, [tasks, clientWorksheets]);

	useEffect(() => {
		//console.log('Tasks updated:', tasks);
	}, [tasks]);

	useEffect(() => {
		//console.log('Client worksheets updated:', clientWorksheets);
	}, [clientWorksheets]);

	const deleteWorksheet = async (worksheetId, name, url, email) => {
		setIsDeleteModalVisible(false);
		setWorksheetToDelete(null);
		try {
			setWorksheets((prevWorksheets) => prevWorksheets.filter((ws) => ws.id !== worksheetId));

			const response = await fetch(`${api}/WorkSheets/${worksheetId}/`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Use the encoded authorization header
					'Content-Type': 'application/json', // Specify the content type
				},
			});

			if (!response.ok) {
				throw new Error(`Failed to delete worksheet: ${response.statusText}`);
			}

			//console.log('Worksheet deleted successfully');
			await findAndDeleteTherapySheet(url, name, clients);
		} catch (error) {
			console.error('Error deleting worksheet:', error);
		}
	};

	const deleteSavedWorksheet = async (worksheetId) => {
		setIsDeleteModalVisible(false);
		setWorksheetToDelete(null);
		try {
			setSavedWorksheets((prevSavedWorksheets) => prevSavedWorksheets.filter((ws) => ws.id !== worksheetId));

			const response = await fetch(`${api}/SavedWorksheets/${worksheetId}/`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Use the encoded authorization header
					'Content-Type': 'application/json', // Specify the content type
				},
			});

			if (!response.ok) {
				throw new Error(`Failed to delete saved worksheet: ${response.statusText}`);
			}

			//console.log('Saved worksheet deleted successfully');

			await axios.patch(
				`${api}/CommunityWorksheets/${savedWorksheetToDelete.communityId}/`,
				{ numberSaves: Math.max(0, (savedWorksheetToDelete.numberSaves || 0) - 1) }, // Ensure it doesn't go below 0
				{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
					},
				}
			);
		} catch (error) {
			console.error('Error deleting worksheet:', error);
		}
	};

	const defaultFeatures = [
		"journaling",
		"worksheets",
		"help",
		"therapy_journal"
	];

	// Function to delete a client
	const deleteClient = async (clientId, clientEmail) => {
		try {
			// Fetch the provider username for later task deletion
			const clientFetchResponse = await fetch(`${api}/Clients/${clientId}/`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				},
			});

			if (!clientFetchResponse.ok) {
				throw new Error('Failed to fetch client data');
			}

			const clientData = await clientFetchResponse.json();
			const providerUsername = clientData.username;

			// Delete client
			const response = await fetch(`${api}/Clients/${clientId}/`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				},
			});

			if (!response.ok) {
				throw new Error('Failed to delete client');
			}

			setClients((prevClients) => prevClients.filter((client) => client.id !== clientId));
			//console.log('Client deleted successfully');

			// Reset features to default
			const clientUsername = await fetchUsernameByEmail(clientEmail);

			const featureResponse = await fetch(`${api}/Features/?username=${clientUsername}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				},
			});

			if (!featureResponse.ok) {
				throw new Error('Failed to fetch features during reset features process');
			}

			const featureData = await featureResponse.json();
			const existingEntry = featureData[0];

			if (existingEntry) {
				const updateResponse = await fetch(`${api}/Features/${existingEntry.id}/`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
					},
					body: JSON.stringify({
						...existingEntry,
						features: defaultFeatures,
					}),
				});

				if (!updateResponse.ok) {
					throw new Error('Failed to reset features');
				}
			}

			// Delete client's outstanding tasks
			const tasksResponse = await fetch(`${api}/Tasks/?username=${providerUsername}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				},
			});

			if (!tasksResponse.ok) {
				throw new Error('Failed to fetch tasks for deletion');
			}

			const tasksData = await tasksResponse.json();

			// Filter tasks to match username and clientUsername
			const tasksToDelete = tasksData.filter(
				(task) => task.clientUsername === clientUsername && task.username === providerUsername && task.status === "Incomplete"
			);

			// Delete each task
			for (const task of tasksToDelete) {
				const deleteTaskResponse = await fetch(`${api}/Tasks/${task.id}/`, {
					method: 'DELETE',
					headers: {
						'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
					},
				});
				//console.log('deleted task', task);

				if (!deleteTaskResponse.ok) {
					console.warn(`Failed to delete task with ID: ${task.id}`);
				} else {
					//console.log(`Task with ID ${task.id} deleted successfully.`);
				}
			}

			//console.log('All matching tasks deleted.');
		} catch (error) {
			console.error('Error deleting client:', error);
		}
	};

	const postClient = async (name, email) => {
		const formData = new FormData();

		try {


			formData.append('username', username);
			formData.append('name', name);
			formData.append('email', email);
			formData.append('isRegistered', false);
			formData.append('registrationStatus', 'Not Registered');

			const response = await fetch(`${api}/Clients/`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				},
				body: formData,
			});
			////console.log(error);

			if (!response.ok) {
				throw new Error(`Failed to post client: ${response.statusText}`);

			}

			await LoadClients();
			await checkRegistrationStatus();
			setRefresh((prev) => !prev);
		} catch (error) {
			console.error('Error posting client:', error);
			throw error;
		}
	};

	const LoadClients = async () => {
		console.log("called");
		setLoading(true);
		try {
			const response = await fetch(`${api}/Clients/?username=${username}`, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				},
			});

			if (!response.ok) throw new Error('Failed to fetch clients');

			const data = await response.json();
			console.log("Fetched Clients:", data); // 🔹 Debugging
			setClients(data); // ✅ This should update state
		} catch (error) {
			console.error('Error loading clients:', error);
		} finally {
			setLoading(false);
		}
	};

	const postTask = async (task) => {
		try {
			// POST request to the API endpoint
			const response = await axios.post(`${api}/Tasks/`, task, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
					'Content-Type': 'application/json',
				},
			});

			// Return the server response
			return response.data;
		} catch (error) {
			console.error("Error posting task:", error);
			throw new Error("Failed to post task. Please try again.");
		}
	};

	const navigate = useNavigate();
	const goToHome = () => {
		const user = userPool.getCurrentUser();

		if (user) {
			//user.signOut();
			////console.log('user is user')
			//navigate('/');
		} else {
			////console.log('No user is currently logged in.');
			navigate('/');
		}
	};

	const deleteTask = async (taskId) => {
		try {
			// Optimistically remove the task from the frontend
			setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));

			// Make the DELETE request to the backend
			const response = await axios.delete(`${api}/Tasks/${taskId}/`, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Authorization header
				},
			});

			if (response.status === 204) {
				message.success('Task deleted successfully');
			} else {
				throw new Error('Failed to delete task from the backend');
			}
		} catch (error) {
			console.error('Error deleting task:', error);
			message.error('Failed to delete task. Please try again.');

			// Rollback: Reload the tasks if the delete fails
			await loadClientTasks();
		}
	};

	const loadClientTasks = async () => {
		try {

			if (!clients.length) {
				//console.log("No clients available to load tasks for.");
				return;
			}

			const promises = clients.map(async (client) => {
				const email = client.email;
				//console.log("email", email);

				const response = await fetch(
					`${api}/Tasks?email=${encodeURIComponent(email)}`
				);

				if (!response.ok) {
					console.error(`Failed to fetch tasks for email: ${email}`);
					return { clientId: client.id, tasks: [] };
				}

				const tasks = await response.json();

				return { clientId: client.id, tasks };
			});

			const allClientTasks = await Promise.all(promises);

			const removeDuplicates = (tasks) => {
				return tasks.filter((task, index, self) =>
					index === self.findIndex((t) => t.id === task.id)
				);
			};

			const flattenedTasks = allClientTasks.flatMap(({ tasks }) => tasks);
			const uniqueTasks = removeDuplicates(flattenedTasks);
			setTasks(uniqueTasks);
			//console.log(uniqueTasks);
		} catch (error) {
			console.error("Error loading client tasks:", error);
		}
	};

	const loadTasks = async (clientUsername) => {
		try {
			// Make an API call to fetch tasks filtered by clientUsername
			const response = await axios.get(`${api}/Tasks/?username=${encodeURIComponent(clientUsername)}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('access_token')}`,
				},
			});

			if (response.status === 200) {
				return response.data; // Return the tasks for this specific client
			} else {
				throw new Error(`Failed to fetch tasks for username: ${clientUsername}`);
			}
		} catch (error) {
			console.error(`Error loading tasks for username ${clientUsername}:`, error);
			message.error(`Failed to load tasks for username: ${clientUsername}`);
			return []; // Return an empty array if there’s an error
		}
	};

	useEffect(() => {
		// Simulate a delay to fetch data (replace with actual fetch calls)
		setTimeout(() => {
			setLoading(false); // Set loading to false after data is fetched
		}, 1000); // Adjust the delay as needed
	}, []);

	const generateItemsTable = (clientId, clientEmail) => [
		{
			key: `sendAssignment-${clientId}`,
			label: 'Send Assignment',
			onClick: () => handleAssignTask(clientId, clientEmail),
		},
		{
			key: `deleteProfile-${clientId}`,
			label: 'Delete Profile',
			onClick: () => showDeleteConfirmModal(clientId, clientEmail),
		},
	];

	const handleAssignTask = (clientId, clientEmail) => {
		setSelectedClientKey(clientId);
		setSelectedClientEmail(clientEmail);
		setIsAssignModalVisible(true);
	};

	const handlePostTask = async (clientEmail, taskData) => {
		try {
			const clientUsername = await fetchUsernameByEmail(clientEmail);
			if (!clientUsername) {
				message.error('User is not registered. Please ensure the client is registered.');
				return;
			}

			const tasksToPost = taskData.tasks.map((task) => ({
				id: Date.now(),
				username: username,
				clientUsername: clientUsername,
				email: clientEmail,
				type: task.name,
				name: task.name,
				file: task.file || null,
				worksheet: task.worksheet,
				status: 'Incomplete',
				response: '',
				message: task.message,
				shared: 'False',
			}));

			// Optimistically update the frontend with all tasks
			setTasks((prevTasks) => [...prevTasks, ...tasksToPost]);

			// Post each task to the backend
			const postedTasks = await Promise.all(
				tasksToPost.map((newTask) => postTask(newTask))
			);

			// Replace temporary tasks with actual tasks from the backend
			/*setTasks((prevTasks) =>
			prevTasks.map((task) =>
				postedTasks.find((postedTask) => postedTask.name === task.name) || task
			)
			);*/

			message.success('Assigned successfully!');
			sendPushNotification(clientUsername, 'You have a new assignment!')
			.catch((err) => {
			  console.error('Push notification failed, but ignoring:', err);
			});
			LoadClients();
		} catch (error) {
			console.error('Error assigning:', error);
			message.error('Failed to assign. Please try again.');
		}
	};

	useEffect(() => {
		if (username === '') return;
		const loadInitialData = async () => {
			setLoading(true);
			await LoadClients();
		};
		loadInitialData();
	}, [username]);

	useEffect(() => {
		const loadMoreInitialData = async () => {
			await LoadCustomWorksheets();
			await LoadSavedWorksheets();
			await LoadWorksheets();
			await loadClientTasks();
			setLoading(false);
		};
		loadMoreInitialData();
	}, [clients]);

	async function handleDeleteAccount() {
		try {
			await deleteUser();
			navigate('/');
		} catch (error) {
		}
	}

	const handlePostWorkSheet = async () => {

		const name = nameInputRef.current.getValue();
		const { file } = newWorksheet;

		if (!name || !file) {

			message.error('Please enter or reenter a name and select a file.');
			return;
		}


		if (username) {
			await postWorkSheet(username, name, file);
			setSelectedWorksheets(prevWorksheets => [...prevWorksheets, newWorksheet]);

			nameInputRef.current.clearValue();
			setNewWorksheet({ name: '', file: null });
			setIsWorksheetModalVisible(false);
		}
	};



	const handleDeleteClient = (clientId, clientEmail) => {

		deleteClient(clientId, clientEmail);
		//deleteNotifications(clientEmail);
		//deleteTasks(clientEmail);
	};

	const showResponseModal = (task) => {
		setResponseContent(task.response);
		setIsResponseModalVisible(true);
	}

	const handleResponseModalClose = () => {
		setIsResponseModalVisible(false);
		setResponseContent("");
	}

	const openResponseModal = (task) => {
		const combinedData = task.worksheet.map((element) => ({
			...element,
			response: task.response_json?.[element.id] || 'No response provided',
		}));

		// Add the date field as a separate item in the combinedData array
		if (task.response_json?.date) {
			combinedData.push({
				id: 'date',
				type: 'Date',
				value: '',
				response: new Date(task.response_json.date).toLocaleString(), // Format the date for readability
			});
		}

		setResponseData(combinedData); // Combine worksheet, response, and date data
		setIsCombinedResponseModalVisible(true);
	};

	const closeResponseModal = () => {
		setIsCombinedResponseModalVisible(false);
	};

	const openInstructionsModal = (message) => {
		setInstructionsData(message);
		setIsInstructionsModalVisible(true);
	};

	const closeInstructionsModal = () => {
		setIsInstructionsModalVisible(false);
	};

	const openAssignmentModal = (task) => {
		//console.log('Opening modal with task:', task);
		setAssignmentData(task); // Load worksheet elements into state
		setAssignmentModalVisible(true);
	};

	// Close the modal
	const closeAssignmentModal = () => {
		setAssignmentModalVisible(false);
	};

	const expandedRowRender = (record) => {
		const clientTasksList = filterDataByStatus(
			filterDataByTimeRange(
				tasks.filter((task) => task.email === record.email)
			)
		).sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf());
		const taskColumns = [
			{
				title: 'Status',
				dataIndex: 'status',
				key: 'status',
				width: '40px',
				render: (status) => (
					<Tooltip title={status === 'Complete' ? 'Complete' : 'Incomplete'}>
						{status === 'Complete' ? (
							<CheckCircleOutlined style={{ color: '#52c41a', fontSize: '18px' }} />
						) : (
							<ExclamationCircleOutlined style={{ color: '#faad14', fontSize: '18px' }} />
						)}
					</Tooltip>
				),
			},
			{
				title: 'Task Name',
				dataIndex: 'name',
				key: 'name',
				width: '300px',
			},
			{
				title: 'Date',
				dataIndex: 'date',
				key: 'date',
				width: '375px',
				render: (date) => dayjs(date).format('MMMM D, YYYY, h:mm A'),
			},
			{
				title: 'Action',
				key: 'action',
				width: '100px',
				render: (_, task) => (
					<Space>
						{/* Conditional rendering for tasks with files (PDF) */}
						{task.file && (
							<Tooltip title="View PDF">
								<Button
									type="text"
									icon={<FilePdfOutlined style={{ fontSize: '16px', color: '#d32f2f' }} />} // Red for PDF
									onClick={() => openPdfModal(task.file)}
								/>
							</Tooltip>
						)}

						{/* Conditional rendering for tasks with worksheets */}
						{task.worksheet && task.worksheet.length > 0 && (
							<Tooltip title="Preview Assignment">
								<Button
									type="text"
									icon={<MobileOutlined style={{ fontSize: '16px', color: '#28a745' }} />} // Blue for assignments
									onClick={() => openAssignmentModal(task)}
								/>
							</Tooltip>
						)}

						{/* Conditional rendering for tasks with messages */}
						{task.message && (
							<Tooltip title="View Instructions">
								<Button
									type="text"
									icon={<MessageOutlined style={{ fontSize: '16px', color: '#ffa500' }} />} // Orange for messages
									onClick={() => openInstructionsModal(task.message)}
								/>
							</Tooltip>
						)}

						{/* View Chart Button */}
						{task.type === 'PHQ-9' && task.status === 'Complete' && (
							<Tooltip title="View PHQ-9 Chart">
								<Button
									type="text" // Removes button background styling
									icon={<LineChartOutlined style={{ fontSize: '16px', color: '#1890ff' }} />} // Adjust styling as needed
									onClick={() =>
										navigate(`/client/${record.clientUsername}`, {
											state: {
												client: record, // pass the client record
												tasks: tasks.filter((task) => task.email === record.email), // pass only tasks for this client
												activeTab: '2',
												selectedChart: 'phq9',
											},
										})
									}
								/>
							</Tooltip>
						)}
						{task.type === 'GAD-7' && task.status === 'Complete' && (
							<Tooltip title="View GAD-7 Chart">
								<Button
									type="text" // Removes button background styling
									icon={<LineChartOutlined style={{ fontSize: '16px', color: '#1890ff' }} />} // Adjust styling as needed
									onClick={() =>
										navigate(`/client/${record.clientUsername}`, {
											state: {
												client: record, // pass the client record
												tasks: tasks.filter((task) => task.email === record.email), // pass only tasks for this client
												activeTab: '2',
												selectedChart: 'gad7',
											},
										})
									}
								/>
							</Tooltip>
						)}
						{task.type === 'PCL-5' && task.status === 'Complete' && (
							<Tooltip title="View PCL-5 Chart">
								<Button
									type="text" // Removes button background styling
									icon={<LineChartOutlined style={{ fontSize: '16px', color: '#1890ff' }} />} // Adjust styling as needed
									onClick={() =>
										navigate(`/client/${record.clientUsername}`, {
											state: {
												client: record, // pass the client record
												tasks: tasks.filter((task) => task.email === record.email), // pass only tasks for this client
												activeTab: '2',
												selectedChart: 'pcl5',
											},
										})
									}
								/>
							</Tooltip>
						)}
					</Space>
				),
			},
			{
				title: 'Delete',
				key: 'delete',
				render: (_, task) => (
					<Space>
						<Tooltip title="Delete Assignment">
							<Button
								type="text"
								danger
								icon={<DeleteOutlined style={{ fontSize: '16px', color: '#fa541c' }} />} // Softer red
								onClick={() => showDeleteTaskConfirmModal(task)}
							/>
						</Tooltip>
					</Space>
				),
			}
		];

		return (
			<div>
				{/* Filter Button */}
				<div className="chart-wrapper" style={{ flexDirection: 'row' }}>
					<h3 style={{ marginLeft: 29, marginTop: 4.7, marginRight: 10, fontSize: 'large' }}>All Assignments</h3>
					<Select
						style={{ width: '150px', marginLeft: 20, marginBottom: 10 }}
						value={timeSelection}
						onChange={(value) => {
							setTimeSelection(value);
						}}
					>
						<Option value="week">Last Week</Option>
						<Option value="month">Last Month</Option>
						<Option value="year">Last Year</Option>
						<Option value="all">All Time</Option>
					</Select>
					<Select
						style={{ width: '150px', marginLeft: 20, marginBottom: 10 }}
						value={statusSelection}
						onChange={(value) => {
							setStatusSelection(value);
						}}
					>
						<Option value="all">All</Option>
						<Option value="incomplete">Incomplete</Option>
						<Option value="complete">Complete</Option>
					</Select>
				</div>
				<Table columns={taskColumns} dataSource={clientTasksList} pagination={false} showHeader={false} style={{ marginLeft: 20 }} />
			</div>
		);
	};

	const filterDataByTimeRange = (data) => {
		if (!data || data.length === 0) return []; // Handle empty data

		const now = dayjs(); // Current date

		return data.filter((entry) => {
			if (!entry.date) return false; // Ensure date exists
			const entryDate = dayjs(entry.date); // Ensure entry.date is parsed correctly

			switch (timeSelection) {
				case 'week':
					return entryDate.isAfter(now.subtract(1, 'week')); // Fix: Changed to 1 week
				case 'month':
					return entryDate.isAfter(now.subtract(1, 'month'));
				case 'year':
					return entryDate.isAfter(now.subtract(1, 'year'));
				case 'all':
				default:
					return true; // Return all data if 'all' is selected
			}
		});
	};

	const filterDataByStatus = (data) => {
		if (!data || data.length === 0) return []; // Handle empty data

		if (statusSelection === 'all') return data; // Show all tasks

		return data.filter((task) => {
			if (!task.status) return false; // Ensure status exists
			return statusSelection === 'complete' ? task.status === 'Complete' : task.status !== 'Complete';
		});
	};

	const columns = [
		{
			title: 'Status',
			dataIndex: 'registrationStatus',
			key: 'registrationStatus',
			width: 10,
			render: (registrationStatus) =>
				registrationStatus !== 'Registered' ? (
					<Tooltip title={registrationStatus}> {/* Tooltip displays the status on hover */}
						<div
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<ExclamationCircleOutlined
								style={{
									fontSize: '24px',
									color: '#fa541c',
									marginTop: '2px',
								}}
							/>
						</div>
					</Tooltip>
				) : null,
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			onCell: () => ({
				style: {
					maxWidth: '250px', // Set a maximum width
				},
			}),
			render: (text, record) => (
				<a
					style={{ cursor: record.registrationStatus === 'Registered' || record.registrationStatus === 'Disabled' ? 'pointer' : 'auto', color: 'black', textDecoration: 'none' }}
					className='tour-client-name'
					onClick={() => {
						if (record.registrationStatus === 'Registered' || record.registrationStatus === 'Disabled') {
							navigate(`/client/${record.clientUsername}`, {
								state: {
									client: record,
									tasks: tasks.filter((task) => task.email === record.email), // Pass only tasks for this client
									activeTab: '1',
								},
							});
						}
					}}
				>
					{text}
				</a>
			),
		},
		{
			title: 'Action',
			dataIndex: 'operation',
			key: 'operation',
			render: (_, record) => (
				<Space size="small">
					{/* Button to navigate to the client page */}
					<Button
						className={`custom-client-button ${record.registrationStatus === 'Registered' || record.registrationStatus === 'Disabled' ? 'registered' : 'not-registered'
							}`}
						// Disable if not 'Registered' or 'Disabled' -- should still be able to view disabled clients' pages/appropriate data
						disabled={record.registrationStatus !== 'Registered' && record.registrationStatus !== 'Disabled'}
						onClick={() =>
							navigate(`/client/${record.clientUsername}`, {
								state: {
									client: record,
									tasks: tasks.filter((task) => task.email === record.email), // Pass only tasks for this client
									activeTab: '1',
								},
							})
						}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<FundViewOutlined style={{ fontSize: '16px', marginLeft: '1px', marginTop: '2px' }} />
					</Button>

					<Button
						className={`custom-client-button tour-send-assignment ${record.registrationStatus === 'Registered' ? 'registered' : 'not-registered' // disabled clients can't be assigned new tasks
							}`}
						disabled={record.registrationStatus !== 'Registered'} // Disable if not 'Registered'
						onClick={() => handleAssignTask(record.id, record.email)}
					>
						Send Assignment
					</Button>
				</Space>

			),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (email) => (
				<a href={`mailto:${email}`} style={{ textDecoration: 'none', color: 'black' }}>
					{email}
				</a>
			),
		},
		{
			title: 'Delete',
			dataIndex: 'deleteAction',
			key: 'deleteAction',
			render: (_, record) => (
				<Tooltip title="Remove Client">
					<Button
						type="text"
						style={{ color: '#fa541c' }}
						icon={<DeleteOutlined />}
						onClick={() => showDeleteConfirmModal(record.id, record.email)}
					/>
				</Tooltip>
			),
		},
	];

	const columnsWorksheet = worksheets.length > 0 ? [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			onCell: () => ({
				style: {
					maxWidth: '250px', // Set a maximum width
				},
			}),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button
						className={`custom-library-button`}
						onClick={() => openPdfModal(record.file)}
					>
						View File
					</Button>
				</Space>
			),
		},
		{
			title: 'Delete',
			key: 'delete',
			render: (_, record) => (
				<Space size="middle">
					<Button
						type="text"
						style={{ color: '#fa541c' }}
						icon={<DeleteOutlined />}
						onClick={() => handleDeleteWorksheet1(record)}
					/>
				</Space>
			),
		},
	] : [];

	const columnsCustomWorksheets = customWorksheets.length > 0 ? [
		{
			title: 'Name',
			dataIndex: 'title',
			key: 'title',
			onCell: () => ({
				style: {
					maxWidth: '250px', // Set a maximum width
				},
			}),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button
						className={`custom-library-button`}
						onClick={() => {
							navigate('/assignment-builder/', {
								state: { worksheetId: record.id }, // Pass the worksheet ID
							})
						}}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<EditOutlined style={{ fontSize: '16px' }} />
					</Button>

					<Button
						className={`custom-library-button`}
						onClick={() => openAssignmentModal({
							name: record.title, // Pass the record's name
							worksheet: record.elements, // Pass the worksheet data
						})}
					>
						Preview
					</Button>
				</Space>
			),
		},
	] : [];

	const columnsSavedWorksheets = savedWorksheets.length > 0 ? [
		{
			title: 'Name',
			dataIndex: 'title',
			key: 'title',
			onCell: () => ({
				style: {
					maxWidth: '250px', // Set a maximum width
				},
			}),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button
						className={`custom-library-button`}
						onClick={() => openAssignmentModal({
							name: record.title, // Pass the record's name
							worksheet: record.worksheet, // Pass the worksheet data
						})}
					>
						Preview
					</Button>
				</Space>
			),
		},
		{
			title: 'Delete',
			key: 'delete',
			render: (_, record) => (
				<Space size="middle">
					<Button
						type="text"
						style={{ color: '#fa541c' }}
						icon={<DeleteOutlined />}
						onClick={() => handleDeleteSavedWorksheet(record)}
					/>
				</Space>
			),
		},
	] : [];

	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	const handleDeleteWorksheet1 = (worksheet) => {
		setWorksheetToDelete(worksheet);
		setIsDeleteWorksheetModalVisible(true);
	};

	const handleDeleteSavedWorksheet = async (worksheet) => {
		setSavedWorksheetToDelete(worksheet);
		setIsDeleteSavedWorksheetModalVisible(true);
	};

	const sendEmailInvite = async (email, name, therapistName) => {
		//console.log(`Sending email invite to ${email} for ${name}`);

		try {
			const response = await fetch(`${api}/Clients/send-invite/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: email,
					name: name,
					therapist_name: therapistName,
				}),
			});

			if (!response.ok) {
				const errorDetails = await response.json(); // Parse the response body
				console.error('Server response details:', errorDetails);
				throw new Error(`Failed to send email invite: ${response.status}`);
			}

			const data = await response.json();
			//console.log('Email invite sent successfully:', data);

			return data;
		} catch (error) {
			console.error('Error sending email invite:', error);
			throw error; // Rethrow the error for further handling
		}
	};

	const checkClientLimit = async (therapistEmail) => {
		//console.log('therapist email', therapistEmail);
		try {
			// Fetch the therapist's payment plan and client count
			const response = await axios.get(`${api}/user-payments/`, {
				params: { email: therapistEmail }
			});

			if (!response.data) {
				message.error("Failed to retrieve therapist details.");
				return false;
			}

			//console.log(response.data);

			const pricing_plan = response.data[0]?.pricing_plan;

			//console.log(`Pricing plan: ${pricing_plan}`);

			const maxClients = pricing_plan === "free"
				? 4
				: pricing_plan === "starter"
					? 15
					: pricing_plan === "practice"
						? 100
						: 100; // how many clients we allow

			const client_count = clients.length;
			//console.log(`Max clients: ${maxClients}`);

			if (client_count >= maxClients) {
				//message.error(`You have reached the client limit for the ${pricing_plan} plan.`);
				return false;
			}

			return true;
		} catch (error) {
			console.error("Error checking client limit:", error);
			message.error("Error verifying client limit. Please try again.");
			return false;
		}
	};

	const handleInviteOk = async () => {
		const name = nameInputRef.current.getValue();
		const email = emailInputRef.current.getValue();

		if (!name || !email) {
			message.error('Please enter a name and email.');
			return;
		}

		if (clients.some((client) => client.email.toLowerCase() === email.toLowerCase())) {
			message.error('A client with this email is already in your client list.');
			return;
		}
		const withinLimit = await checkClientLimit(therapistEmail);
		if (!withinLimit) {
			message.error('Your plan does not allow this many clients');
			return;
		}

		try {

			const clientUsername = await fetchUsernameByEmail(email); // May return null

			// Use an empty string if username is null to prevent backend errors
			const usernameForBackend = clientUsername || "";

			// Add the client
			await postClient(name, email, usernameForBackend);

			if (!clientUsername) {
				// If client is NOT registered, send an email invite
				//console.log('client email', email);
				await sendEmailInvite(email, name, therapistName);
				await handleSetFeatures(email, selectedFeatures);
				await postNotification(clientUsername, email, 'invite', therapistName);
				message.success(
					'An email regarding onboarding instructions has successfully been sent to your client. Once they register, their status will update.'
				);
			} else {
				// If client IS registered, send an in-app notification
				await postNotification(clientUsername, email, 'invite', therapistName);
				await sendPushNotification(clientUsername, 'You have received a new invite!');
				await handleSetFeatures(clientUsername, selectedFeatures);
				message.success(
					'Your client has already installed Yosa. They will receive an in-app notification to accept your invite. Once they accept, their registration status will be updated in the clients table.'
				);
			}

			// Clear form inputs and close modal
			nameInputRef.current.clearValue();
			emailInputRef.current.clearValue();
			setIsInviteModalVisible(false);
		} catch (error) {
			message.error('Failed to post client.');
			console.error('Error in handleInviteOk:', error);
		}
	};



	


// sendPushNotification.js
 const sendPushNotification = async (username, message) => {
  try {
    // POST to your server with the username + message
    const response = await fetch('https://admin.yosaapp.com/api/send-notification/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, message }),
    });

    if (!response.ok) {
      throw new Error(`Server responded with status ${response.status}`);
    }

    const data = await response.json();
    // Return the server's response (e.g., {"status": "success", ...} or error details)
    return data;
  } catch (err) {
    console.error('Error sending push notification:', err);
    // Rethrow the error so the caller can handle it
    throw err;
  }
};


	const beforeUpload = useCallback((file) => {
		setNewWorksheet({ ...newWorksheet, file });
		return false; // Prevent automatic upload
	}, [newWorksheet]);

	const checkUserAndNavigate = async (goToHome, goToDashboard) => {
		try {
			const { username, userId, signInDetails } = await getCurrentUser();

			return username;
		} catch (err) {
			console.error('Error getting current user:', err);
			throw err;
		}
	};

	const handleCancelWorksheet = useCallback(() => {

		nameInputRef.current.clearValue();
		setNewWorksheet({ name: '', file: null });
		setIsWorksheetModalVisible(false);

	}, []);

	const handleCancelInvite = useCallback(() => {

		nameInputRef.current.clearValue();
		emailInputRef.current.clearValue();
		setIsInviteModalVisible(false);

	}, []);

	const handleCancel = () => {

		setIsAssignModalVisible(false);
		setIsPdfModalVisible(false);

	};

	const handleUpload = (values) => {

		const { name, file } = values;

		Storage.put(name, file).then(resp => {

		}).catch(err => { console.log(err); });



		if (!file || !file.fileList || file.fileList.length === 0) {
			console.error('File is missing or incorrect:', file);
			message.error('Please select a file to upload.');
			return;
		}

		const fileToUpload = file.fileList[0].originFileObj;

		const newWorksheet = {
			key: (worksheets.length + 1).toString(),
			name: name,
			file: fileToUpload // Corrected this line to get the file object
		};

		setWorksheets([...worksheets, newWorksheet]);
		setIsWorksheetModalVisible(false);
	};

	const openPdfModal = (fileUrl) => {
		setPdfUrl(fileUrl);
		setIsPdfModalVisible(true);
	};

	const DEFAULT_FEATURES = ["journaling", "therapy_journal", "worksheets", "help"];

	const OPTIONAL_FEATURES = [
		"breathing_room",
		"pmr",
		"symptom_log",
		"medication",
		"substance_log",
		"safety_plan",
		"value_sort",
		"thought_record",
		"goals",
		"activity_log",
		"exposure_therapy",
		"meal_donations",
	];

	const [selectedFeatures, setSelectedFeatures] = useState([...DEFAULT_FEATURES]);

	const toggleFeatureSelection = (key) => {
		setSelectedFeatures((prev) =>
			prev.includes(key) ? prev.filter((feature) => feature !== key) : [...prev, key]
		);
	};

	const toggleOptionalFeatures = () => {
		setSelectedFeatures((prev) => {
			// Check if every optional feature is already selected
			const allOptionalSelected = OPTIONAL_FEATURES.every(feature => prev.includes(feature));
			if (allOptionalSelected) {
				// Remove all optional features, keep only default features
				return prev.filter(feature => DEFAULT_FEATURES.includes(feature));
			} else {
				// Add all optional features (avoiding duplicates)
				const newOptionalFeatures = OPTIONAL_FEATURES.filter(feature => !prev.includes(feature));
				return [...prev, ...newOptionalFeatures];
			}
		});
	};

	const toggleClientsOpen = () => {
		setIsClientsOpen(!isClientsOpen);
	};

	const toggleWorksheetsOpen = () => {
		setIsWorksheetsOpen(!isWorksheetsOpen);
	};

	const toggleAnalyticsOpen = () => {
		setIsAnalyticsOpen(!isAnalyticsOpen);
	};

	const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
	const [isDeleteTaskModalVisible, setIsDeleteTaskModalVisible] = useState(false);
	const [taskToDelete, setTaskToDelete] = useState(false);
	const showDeleteTaskConfirmModal = (task) => {
		setTaskToDelete(task);
		setIsDeleteTaskModalVisible(true);
	};
	const handleDeleteTaskConfirm = () => {
		const task = taskToDelete;
		deleteTask(task.id);
		setIsDeleteTaskModalVisible(false);
	};
	const handleCancelTaskDelete = () => {
		setIsDeleteTaskModalVisible(false);
	};

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [clientToDelete, setClientToDelete] = useState({ clientId: null, clientEmail: null });
	const showDeleteConfirmModal = (clientId, clientEmail) => {
		setClientToDelete({ clientId, clientEmail });
		setIsDeleteModalVisible(true);
	};
	const handleDeleteConfirm = () => {
		const { clientId, clientEmail } = clientToDelete;
		handleDeleteClient(clientId, clientEmail); // Call your existing delete function
		setIsDeleteModalVisible(false); // Close the modal
	};
	const handleCancelDelete = () => {
		setIsDeleteModalVisible(false); // Close the modal without deleting
	};

	const handleTemplateOk = () => {
		setIsTemplateModalVisible(false);
	};

	const handleTemplateCancel = () => {
		setIsTemplateModalVisible(false);
	};

	const copyToClipboard = () => {
		const text =
			`Welcome To Yosa! 🧠

We’re excited to have you joining us, but first, we need to onboard you to our system!

Yosa isn’t available to the general public; only a select few therapists and clients have access to our platform. 🔒

To ensure the safety of your information and the exclusivity of our services, we have a rather secure onboarding process. So please bear with us! 😅

Follow these steps to properly register:

	1.	Download the iOS app called “Yosa” from the App Store. (Our logo is a brain.)
	2.	Enter the code your therapist provided for you in the given field. (You’ll see what we mean!). Your code is **[ENTER YOUR CLIENT'S CODE HERE]**
	3.	Enter your name, email, and password.
	4.	Verify your email address.
	5.	Sign in with your new credentials.
	6.	Smooth sailing, off you go!

Please don’t hesitate to reach out to support@yosaapp.com with any questions or concerns!

We’re more than happy to have you on board! 🎉`;
		navigator.clipboard.writeText(text).then(() => {
			message.success('Text copied to clipboard');
		}).catch(() => {
			message.error('Failed to copy text');
		});
	};

	const confirmDelete = () => {
		// Perform the delete action here
		deleteWorksheet(worksheetToDelete.id, worksheetToDelete.name, worksheetToDelete.file);
		setIsDeleteWorksheetModalVisible(false);
		setWorksheetToDelete(null);
	};

	const cancelDelete = () => {
		setIsDeleteWorksheetModalVisible(false);
		setWorksheetToDelete(null);
	};

	const confirmSavedDelete = () => {
		// Perform the delete action here
		deleteSavedWorksheet(savedWorksheetToDelete.id);
		setIsDeleteSavedWorksheetModalVisible(false);
		setSavedWorksheetToDelete(null);
	};

	const cancelSavedDelete = () => {
		setIsDeleteSavedWorksheetModalVisible(false);
		setSavedWorksheetToDelete(null);
	};

	const [isDeleteWorksheetModalVisible, setIsDeleteWorksheetModalVisible] = useState(false);
	const [isDeleteSavedWorksheetModalVisible, setIsDeleteSavedWorksheetModalVisible] = useState(false);
	const [worksheetToDelete, setWorksheetToDelete] = useState(null);
	const [savedWorksheetToDelete, setSavedWorksheetToDelete] = useState(null);

	const clientsWithKey = clients.map((client, index) => ({ ...client, key: index }));
	const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] = useState(false);
	const showDeleteConfirmation = () => {
		setIsDeleteAccountModalVisible(true);
	};

	const { Header, Content } = Layout;
	const items = [
		{ key: '1', icon: <UserAddOutlined />, label: <span className="sidebar-add-client">Add Client</span>, onClick: () => setIsInviteModalVisible(true), className: "sidebar-add-client" },
		{ key: '2', icon: <EditOutlined />, label: <span className='sidebar-assignment-builder'>Assignment Builder</span>, onClick: () => navigate('/assignment-builder/'), className: 'sidebar-assignment-builder' },
		{ key: '3', icon: <UploadOutlined />, label: <span className='sidebar-upload-pdf'>Upload PDF</span>, onClick: () => setIsWorksheetModalVisible(true), className: 'sidebar-upload-pdf' },
		{ key: '4', icon: <ShopOutlined />, label: <span className='sidebar-community'>Community</span>, onClick: () => navigate('/community'), className: 'sidebar-community' },
		{
			key: '8', // New key for Assignment Toolbox
			icon: <InfoCircleOutlined />, // Add an appropriate icon
			label: <span className='sidebar-help'>Help</span>, // Label for the new option
			onClick: () => navigate('/assignment-toolbox'), // Navigate to AssignmentToolbox
			className: 'sidebar-help' // Add a class for styling
		},
		{ key: '7', icon: <DollarOutlined />, label: <span className='sidebar-billing'>Billing</span>, onClick: () => navigate('/billing', { state: { therapistEmail } }), className: 'sidebar-billing' },
		{
			key: '5',
			icon: <LogoutOutlined />,
			label: 'Log Out',
			onClick: async () => {
				try {
					await signOut();
					goToHome();
				} catch (error) {
					console.error('Error signing out:', error);
				}
			}
		},
		/*{
			key: '6',
			icon: <DeleteOutlined style={{ color: '#fa541c' }} />,
			label: <span style={{ color: '#fa541c' }}>Delete Account</span>,
			onClick: showDeleteConfirmation,
		},*/
	];

	const {
		token: { colorBgContainer, borderRadiusLG },
	} = theme.useToken();


	if (trialExpired) {
		return (
			<>
				<head>
					<meta charSet="UTF-8" />
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
					<title>Yosa Trial Ended</title>
					<style>
						{`@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');`}
					</style>
				</head>
				<div
					className="trial-expired-screen"
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
						backgroundColor: "#f7f7f7",
						textAlign: "center",
						padding: "20px",
					}}
				>
					<h1>Your trial has ended</h1>
					<p>
						Your free trial period has expired. To continue using our service,
						please choose one of the following subscription options:
					</p>
					<div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
						<Button
							type="primary"
							onClick={() =>
							(window.location.href =
								"https://buy.stripe.com/cN229PgtScnUdNu8wx")
							}
							style={{
								backgroundColor: "#1C3727",
								borderColor: "black",
								color: "white",
							}}
						>
							Starter Level
						</Button>
						<Button
							type="primary"
							onClick={() =>
							(window.location.href =
								"https://buy.stripe.com/bIYdSx6TidrYeRy3cf")
							}
							style={{
								backgroundColor: "#1C3727",
								borderColor: "black",
								color: "white",
							}}
						>
							Practice Level
						</Button>
					</div>
				</div>
			</>
		);
	}

	// todo replace with real data; only for demo purposes
	const totalEarnings = 12345.67; // computed total earnings
	const homeworkCompliance = 85;  // a percentage value, e.g., 85%
	const earningsData = [
		{ period: "Jan-Feb", earnings: 5000 },
		{ period: "Mar-Apr", earnings: 6000 },
		// ... more data for 2-month periods
	];
	const complianceData = [
		{ period: "Week 1-2", compliance: 70 },
		{ period: "Week 3-4", compliance: 75 },
		{ period: "Week 5-6", compliance: 80 },
		// ... more data for 2-week periods
	];

	return (
		<>
			<head>
				<meta charSet='UTF-8' />
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />
				<title>Yosa Provider Dashboard</title>
				<style>
					@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
				</style>
			</head>
			<div className='wholescreen'>
				<Layout>
					<Sidebar items={items} />
					<Layout>
						<Header
							className='header'
							style={{
								padding: 40,
								fontWeight: 'bold',
								fontSize: 25,
								fontFamily: 'Mulish',
								backgroundColor: '#C3D7CB',
								borderBottom: '3px solid #375945',
								color: 'black',
								minWidth: '-moz-max-content',
							}}
						>
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<div>
									{therapistName ? `Hello, ${therapistName}!` : 'Welcome Back!'}
								</div>
								<TourGuide />
							</div>
						</Header>
						<Content
							className="scrollable-content" /* Add class to make content scrollable */
							style={{
								overflowY: 'auto', /* Enable vertical scrolling */
								backgroundColor: 'white',
							}}
						>
							<div className='dashboard-content'>
								<h2 className='dashboard-title'>Dashboard</h2>
								<div className='top-section'>
									<div className='clients-section'>
										<div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
											<div onClick={toggleClientsOpen} className="toggle-icon" style={{ marginTop: '1px' }}>
												<RightOutlined
													className={`arrow-icon ${isClientsOpen ? 'rotate-down' : 'rotate-right'}`}
												/>
											</div>
											<h2 className='tablename clientname' style={{ marginLeft: '4px' }}>My Clients</h2>
											<button className="check-registration-status-button" onClick={() => window.location.reload()}>
												Check Registration Status
											</button>
										</div>
										{isClientsOpen && (
											<Spin spinning={loading}> {/* Add Spin component to show loader */}
												<Table
													className='clients-table'
													columns={columns}
													expandable={{
														expandedRowRender,
														expandIcon: ({ expanded, onExpand, record }) => (
															<div
																onClick={e => onExpand(record, e)}
																className="toggle-icon tour-expand-icon"
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	cursor: 'pointer',
																	marginLeft: '20px',
																	marginTop: '1px',
																}}
															>
																<RightOutlined
																	className={`arrow-icon ${expanded ? 'rotate-down' : 'rotate-right'}`}
																/>
															</div>
														),
														onExpand: async (expanded, record) => {
															if (expanded) {
																if (record.registrationStatus === "Registered" || record.registrationStatus === "Disabled") {
																	await LoadClientWorksheets(record.email); // Wait for data to load
																	setExpandedRowKeys([record.key]);
																}
															} else {
																setExpandedRowKeys([]); // Collapse the row
															}
														},
													}}
													expandedRowKeys={expandedRowKeys}
													dataSource={clientsWithKey}
													pagination={false}
													showHeader={false}
													locale={{
														emptyText: (
															<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
																<div style={{ color: 'red' }}>*</div><p className='nothingtext' style={{ marginRight: '50px' }}>You have no clients. Click "Add Client" on the left sidebar to add your first client! We recommend adding yourself as a client first to see how the app works. As a reminder, the web portal here is for you, the provider, while your clients will use the Yosa mobile app. Click the info icon here for an interactive tour to get started!<TourGuide /></p>
															</div>
														),
													}}
													size="middle"
													scroll={{ x: 'max-content' }}
												/>
											</Spin>
										)}
									</div>
								</div>
								<div className='top-section'>
									<div className='worksheet-section' >
										<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
											{/* Left side: My Library */}
											<div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
												<div onClick={toggleWorksheetsOpen} className="toggle-icon" style={{ marginTop: '1px' }}>
													<RightOutlined
														className={`arrow-icon ${isWorksheetsOpen ? 'rotate-down' : 'rotate-right'}`}
													/>
												</div>
												<h2 className='tablename' style={{ marginLeft: '4px' }}>My Library</h2>
											</div>
											{/* Right side: Custom Tabs */}
											{isWorksheetsOpen && (
												<div className="custom-tabs-container" style={{ display: 'flex', gap: '20px', marginBottom: '22px', marginLeft: '60px' }}>
													<button
														className={`custom-tab ${activeTab === 'customWorksheets' ? 'active' : ''}`}
														onClick={() => handleTabChange('customWorksheets')}
													>
														Assignments
													</button>
													<button
														className={`custom-tab ${activeTab === 'community' ? 'active' : ''}`}
														onClick={() => handleTabChange('community')}
													>
														Community
													</button>
													<button
														className={`custom-tab ${activeTab === 'pdfs' ? 'active' : ''}`}
														onClick={() => handleTabChange('pdfs')}
													>
														PDFs
													</button>
												</div>
											)}
										</div>

										{/* Tab Content */}
										{isWorksheetsOpen && (
											<div className="tab-content">
												{activeTab === 'customWorksheets' && (
													<Table
														className='worksheets-table'
														columns={columnsCustomWorksheets}
														dataSource={customWorksheets.length > 0 ? customWorksheets : []}
														pagination={false}
														showHeader={false}
														locale={{
															emptyText: (
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
																	<p className='nothingtext2' style={{ marginLeft: '7px' }}>
																		Nothing here yet. Visit the Assignment Builder on the sidebar to get started!
																	</p>
																</div>
															),
														}}
														size="middle"
													/>
												)}
												{activeTab === 'community' && (
													<Table
														className='worksheets-table'
														columns={columnsSavedWorksheets}
														dataSource={savedWorksheets.length > 0 ? savedWorksheets : []}
														pagination={false}
														showHeader={false}
														locale={{
															emptyText: (
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
																	<p className='nothingtext2' style={{ marginLeft: '7px' }}>
																		Nothing here yet. Visit Community on the sidebar and save community-built assignments to get started!
																	</p>
																</div>
															),
														}}
														size="middle"
													/>
												)}
												{activeTab === 'pdfs' && (
													<Table
														className='worksheets-table'
														columns={columnsWorksheet}
														dataSource={worksheets.length > 0 ? worksheets : []}
														pagination={false}
														showHeader={false}
														locale={{
															emptyText: (
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
																	<p className='nothingtext2' style={{ marginLeft: '7px' }}>
																		Nothing here yet. Click "Upload PDF" on the sidebar to the left to get started!
																	</p>
																</div>
															),
														}}
														size="middle"
													/>
												)}
											</div>
										)}
									</div>
								</div>
								{/* Tab Content 
								<div className='top-section'>
									<div className='analytics-section' >
										<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
											<div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
												<div onClick={toggleAnalyticsOpen} className="toggle-icon" style={{ marginTop: '1px' }}>
													<RightOutlined
														className={`arrow-icon ${isAnalyticsOpen ? 'rotate-down' : 'rotate-right'}`}
													/>
												</div>
												<h2 className='tablename' style={{ marginLeft: '4px' }}>My Analytics</h2>
											</div>
										</div>

										{isAnalyticsOpen && (
											<div className="tab-content">
												<Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
													<Col span={6}>
														<Card title="Lifetime Earnings" bordered style={{ height: '300px' }}>
															<DollarOutlined style={{ fontSize: '24px', color: '#52c41a' }} />
															<Title level={2}>{totalEarnings.toFixed(2)}</Title>
														</Card>
													</Col>
													<Col span={6}>
														<Card title="Earnings Breakdown" bordered style={{ height: '300px' }}>
															<BarChart
																width={260}
																height={210}
																data={earningsData}
																margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
															>
																<CartesianGrid strokeDasharray="3 3" />
																<XAxis dataKey="period" />
																<YAxis />
																<Tooltip />
																<Bar dataKey="earnings" fill="#59846a" />
															</BarChart>
														</Card>
													</Col>
													<Col span={6}>
														<Card title="Homework Compliance" bordered style={{ height: '300px' }}>
															<Progress
																percent={homeworkCompliance}
																size="small"
																status="active"
																style={{ marginBottom: '10px' }}
																showInfo={false}
																strokeColor={"#59846a"}
															/>
															<Title level={2}>{homeworkCompliance}%</Title>
														</Card>
													</Col>
													<Col span={6}>
														<Card title="Cumulative Homework Compliance" bordered style={{ height: '300px' }}>
															<LineChart
																width={260}
																height={210}
																data={complianceData}
																margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
															>
																<CartesianGrid strokeDasharray="3 3" />
																<XAxis dataKey="period" />
																<YAxis />
																<Tooltip />
																<Line type="monotone" dataKey="compliance" stroke="#59846a" />
															</LineChart>
														</Card>
													</Col>
												</Row>
											</div>
										)}
									</div>
								</div>
								*/}
							</div>
						</Content>
					</Layout>
				</Layout>

				<Modal
					title="Are you sure?"
					open={isDeleteWorksheetModalVisible} // Updated from `visible`
					onCancel={cancelDelete}
					footer={[
						<Button key="cancel" onClick={cancelDelete}>
							Cancel
						</Button>,
						<Button
							key="delete"
							type="primary"
							danger
							onClick={confirmDelete}
						>
							Delete
						</Button>,
					]}
				>
					Deleting this PDF will prevent the client from viewing it in their app. Are you sure you want to proceed?
				</Modal>

				<Modal
					title="Are you sure?"
					open={isDeleteSavedWorksheetModalVisible} // Updated from `visible`
					onCancel={cancelSavedDelete}
					footer={[
						<Button key="cancel" onClick={cancelSavedDelete}>
							Cancel
						</Button>,
						<Button
							key="remove"
							type="primary"
							danger
							onClick={confirmSavedDelete}
						>
							Remove
						</Button>,
					]}
				>
					This saved worksheet will be removed from your library.
				</Modal>

				<AssignmentPreviewModal
					title={assignmentData.name}
					assignmentData={assignmentData.worksheet}
					isVisible={isAssignmentModalVisible}
					onClose={closeAssignmentModal}
				/>

				<Modal
					title="Response Details"
					visible={isCombinedResponseModalVisible}
					onCancel={closeResponseModal}
					footer={[
						<Button key="close" onClick={closeResponseModal}>
							Close
						</Button>,
					]}
				>
					{responseData.map((item, index) => (
						<div key={index} style={{ marginBottom: '10px' }}>
							<strong>{item.type}</strong>: {item.value}
							<br />
							{item.type.toLowerCase() !== 'text' && item.id !== 'date' && (
								<>
									<em>Response:</em> {item.response}
								</>
							)}
							{item.id === 'date' && (
								<>
									{item.response}
								</>
							)}
						</div>
					))}
				</Modal>

				<Modal
					title="Instructions"
					visible={isInstructionsModalVisible}
					onCancel={closeInstructionsModal}
					footer={[
						<Button key="close" onClick={closeInstructionsModal}>
							Close
						</Button>,
					]}
				>
					{instructionsData}
				</Modal>

				<Modal
					title={"Response"}
					visible={isResponseModalVisible}
					onCancel={handleResponseModalClose}
					footer={[
						<Button key="close" onClick={handleResponseModalClose}>
							Close
						</Button>,
					]}
				>
					<p>{responseContent || "No content provided."}</p>
				</Modal>

				<Modal
					title={
						<>
							<div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Upload PDF</div>
							<div style={{ marginTop: '10px' }}>We only accept pdf files (the ones that end in .pdf!) And they have to be under 50MB in size.</div>
							<div style={{ marginTop: '10px', fontWeight: 'normal' }}>Click <strong>Select File</strong> below to get started.</div>
							<div style={{ marginTop: '20px', fontWeight: 'normal' }}>After uploading a PDF you can assign it to a client by clicking <strong>Send Assignment</strong> next to the desired client.</div>
						</>
					}
					visible={isWorksheetModalVisible}
					onCancel={handleCancelWorksheet}
					footer={[
						<Button key="cancel" onClick={handleCancelWorksheet}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							onClick={() => {
								const form = document.getElementById('worksheetForm');
								if (form) {
									form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
								}
							}}
						>
							OK
						</Button>,
					]}
				>
					<Form id="worksheetForm" onFinish={handlePostWorkSheet} layout="vertical">
						<Form.Item >
							<InputComponent label="Name" required ref={nameInputRef}
								placeholder="Write here"
							/>
						</Form.Item>
						<Form.Item label="File" name="file" required>
							<Upload
								beforeUpload={beforeUpload}
								fileList={newWorksheet.file ? [newWorksheet.file] : []}
								onRemove={() => {
									setNewWorksheet({ ...newWorksheet, file: null });
								}}
							>
								<Button icon={<UploadOutlined />}>Select File</Button>
							</Upload>
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit" style={{ display: 'none' }}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Modal>

				<Modal
					title="Are you sure?"
					visible={isDeleteAccountModalVisible}
					onOk={handleDeleteAccount}
					onCancel={() => setIsDeleteAccountModalVisible(false)}
					okText="Delete"
					okButtonProps={{ style: { backgroundColor: 'red', color: 'white' } }}
				>
					Are you sure you want to delete your account? This action cannot be undone.
				</Modal>

				<Modal zIndex={1001}
					title="Copy this text and forward it to your client!"
					visible={isTemplateModalVisible}
					onOk={handleTemplateOk}
					onCancel={handleTemplateCancel}
					okText="Done"
					width={800}>
					<p>
						<strong>Welcome To Yosa! 🧠</strong><br />
						<span style={{ display: 'block', marginTop: '20px' }}>We're excited to have you joining us, but first, we need to onboard you to our system!<br /></span>
						<span style={{ display: 'block', marginTop: '20px' }}>Yosa isn't available to the general public; only a select few therapists and clients
							have access to our platform. 🔒
						</span>
						<span style={{ display: 'block', marginTop: '20px' }}>To ensure the <strong>safety</strong> of your information and the <strong>exclusivity</strong> of our services, we have a rather secure onboarding process. So please bear with us! 😅
						</span>
						<span style={{ display: 'block', marginTop: '20px' }}>Follow these steps to properly register:</span>
					</p>
					<ol>
						<li>Download the iOS app called "Yosa" from the App Store. (Our logo is a brain.) </li>
						<li>Enter the code your therapist provided for you in the given field. (You'll see what we mean!). Your Code is <strong>**[ENTER YOUR CLIENT'S CODE HERE]**</strong></li>
						<li>Enter your name, email, and password.</li>
						<li>Verify your email address.</li>
						<li>Sign in with your new credentials.</li>
						<li>Smooth sailing, off you go!</li>
					</ol>
					<span style={{ display: 'block', marginTop: '20px' }}>Please don't hesitate to reach out to <a href="mailto:support@yosaapp.com">support@yosaapp.com</a> with any questions or concerns!</span>
					<span style={{ display: 'block', marginTop: '20px' }}>We're more than happy to have you on board! 🎉</span>
					<span style={{ display: 'block', marginTop: '20px' }}></span>
					<Tooltip
						title="Click here"
					//Style={{ backgroundColor: 'white', color: 'black' }}
					>
						<Button
							onClick={copyToClipboard}
							style={{ backgroundColor: 'black', color: 'white' }}
						>
							Copy
						</Button>
					</Tooltip>
				</Modal>

				<Modal
					title="Are you sure?"
					open={isDeleteTaskModalVisible} // `open` instead of `visible` for consistency
					onCancel={handleCancelTaskDelete}
					footer={[
						<Button key="cancel" onClick={handleCancelTaskDelete}>
							Cancel
						</Button>,
						<Button
							key="delete"
							type="primary"
							danger
							onClick={handleDeleteTaskConfirm}
						>
							Delete
						</Button>,
					]}
				>
					You or your client may lose access to this task and its associated data.
				</Modal>

				<Modal
					title="Please Read Carefully."
					visible={isDeleteModalVisible}
					onOk={handleDeleteConfirm}
					onCancel={handleCancelDelete}
					okText={<strong>Delete</strong>}
					okButtonProps={{ style: { backgroundColor: '#a00', color: 'white', border: 'none' } }}
				>
					<div style={{ marginBottom: '20px' }}> {/* Add a div with marginBottom for spacing */}
						<p>
							<strong>Are you sure you wish to delete this client?</strong>
							<br />
							<br />
							You won't be able to see your client's work, and any outstanding tasks will be deleted for your client. Your client's non-default features will be removed from their app.
							<br />
							<br />
							If you believe you entered the incorrect email for your client and the client remains <strong>Not Registered</strong> even though your client claims they have properly registered,
							proceed to delete the profile and enter the correct email.
							<br />
							<br />
							If the correct email is entered, the client is registered in the mobile app, and the client has accepted your invitation, the registration status will
							update and you will be able to view their page and send them assignments. If it isn't updating, try reloading the page.
							<br />
							<br />
							If you are unsure how to proceed, please contact <a href="mailto:support@yosaapp.com">support@yosaapp.com</a>.

						</p>
					</div>
				</Modal>

				<FeaturesTabInfoModal
					visible={isInfoModalVisible}
					onClose={() => setIsInfoModalVisible(false)}
				/>


				<Modal zIndex={1000} title="New Client Invite" visible={isInviteModalVisible} onOk={handleInviteOk} onCancel={handleCancelInvite} okText="Send Invite">
					<div>
						<strong>READ CAREFULLY</strong>

						<ol>
							<li><strong>Input Name and Email:</strong> Correctly input the client's name and the  <strong>email they intend to register with</strong> or the
								<strong> email they have already registered with</strong> into the fields below.</li>
							<li><strong>Select Features:</strong> Select the features you would like this client to be able to access. You can edit this at any point in the future.</li>
							<li><strong>Send Invite:</strong> If your client has not downloaded the Yosa app, we will send them an email with instructions guiding them along.
								Otherwise, they will receive an in-app invitation from you which they can accept.</li>
							<li><strong>Client Registration:</strong> Once your client has registered and accepted your invitation, their status will update when you click "Check Registration Status" or refresh the page.</li>
						</ol>

						If you have issues you cannot resolve, please contact <a href="mailto:support@yosaapp.com">support@yosaapp.com</a>.
						<br />
						&nbsp;

					</div>
					<Form layout="vertical">
						<Form.Item required>
							<InputComponent label="Name" required ref={nameInputRef} maxLength={100} placeholder="Enter name here" />
						</Form.Item>
						<Form.Item required>
							<InputComponent label="Email" required ref={emailInputRef} maxLength={254} placeholder="Enter email here" />
						</Form.Item>
						<Form.Item>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<div style={{ marginTop: '5px', fontWeight: 'bold', textAlign: 'center' }}>Select Features This Client Will Have Access To:</div>
								<InfoCircleOutlined
									style={{ marginLeft: "15px", color: "#59846A", cursor: "pointer" }}
									onClick={() => setIsInfoModalVisible(true)}
								/>
							</div>
							<FeaturesTabInfoModal
								visible={isInfoModalVisible}
								onClose={() => setIsInfoModalVisible(false)}
							/>

							{/* Toggle All Button */}
							<Button
								onClick={toggleOptionalFeatures}
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
									border: OPTIONAL_FEATURES.every(feature => selectedFeatures.includes(feature))
										? '2px solid #AC8200'
										: '1px solid #d9d9d9',
									borderRadius: '8px',
									padding: '15px',
									marginTop: '10px',
									marginBottom: '15px',
									width: '200px',
									cursor: 'pointer',
									background: OPTIONAL_FEATURES.every(feature => selectedFeatures.includes(feature))
										? '#FFF8E5'
										: '#fff',
									boxShadow: OPTIONAL_FEATURES.every(feature => selectedFeatures.includes(feature))
										? '0 4px 8px rgba(0, 0, 0, 0.1)'
										: 'none',
									margin: '0 auto', // centers the button horizontally
								}}
							>
								{OPTIONAL_FEATURES.every(feature => selectedFeatures.includes(feature))
									? 'Deselect All'
									: 'Select All'}
							</Button>

							<div
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									gap: '15px',
									justifyContent: 'center', // Center the cards
									marginTop: '10px',
								}}
							>
								{[
									{ label: 'Breathing Room', value: 'breathing_room', image: breathing },
									{ label: 'PMR', value: 'pmr', image: pmr },
									{ label: 'Symptom Log', value: 'symptom_log', image: mood },
									{ label: 'Medication', value: 'medication', image: medication },
									{ label: 'Substance Log', value: 'substance_log', image: substance },
									{ label: 'Safety Plan', value: 'safety_plan', image: safetyplan },
									{ label: 'Value Sort', value: 'value_sort', image: valuesort },
									{ label: 'Thought Record', value: 'thought_record', image: thoughtrecord },
									{ label: 'Goals', value: 'goals', image: goals },
									{ label: 'Activity Log', value: 'activity_log', image: activitylog },
									{ label: 'Exposure Therapy', value: 'exposure_therapy', image: exposuretherapy },
									{ label: 'Journey', value: 'meal_donations', image: mealdonations },
								].map((feature) => (
									<div
										key={feature.value}
										onClick={() => toggleFeatureSelection(feature.value)}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											border: selectedFeatures.includes(feature.value) ? '2px solid #AC8200' : '1px solid #d9d9d9',
											borderRadius: '8px',
											padding: '15px',
											width: '120px',
											cursor: 'pointer',
											background: selectedFeatures.includes(feature.value) ? '#FFF8E5' : '#fff',
											boxShadow: selectedFeatures.includes(feature.value) ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
										}}
									>
										<img
											src={feature.image}
											alt={feature.label}
											style={{
												width: '60px',
												height: '60px',
												objectFit: 'contain', // Preserve aspect ratio and contain within dimensions
												marginBottom: '10px',
												borderRadius: '8px',
											}}
										/>
										<span style={{ fontFamily: 'Mulish', fontWeight: '600', textAlign: 'center' }}>{feature.label}</span>
									</div>
								))}
							</div>
						</Form.Item>


					</Form>
				</Modal>

				<SendAssignmentModal
					isAssignModalVisible={isAssignModalVisible}
					handleCancel={handleCancel}
					customWorksheets={customWorksheets}
					savedWorksheets={savedWorksheets}
					worksheets={worksheets}
					client={
						clients.find((client) => client.id === selectedClientKey) || null
					}
					handlePostTask={(clientEmail, taskData) => {
						const formattedTasks = taskData.tasks.map((task) => {
							const worksheet = worksheets.find((w) => w.name === task.name);
							return {
								...task,
								file: worksheet?.file || null, // Attach the corresponding file if available
							};
						});
						handlePostTask(clientEmail, { tasks: formattedTasks });
					}}
				/>

				<Modal
					title="View PDF"
					visible={isPdfModalVisible}
					onCancel={handleCancel}
					footer={[
						<Button key="close" onClick={handleCancel}>
							Close
						</Button>,
					]}
					width="80%"
					style={{ top: 20 }}
				>
					{pdfUrl && (
						<iframe
							src={pdfUrl}
							style={{ width: '100%', height: '80vh' }}
							frameBorder="0"
						></iframe>
					)}
				</Modal>
			</div>
		</>
	)
};

export default Dashboard;