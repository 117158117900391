import React, { useState, useMemo } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Select } from 'antd';
import dayjs from 'dayjs';

const { Option } = Select;

/**
 * AllDataChart Component
 *
 * Props:
 *  - symptomData: Array from Symptom Log (objects with mood, energy, stress, sleep, socialInteraction)
 *  - gad7Data: Array of objects { date, Score }
 *  - phq9Data: Array of objects { date, Score }
 *  - pcl5Data: Array of objects { date, Score }
 *  - otherData: Array of objects capturing all other data (e.g., medications, journaling, activity logs, etc.)
 *
 * The component merges all data by date and scales the validated measures (GAD-7, PHQ-9, PCL-5)
 * to a 0–100 range. A custom tooltip shows details (from otherData) on hover.
 *
 * Additionally, the user can filter by time range (week, month, year, all)
 * and select which metrics (data lines) to display.
 */
const AllDataChart = ({
  symptomData = [],
  gad7Data = [],
  phq9Data = [],
  pcl5Data = [],
  otherData = [],
}) => {
  // Control states for filtering and selecting metrics
  const [timeRange, setTimeRange] = useState("month");
  const [visibleMetrics, setVisibleMetrics] = useState([
    "mood",
    "energy",
    "stress",
    "sleep",
    "socialInteraction",
    "gad7",
    "phq9",
    "pcl5",
  ]);

  // Mapping for colors and display names
  const metricInfo = {
    mood: { name: "Mood", color: "#FF5733" },
    energy: { name: "Energy", color: "#33C4FF" },
    stress: { name: "Stress", color: "#FFC300" },
    sleep: { name: "Sleep", color: "#33FF57" },
    socialInteraction: { name: "Social Interaction", color: "#8D33FF" },
    gad7: { name: "GAD-7", color: "#4B0082" },
    phq9: { name: "PHQ-9", color: "#a68200" },
    pcl5: { name: "PCL-5", color: "#FF1493" },
  };

  // Merge all data by date.
  const mergedData = useMemo(() => {
    // Helper to format a date string into a consistent format (MM/DD/YYYY)
    const fmt = (d) => dayjs(d, ["M/D/YYYY", "MM/DD/YYYY"]).format("MM/DD/YYYY");

    // Validated measures
    const gadMap = gad7Data.reduce((acc, entry) => {
      acc[fmt(entry.date)] = (entry.Score);
      return acc;
    }, {});
    const phqMap = phq9Data.reduce((acc, entry) => {
      acc[fmt(entry.date)] = (entry.Score);
      return acc;
    }, {});
    const pclMap = pcl5Data.reduce((acc, entry) => {
      acc[fmt(entry.date)] = (entry.Score);
      return acc;
    }, {});

    // Symptom log: assume the values are already on a 0–100 scale.
    const symptomMap = symptomData.reduce((acc, entry) => {
      const dateKey = fmt(entry.date);
      acc[dateKey] = {
        mood: entry.mood ?? null,
        energy: entry.energy ?? null,
        stress: entry.stress ?? null,
        sleep: entry.sleep ?? null,
        socialInteraction: entry.socialInteraction ?? null,
      };
      return acc;
    }, {});

    // "Other" data: for each day, combine all details into an array.
    const otherMap = otherData.reduce((acc, entry) => {
      const dateKey = fmt(entry.date);
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(...(entry.details || []));
      return acc;
    }, {});

    // Collect all unique dates from all sources.
    const allDatesSet = new Set([
      ...Object.keys(symptomMap),
      ...Object.keys(gadMap),
      ...Object.keys(phqMap),
      ...Object.keys(pclMap),
      ...Object.keys(otherMap),
    ]);

    // Build the final merged array.
    const merged = Array.from(allDatesSet).map((dateKey) => {
      const sym = symptomMap[dateKey] || {};
      return {
        date: dateKey,
        mood: sym.mood ?? null,
        energy: sym.energy ?? null,
        stress: sym.stress ?? null,
        sleep: sym.sleep ?? null,
        socialInteraction: sym.socialInteraction ?? null,
        // Validated measures scaled to 0–100.
        gad7: gadMap[dateKey] ?? null,
        phq9: phqMap[dateKey] ?? null,
        pcl5: pclMap[dateKey] ?? null,
        // Other details for tooltip.
        details: otherMap[dateKey] || [],
      };
    });

    // Sort merged data by date ascending.
    merged.sort(
      (a, b) =>
        dayjs(a.date, "MM/DD/YYYY").toDate() - dayjs(b.date, "MM/DD/YYYY").toDate()
    );

    return merged;
  }, [symptomData, gad7Data, phq9Data, pcl5Data, otherData]);

  // Filter merged data based on selected time range.
  const filteredData = useMemo(() => {
    if (timeRange === "all") return mergedData;
    const now = dayjs();
    return mergedData.filter((entry) => {
      const entryDate = dayjs(entry.date, "MM/DD/YYYY");
      if (timeRange === "week") return entryDate.isAfter(now.subtract(1, 'week'));
      if (timeRange === "month") return entryDate.isAfter(now.subtract(1, 'month'));
      if (timeRange === "year") return entryDate.isAfter(now.subtract(1, 'year'));
      return true;
    });
  }, [mergedData, timeRange]);

  const dataWithDummy = filteredData.map((entry) => ({
    ...entry,
    dummyValue: 0, // ensures there's always at least one dataKey with a numeric value
  }));
  

  // Custom tooltip that shows metric values and other details.
  const renderCustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;
    const dataObj = payload[0].payload;
    const lines = [];
    let lastTaskIndex = -1;
    let lastSubstanceIndex = -1;

    dataObj.details.forEach((detail) => {
        if (detail.startsWith("Task:")) {
        // Create a new parent line for Task
        lines.push({ parent: detail, sublines: [] });
        lastTaskIndex = lines.length - 1;
        } else if (detail.startsWith("Substance:")) {
        // Create a new parent line for Substance
        lines.push({ parent: detail, sublines: [] });
        lastSubstanceIndex = lines.length - 1;
        } else if (detail.startsWith("Status:") && lastTaskIndex !== -1) {
        // Sub-bullet under the most recent “Task:”
        lines[lastTaskIndex].sublines.push(detail);
        } else if (detail.startsWith("Amount:") && lastSubstanceIndex !== -1) {
        // Sub-bullet under the most recent “Substance:”
        lines[lastSubstanceIndex].sublines.push(detail);
        } else {
        // Otherwise treat it as a top-level bullet
        lines.push({ parent: detail, sublines: [] });
        }
    });

    return (
      <div
        style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '4px',
        }}
      >
        <strong>{label}</strong>
        <ul style={{ paddingLeft: '18px', marginTop: '5px' }}>
          {payload.map((pl) => (
            <li key={pl.dataKey} style={{ color: pl.stroke }}>
              {metricInfo[pl.dataKey]?.name || pl.dataKey}: {pl.value !== null ? pl.value.toFixed(1) : 'N/A'}
            </li>
          ))}
        </ul>
        {dataObj.details && dataObj.details.length > 0 && (
          <>
            <hr />
            <strong>Other Activity:</strong>
            <ul style={{ paddingLeft: "18px", marginTop: "5px" }}>
                {lines.map((item, idx) => (
                    <li key={idx}>
                    {item.parent}
                    {item.sublines.length > 0 && (
                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                        {item.sublines.map((sub, sIdx) => (
                            <li key={sIdx}>{sub}</li>
                        ))}
                        </ul>
                    )}
                    </li>
                ))}
            </ul>
          </>
        )}
      </div>
    );
  };

  return (
    <div style={{ width: '100%', marginTop: 20 }}>
      <h3 style={{ marginTop: 10, marginBottom: 20, fontWeight: 'bold' }}>All Activity</h3>
      <p style={{ marginBottom: 10 }}>
        A combined view of all data. Hover over a specific date to see more info (substance use, journaling, etc.).
      </p>
      {/* Controls for time range and metrics */}
      <div style={{ marginBottom: 20, display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
        <div>
          <Select value={timeRange} style={{ width: 120 }} onChange={setTimeRange}>
            <Option value="week">Last Week</Option>
            <Option value="month">Last Month</Option>
            <Option value="year">Last Year</Option>
            <Option value="all">All Time</Option>
          </Select>
        </div>
        <div>
          <Select
            mode="multiple"
            allowClear
            style={{ width: 500 }}
            placeholder="Select metrics to display"
            value={visibleMetrics}
            onChange={setVisibleMetrics}
          >
            {Object.keys(metricInfo).map((key) => (
              <Option key={key} value={key}>
                {metricInfo[key].name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      {filteredData.length === 0 ? (
        <p>No data available.</p>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={dataWithDummy} margin={{ top: 10, right: 10, left: 0, bottom: 10 }}>
            <CartesianGrid stroke="#ccc" />
            <XAxis
              dataKey="date"
              tickFormatter={(tick) => dayjs(tick, "MM/DD/YYYY").format("M/D/YY")}
            />
            <YAxis domain={[0, 100]} />
            <Tooltip content={renderCustomTooltip} />
            <Legend />
            {/* Hidden line so Recharts creates a point on every date */}
            <Line
              dataKey="dummyValue"
              stroke="transparent"
              dot={false}
              activeDot={{ r: 5, stroke: "transparent" }}
            />
            {/* Render a Line for each metric if selected */}
            {visibleMetrics.map((metricKey) => (
              <Line
                key={metricKey}
                type="monotone"
                dataKey={metricKey}
                stroke={metricInfo[metricKey]?.color}
                strokeWidth={3}
                name={metricInfo[metricKey]?.name}
                dot={{ r: 5 }}
                isAnimationActive={false}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default AllDataChart;
