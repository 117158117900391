import React, { useEffect, useState } from "react";
import { Modal, Card, Button, message, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import FeaturesTabInfoModal from "./FeaturesTabInfoModal";
// Import feature images
import therapyjournal from "../assets/talk-therapy.png";
import journaling from "../assets/journaling.png";
import breathing from "../assets/meditation.png";
import mood from "../assets/mood.png";
import medication from "../assets/medication.png";
import substance from "../assets/substance.png";
import thoughtrecord from "../assets/thoughtrecord.png";
import goals from "../assets/goals.png";
import activitylog from "../assets/activitylog.png";
import exposuretherapy from "../assets/exposuretherapy.png";
import pmr from "../assets/PMR.png";
import worksheets from "../assets/therapy.png";
import valuesort from "../assets/valuesort.png";
import safetyplan from "../assets/safety.png";
import help from "../assets/help.png";
import mealdonations from "../assets/meal donations.png";
import axios from 'axios';

// List of features
const features = [
  { key: "journaling", title: "Journaling", description: "Self-reflection and expression.", image: journaling },
  { key: "breathing_room", title: "Breathing Room", description: "Guided breathing exercises.", image: breathing },
  { key: "pmr", title: "PMR", description: "Guided Progressive Muscle Relaxation.", image: pmr },
  { key: "symptom_log", title: "Symptom Log", description: "Track mood and well-being.", image: mood },
  { key: "medication", title: "Medication", description: "Track medication intake.", image: medication },
  { key: "substance_log", title: "Substance Log", description: "Monitor substance use.", image: substance },
  { key: "worksheets", title: "Worksheets", description: "Send custom assignments.", image: worksheets },
  { key: "safety_plan", title: "Safety Plan", description: "Plan for crisis situations.", image: safetyplan },
  { key: "therapy_journal", title: "Therapy Journal", description: "Pre- and post-session reflection.", image: therapyjournal },
  { key: "thought_record", title: "Thought Record", description: "Challenge negative thoughts.", image: thoughtrecord },
  { key: "value_sort", title: "Value Sort", description: "Identify important personal values.", image: valuesort },
  { key: "goals", title: "Goals", description: "Set and manage SMART goals.", image: goals },
  { key: "activity_log", title: "Activity Log", description: "Plan and reflect on activities.", image: activitylog },
  { key: "exposure_therapy", title: "Exposure Therapy", description: "Gradual practice to confront fears.", image: exposuretherapy },
  { key: "help", title: "Help", description: "Directory of help hotlines and resources.", image: help },
  { key: "meal_donations", title: "Journey", description: "Gamification: Incentivize clients to complete tasks. The more they use the app, the more meals we donate. Win-win-win-win.", image: mealdonations },
];

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const FeaturesTab = ({ clientUsername, name }) => {
  const [selectedFeatures, setSelectedFeatures] = useState(["journaling", "therapy_journal", "worksheets", "help"]);
  const [tasks, setTasks] = useState([]);
  const [previewFeature, setPreviewFeature] = useState(null);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [currentKey, setCurrentKey] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    LoadFeatures();
    LoadTasks();
  }, []);

  const LoadFeatures = async () => {
    try {
      const username = clientUsername;

      // Fetch features from the API
      const response = await fetch(`${api}/Features/`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Use stored token
        },
      });

      // Check if response is successful
      if (!response.ok) {
        throw new Error('Failed to fetch features');
      }

      // Parse the response data
      const data = await response.json();

      // Find the features for the given username
      const userFeatures = data.find((item) => item.username === username);

      if (userFeatures && userFeatures.features) {
        // Update state with the features list
        setSelectedFeatures(userFeatures.features);
      } else {
        // Default to an empty array if no features are found
        setSelectedFeatures([]);
      }
    } catch (error) {
      console.error('Error loading features:', error);

      // Set an empty state on error to avoid breaking the UI
      setSelectedFeatures([]);
    }
  };

  const LoadTasks = async () => {
    try {
      const username = clientUsername;

      // Fetch tasks from the API
      const response = await fetch(`${api}/Tasks/`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      const data = await response.json();

      if (!Array.isArray(data)) {
        console.error('Unexpected API response format:', data);
        setTasks([]);
        return;
      }

      // Filter for tasks with status "Incomplete"
      const incompleteTasks = data.filter((task) => task.status === 'Incomplete');

      // Remove redundant task types
      const uniqueTasks = Array.from(
        new Map(incompleteTasks.map((task) => [task.type, task])).values()
      );

      // Set the filtered unique tasks
      setTasks(uniqueTasks.map((task) => task.type));
    } catch (error) {
      console.error('Error loading tasks:', error);

      // Set an empty state on error to avoid breaking the UI
      setTasks([]);
    }
  };

  const otherAssignmentMappings = {
    'Therapy Journal Entry (Before)': 'therapy_journal',
    'Therapy Journal Entry (After)': 'therapy_journal',
    'Journal Entry': 'journaling',
    'Breating Exercise': 'breathing_room',
    'Symptom Log': 'symptom_log',
    'Medication': 'medication',
    'Substance Log': 'substance_log',
    'Goals': 'goals',
    'Value Sort': 'value_sort',
    'Safety Plan': 'safety_plan',
    'Activity Log': 'activity_log',
    'Thought Record': 'thought_record',
    'Exposure Therapy': 'exposure_therapy',
  }

  const toggleFeatureSelection = (key) => {
    const lockedFeatures = ["journaling", "therapy_journal", "worksheets", "help"];
    if (lockedFeatures.includes(key)) {
      return;
    }

    if (selectedFeatures.includes(key) && tasks.some((task) => otherAssignmentMappings[task] === key)) {
      setIsModalVisible(true);
      setCurrentKey(key);
      return;
    }

    setSelectedFeatures((prev) =>
      prev.includes(key) ? prev.filter((feature) => feature !== key) : [...prev, key]
    );
  };

  const handleProceed = (key) => {
    setSelectedFeatures((prev) =>
      prev.includes(key) ? prev.filter((feature) => feature !== key) : [...prev, key]
    );
    setIsModalVisible(false);
  };

  const handleSave = async () => {
    try {
      const username = clientUsername;
      if (!username) {
        message.error('User is not registered. Please ensure the client is registered.');
        return;
      }

      const newFeatures = {
        id: Date.now(),
        username,
        features: selectedFeatures,
      };

      await postFeatures(newFeatures);

      message.success('Features updated successfully!');
    } catch (error) {
      console.error('Error assigning features:', error);
      message.error('Failed to assign features. Please try again.');
    }
  }

  const postFeatures = async (features) => {
    try {
      // POST request to the API endpoint
      const response = await axios.post(`${api}/Features/`, features, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json',
        },
      });

      // Return the server response
      return response.data;
    } catch (error) {
      console.error("Error posting features:", error);
      throw new Error("Failed to post features. Please try again.");
    }
  }

  const lockedFeatures = ["journaling", "therapy_journal", "worksheets", "help"];

  return (
    <div style={{ padding: "20px" }}>
      <h2 style={{ textAlign: "center", fontFamily: 'Mulish', fontWeight: '600', color: '#375945' }}>Choose features {name} can access
        <InfoCircleOutlined
          style={{ marginLeft: "15px", color: "#59846A", cursor: "pointer" }}
          onClick={() => setIsInfoModalVisible(true)}
        />
      </h2>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {features.map((feature) => {
          if (!selectedFeatures) {
            return null;
          }
          const isLocked = lockedFeatures.includes(feature.key);
          const cardContent = (
            <Card
              key={feature.key}
              hoverable={!isLocked}
              style={{
                width: 200,
                border: selectedFeatures.includes(feature.key)
                  ? "2px solid #AC8200"
                  : "1px solid #d9d9d9",
                borderRadius: "10px",
                padding: "10px",
                cursor: isLocked ? "not-allowed" : "pointer",
                background: selectedFeatures.includes(feature.key) ? "#FFF8E5" : "#fff",
                boxShadow: selectedFeatures.includes(feature.key)
                  ? "0 4px 8px rgba(0, 0, 0, 0.1)"
                  : "none",
              }}
              onClick={() => toggleFeatureSelection(feature.key)}
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src={feature.image}
                  alt={feature.title}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                    marginBottom: "10px",
                  }}
                />
                <h3 style={{ fontSize: "16px", marginBottom: "5px" }}>{feature.title}</h3>
                <p style={{ fontSize: "12px", color: "#555" }}>{feature.description}</p>
              </div>
            </Card>
          );

          return isLocked ? (
            <Tooltip
              key={feature.key}
              title="This is a default feature all clients have access to. You may not de-select."
            >
              {cardContent}
            </Tooltip>
          ) : (
            cardContent
          );
        })}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%", // Ensures it spans the width of the parent container
            marginTop: "30px", // Adds some spacing between the cards and the button
          }}
        >
          <Button
            style={{
              backgroundColor: '#375945',
              color: '#fff',
              border: 'none',
              borderRadius: '25px',
              padding: '20px 100px', // Adjust padding for button size
              fontFamily: 'Mulish',
              fontWeight: '600',
              fontSize: '18px',
              cursor: 'pointer',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>

      {previewFeature && (
        <Modal
          title={previewFeature.title}
          visible={!!previewFeature}
          onCancel={() => setPreviewFeature(null)}
          footer={[
            <Button key="close" onClick={() => setPreviewFeature(null)}>
              Close
            </Button>,
          ]}
        >
          <p>{previewFeature.description}</p>
        </Modal>
      )}

      <FeaturesTabInfoModal
        visible={isInfoModalVisible}
        onClose={() => setIsInfoModalVisible(false)}
      />

      <Modal
        title="Outstanding Task"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)} // Use a function
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}> {/* Pass function */}
            Cancel
          </Button>,
          <Button key="proceed" type="primary" onClick={() => handleProceed(currentKey)}> {/* Pass function */}
            Yes
          </Button>,
        ]}
      >
        <p>This client has an incomplete task using this feature. Proceed? The client will still be able to complete the task but will not be able to access their response.</p>
      </Modal>
    </div>
  );
};

export default FeaturesTab;
