import React, { useEffect, useState } from "react";
import "../styles/CommunityAssignmentCard.css";
import AssignmentPreviewModal from '../components/AssignmentPreviewModal';
import axios from 'axios';
import { Button, Modal } from 'antd';

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const CommunityAssignmentCard = ({ communityWorksheet, username, isOwned, savedAssignments, reFetch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const areWorksheetsEqual = (worksheet1, worksheet2) => {
    if (!worksheet1 || !worksheet2) return false; // Ensure both exist
    if (worksheet1.length !== worksheet2.length) return false; // Different lengths

    return worksheet1.every((item, index) => {
      const match = worksheet2[index];
      return match && item.type === match.type && item.value === match.value;
    });
  };

  useEffect(() => {
    const isAlreadySaved = savedAssignments.some(saved =>
      areWorksheetsEqual(saved.worksheet, communityWorksheet.worksheet)
    );

    setIsSaved(isAlreadySaved);
  }, [savedAssignments, communityWorksheet]);

  const saveWorksheet = async () => {
    try {
      // save worksheet
      const assignmentToPost = {
        id: Date.now(),
        title: communityWorksheet.title,
        communityId: communityWorksheet.id,
        username: username,
        worksheet: communityWorksheet.worksheet,
      };

      await axios.post(`${api}/SavedWorksheets/`, assignmentToPost, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json',
        },
      });

      // increment saves
      await axios.patch(
        `${api}/CommunityWorksheets/${communityWorksheet.id}/`,
        {
          numberSaves: (communityWorksheet.numberSaves || 0) + 1
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        }
      );

      // reload page
      reFetch();
    } catch (error) {
      console.error('Error sharing with community:', error);
    }
  };

  const removeWorksheet = async () => {
    await axios.delete(`${api}/CommunityWorksheets/${communityWorksheet.id}/`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type': 'application/json',
      },
    });

    // reload page
    reFetch();
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const getTagCategory = (tag) => {
    const modalityTags = ["ABA", "ACT", "CBT", "DBT", "EMDR", "IFS", "MBCT", "SFBT"];
    const demographicTags = ["Teen", "Adult"];
    const topicTags = [
      "Addiction", "ADHD", "Anger Management", "Anxiety", "Behavioral Issues",
      "Bipolar Disorder", "Borderline Personality Disorder", "Coping Skills",
      "Depression", "Dissociative Disorders (DID)", "Eating Disorders", "Grief",
      "Mindfulness", "Obsessive-Compulsive (OCD)", "Relationships", "Self-Esteem",
      "Self-Harm", "Sleep", "Spirituality", "Stress", "Substance Abuse",
      "Trauma and PTSD", "Other"
    ];

    if (modalityTags.includes(tag)) return "tag-modality";
    if (demographicTags.includes(tag)) return "tag-demographic";
    if (topicTags.includes(tag)) return "tag-topic";

    return "";
  };

  return (
    <div className="assignment-card">
      {/* Header Section */}
      <div className="card-header">
        <div className="header-left">
          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
            <h3>{communityWorksheet.title}</h3>
          </div>
          <div style={{ marginTop: '5px' }}>
            {communityWorksheet.numberSaves} save{communityWorksheet.numberSaves !== 1 && 's'}
          </div>
          <div className="header-buttons">
            {/* View Button */}
            <button className="action-item preview-action" onClick={openModal}>
              Preview
            </button>
            {/* Bookmark Button */}
            {!isOwned && (
              isSaved ? (
                <button className="action-item saved">
                  Saved
                </button>
              ) : (
                <button className="action-item save-action" onClick={saveWorksheet}>
                  Save
                </button>
              )
            )}
            {isOwned && (
              <button className="action-item discard-action" onClick={() => setIsDeleteModalOpen(true)}>
                Remove
              </button>
            )}
          </div>
          {/* Tags */}
          <div className="tags">
            {communityWorksheet.tags.map((tag, index) => (
              <span
                key={index}
                className={`tag ${getTagCategory(tag)}`}
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="card-content">
        <p className="assignment-description">{communityWorksheet.description}</p>
      </div>

      <Modal
        title="Are you sure?"
        open={isDeleteModalOpen} // `open` prop controls visibility
        onCancel={() => setIsDeleteModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsDeleteModalOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="remove"
            type="primary"
            danger
            onClick={() => {
              removeWorksheet();
              setIsDeleteModalOpen(false);
            }}
          >
            Yes, Remove
          </Button>,
        ]}
      >
        <p>This assignment will be removed from Community. Your personal assignments will not change. This action cannot be undone.</p>
      </Modal>

      <AssignmentPreviewModal
        title={communityWorksheet.title}
        assignmentData={communityWorksheet.worksheet}
        isVisible={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
};

export default CommunityAssignmentCard;
