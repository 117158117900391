import React, { useState } from 'react';
import { Button, Form, Input, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { RollbackOutlined } from '@ant-design/icons';
import axios from 'axios';
import logincover from '../assets/logincover.png';
import '../styles/SignUp.css';

const poolData = {
    UserPoolId: 'us-east-1_2Pbc3zfmr',
    ClientId: '7q641c75ljpv2snpitel88muip',
};

const userPool = new CognitoUserPool(poolData);

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api/";

const LogIn = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");

    const checkPaymentStatus = async (email) => {
        try {
            const response = await axios.get(`${api}/user-payments/`, {
                params: { email: email }  // ✅ Ensure correct query param
            });
    
            //console.log("📩 Full API Response:", response.data);  // ✅ Log full API response
    
            if (response.data.length === 0) {
                console.warn("⚠️ No payment record found for this email.");
                setErrorMessage("No user found at this email.");
                return false;
            }
    
            const paymentStatus = response.data[0]?.payment_status;  // ✅ Check first result
    
            //console.log("🔍 Extracted Payment Status:", paymentStatus);  // ✅ Log the extracted status
    
            if (paymentStatus !== "active") {
                console.warn("⚠️ Payment not completed. Access denied.");
                setErrorMessage("Your payment is incomplete. Please complete payment before logging in.");
                return false;
            }
    
            //console.log("✅ Payment confirmed as ACTIVE.");
            return true;
    
        } catch (error) {
            console.error("❌ Error verifying payment:", error.response?.data || error.message);
            setErrorMessage("Cannot find account. Ensure the email you entered is correct.");
            return false;
        }
    };

    // Cognito Sign-In Function
    const onSignIn = async (email, password) => {
        try {
            // ✅ Step 1: Check Payment Status Before Proceeding
            const isPaymentActive = await checkPaymentStatus(email);
    
            if (!isPaymentActive) {
                console.warn("⚠️ Payment is not active. Blocking login.");
                return;
            }
    
            //console.log("✅ Payment verified. Proceeding with login.");
    
            const authenticationDetails = new AuthenticationDetails({
                Username: email,
                Password: password,
            });
    
            const userData = {
                Username: email,
                Pool: userPool,
            };
    
            const cognitoUser = new CognitoUser(userData);
    
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: async (result) => {
                    //console.log('✅ Login Success:', result);
                    const idToken = result.getIdToken().getJwtToken();
    
                    try {
                        // ✅ Step 2: Authenticate with Backend
                        const authResponse = await axios.post(`${api}/UserToken/obtain_pair/`, {
                            username: email,
                            id_token: idToken,
                        });
    
                        const { access, refresh } = authResponse.data;
                        localStorage.setItem('access_token', access);
                        localStorage.setItem('refresh_token', refresh);
    
                        // ✅ Step 3: Redirect to dashboard
                        //console.log("✅ Authentication successful. Redirecting...");
                        navigate('/dashboard');
    
                    } catch (backendError) {
                        console.error("❌ Backend authentication error:", backendError);
                        setErrorMessage("Failed to authenticate with server. Please try again.");
                    }
                },
                onFailure: (err) => {
                    console.error('❌ Login Error:', err);
                    setErrorMessage(err.message || 'An error occurred during login.');
                },
            });
    
        } catch (error) {
            console.error('❌ Unexpected Error:', error);
            setErrorMessage('An unexpected error occurred. Please try again.');
        }
    };

    // Form Submission
    const onFinish = (values) => {
        //console.log('Form Submitted:', values);
        onSignIn(values.email, values.password);
    };

    const onFinishFailed = (errorInfo) => {
        //console.log('Form Submission Failed:', errorInfo);
    };

    const goToSignUp = () => {
        navigate('/signup');
    };

    const goToForgotPassword = () => {
        navigate('/forgotpassword');
    };

    return (
        <>
            <head>
                <meta charSet='UTF-8' />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <title>Yosa Home Screen</title>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
                </style>
            </head>
            
            <section
                className="signup-container"
                style={{
                    display: "flex",
                    height: "100vh",
                    fontFamily: "Mulish, sans-serif",
                }}
            >
                {/* Left Side */}
                <div
                    className="signup-left"
                    style={{
                        flex: "1",
                        minWidth: "300px",
                        backgroundColor: "#FFEBA3",
                        padding: "1rem",
                    }}
                >
                    <img
                        id="signupimage"
                        src={logincover}
                        alt="Yosa Cover"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    />
                </div>

                {/* Right Side */}
                <div
                    className="signup-right"
                    style={{
                        flex: "1",
                        maxWidth: "500px",
                        overflowY: "auto",
                        padding: "2.5rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <RollbackOutlined className="payment-back-button" onClick={() => window.history.back()} />
                    <h1 className="signup-title" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                        Log In
                    </h1>
                    <p
                        className="signup-subtitle"
                        style={{ color: "#323232", marginBottom: "2.5rem" }}
                    >
                        Welcome back! Let's get you logged in!
                    </p>

                    <Form
                        name="basic"
                        style={{ width: "100%" }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, message: "Please input your email!" },
                                { type: "email", message: "Please enter a valid email address!" },
                                { max: 254, message: "Email cannot exceed 254 characters!" },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                { required: true, message: "Please input your password!" },
                                { min: 8, message: "Password must be at least 8 characters long!" },
                                { max: 128, message: "Password cannot exceed 128 characters!" },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        {errorMessage && (
                            <div className="error-message" style={{ color: "red", marginBottom: "1rem" }}>
                                {errorMessage}
                            </div>
                        )}

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                                style={{
                                    backgroundColor: "#FFEBA3",
                                    color: "#A68200",
                                    fontWeight: "bold",
                                    borderRadius: "40px",
                                    border: "none",
                                }}
                            >
                                Log In
                            </Button>
                        </Form.Item>
                    </Form>

                    <p
                        className="signup-footer"
                        style={{
                            marginTop: "1rem",
                            fontSize: "0.9rem",
                            color: "#323232",
                            textAlign: "center",
                        }}
                    >
                        Don't have an account?{" "}
                        <a
                            onClick={goToSignUp}
                            style={{ cursor: "pointer", color: "#323232", fontWeight: "bold" }}
                        >
                            Sign Up
                        </a>
                    </p>
                    <p
                        className="signup-footer"
                        style={{
                            fontSize: "0.9rem",
                            color: "#323232",
                            textAlign: "center",
                        }}
                    >
                        Forgot Password?{" "}
                        <a
                            onClick={goToForgotPassword}
                            style={{ cursor: "pointer", color: "#323232", fontWeight: "bold" }}
                        >
                            Click here
                        </a>
                    </p>
                </div>
            </section>
        </>
    );
};

export default LogIn;
