import React from 'react';
import { Modal, Button } from 'antd';
import moodimage from '../assets/moodselection.png'
import sliderimage from '../assets/slidercoloredleft.png'
import '../styles/AssignmentPreviewModal.css';

const AssignmentPreviewModal = ({
	title,
	assignmentData,
	isVisible,
	onClose,
}) => {
	return (
		<Modal
			title="Assignment Preview"
			visible={isVisible}
			onCancel={onClose}
			footer={[
				<Button key="close" onClick={onClose}>
					Close
				</Button>,
			]}
		>
			{
				<div
					style={{
						width: '375px', // Standard phone width
						height: '750px', // Standard phone height
						borderRadius: '30px', // Rounded corners
						border: '5px solid #000', // Thick border for the phone frame
						overflow: 'hidden', // Ensure content stays within the container
						backgroundColor: '#fff', // Background color for the phone
						boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)', // Add shadow for a 3D effect
						margin: 'auto', // Center the phone horizontally
					}}
				>
					<div
						style={{
							padding: '20px', // Add padding to the content inside the phone
							overflowY: 'auto', // Allow vertical scrolling if content overflows
							height: '100%', // Ensure it takes the full height of the phone container
						}}
					>
						{/* Display the title */}
						<h4 className="element-preview-header">
							{title}
						</h4>

						{/* Display the elements if worksheet exists */}
						{assignmentData ? (
							assignmentData.map((element, index) => (
								<div key={index} style={{ marginBottom: '25px' }}> {/* Add margin between elements */}
									{element.type === 'Text' && (
										<p className="element-preview">{element.value}</p>
									)}
									{element.type === 'Text Response' && (
										<div className="element-preview">
											<div style={{ marginBottom: '10px' }}>
												<label>{element.value}</label>
											</div>
											<div>
												<input
													type="text"
													disabled
													placeholder="Type your response"
													style={{ padding: '5px', width: '320px', borderRadius: '4px', borderColor: 'gray', backgroundColor: '#f8f8f8', borderStyle: 'solid', borderWidth: '1px', height: '40px' }}
												/>
											</div>
										</div>
									)}
									{element.type === 'Checkbox Response' && (
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												marginBottom: '10px', // Optional margin for better spacing
											}}
											className="element-preview"
										>
											<div>
												<input
													type="checkbox"
													disabled
													style={{ marginRight: '10px' }}
												/>
											</div>
											<div>
												<label>{element.value}</label>
											</div>
										</div>
									)}
									{element.type === 'Mood Response' && (
										<div className="element-preview">
											<p>{element.value}</p>
											<div>
												<img
													src={moodimage}
													alt="[mood selector]"
													className="moodimage"
													style={{ height: '48px' }} // Set smaller size for the image
												/>
											</div>
										</div>
									)}
									{element.type.startsWith('Slider Response') && (
										<div className="element-preview">
											<div>
												<label>{element.value}</label>
											</div>
											<div>
												<p style={{ width: '100%', textAlign: 'center', color: '#a7a7a7', margin: 0 }}>(0 - {element.type.split(' ')[2]})</p>
												<img
													src={sliderimage}
													alt="[slider]"
													className="sliderimage"
													style={{ height: '18px' }} // Set smaller size for the slider image
												/>
											</div>
										</div>
									)}
								</div>
							))
						) : (
							<p>No worksheet data available.</p>
						)}

						<div
							style={{
								flexDirection: 'row',
								justifyContent: 'center',
								marginTop: '35px',
								display: 'flex', // Flex layout for buttons
							}}
						>
							<div
								style={{
									backgroundColor: '#FFEBA3',
									borderRadius: '10px',
									padding: '15px',
									margin: '0 5px',
									width: '150px',
									height: '50px',
									textAlign: 'center',
									verticalAlign: 'center',
									fontSize: '14px',
									fontFamily: 'Mulish',
									fontWeight: 'bolder',
									color: 'black',
									border: 'none',
									cursor: 'pointer',
								}}
							>
								SAVE
							</div>
							<div
								style={{
									backgroundColor: '#FFEBA3',
									borderRadius: '10px',
									padding: '15px',
									margin: '0 5px',
									width: '150px',
									height: '50px',
									textAlign: 'center',
									verticalAlign: 'center',
									fontSize: '14px',
									fontFamily: 'Mulish',
									fontWeight: 'bolder',
									color: 'black',
									border: 'none',
									cursor: 'pointer',
								}}
							>
								SAVE & SHARE
							</div>
						</div>
					</div>
				</div>
			}
		</Modal>
	);
};

export default AssignmentPreviewModal;
