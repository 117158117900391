import React from 'react';
import { Modal, Button } from 'antd';

const FeaturesTabInfoModal = ({ visible, onClose }) => {
  return (
    <Modal
      title="Choosing features your client can access"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <p>
        The features you select here will determine what this client can see and access on the app. For example, if you select "Medication", "Thought Record", "Goals", and "Journey", your client will only be able to see and interact with those features on their app, in addition to the following default features:
      </p>
      <strong>Default Features</strong>
      <p>Journaling, Therapy Journal, Worksheets, Help</p>
    </Modal>
  );
};

export default FeaturesTabInfoModal;
