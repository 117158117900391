import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import linkedinicon from '../assets/linkedinicon.png';
import { logosGreen } from '../config/logos';
import '../styles/SignUp.css';

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";
const currentEnv = process.env.REACT_APP_ENV || "development";

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const currentYear = new Date().getFullYear();
    
    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };

    const goToSignUp = () => {
        navigate('/signup');
    };

    const goToLogIn = () => {
        navigate('/login');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch(`${api}/api/Waitlist/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
                //console.log("error");
            }

            alert('You successfully joined our mailing list!');
            setEmail('');
        } catch (error) {
            setError('There was a problem submitting your email. Please try again later.');
        }
    };

  const evidenceMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
          </Menu.Item>
        </Menu>
      );

  const aboutMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/About" style={{ textDecoration: 'none' }}>About</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
          </Menu.Item>
        </Menu>
      );

    return (
        <>
            <html>
            <head>
                <meta charSet='UTF-8' />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <title>Yosa Privacy Policy</title>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
                    
                </style>
            </head>
            <body>

            <div className="new-home">

                    {/* Header */}
                    <header className="header">
                      <a href="/" className="logo">
                        <img src={logosGreen[currentEnv]} alt="Yosa Logo" />
                      </a>

                      {/* Hamburger Menu Icon */}
                      <button
                        className={`menu-toggle ${menuOpen ? "open" : ""}`}
                        onClick={toggleMenu}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>

                      <nav className={`navigation ${menuOpen ? "open" : ""}`}>
                        <ul>
                          <li><a className="bold-header-title" style={{ fontWeight: "900" }} href="/">Home</a></li>
                          <li><a href="/Product">Product</a></li>
                          <li>
                            <Dropdown overlay={evidenceMenu} trigger={['hover']}>
                              <a style={{ textDecoration: 'none' }} href="/Evidence">
                                Evidence <DownOutlined />
                              </a>
                            </Dropdown>
                          </li>
                          <li><a href="/Pricing">Pricing</a></li>
                          {/*<li><a href="/Admin">Admin</a></li>*/}
                          <li>
                            <Dropdown overlay={aboutMenu} trigger={['hover']}>
                              <a style={{ textDecoration: 'none' }} href="/About">
                                About <DownOutlined />
                              </a>
                            </Dropdown>
                          </li>
                          <li><a href="/LogIn">Log In</a></li>
                          <li><a href="/SignUp">Sign Up</a></li>
                        </ul>
                      </nav>
                    </header>




                    <main>
                      <h1 className="privacy-title">Privacy Policy</h1>
                      <p className="last-updated">Last Updated: January 30, 2025</p>
                      <div className="content-container">
                        <p>
                          This Privacy Policy describes how Yosa Inc. (“Yosa” or “we” or “us” or
                          “our”) uses and discloses the Personal Information our customers
                          (“Customers” or “you”) provide to us or that we collect when you use our
                          websites, mobile applications, software, platforms and services that we
                          operate and that link to this Privacy Policy (“Services”). Before using
                          the Services or otherwise providing Personal Information to us, please
                          review this Privacy Policy carefully.
                        </p>
                        <p>In summary:</p>
                        <ul>
                          <li>
                            We only use personal information:
                            <ul>
                              <li>
                                For our customers and their clients to be able to use the Yosa
                                website and/or app
                              </li>
                              <li>
                                For our legitimate business purposes (e.g. making general analyses
                                for our own business insights, communicating with you, keeping an
                                administration)
                              </li>
                            </ul>
                          </li>
                          <li>We do not sell your personal information or your clients’ personal information</li>
                          <li>
                            Besides using Amazon Web Services (AWS) to host our data, we do not
                            share your personal information or your clients’ personal information
                            with 3rd parties
                          </li>
                          <li>
                            We have implemented appropriate technical and organizational measures
                            to secure your personal information
                          </li>
                        </ul>
                        <p>
                          This Privacy Policy is intended to inform you of our practices regarding
                          your personal information. By using our Services, you acknowledge and
                          agree to these practices as described herein.
                        </p>

                        <p className="policy-header">1. Introduction</p>
                        <p>
                          Welcome to Yosa, where we empower therapists and their clients through
                          innovative digital solutions designed to enhance the therapeutic process.
                          Our Services, accessible via both our web interface for therapists and a
                          dedicated mobile application for clients, facilitate seamless interaction
                          and management of therapeutic activities and client progress monitoring.
                        </p>
                        <p>
                          This Privacy Policy outlines our commitment to safeguarding the personal
                          and health information you entrust to us. Yosa is committed to protecting
                          your privacy and maintaining compliance with the Health Insurance
                          Portability and Accountability Act (HIPAA) and other relevant privacy
                          laws. We understand the importance of confidentiality and integrity in
                          handling personal health information (PHI) and have implemented robust
                          measures to ensure its security and privacy.
                        </p>
                        <p>
                          At Yosa, we regularly review and update our Privacy Policy to reflect
                          changes in our practices or relevant legal requirements. We encourage you
                          to review this policy periodically to stay informed about how we are
                          protecting the information we collect. By using our Services, you
                          acknowledge and agree to the practices described in this policy.
                        </p>

                        <p className="policy-header">2. Personal Information We Collect</p>
                        <p>
                          “Personal Information” is information that identifies, relates to,
                          describes, is capable of being associated with, or could reasonably be
                          linked, directly or indirectly, with you or your household, such as your
                          name, email address, IP address, telephone number, and broader categories
                          of information such as your professional, educational or health
                          information, commercial information and internet activity. Personal
                          information does not include aggregated or de-identified information
                          maintained in a form that cannot be associated with or linked to a
                          person.
                        </p>
                        <p>
                          We do not collect any Personal Information from third-party sources. The
                          categories of Personal Information we collect from you depend on your
                          interactions with us. For example, we may collect:
                        </p>
                        <ul>
                          <li>
                            Identifiers and contact information, such as your name, email address,
                            contact information, and unique identifiers such as your password. We
                            collect this information directly from you for the purpose of creating
                            and managing your Yosa account (“Account”), for communicating with you,
                            verifying your identity, and providing our Services to you.
                          </li>
                          <li>
                            Health information, such as mood, symptom, medication, and substance
                            use entries. We collect this information directly from you for the
                            purpose of facilitating your own monitoring. We also may collect text
                            entries through journal entries or therapeutic assignments which may
                            include personal or health-related information that may include, but is
                            not limited to, mental health states, personal experiences, and
                            behaviors related to their therapy. We collect this information for the
                            use and documentation by users and it is not shared with third parties
                            besides our hosting provider (AWS), except as required by law.
                          </li>
                          <li>
                            Sensitive personal information, such as your account login information.
                            We collect this information for you to log in and access the Services.
                          </li>
                        </ul>
                        <p className="policy-header">3. How We Use Personal Information</p>
                        <p>
                          In addition to the purposes for collection described above, we also
                          collect and use your Personal Information for the following general
                          purposes:
                        </p>
                        <ul>
                          <li>
                            To maintain and service your Account, including to send you requested
                            product and Service information, and to send you product and Service
                            updates;
                          </li>
                          <li>
                            To respond to your customer service requests and address your questions
                            and concerns;
                          </li>
                          <li>
                            To develop and manage our databases, businesses, and operations;
                          </li>
                          <li>
                            To detect security incidents, to protect against malicious, deceptive,
                            fraudulent or illegal activity, and to comply with our policies and
                            procedures;
                          </li>
                          <li>
                            To comply with our legal, regulatory and risk management obligations,
                            including establishing, exercising and/or defending legal claims;
                          </li>
                          <li>Any other purpose with your consent.</li>
                        </ul>
                        <p>
                          In addition to the purposes for collection described previously, we also
                          collect and use your Personal Information for the following specific
                          purposes:
                        </p>
                        <ul>
                          <li>
                            Treatment Support: While Yosa is not a healthcare provider, the
                            information collected through our Services may be used by therapists to
                            enhance the treatment processes. This includes creating and managing
                            assignments and tracking patient progress.
                          </li>
                          <li>
                            Operational Management: We use Personal Information to manage and
                            operate our Services efficiently. This includes maintaining service
                            continuity, managing user accounts, supporting customer service
                            inquiries, and enhancing overall platform functionality.
                          </li>
                          <li>
                            Payment Processes: Personal Information may be used to process payments, verify
                            transactions, and manage user subscriptions effectively.
                          </li>
                        </ul>
                        <p className="policy-header">4. How We Share and Disclose Personal Information</p>
                        <p>We may share your Personal Information in the following circumstances:</p>
                        <ul>
                          <li>
                            Publicly, but only with your permission: We may share your Personal Information publicly with your permission. For example, with your permission, we may publicly post your photograph, your name, professional titles, and testimonials about using Yosa.
                          </li>
                          <li>
                            To Service Providers: We use Amazon Web Services (AWS) to host our platform and store data. AWS is a trusted cloud service provider known for its robust security measures that comply with industry standards, including HIPAA compliance for eligible services. We have entered into a Business Associate Agreement (BAA) with AWS to ensure that all PHI handled by AWS on our behalf is protected in accordance with HIPAA regulations. This agreement obligates AWS to implement appropriate safeguards to secure your data and restricts their use and disclosure of your information. Your information is stored on servers located in N. Virginia, USA on the secure sites of Amazon Web Services.
                          </li>
                          <li>
                            To parties outside of Yosa:
                            <ul>
                              <li>
                                It is possible we may be required to provide Personal Information to a third party in order to comply with a subpoena, court order, government investigation, or similar legal process.
                              </li>
                              <li>
                                We may also share your Personal Information to third parties, such as law enforcement agencies, when we, in good faith, believe that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.
                              </li>
                              <li>
                                To any other third party for whom you have given your consent for us to share your Personal Information.
                              </li>
                              <li>
                                In a corporate transaction: If Yosa is involved in a corporate transaction, such as a bankruptcy, merger, acquisition, reorganization, or sale of all or a portion of its assets, we may share or transfer your Personal Information as part of any such transaction.
                              </li>
                            </ul>
                          </li>
                        </ul>

                        <p className="policy-header">5. Access and Choice</p>
                        <p>
                          Account Settings: If your Personal Information changes, or if you no longer desire our Services, you may modify or remove it by logging into your Account and making the given changes.
                        </p>
                        <p>
                          Account Deletion: Yosa customers may cancel and delete their Accounts at any time. Before canceling and deleting your account, please export your data using a private internet connection that is password protected. Yosa is not responsible for any lost or stolen data resulting from a customer’s lack of diligence or failure to follow reasonable security protocols during or after the data export process. Please note, if you do not export your data before deleting your account, there may be no way to retrieve the data.
                        </p>
                        <p className="policy-header">6. Data Collection Technologies and Cookies</p>
                        <p>As is true of many digital properties, we and our third-party partners may automatically collect certain information from or in connection with your device when visiting or interacting with our Services, such as:</p>
                        <ul>
                          <li>
                            Log Data, including internet protocol (IP) address, operating system, device type and version, browser type and version, browser ID, the URL visited and the referring page/campaign, date/time of visit, other user agent string data, the time spent on our Services, and any errors that may occur during the visit to our Services. Log data may overlap with the other categories of data below.
                          </li>
                          <li>
                            Analytics Data, including the electronic path you take to our Services, through our Services and when exiting our Services, UTM source, as well as your usage and activity on our Services, such as the time zone, activity information (first and last active date and time), usage history (emails opened, total log-ins) as well as the pages and links you view, click or otherwise interact with.
                          </li>
                          <li>
                            Location Data, such as general geographic location which can be inferred based on your IP address.
                          </li>
                        </ul>
                        <p>
                          We and our third-party Service Providers may use (i) cookies or small data files that are sent to your browser from a web server and stored on your computer’s hard drive and (ii) other, related technologies, such as web beacons, pixels, SDKs, embedded scripts, and data collection technologies (“cookies”) to automatically collect this information. We may use this information to monitor and analyze how you use and interact with our Services.
                        </p>
                        <p>
                          We use information gathered from these technologies so that we can analyze trends, administer the Services, and track users’ movements around the Services.
                        </p>
                        <p>
                          If you would prefer not to accept cookies, most browsers will allow you to change the setting of cookies by adjusting the settings on your browser to: (i) notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) disable existing cookies; or (iii) set your browser to automatically reject cookies. Be aware that disabling cookies may negatively affect the functionality of this and many other websites that you visit. Disabling cookies may result in also disabling certain functionalities and features of the Services.
                        </p>
                        <p>
                          Depending on your device and operating system, you may not be able to delete or block all cookies. In addition, if you want to reject cookies across all your browsers and devices, you will need to do so on each browser on each device you actively use. You may also set your email options to prevent the automatic downloading of images that may contain technologies that would provide us with information about your access to and engagement with the email and its contents.
                        </p>
                        <p>
                          Do Not Track: Please note that our Services are not presently configured to respond to “do not track” or “DNT” signals from web browsers or mobile devices. As such, we do not recognize or respond to Do Not Track requests.
                        </p>

                        <p className="policy-header">7. Retention and Security</p>
                        <p>
                          We will retain your Personal Information for as long as your Account is active, as needed to provide you Services, and as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                        </p>
                        <p>
                          We follow generally accepted standards to protect the Personal Information submitted to us, both during transmission and once we receive it. For example, when you enter sensitive information (such as your login credentials), we encrypt the transmission of that information. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security.
                        </p>
                        <p className="policy-header">8. Data Privacy Statement</p>
                        <p>
                          Residents of certain states have specific rights under their state’s privacy laws. The disclosures and privacy rights below apply to individual residents of the state of California, Colorado, Connecticut, Nevada, Utah, and Virginia.
                        </p>
                        <p>
                          Personal Information Disclosures: In general, within the preceding 12 months:
                        </p>
                        <ul>
                          <li>We have collected the categories of Personal Information listed in Section 2 above.</li>
                          <li>We have collected these categories of Personal Information directly from you, when you use our Services, automatically through data collection technologies for the purposes described in Sections 2 and 3 above.</li>
                          <li>We have disclosed the following categories of Personal Information for business purposes: Identifiers and contact information; electronic and visual information; and internet network activity information.</li>
                          <li>We have not sold your Personal Information.</li>
                        </ul>
                        <p>
                          Data Privacy Rights: Customers who wish to exercise the rights listed below should send an email to <a href="mailto:support@yosaapp.com">support@yosaapp.com</a>.
                        </p>
                        <ul>
                          <li>
                            The right to know. You have the right to request to know (i) the specific pieces of Personal Information we have about you; (ii) the categories of Personal Information we have collected about you in a designated time period; (iii) the categories of sources from which that Personal Information was collected; (iv) the categories of your Personal Information that we sold or disclosed in a designated time period; (v) the categories of third parties to whom your Personal Information was sold or disclosed in a designated time period; and (vi) the purpose for collecting and selling your Personal Information.
                          </li>
                          <li>
                            The right to deletion. You have the right to request that we delete the Personal Information that we, including our third-party Service Providers, have collected or maintain about you. We may deny your request under certain circumstances, such as if we need to comply with our legal obligations or complete a transaction for which your Personal Information was collected. If we deny your request for deletion, we will let you know the reason why.
                          </li>
                          <li>
                            The right to correct. You have the right to request correction of any inaccurate Personal Information we have about you.
                          </li>
                          <li>
                            The right to access and data portability. You have the right to easy and portable access to all pieces of Personal Information that we have collected or maintain about you.
                          </li>
                          <li>
                            The right to opt-in and opt-out of selling your Personal Information. We do not sell your Personal Information.
                          </li>
                          <li>
                            The right to opt-in and opt-out of sharing of your Personal Information for cross-contextual behavioral advertising, otherwise referred to as targeted advertising. We do not use your Personal Information for this purpose.
                          </li>
                          <li>
                            The right to limit use and disclosure of sensitive personal information. You have the right to restrict how we use and disclose your sensitive personal information. We do not use, share, or disclose your sensitive personal information in any way, except as outlined in this privacy policy for the purposes of providing our Services to you. We do not exchange sensitive personal information for targeted advertising, nor for any commercial or monetary purposes.
                          </li>
                          <li>
                            The right to opt-out of profiling based upon personal data. You have the right to opt out of any processing of personal data for the purposes of profiling for decisions that produce legal effects or similarly significant effects on you. We do not use your Personal Information for this purpose.
                          </li>
                          <li>
                            The right to equal service. If you choose to exercise any of these rights, we will not discriminate against you in any way. If you exercise certain rights, understand that you may be unable to use or access certain features of our Services.
                          </li>
                        </ul>
                        <p>
                          We will take steps to verify your identity before processing your privacy rights requests. We will not fulfill your request unless you have provided sufficient information for us to verify you are the individual about whom we collected Personal Information. If you have an Account with us and use our Services, we will use our existing Account authentication practices to verify your identity. If you do not have an Account with us, we may request additional information about you to verify your identity. We will only use the Personal Information provided in the verification process to verify your identity or authority to make a request and to track and document request responses, unless you initially provided the information for another purpose.
                        </p>
                        <p>
                          You may use an authorized agent to submit a privacy rights request. When we verify your agent’s request, we may verify both your and your agent’s identity and request a signed document from you that authorizes your agent to make the request on your behalf. To protect your Personal Information, we reserve the right to deny a request from an agent that does not submit proof that they have been authorized by you to act on their behalf.
                        </p>
                        <p>
                          Appealing Privacy Rights Decisions: You may appeal a decision we have made in connection with your privacy rights request. All appeal requests should be submitted by emailing us at <a href="mailto:support@yosaapp.com">support@yosaapp.com</a> with the subject line “Privacy Request Appeal.”
                        </p>
                        <p>
                          Shine the Light: Our California customers are also entitled to request and obtain from Yosa once per calendar year information about any of your Personal Information shared with third parties for their own direct marketing purposes, including the categories of information and the names and addresses of those businesses with which we have shared such information. We do not use your Personal Information for this purpose. To request this information, please contact us at <a href="mailto:support@yosaapp.com">support@yosaapp.com</a>.
                        </p>

                        <p className="policy-header">9. Additional Information</p>
                        <p>
                          Information for Visitors from Outside of the United States: We are committed to complying with this Privacy Policy and the data protection laws that apply to our collection and use of your Personal Information. We are located in the United States, where the laws may be different and, in some cases, less protective than the laws of other countries. By providing us with your Personal Information and using the Services, you acknowledge that your Personal Information will be transferred to and processed in the United States.
                        </p>
                        <p>
                          Children’s Privacy: The Services we provide to our Customers are not directed to, nor do we knowingly collect any Personal Information from children under 13. If we learn that we have received any Personal Information directly from a child under the age of 13 without first receiving the child’s parent’s verified consent, we will use that Personal Information only to respond directly to that child (or the parent or legal guardian) to inform the child that he or she cannot use the Services. We will then subsequently delete that child’s Personal Information. If you believe that a child under 13 may have provided us with Personal Information, please contact us at <a href="mailto:support@yosaapp.com">support@yosaapp.com</a>.
                        </p>
                        <p>
                          Changes to this Policy: We regularly review and update our Privacy Policy to reflect changes in our practices or in legal requirements. The current version number and the date of the latest revision will be displayed prominently at the top of the Privacy Policy. Any significant changes will be announced through an in-app notification and a visible banner on our website, especially on the Privacy Policy page itself. We encourage you to review the Privacy Policy regularly to stay informed of any updates. Please note that continued use of our services after any changes signifies your acceptance of these changes.
                        </p>

                        <p className="policy-header">10. Contact Us</p>
                        <p>
                          For help with matters not related to exercising your rights under the aforementioned privacy laws, please contact us at <a href="mailto:support@yosaapp.com">support@yosaapp.com</a>.
                        </p>
                      </div>
                    </main>


              <footer className="footer">
                <div className="footer-content">
                  <p>&copy; {currentYear} Yosa Inc. All rights reserved.</p>
                  <nav>
                    <ul>
                      <li><a href="/privacy-policy">Privacy Policy</a></li>
                      <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                      <li><a href="/terms-of-service">Terms of Service</a></li>
                      <li><a href="/baa">Business Associate Agreement</a></li>
                    </ul>
                  </nav>
                  <div className="footer-bottom">
                    <p>
                      <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
                      <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
                        <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
                      </a>
                    </p>
                  </div>
                </div>
              </footer>
            </div>
          </body>
        </html>
      </>
    )
}

export default PrivacyPolicy;