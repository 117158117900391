import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import logincover from '../assets/logincover.png';
import { resetPassword } from 'aws-amplify/auth';
import { RollbackOutlined } from '@ant-design/icons';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");

    async function handleResetPassword(username) {
        try {
            const output = await resetPassword({ username });
            handleResetPasswordNextSteps(output, username);
        } catch (error) {
            console.error(error);
            setErrorMessage(error.message || 'An error occurred during reset password.');
        }
    }

    function handleResetPasswordNextSteps(output, email) {
        const { nextStep } = output;
        switch (nextStep.resetPasswordStep) {
            case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                //console.log(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`);
                goToConfirmResetPassword(email);
                break;
            case 'DONE':
                //console.log('Successfully reset password.');
                break;
            default:
                console.error('Unknown step:', nextStep);
                setErrorMessage('An unknown error occurred.');
                break;
        }
    }

    const onFinish = (values) => {
        handleResetPassword(values.email);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Form submission failed:', errorInfo);
        setErrorMessage('Please fill in all required fields.');
    };

    const goToLogin = () => {
        navigate('/login');
    };

    const goToConfirmResetPassword = (email) => {
        navigate('/confirmresetpassword', { state: { email } });
    };

    return (
        <>
            <section
                style={{
                    display: "flex",
                    height: "100vh",
                    fontFamily: "Mulish, sans-serif",
                }}
            >
                {/* Left Section */}
                <div
                    style={{
                        flex: "1",
                        minWidth: "300px",
                        backgroundColor: "#FFEBA3",
                        padding: "1rem",
                    }}
                >
                    <img
                        src={logincover}
                        alt="Yosa Cover"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    />
                </div>

                {/* Right Section */}
                <div
                    style={{
                        flex: "1",
                        maxWidth: "500px",
                        overflowY: "auto",
                        padding: "2.5rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <RollbackOutlined className="payment-back-button" onClick={() => window.history.back()} />
                    <h1 style={{ fontSize: "2rem", fontWeight: "bold" }}>Forgot Password</h1>
                    <p style={{ color: "#323232", marginBottom: "2.5rem" }}>
                        Enter your email to reset your password.
                    </p>

                    <Form
                        name="forgot-password-form"
                        style={{ width: "100%" }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your email!",
                                },
                                {
                                    type: "email",
                                    message: "Please enter a valid email address!",
                                },
                                {
                                    max: 254,
                                    message: "Email cannot exceed 254 characters!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        {errorMessage && (
                            <div style={{ color: "red", marginBottom: "1rem" }}>{errorMessage}</div>
                        )}

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                                style={{
                                    backgroundColor: "#FFEBA3",
                                    color: "#A68200",
                                    fontWeight: "bold",
                                    borderRadius: "40px",
                                    border: "none",
                                }}
                            >
                                Reset Password
                            </Button>
                        </Form.Item>
                    </Form>

                    <p style={{ marginTop: "1rem", fontSize: "0.9rem", color: "#323232" }}>
                        Remembered your password?{" "}
                        <a
                            onClick={goToLogin}
                            style={{ cursor: "pointer", color: "#323232", fontWeight: "bold" }}
                        >
                            Log in
                        </a>
                    </p>
                </div>
            </section>
        </>
    );
};

export default ForgotPassword;