import yosaLogo from '../assets/yosalogo.png';
import yosaLogoStaging from '../assets/yosalogostaging.png';
import yosaLogoDev from '../assets/yosalogodev.png';
import yosaLogoWhite from '../assets/yosalogowhite.png';
import yosaLogoWhiteStaging from '../assets/yosalogowhitestaging.png';
import yosaLogoWhiteDev from '../assets/yosalogowhitedev.png';
import yosaLogoGreen from '../assets/yosalogogreen.png';
import yosaLogoGreenStaging from '../assets/yosalogogreenstaging.png';
import yosaLogoGreenDev from '../assets/yosalogogreendev.png';

const logos = {
    production: yosaLogo,
    staging: yosaLogoStaging,
    development: yosaLogoDev
};

const logosWhite = {
    production: yosaLogoWhite,
    staging: yosaLogoWhiteStaging,
    development: yosaLogoWhiteDev
};

const logosGreen = {
    production: yosaLogoGreen,
    staging: yosaLogoGreenStaging,
    development: yosaLogoGreenDev
};

export { logos, logosWhite, logosGreen };