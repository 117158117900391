import React from 'react';
import {
    DeleteOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
} from '@ant-design/icons';
import '../styles/ElementEditor.css';
import moodSelection from '../assets/moodselection.png';
import slider from '../assets/slider.png';

const maxCharsLong = 2500;
const maxCharsShort = 1000;

const ElementEditor = ({
    element,
    index,
    onDelete,
    onMoveUp,
    onMoveDown,
    onChange,
    isFirst,
    isLast,
}) => {
    const renderEditor = () => {
        switch (element.type) {
            case 'Text':
                return (
                    <div className="element-editor">
                        <textarea
                            defaultValue={element.value || ''}
                            placeholder={`Enter ${element.type}`}
                            onChange={(e) => onChange(index, e.target.value)}
                            className="element-input-full-ta"
                            maxLength={maxCharsLong}
                        />
                    </div>
                );
            case 'Text Response':
                return (
                    <div className="element-editor">
                        <textarea
                            defaultValue={element.value || ''}
                            placeholder={`Enter ${element.type} Instructions`}
                            onChange={(e) => onChange(index, e.target.value)}
                            className="element-input-full-ta"
                            maxLength={maxCharsShort}
                        />
                        <input
                            type="text"
                            disabled
                            className="element-input-full"
                        />
                    </div>
                );
            case 'Mood Response':
                return (
                    <div className="element-editor">
                        <textarea
                            defaultValue={element.value || ''}
                            placeholder={`Enter ${element.type} Instructions`}
                            onChange={(e) => onChange(index, e.target.value)}
                            className="element-input-full-ta"
                            maxLength={maxCharsShort}
                        />
                        <img src={moodSelection} alt="Mood Selection" className="element-image-mood" />
                    </div>
                );
            case 'Slider Response 5':
                return (
                    <div className="element-editor">
                        <textarea
                            defaultValue={element.value || ''}
                            placeholder={`Enter ${element.type} Instructions`}
                            onChange={(e) => onChange(index, e.target.value)}
                            className="element-input-full-ta"
                            maxLength={maxCharsShort}
                        />
                        <div className="slider-text-container">
                            <div className="slider-text">5</div>
                        </div>
                        <img src={slider} alt="Slider" className="element-image-slider" />
                    </div>
                );
            case 'Slider Response 10':
                return (
                    <div className="element-editor">
                        <textarea
                            defaultValue={element.value || ''}
                            placeholder={`Enter ${element.type} Instructions`}
                            onChange={(e) => onChange(index, e.target.value)}
                            className="element-input-full-ta"
                            maxLength={maxCharsShort}
                        />
                        <div className="slider-text-container">
                            <div className="slider-text">10</div>
                        </div>
                        <img src={slider} alt="Slider" className="element-image-slider" />
                    </div>
                );
            case 'Slider Response 100':
                return (
                    <div className="element-editor">
                        <textarea
                            defaultValue={element.value || ''}
                            placeholder={`Enter ${element.type} Instructions`}
                            onChange={(e) => onChange(index, e.target.value)}
                            className="element-input-full-ta"
                            maxLength={maxCharsShort}
                        />
                        <div className="slider-text-container">
                            <div className="slider-text">100</div>
                        </div>
                        <img src={slider} alt="Slider" className="element-image-slider" />
                    </div>
                );
            case 'Checkbox Response':
                return (
                    <div className="element-editor-checkbox">
                        <input
                            type="checkbox"
                            disabled
                            placeholder={`[User Number]`}
                            className="element-input-full"
                            style={{ width: '5%' }}
                            maxLength={maxCharsShort}
                        />
                        <textarea
                            defaultValue={element.value || ''}
                            placeholder={`Enter Checkbox Description`}
                            onChange={(e) => onChange(index, e.target.value)}
                            className="element-input-full-ta"
                            style={{ width: '83.5%', minWidth: '100px', height: '42px' }}
                        />
                    </div>
                );
            default:
                return <p>{element.value || 'Error. Contact support or try again.'}</p>;
        }
    };

    return (
        <div className="element-list-item">
            {renderEditor()}
            <div className="element-actions">
                <DeleteOutlined
                    className="delete-icon-2"
                    onClick={() => onDelete(index)}
                />
                {!isFirst && (
                    <ArrowUpOutlined
                        className="move-up-icon"
                        onClick={() => onMoveUp(index)}
                    />
                )}
                {!isLast && (
                    <ArrowDownOutlined
                        className="move-down-icon"
                        onClick={() => onMoveDown(index)}
                    />
                )}
            </div>
        </div>
    );
};

export default ElementEditor;
