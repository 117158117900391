// src/components/providerdashboard/CarouselComponent.jsx
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import w3 from '../assets/home2.png';
import al1 from '../assets/assignmenttoolbox/al1.png';
import meditation1 from '../assets/assignmenttoolbox/meditation1.png';
import et2 from '../assets/assignmenttoolbox/et2.png';
import g3 from '../assets/assignmenttoolbox/g3.png';
import gad1 from '../assets/assignmenttoolbox/gad1.png';
import j3 from '../assets/assignmenttoolbox/j3.png';
import med1 from '../assets/assignmenttoolbox/med1.png';
import pmr2 from '../assets/assignmenttoolbox/pmr2.png';
import tjbefore from '../assets/assignmenttoolbox/tjbefore.png';
import sym3 from '../assets/assignmenttoolbox/sym3.png';
import tr from '../assets/assignmenttoolbox/tr.png';
import v1 from '../assets/assignmenttoolbox/v1.png';

const CarouselComponent = () => {
  return (
    <div className="custom-carousel">
        <Carousel autoPlay interval={5000} infiniteLoop>
        <div>
            <img src={w3} alt="Home Screen" />
        </div>
        <div>
            <img src={al1} alt="Activity Log" />
        </div>
        <div>
            <img src={meditation1} alt="Meditation" />
        </div>
        <div>
            <img src={et2} alt="Exposure Therapy" />
        </div>
        <div>
            <img src={g3} alt="Goals" />
        </div>
        <div>
            <img src={gad1} alt="GAD-7" />
        </div>
        <div>
            <img src={j3} alt="Journaling" />
        </div>
        <div>
            <img src={med1} alt="Medication" />
        </div>
        <div>
            <img src={pmr2} alt="PMR" />
        </div>
        <div>
            <img src={tjbefore} alt="Therapy Journal" />
        </div>
        <div>
            <img src={sym3} alt="Symptom Log" />
        </div>
        <div>
            <img src={tr} alt="Thought Record" />
        </div>
        <div>
            <img src={v1} alt="Value Sort" />
        </div>
        </Carousel>
    </div>
  );
};

export default CarouselComponent;