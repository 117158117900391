import React, { useState } from "react";
import { Card, Radio } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const AnalyticsTab = () => {
  // Dummy data for demonstration
  const dummyData = [
    { name: "Jan 1", totalProviders: 2, totalClients: 5 },
    { name: "Jan 8", totalProviders: 5, totalClients: 12 },
    { name: "Jan 15", totalProviders: 7, totalClients: 22 },
    { name: "Jan 22", totalProviders: 9, totalClients: 25 },
    { name: "Jan 29", totalProviders: 10, totalClients: 30 },
    { name: "Feb 5", totalProviders: 13, totalClients: 40 },
    { name: "Feb 12", totalProviders: 15, totalClients: 45 },
    { name: "Feb 19", totalProviders: 16, totalClients: 50 },
    { name: "Feb 26", totalProviders: 17, totalClients: 55 },
  ];

  const [timeRange, setTimeRange] = useState("month");

  // Returns different subsets of the dummy data based on the selected time range
  const getFilteredData = () => {
    switch (timeRange) {
      case "week":
        return dummyData.slice(0, 3);
      case "month":
        return dummyData.slice(0, 6);
      case "year":
        // For demo, we return the full dummyData array to represent a year.
        return dummyData;
      case "all":
        return dummyData;
      default:
        return dummyData;
    }
  };

  const handleTimeRangeChange = (e) => {
    setTimeRange(e.target.value);
    // In a real app, you might refetch data or adjust calculations here.
  };

  return (
    <div style={{ fontFamily: "Mulish, sans-serif", color: "#375945" }}>
      {/* Time Range Selector */}
      <Radio.Group
        onChange={handleTimeRangeChange}
        value={timeRange}
        style={{ marginBottom: 16 }}
      >
        <Radio.Button value="week">Last Week</Radio.Button>
        <Radio.Button value="month">Last Month</Radio.Button>
        <Radio.Button value="year">Last Year</Radio.Button>
        <Radio.Button value="all">All Time</Radio.Button>
      </Radio.Group>

      {/* Multi-Line Chart Card */}
      <Card title="Users Over Time" style={{ marginBottom: "1rem" }}>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <LineChart
              data={getFilteredData()}
              margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="totalProviders"
                stroke="#FFC300"
                name="Total Providers"
                strokeWidth={2.5}
                dot={{ r: 4 }}
                activeDot={{ r: 5 }}
              />
              <Line
                type="monotone"
                dataKey="totalClients"
                stroke="#8D33FF"
                name="Total Clients"
                strokeWidth={2.5}
                dot={{ r: 4 }}
                activeDot={{ r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </div>
  );
};

export default AnalyticsTab;
