import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RollbackOutlined } from '@ant-design/icons';
import "../styles/Community.css"; // Import your CSS file
import AssignmentCard from "../components/CommunityAssignmentCard";
import ShareWithCommunityModal from "../components/ShareWithCommunityModal";
import { getCurrentUser } from 'aws-amplify/auth';
import { logosWhite } from '../config/logos';
import { Layout } from 'antd';

//const api = process.env.REACT_APP_API_URL;
const currentEnv = process.env.REACT_APP_ENV || "production";

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const Community = () => {
  const [assignments, setAssignments] = useState([]);
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const [userAssignments, setUserAssignments] = useState([]);
  const [savedWorksheets, setSavedWorksheets] = useState([]);
  const [openSection, setOpenSection] = useState(""); // Tracks which accordion section is open
  const [filters, setFilters] = useState({ saved: false, owner: false, modality: [], demographic: [], topic: [] });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const assignmentsPerPage = 20; // Adjust based on preference

  // Calculate start and end index for slicing
  const indexOfLastAssignment = currentPage * assignmentsPerPage;
  const indexOfFirstAssignment = indexOfLastAssignment - assignmentsPerPage;
  const currentAssignments = filteredAssignments.slice(indexOfFirstAssignment, indexOfLastAssignment);

  // Handle pagination buttons
  const nextPage = () => {
    if (indexOfLastAssignment < filteredAssignments.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [username, setUsername] = useState("");
  const currentYear = new Date().getFullYear();

  const { Header } = Layout;

  let api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

  const fetchUserDetails = async () => {
    try {
      // Fetch the current authenticated user
      const user = await getCurrentUser();
      if (!user) {
        //console.log('No user is signed in.');
        return null;
      }
      setUsername(user.username);
    } catch (err) {
      console.error('Error getting current user:', err);
      throw err;
    }
  };

  const reFetch = async () => {
    await fetchCommunityAssignments();
    await fetchSavedAssignments(username);
  }

  const fetchCommunityAssignments = async () => {
    const communityWorksheetsUrl = new URL(`${api}/CommunityWorksheets/`);

    const communityWorksheetsResponse = await fetch(communityWorksheetsUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!communityWorksheetsResponse.ok) {
      throw new Error(`Failed to fetch Community Worksheets data: ${communityWorksheetsResponse.statusText}`);
    }

    const communityWorksheetsRawData = await communityWorksheetsResponse.json();

    setAssignments(communityWorksheetsRawData);
    setFilteredAssignments(communityWorksheetsRawData);
  }

  useEffect(() => {
    fetchUserDetails();
    fetchCommunityAssignments();
  }, []);

  const fetchUserAssignments = async (username) => {
    const customWorksheetsUrl = new URL(`${api}/CustomWorksheets/`);
    customWorksheetsUrl.searchParams.set('username', username);

    const customWorksheetsResponse = await fetch(customWorksheetsUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!customWorksheetsResponse.ok) {
      throw new Error(`Failed to fetch Custom Worksheets data: ${customWorksheetsResponse.statusText}`);
    }

    const customWorksheetsRawData = await customWorksheetsResponse.json();

    setUserAssignments(customWorksheetsRawData);
  }

  const fetchSavedAssignments = async (username) => {
    const savedWorksheetsUrl = new URL(`${api}/SavedWorksheets/`);
    savedWorksheetsUrl.searchParams.set('username', username);

    const savedWorksheetsResponse = await fetch(savedWorksheetsUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!savedWorksheetsResponse.ok) {
      throw new Error(`Failed to fetch Saved Worksheets data: ${savedWorksheetsResponse.statusText}`);
    }

    const savedWorksheetsRawData = await savedWorksheetsResponse.json();

    setSavedWorksheets(savedWorksheetsRawData);
  }

  useEffect(() => {
    if (username === '') return;
    fetchUserAssignments(username);
    fetchSavedAssignments(username);
  }, [username]);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? "" : section);
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleFilterChange = (category, value) => {
    setFilters((prevFilters) => {
      if (category === "saved") {
        return { ...prevFilters, saved: !prevFilters.saved };
      }

      if (category === "owner") {
        return { ...prevFilters, owner: !prevFilters.owner };
      }

      // Toggle values in modality, demographic, or topic arrays
      const updatedList = prevFilters[category].includes(value)
        ? prevFilters[category].filter((item) => item !== value)
        : [...prevFilters[category], value];

      return { ...prevFilters, [category]: updatedList };
    });
  };

  const areWorksheetsEqual = (worksheet1, worksheet2) => {
    if (!worksheet1 || !worksheet2) return false; // Ensure both exist
    if (worksheet1.length !== worksheet2.length) return false; // Different lengths

    return worksheet1.every((item, index) => {
      const match = worksheet2[index];
      return match && item.type === match.type && item.value === match.value;
    });
  };

  // Apply Filters
  useEffect(() => {
    let filtered = assignments;

    // Filter by saved worksheets
    if (filters.saved) {
      filtered = filtered.filter(assignment =>
        savedWorksheets.some(saved => areWorksheetsEqual(saved.worksheet, assignment.worksheet))
      );
    }

    // Filter by my worksheets
    if (filters.owner) {
      filtered = filtered.filter(assignment =>
        assignment.username === username
      );
    }

    // Filter by modality, demographic, and topic
    if (filters.modality.length > 0) {
      filtered = filtered.filter(assignment =>
        assignment.tags.some(tag => filters.modality.includes(tag))
      );
    }
    if (filters.demographic.length > 0) {
      filtered = filtered.filter(assignment =>
        assignment.tags.some(tag => filters.demographic.includes(tag))
      );
    }
    if (filters.topic.length > 0) {
      filtered = filtered.filter(assignment =>
        assignment.tags.some(tag => filters.topic.includes(tag))
      );
    }

    setFilteredAssignments(filtered);
  }, [filters, assignments, savedWorksheets]);

  return (
    <>
      <head>
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>Yosa Community</title>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
        </style>
      </head>
      <div className="community-container">
        <div className="filter-section">
          <div>
            <a href="/" className="logo" style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
              <img src={logosWhite[currentEnv]} alt="Yosa Logo" />
            </a>
            <div className="back-button" onClick={() => navigate('/dashboard')}>
              <RollbackOutlined />
              <span style={{ marginRight: '10px' }}>Back</span>
            </div>
            <div className="share-with-community-button" onClick={toggleModal}>
              <span>Share With Yosa Providers</span>
            </div>
            <h2 style={{ marginTop: '30px' }}>Filter By:</h2>

            {/* Filters here */}
            <div className="filters-container">
              {/* Saved Assignments */}
              <div className="accordion-content">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={filters.saved}
                    onChange={() => handleFilterChange("saved")}
                  />
                  My saved assignments
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={filters.owner}
                    onChange={() => handleFilterChange("owner")}
                  />
                  My shared assignments
                </label>
              </div>

              {/* Modality Accordion */}
              <div className="accordion">
                <div className="accordion-header" onClick={() => toggleSection("modality")}>
                  <h3>Modality</h3>
                  <span>{openSection === "modality" ? "-" : "+"}</span>
                </div>
                {openSection === "modality" && (
                  <div className="accordion-content">
                    {["ABA", "ACT", "CBT", "DBT", "EMDR", "IFS", "MBCT", "SFBT"].map((modality) => (
                      <label key={modality} className="checkbox-label">
                        <input
                          type="checkbox"
                          checked={filters.modality.includes(modality)}
                          onChange={() => handleFilterChange("modality", modality)}
                        />
                        {modality}
                      </label>
                    ))}
                  </div>
                )}
              </div>

              {/* Demographic Accordion */}
              <div className="accordion">
                <div className="accordion-header" onClick={() => toggleSection("demographic")}>
                  <h3>Demographic</h3>
                  <span>{openSection === "demographic" ? "-" : "+"}</span>
                </div>
                {openSection === "demographic" && (
                  <div className="accordion-content">
                    {["Teen", "Adult"].map((demographic) => (
                      <label key={demographic} className="checkbox-label">
                        <input
                          type="checkbox"
                          checked={filters.demographic.includes(demographic)}
                          onChange={() => handleFilterChange("demographic", demographic)}
                        />
                        {demographic}
                      </label>
                    ))}
                  </div>
                )}
              </div>

              {/* Topic Accordion */}
              <div className="accordion">
                <div className="accordion-header" onClick={() => toggleSection("topic")}>
                  <h3>Topic</h3>
                  <span>{openSection === "topic" ? "-" : "+"}</span>
                </div>
                {openSection === "topic" && (
                  <div className="accordion-content">
                    {[
                      "Addiction", "ADHD", "Anger Management", "Anxiety", "Behavioral Issues",
                      "Bipolar Disorder", "Borderline Personality Disorder", "Coping Skills",
                      "Depression", "Dissociative Disorders (DID)", "Eating Disorders", "Grief",
                      "Mindfulness", "Obsessive-Compulsive (OCD)", "Relationships", "Self-Esteem",
                      "Self-Harm", "Sleep", "Spirituality", "Stress", "Substance Abuse",
                      "Trauma and PTSD", "Other"
                    ].map((topic) => (
                      <label key={topic} className="checkbox-label">
                        <input
                          type="checkbox"
                          checked={filters.topic.includes(topic)}
                          onChange={() => handleFilterChange("topic", topic)}
                        />
                        {topic}
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Footer (Will be pushed down naturally) */}
          <div style={{ color: '#1C3727', fontSize: 'small', marginTop: '15px' }}>
            <p>&copy; {currentYear} Yosa Inc.</p>
            <p>
              Questions?<br />
              <a style={{ color: '#1C3727' }} href="/StartUpGuide" target="_blank">
                Start up guide
              </a><br />
              <a style={{ color: '#1C3727' }} href="/FAQs" target="_blank">
                FAQs
              </a><br />
              Contact: <a style={{ color: '#1C3727' }} href="mailto:support@yosaapp.com">support@yosaapp.com</a>
            </p>
          </div>
        </div>


        <div className="community-assignments-container">
          <Header
            className='header'
            style={{
              padding: 40,
              fontWeight: 'bold',
              fontSize: 25,
              fontFamily: 'Mulish',
              backgroundColor: '#C3D7CB',
              borderBottom: '3px solid #375945',
              color: 'black',
              minWidth: '-moz-max-content',
            }}
          >
            <div>
              Community Assignments
            </div>
          </Header>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="community-info-text">
              {filteredAssignments.length === 0 ? (
                filteredAssignments === assignments ? (
                  <p>No community worksheets exist yet - click Share With Community to add one!</p>
                ) : (
                  <p>No community worksheets match your current filters. Try adjusting your selection.</p>
                )
              ) : (
                <p>Showing {indexOfFirstAssignment + 1}-{Math.min(indexOfLastAssignment, currentAssignments.length)} of {filteredAssignments.length} result{filteredAssignments.length !== 1 ? 's:' : ':'}</p>
              )}
            </div>
            {/* Pagination Controls */}
            <div className="pagination-controls">
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className="pagination-button"
              >
                Previous
              </button>
              <span>Page {currentPage} of {Math.ceil(filteredAssignments.length / assignmentsPerPage)}</span>
              <button
                onClick={nextPage}
                disabled={indexOfLastAssignment >= filteredAssignments.length}
                className="pagination-button"
              >
                Next
              </button>
            </div>
          </div>
          <div className="assignments-list">
            {currentAssignments
              .slice() // Create a shallow copy to avoid mutating state directly
              .sort((a, b) => (b.numberSaves || 0) - (a.numberSaves || 0)) // Sort in descending order
              .map((assignment) => (
                <AssignmentCard
                  key={assignment.id}
                  communityWorksheet={assignment}
                  username={username}
                  isOwned={assignment.username === username}
                  savedAssignments={savedWorksheets}
                  reFetch={reFetch}
                />
              ))}
          </div>
        </div>
        {/* Share With Community modal */}
        <ShareWithCommunityModal
          assignments={assignments}
          userAssignments={userAssignments}
          username={username}
          isOpen={isModalOpen}
          onClose={toggleModal}
          reFetch={reFetch}
        />
      </div>
    </>
  );
};

export default Community;
