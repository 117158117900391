// File: src/screens/Evidence.jsx
import React from "react";
import { useState } from 'react';
import "../styles/NewHome.css"; // Import the corresponding CSS file
import "../styles/Product.css";
import "../styles/Evidence.css";
import { Collapse, Button, Tabs, Menu, Dropdown } from 'antd';
import { RollbackOutlined, DownOutlined } from '@ant-design/icons';
//image imports
import { logosGreen } from '../config/logos';
import linkedinicon from '../assets/linkedinicon.png';
import bannerimage from '../assets/bannerimage.jpg';
import homeworkicon from '../assets/homeworkicon.png';
import solution from '../assets/talk-therapy.png';

const currentEnv = "production";

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const Homework = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const evidenceMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
          </Menu.Item>
        </Menu>
      );

  const aboutMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/About" style={{ textDecoration: 'none' }}>About</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
          </Menu.Item>
        </Menu>
      );

  const productMenu = (
      <Menu>
        <Menu.Item key="product">
          <a href="/Product" style={{ textDecoration: 'none' }}>Product</a>
        </Menu.Item>
        <Menu.Item key="startupguide">
          <a href="/StartUpGuide" style={{ textDecoration: 'none' }}>Getting Started</a>
        </Menu.Item>
      </Menu>
    );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch(`${api}/Waitlist/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
        //console.log("error");
      }

      alert('You successfully joined our mailing list! We will contact you soon.');
      setEmail('');
    } catch (error) {
      setError('There was a problem submitting your email. Please try again later.');
    }
  };

  const modalitiesContent = [
    {
      key: '1',
      label: 'Acceptance & Commitment Therapy (ACT)',
      content: (
        <>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}><strong>Description:</strong> ACT is a “third-wave” cognitive-behavioral approach designed to enhance psychological flexibility, which is the ability to stay present in the moment while pursuing actions aligned with personal values (Hayes et al., 2006). Unlike traditional therapies that focus on reducing or controlling distressing psychological experiences, ACT encourages individuals to adopt a stance of openness and acceptance toward these experiences, fostering a healthier relationship with their thoughts and emotions.</p>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}>Grounded in six core processes—including acceptance, cognitive defusion, mindfulness, self-as-context, values clarification, and committed action—ACT aims to help individuals reduce the impact of unhelpful thoughts and feelings while promoting meaningful, value-driven behavior (Hayes et al., 1996). By cultivating mindfulness and acceptance strategies, ACT enables individuals to respond to life’s challenges with greater adaptability and resilience, rather than avoidance or suppression of difficult experiences.</p>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}>Over the past few decades, ACT has been supported by empirical research and has become an established intervention for addressing a range of psychological difficulties, including anxiety, depression, chronic pain, and stress-related conditions (Hayes et al., 2006; Bach & Hayes, 2002).</p>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}><strong>Homework Use:</strong> Clients may be assigned mindfulness exercises, value identification tasks, or create committed action plans aligned with their goals.</p>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}><strong>ACT on Yosa:</strong> Our Value Sort feature allows clients to sort a number of values into buckets ranging from "Very Important" to "Not Important at all", allowing clear identification of values to be referred back to in the future. Clients may also access a number of breathing and meditation excercises, as well as structured goal setting.</p>
        </>
      ),
    },
    {
      key: '2',
      label: 'Dialectical Behavior Therapy (DBT)',
      content: (
        <>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}><strong>Description:</strong> DBT is an evidence-based therapeutic approach originally designed to treat borderline personality disorder, but is now more broadly applied to treat depression, PTSD, eating disorders, substance use disorders, and more. Although it may sound contradictory, DBT combines principles of acceptance and change, hence the word 'Dialectical', which means the existence of opposites (Linehan, 1993).</p>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}>A central component of DBT is its skills training, which equips individuals with practical tools to navigate emotional and interpersonal challenges. Skills training focuses on four core modules: <strong>mindfulness</strong>, which enhances awareness of the present moment and promotes nonjudgmental acceptance; <strong>distress tolerance</strong>, which provides techniques for managing crises without resorting to harmful actions; <strong>emotion regulation</strong>, which helps individuals understand and regulate intense emotions; and <strong>interpersonal effectiveness</strong>, which focuses on improving communication, setting boundaries, and building healthier relationships (Linehan, 2014).</p>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}><strong>Homework Use:</strong> Homework in DBT is designed to reinforce skills learned during therapy sessions, helping clients apply these tools to real-life situations. Common assignments include completing diary cards to track emotions, urges, and behaviors; practicing mindfulness exercises to stay present; and applying specific skills, such as distress tolerance techniques during crises or interpersonal effectiveness strategies in challenging relationships (Linehan, 2014).</p>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}><strong>DBT on Yosa:</strong> Yosa offers interactive tools to support the practice of DBT skills, including guided mindfulness exercises to enhance present-moment awareness. Additionally, Yosa provides structured assignments for emotion regulation, such as mood logs and emotion tracking. Therapists can also create custom interactive worksheets tailored to DBT concepts, such as distress tolerance plans or interpersonal effectiveness exercises, and share PDFs directly with clients for additional support.</p>
        </>
      ),
    },
    {
      key: '3',
      label: 'Other Modalities on Yosa',
      content: (
        <>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}><strong>Other Modalities on Yosa:</strong> Yosa’s features are designed to complement a wide range of therapeutic approaches, making it a valuable tool for any provider. Therapists can assign journaling tasks to promote reflection and insight, or use therapy-related worksheets to guide clients in structured self-exploration. The built-in meditation and breathing exercises support mindfulness and stress management, while tools like the safety plan and symptom trackers ensure continuity of care for clients in crisis or ongoing treatment.</p>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}>With validated assessments such as the GAD-7, PHQ-9, and PCL-5, Yosa helps providers monitor progress and tailor treatment strategies. Whether the focus is trauma processing, anxiety reduction, or overall wellness, Yosa’s flexible tools empower therapists to adapt assignments to their clients’ unique goals and needs.</p>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#375945' }}><strong>Whether you are a therapist that utilizes homework or not, Yosa is an all-in-one tool you can easily handoff to clients to ensure continuous support between sessions.</strong></p>
        </>
      ),
    },
  ];

  return (
    <>
      <head>
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>Yosa Homework</title>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
        </style>
      </head>

      <div className="new-home">

        {/* Header */}
        <header className="header">
          <a href="/" className="logo">
            <img src={logosGreen[currentEnv]} alt="Yosa Logo" />
          </a>

          {/* Hamburger Menu Icon */}
          <button
            className={`menu-toggle ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>

          <nav className={`navigation ${menuOpen ? "open" : ""}`}>
            <ul>
              <li><a href="/">Home</a></li>
              <li>
                            <Dropdown overlay={productMenu} trigger={['hover']}>
                              <a style={{ textDecoration: 'none' }} href="/StartUpGuide">
                                Product <DownOutlined />
                              </a>
                            </Dropdown>
                            </li>
              <li>
                <Dropdown overlay={evidenceMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/Evidence">
                    Evidence <DownOutlined />
                  </a>
                </Dropdown>
              </li>
              <li><a href="/Pricing">Pricing</a></li>
              <li>
                <Dropdown overlay={aboutMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/About">
                    About <DownOutlined />
                  </a>
                </Dropdown>
              </li>                <li><a href="/LogIn">Log In</a></li>
              <li><a href="/SignUp">Sign Up</a></li>
            </ul>
          </nav>
        </header>

        {/* Hero Section 
      <section className="hero-research">
        <div className="evidence-header">
          <h1>Grounded in research, designed for impact: Yosa bridges the gap between therapy and everyday life</h1>
        </div>
        <img src={bannerimage} alt="Banner" />
        <div className="evidence-subheader">
          <h2>Discover the science and evidence that makes Yosa a game-changer in therapy</h2>
        </div>
      </section> */}

        <Button
          href="/Evidence"
          icon={<RollbackOutlined />}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '2.5rem 2rem',
            fontSize: '1rem',
            fontFamily: 'Mulish, sans-serif',
            backgroundColor: 'transparent',
            border: 'none',
            color: '#375945',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#f0f0f0';
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = 'transparent';
          }}
        >
          Back to Evidence
        </Button>

        {/* Aaron Beck Quote */}
        <section className="assignment-builder">
          <div className="assignment-builder-text">
            <div style={{ marginTop: '3rem' }}></div>
            <p className="beckquote">
              “Longer-lasting changes are more easily accomplished if the patient is actively involved in experiences outside the office. <span className="bold-text">Homework is not just an elective, adjunct procedure... [It is] an integral, vital component.”</span>
            </p>
            <p className="beck-bridging-text">
              - Dr. Aaron Beck, Founder of Cognitive Behavioral Therapy
            </p>
          </div>
        </section>


        {/* Theoretical Foundations of Homework */}
        <section className="limitations-section">
          <div className="limitations-text">
            <h2>Theoretical Foundations of Homework</h2>
            <p>
              Homework has long been recognized as a foundational component of psychotherapy, particularly in Cognitive Behavioral Therapy (CBT). Developed by Aaron Beck, CBT emphasizes structured, problem-focused, and time-sensitive approaches to treatment aimed at reducing emotional distress and fostering adaptive behavior (Beck et al., 1983; Wenzel, 2017). Within this framework, homework serves as a vital tool for helping patients generalize the skills and insights developed in therapy to their daily lives, promoting lasting change (Kazantzis & Miller, 2021).
            </p>
          </div>
          <div className="limitations-image">
            <img src={homeworkicon} alt="Why Homework Matters Illustration" />
          </div>
        </section>


        {/* Core Components of CBT and the Role of Homework */}
        <section className="solution-section">
          <div className="solution-text">
            <h2>Core Components of CBT and the Role of Homework</h2>
            <div className="cbt-accordion">
              <Collapse accordion>
                <Collapse.Panel className='cbt-accordionpanel' header="Cognitive Restructuring" key="1">
                  <p><strong>Description:</strong> Cognitive restructuring involves helping patients identify, evaluate, and modify maladaptive or distorted thoughts (Wenzel, 2017). For example, automatic thoughts that arise during moments of stress are challenged and replaced with more realistic, balanced perspectives.</p>
                  <p><strong>Role of Homework:</strong> Homework facilitates cognitive restructuring by providing opportunities to practice thought-challenging techniques between sessions. Thought records encourage patients to document triggering events, identify associated distorted thoughts, and replace these thoughts with adaptive alternatives. This practice deepens understanding and solidifies changes outside therapy (Kazantzis et al., 2013).</p>
                  <p><strong>On Yosa:</strong> Providers can assign our interactive, pre-built thought record for clients to fill out and keep record of in-app. Have a different thought record format you like? Use our assignment builder to build a custom, interactive version from scratch.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="Behavioral Activation" key="2">
                  <p><strong>Description:</strong> Behavioral activation focuses on increasing engagement in rewarding and meaningful activities, combatting avoidance behaviors, and reducing the impact of negative reinforcement cycles seen in depression (Lewinsohn, 1974; Wenzel, 2017).</p>
                  <p><strong>Role of Homework:</strong> Assignments such as scheduling pleasant activities or tracking actions’ effects on mood help patients apply these principles in real contexts. This experiential learning not only boosts mood but also helps sustain behavioral changes (Kazantzis & Dattilio, 2010).</p>
                  <p><strong>On Yosa:</strong> Providers can assign our interactive, pre-built activity log, where clients record planned activities, anticipated emotions, and expected outcomes. After completing the activity, they can document their mood before and after, as well as any insights or lessons learned.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="Exposure" key="3">
                  <p><strong>Description:</strong> Exposure therapy is a cornerstone of CBT, treating anxiety disorders, OCD, and trauma-related conditions. It involves systematic exposure to feared stimuli to reduce fear responses and promote desensitization (Huppert, 2009).</p>
                  <p><strong>Role of Homework:</strong> Exposure homework includes in vivo exposure tasks (e.g., confronting a specific feared situation) or imaginal exercises. These help patients confront avoidance behaviors and build confidence in managing anxiety-provoking situations (Tang & Kreindler, 2017).</p>
                  <p><strong>On Yosa:</strong> Providers can assign our interactive, pre-built exposure therapy worksheet, which is a step-by-step framework to plan and reflect on exposure taks. Before: Identify the specific fear, safety behaviors to target, and worst-case scenarios. During: Log the session's timing and record distress levels (Subjective Units of Distress Scale - SUDS) at various intervals, helping visualize emotional progress. After: Reflect on achievements, insights gained, and plan actionable next steps for continued growth. This feature empowers clients to systematically face their fears, track progress, and build confidence while reducing avoidance behaviors, all while providing valuable insights for therapists to guide treatment.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="Monitoring and Psychoeducation" key="5">
                  <p><strong>Description:</strong> Self-monitoring reflects on thoughts, emotions, and behaviors, supporting CBT's collaborative approach, while psychoeducation provides knowledge to understand symptoms and treatment (Huppert, 2009).</p>
                  <p><strong>Role of Homework:</strong> Homework tasks such as tracking mood or reading educational materials help patients learn about treatment and symptoms, allowing providers to better tailor treatment strategies collaboratively.</p>
                  <p><strong>On Yosa:</strong> Self-monitoring tools such as mood trackers, symptom logs, substance logs, medication tracking, and daily journals allow clients to observe patterns, generating valuable insights for both clients and therapists. Measure such as the PHQ-9, GAD-7, and PCL-5 offer providers validated insights. Providers can also assign psychoeducational PDFs.</p>
                </Collapse.Panel>
              </Collapse>
            </div>
          </div>
          <div className="solution-image">
            <img src={solution} alt="How Yosa is Better Illustration" />
          </div>
        </section>

        <section className="homework-modalities">
          <h2>Homework in Other Modalities</h2>
          <Tabs
            centered
            tabBarStyle={{
              color: '#375945', // Text color for all tabs
              fontWeight: 'bold',
            }}
            items={modalitiesContent.map((modality) => ({
              key: modality.key,
              label: (
                <span style={{ color: '#375945' }}>{modality.label}</span>
              ),
              children: modality.content,
            }))}
            tabBarExtraContent={{
              inkBarStyle: { backgroundColor: '#375945' }, // Change ink bar color
            }}
          />
        </section>

        {/* The word homework */}
        {/*
        <section className="assignment-builder">
          <div className="assignment-builder-text">
            <div style={{ marginTop: '3rem' }}></div>
            <h2>“When we use the word homework, we might as well just throw a stink bomb in the room.” </h2>
            <p className="bridging-text">
              In actual practice, providers often do not actually use the word "homework" with clients, as it can evoke negative associations with schoolwork or pressure. Instead, they may refer to these tasks as "practices" or "exercises" to emphasize the collaborative, exploratory nature of these activities (Kazantzis & Deane, 1998).
            </p>
            <p className="bridging-text">
              <strong>Clients will NEVER see the word "homework" on Yosa.</strong> Instead, they will see "assignments" or "tasks" to complete. This subtle shift in language can help reduce resistance and foster engagement with the therapeutic process.
            </p>
          </div>
        </section>
        */}

        <Button
          href="/Evidence"
          icon={<RollbackOutlined />}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '2.5rem 2rem',
            fontSize: '1rem',
            fontFamily: 'Mulish, sans-serif',
            backgroundColor: 'transparent',
            border: 'none',
            color: '#375945',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#f0f0f0';
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = 'transparent';
          }}
        >
          Back to Evidence
        </Button>

        {/* Waitlist Signup */}
        <section className="newsletter">
          <h2 style={{marginBottom: '30px'}}>Get started today for free! No credit card required.</h2>
          <a href="/signup" className="free-trial-button">Sign Up</a>
        </section>

        <footer className="footer">
          <div className="footer-content">
            <p>&copy; {currentYear} Yosa Inc. All rights reserved.</p>
            <nav>
              <ul>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                <li><a href="/terms-of-service">Terms of Service</a></li>
                <li><a href="/baa">Business Associate Agreement</a></li>
              </ul>
            </nav>
            <div className="footer-bottom">
              <p>
                <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
                <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
                  <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Homework;
