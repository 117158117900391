import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Radio, Input, DatePicker, Checkbox, message, Select } from 'antd';
import dayjs from 'dayjs';
import InfoModal from './InfoModal';
import AssignmentPreviewModal from './AssignmentPreviewModal';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
//Preview images
import tjbeforepreview from '../assets/assignmenttoolbox/tjbefore.png';
import tjafterpreview from '../assets/assignmenttoolbox/tjafter.png';
import journalpreview1 from '../assets/assignmenttoolbox/j1.png';
import journalpreview2 from '../assets/assignmenttoolbox/j2.png';
import brpreview1 from '../assets/assignmenttoolbox/br1.png';
import brpreview2 from '../assets/assignmenttoolbox/br2.png';
import brpreview3 from '../assets/assignmenttoolbox/br3.png';
import brpreview4 from '../assets/assignmenttoolbox/br4.png';
import brpreview5 from '../assets/assignmenttoolbox/br5.png';
import trpreview1 from '../assets/assignmenttoolbox/tr1.png';
import trpreview2 from '../assets/assignmenttoolbox/tr2.png';
import alpreview1 from '../assets/assignmenttoolbox/al1.png';
import alpreview2 from '../assets/assignmenttoolbox/al2.png';
import etpreview1 from '../assets/assignmenttoolbox/et1.png';
import etpreview2 from '../assets/assignmenttoolbox/et2.png';
import etpreview3 from '../assets/assignmenttoolbox/et3.png';
import etpreview4 from '../assets/assignmenttoolbox/et4.png';
import sym1 from '../assets/assignmenttoolbox/sym1.png';
import sym2 from '../assets/assignmenttoolbox/sym2.png';
import sym3 from '../assets/assignmenttoolbox/sym3.png';
import med1 from '../assets/assignmenttoolbox/med1.png';
import v1 from '../assets/assignmenttoolbox/v1.png';
import sub1 from '../assets/assignmenttoolbox/sub1.png';
import g1 from '../assets/assignmenttoolbox/g1.png';
import g2 from '../assets/assignmenttoolbox/g2.png';
import g3 from '../assets/assignmenttoolbox/g3.png';

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const SendAssignmentModal = ({
  isAssignModalVisible,
  handleCancel,
  customWorksheets,
  savedWorksheets,
  worksheets,
  client,
  handlePostTask,

}) => {
  const otherAssignments = [
    {
      name: 'Therapy Journal Entry (Before)',
      description: 'The Therapy Journal (Before) helps clients prepare for their upcoming therapy session. It encourages reflection on their recent experiences, emotions, and challenges, providing a structured space to document what they want to focus on during the session. This feature ensures that sessions start with clarity and direction, saving time and maximizing productivity.',
      images: [tjbeforepreview],
    },
    {
      name: 'Therapy Journal Entry (After)',
      description: 'The Therapy Journal (After) is designed for post-session reflection. Clients can document key takeaways, insights, and actions to apply in their daily lives. This feature reinforces therapeutic lessons and promotes retention.',
      images: [tjafterpreview],
    },
    {
      name: 'Journal Entry',
      description: 'Journaling provides clients with a dedicated space to freely express their thoughts, feelings, and experiences. It encourages self-reflection and emotional processing, helping clients gain clarity and insight into their mental and emotional well-being. Journaling has been shown to reduce stress, improve mood, and enhance overall self-awareness, making it a valuable tool for personal growth and mental health support.',
      images: [journalpreview1, journalpreview2],
    },
    {
      name: 'Breathing Exercise',
      description: 'The Breathing Room provides a variety of guided breathing exercises designed to reduce stress, improve focus, and enhance overall well-being. Users can practice structured techniques like Box Breathing, Belly Breathing, and 4-7-8 Breathing or create a fully customizable breathing cadence to suit their personal preferences. The exercises feature an interactive visual guide, making the experience intuitive and engaging.',
      images: [brpreview1, brpreview2, brpreview3, brpreview4, brpreview5],
    },
    {
      name: 'Symptom Log',
      description: 'The Symptom Log is a tracking tool that allows clients to log and monitor key aspects of their mental and physical well-being over time, including mood, energy, stress, sleep, and social interaction. This feature provides a visual representation of trends through charts, enabling both clients and therapists to identify patterns and gain deeper insights. This allows therapists to better understand their clients\' experiences, supporting informed discussions and data-driven decisions in the therapeutic process.',
      images: [sym1, sym2, sym3],
    },
    {
      name: 'Medication',
      description: 'The Medication feature helps clients stay consistent with their medication regimen by tracking doses, schedules, side effects, and adherence.',
      images: [med1],
    },
    {
      name: 'Substance Log',
      description: 'Clients can log substances they use, specify the unit of measurement (e.g., drinks), and record the quantity consumed each day. This data is visually represented in a chart, allowing clients and therapists to monitor trends over time to gain insight into consumption patterns, fostering awareness, and supporting healthier habits.',
      images: [sub1],
    },
    {
      name: 'Goals',
      description: 'Set goals for your clients.',
      images: [g1, g2, g3],
    },
    {
      name: 'Value Sort',
      description: 'Identify important personal values.',
      images: [v1],
    },
    {
      name: 'Safety Plan',
      description: 'The Safety Plan is a structured tool designed to help clients identify and manage warning signs, coping strategies, and support networks to prevent and respond to crisis situations.',
      images: [],
    },
    {
      name: 'Activity Log',
      description: 'The Activity Log, rooted in behavioral activation, helps users plan and reflect on activities they enjoy or used to enjoy. Users record planned activities, anticipated emotions, and expected outcomes. After completing the activity, they document their mood before and after, as well as any insights or lessons learned. This structured approach encourages users to engage in meaningful activities, identify patterns, and better understand the connection between their actions and emotional states. It\'s a powerful tool for building positive habits and improving mental health through intentional, reflective practice.',
      images: [alpreview1, alpreview2],
    },
    {
      name: 'Thought Record',
      description: 'A cornerstone of Cognitive Behavioral Therapy, the Thought Record guides clients through a structured process to identify, evaluate, and challenge negative thoughts. Users document situations, associated thoughts, and emotions while exploring evidence against thoughts. This reflective process helps individuals reframe unhelpful thinking patterns, leading to healthier perspectives and emotional responses. The feature includes prompts to assess feelings, consider alternative viewpoints, and record outcomes, fostering greater self-awareness and emotional regulation.',
      images: [trpreview1, trpreview2],
    },
    {
      name: 'Exposure Therapy',
      description: 'Exposure Therapy is a structured, evidence-based practice designed to help clients confront and gradually overcome their fears and anxieties. The tool provides a step-by-step framework for planning and reflecting on exposure tasks. Before: Identify the specific fear, safety behaviors to target, and worst-case scenarios. During: Log the session\'s timing and record distress levels (Subjective Units of Distress Scale - SUDS) at various intervals, helping visualize emotional progress. After: Reflect on achievements, insights gained, and plan actionable next steps for continued growth. This feature empowers clients to systematically face their fears, track progress, and build confidence while reducing avoidance behaviors, all while providing valuable insights for therapists to guide treatment.',
      images: [etpreview1, etpreview2, etpreview3, etpreview4],
    },
  ];

  const otherAssignmentMappings = {
    'Therapy Journal Entry (Before)': 'therapy_journal',
    'Therapy Journal Entry (After)': 'therapy_journal',
    'Journal Entry': 'journaling',
    'Breating Exercise': 'breathing_room',
    'Symptom Log': 'symptom_log',
    'Medication': 'medication',
    'Substance Log': 'substance_log',
    'Goals': 'goals',
    'Value Sort': 'value_sort',
    'Safety Plan': 'safety_plan',
    'Activity Log': 'activity_log',
    'Thought Record': 'thought_record',
    'Exposure Therapy': 'exposure_therapy',
  }


  const validatedMeasures = ['PHQ-9', 'GAD-7', 'PCL-5'];

  const [selectedAssignments, setSelectedAssignments] = useState([]);
  const [assignmentDetails, setAssignmentDetails] = useState({});
  const [messageText, setMessageText] = useState('');
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [infoContent, setInfoContent] = useState({ title: '', content: '', images: [] });
  const [isAssignmentModalVisible, setIsAssignmentModalVisible] = useState(false);
  const [isCommunityAssignmentModalVisible, setIsCommunityAssignmentModalVisible] = useState(false);
  const [assignmentData, setAssignmentData] = useState([]);
  const [features, setFeatures] = useState(['journaling', 'therapy_journal', 'worksheets', 'help']);
  const navigate = useNavigate();

  const maxChars = 1000;

  useEffect(() => {
    if (!isAssignModalVisible) {
      setSelectedAssignments([]);
      setAssignmentDetails({});
      setMessageText('');
    }
  }, [isAssignModalVisible]);

  useEffect(() => {
    if (client) {
      getFeatures(client.clientUsername);
    }
  }, [client]);

  const getFeatures = async (username) => {
    try {
      const response = await axios.get(`${api}/Features/`, {
        params: { username },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Add the Authorization header
        },
      });

      setFeatures(response.data[0].features || ['journaling', 'therapy_journal', 'worksheets', 'help']);
    } catch (error) {
      console.error("Error fetching features:", error);
      throw new Error("Failed to fetch features. Please try again.");
    }
  };

  const handleCheckboxChange = (assignment) => {
    setSelectedAssignments((prev) =>
      prev.includes(assignment)
        ? prev.filter((item) => item !== assignment)
        : [...prev, assignment]
    );

    if (!assignmentDetails[assignment]) {
      setAssignmentDetails((prev) => ({
        ...prev,
        [assignment]: {
          frequencyType: 'no-repeat',
          interval: 1,
          unit: 'days',
          startDate: null,
          days: [],
          endCondition: 'never',
          endDate: null,
          occurrences: 1,
        },
      }));
    }
  };

  const handleDetailChange = (assignment, field, value) => {
    setAssignmentDetails((prev) => ({
      ...prev,
      [assignment]: {
        ...prev[assignment],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    if (selectedAssignments.length === 0 && messageText === '') {
      message.error('Please select at least one assignment or enter instructions.');
      return;
    }

    if (!client.email) {
      message.error('Client email is missing. Please try again.');
      return;
    }

    if (messageText.length > maxChars) {
      message.error('Instructions length exceeds the character limit.');
      return;
    }

    if (selectedAssignments.length !== 0) {
      try {
        const formattedTasks = selectedAssignments.map((assignment) => {
          //console.log(assignment.title);
          //console.log(assignment.elements);
          const details = assignmentDetails[assignment] || {};
          const name = (typeof assignment === "string" ? assignment : assignment.title) // .title for custom worksheets
          const type = (typeof assignment === "string" ? details.frequencyType || 'no-repeat' : 'Custom Worksheet')
          const elementsArray = Array.isArray(assignment.elements)
            ? assignment.elements
            : assignment.elements
              ? [assignment.elements]
              : Array.isArray(assignment.worksheet)
                ? assignment.worksheet
                : assignment.worksheet
                  ? [assignment.worksheet]
                  : [];
          return {
            name: name,
            type: type,
            file: null,
            worksheet: elementsArray,
            status: 'Pending',
            response: '',
            frequencyDetails: {
              interval: details.interval || 1,
              unit: details.unit || 'days',
              startDate: details.startDate || null,
              endCondition: details.endCondition || 'never',
              endDate: details.endDate || null,
              occurrences: details.occurrences || null,
              days: details.days || [],
            },
            accessResponse: details.accessResponse || false,
            message: messageText || '',
          };
        });

        const taskData = {
          tasks: formattedTasks, // Pass the formatted tasks
        };

        // Use client.email instead of selectedClientKey
        await handlePostTask(client.email, taskData);
        handleCancel(); // Close the modal after successful submission
      } catch (error) {
        console.error('Error during submission:', error);
        message.error('Failed to submit tasks. Please try again.');
      }
    } else { // for message-only assignments
      try {
        const formattedTask = {
          name: 'See Notes',
          type: 'no-repeat',
          file: null,
          status: 'Pending',
          response: '',
          frequencyDetails: {
            interval: 1,
            unit: 'days',
            startDate: null,
            endCondition: 'never',
            endDate: null,
            occurrences: null,
            days: [],
          },
          accessResponse: false,
          message: messageText,
        };

        const taskData = { tasks: [formattedTask] };

        // Use client.email instead of selectedClientKey
        await handlePostTask(client.email, taskData);
        handleCancel(); // Close the modal after successful submission
      } catch (error) {
        console.error('Error during submission:', error);
        message.error('Failed to submit task. Please try again.');
      }
    }
  };

  const openInfoModal = (title, content, images) => {
    setInfoContent({ title, content, images });
    setIsInfoModalVisible(true);
  };

  const openAssignmentModal = (custom_worksheet) => {
    setAssignmentData(custom_worksheet);
    setIsAssignmentModalVisible(true);
  };

  const closeAssignmentModal = () => {
    setIsAssignmentModalVisible(false);
  };

  const openCommunityAssignmentModal = (custom_worksheet) => {
    setAssignmentData(custom_worksheet);
    setIsCommunityAssignmentModalVisible(true);
  };

  const closeCommunityAssignmentModal = () => {
    setIsCommunityAssignmentModalVisible(false);
  };

  const renderAssignmentControls = (assignment, type) => (
    <div style={{ marginLeft: '24px', marginTop: '10px' }}>
      {/* Frequency Selector */}
      <Radio.Group
        value={assignmentDetails[assignment]?.frequencyType || 'no-repeat'}
        onChange={(e) =>
          handleDetailChange(assignment, 'frequencyType', e.target.value)
        }
      >
        <Radio value="no-repeat">One Time</Radio>
        <Radio value="repeat">Repeat</Radio>
      </Radio.Group>

      {assignmentDetails[assignment]?.frequencyType === 'repeat' && (
        <div style={{ marginTop: '10px' }}>
          <div>
            <span>Starting On:</span>
            <DatePicker
              onChange={(date) =>
                handleDetailChange(
                  assignment,
                  'startDate',
                  date ? dayjs(date).toISOString() : null
                )
              }
              style={{ marginTop: '5px', display: 'block' }}
            />
          </div>
          <span>Every: </span>
          <Input
            type="number"
            min={1}
            value={assignmentDetails[assignment]?.interval}
            onChange={(e) =>
              handleDetailChange(assignment, 'interval', e.target.value)
            }
            placeholder="Interval"
            style={{ width: '80px', marginTop: '10px', marginRight: '10px' }}
          />
          <Select
            value={assignmentDetails[assignment]?.unit || 'days'}
            onChange={(value) => handleDetailChange(assignment, 'unit', value)}
            style={{ width: '120px' }}
          >
            <Select.Option value="days">Days</Select.Option>
            <Select.Option value="weeks">Weeks</Select.Option>
            <Select.Option value="months">Months</Select.Option>
          </Select>

          {assignmentDetails[assignment]?.unit === 'weeks' && (
            <div style={{ marginTop: '10px' }}>
              <span>Repeat On:</span>
              <div style={{ display: 'flex', gap: '10px', marginTop: '5px' }}>
                {daysOfWeek.map((day, index) => (
                  <Button
                    key={index}
                    type={
                      assignmentDetails[assignment]?.days.includes(index)
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() => {
                      const selectedDays = assignmentDetails[assignment]?.days || [];
                      handleDetailChange(
                        assignment,
                        'days',
                        selectedDays.includes(index)
                          ? selectedDays.filter((d) => d !== index)
                          : [...selectedDays, index]
                      );
                    }}
                  >
                    {day}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {assignmentDetails[assignment]?.frequencyType === 'repeat' && (
        <div style={{ marginTop: '10px' }}>
          <span>Ends On: </span>
          <Radio.Group
            value={assignmentDetails[assignment]?.endCondition || 'never'}
            onChange={(e) =>
              handleDetailChange(assignment, 'endCondition', e.target.value)
            }
            style={{ marginTop: '5px' }}
          >
            <Radio value="never">Never</Radio>
            <Radio value="on">On</Radio>
            <Radio value="after">After</Radio>
          </Radio.Group>

          {assignmentDetails[assignment]?.endCondition === 'on' && (
            <DatePicker
              onChange={(date) =>
                handleDetailChange(
                  assignment,
                  'endDate',
                  date ? dayjs(date).toISOString() : null
                )
              }
              style={{ marginTop: '10px', display: 'block' }}
            />
          )}

          {assignmentDetails[assignment]?.endCondition === 'after' && (
            <Input
              type="number"
              min={1}
              value={assignmentDetails[assignment]?.occurrences}
              onChange={(e) =>
                handleDetailChange(assignment, 'occurrences', e.target.value)
              }
              placeholder="Occurrences"
              style={{ width: '120px', marginTop: '10px' }}
            />
          )}
        </div>
      )}
    </div>
  );

  return (
    <>
      <Modal
        title={<div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Send Assignment</div>}
        visible={isAssignModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Send
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <p>
            Assign an assignment, PDF, or other task to your client. Customize its frequency, set optional start and end dates, and include a message.
          </p>

          <Form.Item>
            <div className="instructions">
              <strong>Instructions and Links</strong>
            </div>
            <Input.TextArea
              placeholder="Assign tasks such as calling a friend, going for a walk, or practicing mindfulness. Alternatively, use this space to provide additional instructions or context for the assignment(s) selected below. You can also include links to websites."
              allowClear
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              maxLength={maxChars}
              showCount
              autoSize={{ minRows: 4, maxRows: 30 }}
            />
          </Form.Item>

          <strong>My Assignments</strong>
          {customWorksheets.length > 0 ? (
            customWorksheets.map((customWorksheet) => (
              <Form.Item key={customWorksheet.id}>
                <InfoCircleOutlined
                  style={{ marginRight: 10, color: '#1890ff', cursor: 'pointer' }}
                  onClick={() => openAssignmentModal(customWorksheet)}
                />
                <Checkbox
                  checked={selectedAssignments.includes(customWorksheet)}
                  onChange={() => handleCheckboxChange(customWorksheet)}
                >
                  {customWorksheet.title}
                </Checkbox>
                {/*{selectedAssignments.includes(customWorksheet) &&
                  renderAssignmentControls(customWorksheet, 'customWorksheets')}*/}
              </Form.Item>
            ))
          ) : (
            <p>No assignments available. Create new assignments using Assignment Builder.</p>
          )}

          <strong>Community</strong>
          {savedWorksheets.length > 0 ? (
            savedWorksheets.map((savedWorksheet) => (
              <Form.Item key={savedWorksheet.id}>
                <InfoCircleOutlined
                  style={{ marginRight: 10, color: '#1890ff', cursor: 'pointer' }}
                  onClick={() => openCommunityAssignmentModal(savedWorksheet)}
                />
                <Checkbox
                  checked={selectedAssignments.includes(savedWorksheet)}
                  onChange={() => handleCheckboxChange(savedWorksheet)}
                >
                  {savedWorksheet.title}
                </Checkbox>
                {/*{selectedAssignments.includes(savedWorksheet) &&
                  renderAssignmentControls(savedWorksheet, 'savedWorksheets')}*/}
              </Form.Item>
            ))
          ) : (
            <p>No saved assignments. Save assignments using Community.</p>
          )}

          <strong>My PDFs</strong>
          {worksheets.length > 0 ? (
            worksheets.map((worksheet) => (
              <Form.Item key={worksheet.id}>
                <Checkbox
                  checked={selectedAssignments.includes(worksheet.name)}
                  onChange={() => handleCheckboxChange(worksheet.name)}
                >
                  {worksheet.name}
                </Checkbox>
                {/*{selectedAssignments.includes(worksheet.name) &&
                  renderAssignmentControls(worksheet.name, 'worksheets')}*/}
              </Form.Item>
            ))
          ) : (
            <p>No PDFs available. Upload new PDFs using Upload PDF.</p>
          )}

          <strong>Validated Measures</strong> {/* New Section */}
          {validatedMeasures.map((measure) => (
            <Form.Item key={measure}>
              <Checkbox
                checked={selectedAssignments.includes(measure)}
                onChange={() => handleCheckboxChange(measure)}
              >
                {measure}
              </Checkbox>
              {/*{selectedAssignments.includes(measure) &&
                renderAssignmentControls(measure, 'validatedMeasures')}*/}
            </Form.Item>
          ))}

          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div>
              <strong>Other Assignments</strong>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <button
                type="text"
                onClick={() => {
                  handleCancel(); // Close the modal first
                  navigate(`/client/${client.id}`, {
                    state: {
                      client: client,
                      tasks: worksheets.filter((task) => task.email === client.email),
                      activeTab: '3',
                    },
                  });
                }}
                style={{
                  cursor: 'pointer',
                  color: '#1890ff',
                  border: 'none',
                  background: 'transparent',
                }}
              >
                Add or Remove This Client's Features
              </button>
            </div>
          </div>
          {otherAssignments.filter((a) => features.includes(otherAssignmentMappings[a.name])).map((assignment) => (
            <Form.Item key={assignment.name}>
              <InfoCircleOutlined
                style={{ marginRight: 10, color: '#1890ff', cursor: 'pointer' }}
                onClick={() => openInfoModal(assignment.name, assignment.description, assignment.images)}
              />
              <Checkbox
                checked={selectedAssignments.includes(assignment.name)}
                onChange={() => handleCheckboxChange(assignment.name)}
              >
                {assignment.name}
              </Checkbox>
              {/*{selectedAssignments.includes(assignment.name) &&
                renderAssignmentControls(assignment.name, 'otherAssignments')}*/}
            </Form.Item>
          ))}

        </Form>
      </Modal>

      <InfoModal
        visible={isInfoModalVisible}
        onClose={() => setIsInfoModalVisible(false)}
        title={infoContent.title}
        content={infoContent.content}
        images={infoContent.images}
      />

      <AssignmentPreviewModal
        title={assignmentData.title}
        assignmentData={assignmentData.elements}
        isVisible={isAssignmentModalVisible}
        onClose={closeAssignmentModal}
      />

      <AssignmentPreviewModal
        title={assignmentData.title}
        assignmentData={assignmentData.worksheet}
        isVisible={isCommunityAssignmentModalVisible}
        onClose={closeCommunityAssignmentModal}
      />
    </>
  );
};

export default SendAssignmentModal;