// File: src/components/Product.jsx
import React from "react";
import { useState } from 'react';
import "../styles/NewHome.css"; // Import the corresponding CSS file
import "../styles/Product.css";
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import ToolkitAccordion from "../components/ToolkitAccordion";
import CarouselComponent from "../components/CarouselComponent";
//image imports
import { logosGreen } from '../config/logos';
import linkedinicon from '../assets/linkedinicon.png';
import home from '../assets/home.png';
import md1 from '../assets/assignmenttoolbox/md1.png';
import assignmentbuilder from '../assets/assignmentbuilder.png';
import web from '../assets/webapp.png';

const Product = () => {
    const currentYear = new Date().getFullYear();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    
        const toggleMenu = () => {
          setMenuOpen(!menuOpen);
        };

const handleEmailChange = (e) => {
    setEmail(e.target.value);
};

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";
const currentEnv = process.env.REACT_APP_ENV || "development";

const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch(`${api}/Waitlist/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
                //console.log("error");
            }

            alert('You successfully joined our mailing list! We will contact you soon.');
            setEmail('');
        } catch (error) {
            setError('There was a problem submitting your email. Please try again later.');
        }
    };

  const evidenceMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
          </Menu.Item>
        </Menu>
      );

  const aboutMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/About" style={{ textDecoration: 'none' }}>About</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
          </Menu.Item>
        </Menu>
      );

const productMenu = (
        <Menu>
          <Menu.Item key="product">
            <a href="/Product" style={{ textDecoration: 'none' }}>Product</a>
          </Menu.Item>
          <Menu.Item key="startupguide">
            <a href="/StartUpGuide" style={{ textDecoration: 'none' }}>Getting Started</a>
          </Menu.Item>
        </Menu>
      );

  return (
    <>
    <head>
      <meta charSet='UTF-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <title>Yosa Product</title>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
      </style>
    </head>

    <div className="new-home">

    {/* Header */}
    <header className="header">
        <a href="/" className="logo">
          <img src={logosGreen[currentEnv]} alt="Yosa Logo" />
        </a>
        
        {/* Hamburger Menu Icon */}
        <button
            className={`menu-toggle ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>

        <nav className={`navigation ${menuOpen ? "open" : ""}`}>
            <ul>
            <li><a href="/">Home</a></li>
            <li>
              <Dropdown overlay={productMenu} trigger={['hover']}>
                <a style={{ textDecoration: 'none' }} href="/StartUpGuide">
                  Product <DownOutlined />
                </a>
              </Dropdown>
              </li>
            <li>
              <Dropdown overlay={evidenceMenu} trigger={['hover']}>
                <a style={{ textDecoration: 'none' }} href="/Evidence">
                  Evidence <DownOutlined />
                </a>
              </Dropdown>
            </li>
            <li><a href="/Pricing">Pricing</a></li>
            <li>
              <Dropdown overlay={aboutMenu} trigger={['hover']}>
                <a style={{ textDecoration: 'none' }} href="/About">
                  About <DownOutlined />
                </a>
              </Dropdown>
            </li>
            <li><a href="/LogIn">Log In</a></li>
            <li><a href="/SignUp">Sign Up</a></li>
            </ul>
        </nav>
    </header>

      {/* Hero Section */}
      <section className="hero">
        <div className="product-text">
          <h1>The tools you need to elevate client engagement during and between sessions</h1>
        </div>
        <div className="hero-images">
            <div className="mobile-image">
                <img src={home} alt="Mobile Mockup" />
            </div>
            <div className="web-image">
                <img src={web} alt="Desktop Mockup" />
            </div>
        </div>
      </section>

      {/* Features */}
        <section className="features-section">
        <div className="features-left">
            <div className="assignment-toolkit-text">
            <h2>Assignment Toolkit</h2>
            <p className="toolkit-description">
                Every new client brings a new situation, requiring a unique approach to their specific care. Yosa allows therapists to personalize the client experience, assigning tools and resources tailored to individual needs. When therapists add a client to Yosa, they can select specific features to suit the client’s therapeutic journey, which can be adjusted any time.
            </p>
            <p>
                <strong className="example-text">Example Scenario:</strong> A client is dealing with depression. Their therapist chooses to start by assigning the PHQ-9 assessment on a recurring basis, creating a collaborative safety plan for crisis moments, and using tools like the Activity Log for behavioral activation or the Journaling feature to enhance self-reflection. After some time, the therapist introduces Thought Records to this client after discussing its purpose and use during a session.
            </p>
            </div>
            <CarouselComponent />
        </div>
        <div className="features-right">
        <ToolkitAccordion />
        </div>
        </section>

      {/* Assignment Builder */}
      <section className="assignment-builder">
        <div className="assignment-builder-text">
          <h2>Assignment Builder</h2>
          <p>
            Yosa’s Assignment Builder allows therapists to create custom assignments with interactive components that clients can complete on the app. Therapists can choose from a variety of components such as text fields, checkboxes, sliders and more to create tailored assignments that align with their clients’ needs. Therapists can also upload PDFs to send to clients, which clients in turn can complete and submit responses back.
          </p>
        </div>
        <img src={assignmentbuilder} alt="Assignment Builder" />
      </section>

      {/* Journey */}
      <section className="meal-donations">
        <div className="meal-donations-text">
          <h2>Journey</h2>
          <p>
          Yosa's Journey feature and meal donation program is designed to motivate clients to stay consistent in their mental health journey while contributing to a meaningful cause. It’s a way to inspire commitment, build accountability, and help your clients feel connected to a larger purpose. Here’s how it works: The more users engage with Yosa's features, such as journaling, breathing exercises, or completing therapeutic tasks, the more meals we will donate on their behalves. This tangible outcome provides a sense of purpose, accomplishment, and giving back, all while reinforcing positive habits. Clients can see their individual impact—how many meals they’ve donated—and the collective contributions of the Yosa community. As clients engage with the app and complete challenges, they also earn experience points (EXP) to level up along the way, with trophies celebrating their consistency and positive contributions, adding an extra layer of engagement. Clients can also complete randomized daily quests for meal donations and EXP. The meal donation program empowers clients to see their mental health efforts not just as personal growth but as an opportunity to make a real-world difference. It’s a unique way to keep them engaged, motivated, and connected to the therapeutic process. While not technically an assignment, this is a feature you can choose to opt into or out of for your clients.          </p>
        </div>
        <img src={md1} alt="Journey" />
      </section>

      <h3 className="learn-more"><a href="/startupguide">Click here to learn more about our features!</a></h3>

      {/* Waitlist Signup */}
      <section className="newsletter">
        <h2 style={{marginBottom: '30px'}}>Get started today for free! No credit card required.</h2>
        <a href="/signup" className="free-trial-button">Sign Up</a>
      </section>

      <footer className="footer">
                <div className="footer-content">
                    <p>&copy; {currentYear} Yosa Inc. All rights reserved.</p>
                    <nav>
                    <ul>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                        <li><a href="/terms-of-service">Terms of Service</a></li>
                        <li><a href="/baa">Business Associate Agreement</a></li>
                    </ul>
                    </nav>
                    <div className="footer-bottom">
                    <p>
                        <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
                        <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
                        <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
                        </a>
                    </p>
                    </div>
                </div>
            </footer>
    </div>
    </>
  );
};

export default Product;
