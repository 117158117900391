import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import logincover from '../assets/logincover.png';
import { RollbackOutlined } from '@ant-design/icons';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { confirmResetPassword } from 'aws-amplify/auth';

const poolData = {
    UserPoolId: 'us-east-1_2Pbc3zfmr',
    ClientId: '7q641c75ljpv2snpitel88muip',
};

const ConfirmResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const email = location.state?.email || ""; // Retrieve the email from the state

    const handleConfirmResetPassword = async ({ username, confirmationCode, newPassword }) => {
        try {
            await confirmResetPassword({ username, confirmationCode, newPassword });
            //console.log('Password reset confirmed successfully.');
            navigate('/login');
        } catch (error) {
            console.error('Confirm Password Reset Error:', error);
            setErrorMessage(error.message || 'An error occurred during password reset confirmation.');
        }
    };

    const onFinish = (values) => {
        const { email, code, password } = values;
        handleConfirmResetPassword({ username: email, confirmationCode: code, newPassword: password });
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Form submission failed:', errorInfo);
        setErrorMessage('Please fill in all required fields.');
    };

    return (
        <>
            <section
                style={{
                    display: "flex",
                    height: "100vh",
                    fontFamily: "Mulish, sans-serif",
                }}
            >
                {/* Left Section */}
                <div
                    style={{
                        flex: "1",
                        minWidth: "300px",
                        backgroundColor: "#FFEBA3",
                        padding: "1rem",
                    }}
                >
                    <img
                        src={logincover}
                        alt="Yosa Cover"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    />
                </div>

                {/* Right Section */}
                <div
                    style={{
                        flex: "1",
                        maxWidth: "500px",
                        overflowY: "auto",
                        padding: "2.5rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <RollbackOutlined className="payment-back-button" onClick={() => window.history.back()} />
                    <h1 style={{ fontSize: "2rem", fontWeight: "bold" }}>Confirm Reset Password</h1>
                    <p style={{ color: "#323232", marginBottom: "2.5rem" }}>
                        Enter your confirmation code and set a new password.
                    </p>

                    <Form
                        name="confirm-reset-password-form"
                        style={{ width: "100%" }}
                        initialValues={{ email }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item label="Email" name="email">
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="Confirmation Code"
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the confirmation code!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="New Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your new password!",
                                },
                                { min: 8, message: "Password must be at least 8 characters long!" },
                                { max: 128, message: "Password cannot exceed 128 characters!" },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Confirm New Password"
                            name="confirmpassword"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: "Please confirm your new password!",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject("Passwords do not match!");
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        {errorMessage && (
                            <div style={{ color: "red", marginBottom: "1rem" }}>{errorMessage}</div>
                        )}

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                                style={{
                                    backgroundColor: "#FFEBA3",
                                    color: "#A68200",
                                    fontWeight: "bold",
                                    borderRadius: "40px",
                                    border: "none",
                                }}
                            >
                                Confirm Password Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </section>
        </>
    );
};

export default ConfirmResetPassword;