import React, { useState, useEffect } from "react";
import { Form, Input, DatePicker, Button, Row, Col, Typography } from "antd";
import moment from "moment";

const { Title, Text } = Typography;
const { TextArea } = Input;

const NewReportForm = ({
  initialValues = {},
  onSubmit,
  onDelete,
  onCancel,
  defaultEditMode = true
}) => {
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(defaultEditMode);

  // Populate form with initialValues, converting date strings -> moment
  useEffect(() => {
    const cloned = { ...initialValues };
    if (cloned.startDate) cloned.startDate = moment(cloned.startDate);
    if (cloned.endDate) cloned.endDate = moment(cloned.endDate);
    form.setFieldsValue(cloned);
  }, [initialValues, form]);

  // Called after user clicks "Save"
  const handleSave = (values) => {
    const payload = {
      ...values,
      // Convert moment to string for start/end date
      startDate: values.startDate ? values.startDate.format("YYYY-MM-DD") : "",
      endDate: values.endDate ? values.endDate.format("YYYY-MM-DD") : "",
    };
    console.log("Submitting payload:", payload);
    onSubmit?.(payload);
    setIsEditMode(false);
  };

  // Standard antd form finish
  const handleFinish = () => {
    form.validateFields().then(handleSave).catch(() => {});
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleDelete = () => {
    onDelete?.(form.getFieldsValue());
  };

  const handleCancel = () => {
    if (isEditMode) {
      // revert form changes to initialValues
      const cloned = { ...initialValues };
      if (cloned.startDate) cloned.startDate = moment(cloned.startDate);
      if (cloned.endDate) cloned.endDate = moment(cloned.endDate);
      form.setFieldsValue(cloned);
      setIsEditMode(false);
    } else {
      // read-only mode => just call onCancel
      onCancel?.();
    }
  };

  // Grab the current form values for read-only display
  const {
    reportId,
    startDate,
    endDate,
    patientName,
    patientExternal,
    patientDOB,
    therapistName,
    therapistNPI,
    practiceName,
    practiceAddress,
    practicePhone,
    diagnosis,
    reasonForRTM,
    timeSpent,
    collectedDates,
    totalDays,
    complianceSummary,
    deviceSupplyCost,
    notes
  } = form.getFieldsValue(true);

  // Format dates for read-only mode
  const displayStartDate = startDate && moment.isMoment(startDate)
    ? startDate.format("YYYY-MM-DD")
    : startDate || "";
  const displayEndDate = endDate && moment.isMoment(endDate)
    ? endDate.format("YYYY-MM-DD")
    : endDate || "";

  // Simple page style for a print-like layout
  const pageStyle = {
    width: '8.5in',
    minHeight: '11in',
    margin: '1rem auto',
    padding: '1rem',
    backgroundColor: '#fff',
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    pageBreakAfter: 'always',
  };

  return (
    <div style={pageStyle}>
      <Form
        form={form}
        layout="vertical"
        style={{ padding: 24 }}
        onFinish={handleFinish}
      >
        {/* Top bar with Save/Delete/Edit/Cancel */}
        <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Col>
            {isEditMode ? (
              <>
                <Button
                  type="primary"
                  onClick={handleFinish}
                  style={{
                    color: "white",
                    backgroundColor: "#375945",
                    borderRadius: 35,
                    padding: "0 40px",
                    marginRight: 8
                  }}
                >
                  Save
                </Button>
                <Button
                  style={{ borderRadius: 35, padding: "0 40px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "#375945",
                    borderRadius: 35,
                    padding: "0 40px",
                    marginRight: 8
                  }}
                  onClick={handleEdit}
                >
                  Edit
                </Button>
                <Button
                  danger
                  style={{ borderRadius: 35, padding: "0 40px", marginRight: 8 }}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
                <Button
                  style={{ borderRadius: 35, padding: "0 40px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
          </Col>
        </Row>

        <Title level={3} style={{ marginTop: 0 }}>
          CPT 98978 Supporting Documentation
        </Title>

        {/* 1) Report #, Start Date, End Date */}
        <Row gutter={16}>
          <Col xs={24} md={8}>
            {isEditMode ? (
              <Form.Item label="Report #" name="reportId">
                <Input disabled />
              </Form.Item>
            ) : (
              <div style={{ marginBottom: 16 }}>
                <Text strong>Report #:</Text> {reportId || "—"}
              </div>
            )}
          </Col>
          <Col xs={24} md={8}>
            {isEditMode ? (
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Please select a start date" }]}
              >
                <DatePicker style={{ width: "100%" }} disabled />
              </Form.Item>
            ) : (
              <div style={{ marginBottom: 16 }}>
                <Text strong>Start Date:</Text> {displayStartDate || "—"}
              </div>
            )}
          </Col>
          <Col xs={24} md={8}>
            {isEditMode ? (
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Please select an end date" }]}
              >
                <DatePicker style={{ width: "100%" }} disabled />
              </Form.Item>
            ) : (
              <div style={{ marginBottom: 16 }}>
                <Text strong>End Date:</Text> {displayEndDate || "—"}
              </div>
            )}
          </Col>
        </Row>

        {/* 2) Patient & Provider */}
        <Row gutter={16} style={{ marginTop: 24 }}>
          <Col xs={24} md={12}>
            <Title level={5}>Patient</Title>
            {isEditMode ? (
              <>
                <Form.Item
                  label="Name"
                  name="patientName"
                  rules={[{ required: true, message: "Patient name is required" }]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="Member ID"
                  name="patientExternal"
                  rules={[{ required: true, message: "Patient member ID is required" }]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="DOB"
                  name="patientDOB"
                  rules={[{ required: true, message: "Patient DOB is required" }]}
                >
                  <Input disabled />
                </Form.Item>
              </>
            ) : (
              <>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Name:</Text> {patientName || "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Member ID:</Text> {patientExternal || "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>DOB:</Text> {patientDOB || "—"}
                </div>
              </>
            )}
          </Col>

          <Col xs={24} md={12}>
            <Title level={5}>Provider</Title>
            {isEditMode ? (
              <>
                <Form.Item
                  label="Name"
                  name="therapistName"
                  rules={[{ required: true, message: "Provider name is required" }]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="NPI"
                  name="therapistNPI"
                  rules={[{ required: true, message: "NPI is required" }]}
                >
                  <Input disabled />
                </Form.Item>
              </>
            ) : (
              <>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Name:</Text> {therapistName || "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>NPI:</Text> {therapistNPI || "—"}
                </div>
              </>
            )}
          </Col>
        </Row>

        {/* 3) Practice Info */}
        <Row style={{ marginTop: 24 }} gutter={16}>
          <Col xs={24} md={12}>
            <Title level={5}>Practice</Title>
            {isEditMode ? (
              <>
                <Form.Item label="Practice Name" name="practiceName">
                  <Input disabled />
                </Form.Item>
                <Form.Item label="Practice Address" name="practiceAddress">
                  <Input disabled />
                </Form.Item>
                <Form.Item label="Practice Phone" name="practicePhone">
                  <Input disabled />
                </Form.Item>
              </>
            ) : (
              <>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Practice Name:</Text> {practiceName || "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Practice Address:</Text> {practiceAddress || "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Practice Phone:</Text> {practicePhone || "—"}
                </div>
              </>
            )}
          </Col>
        </Row>

        {/* 4) Clinical Justification */}
        <Row style={{ marginTop: 24 }} gutter={16}>
          <Col span={24}>
            <Title level={5}>Clinical Justification</Title>
            {isEditMode ? (
              <>
                <Form.Item
                  label="Diagnosis"
                  name="diagnosis"
                  rules={[{ required: true, message: "Diagnosis is required" }]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="Reason for RTM / Use Case"
                  name="reasonForRTM"
                >
                  <TextArea
                    rows={4}
                    disabled
                    style={{ resize: 'inherit' }}
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Diagnosis:</Text> {diagnosis || "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Reason for RTM / Use Case:</Text> {reasonForRTM || "—"}
                </div>
              </>
            )}
            <div style={{ marginTop: 8, fontStyle: "italic", color: "gray" }}>
              Ensuring consistent engagement with therapy-related tasks (often called homework)
              between sessions is essential for lasting progress. Research consistently shows that
              "homework" compliance in therapy significantly improves patient outcomes (e.g.,
              Kazantzis et al., 2010; Kazantzis et al., 2016).
            </div>
          </Col>
        </Row>

        {/* 5) Time Spent */}
        <Row style={{ marginTop: 24 }} gutter={16}>
          <Col xs={24} md={12}>
            <Title level={5}>Time Spent (Minutes)</Title>
            {isEditMode ? (
              <Form.Item
                name="timeSpent"
              >
                <Input placeholder="e.g. 45" />
              </Form.Item>
            ) : (
              <div style={{ marginBottom: 8 }}>
                <Text strong>Time Spent:</Text> {timeSpent || "—"} minutes
              </div>
            )}
          </Col>
        </Row>

        {/* 6) Data Collection (Read-only) */}
        <Row style={{ marginTop: 24 }} gutter={16}>
          <Col span={24}>
            <Title level={5}>Data Collection Details</Title>
            <div style={{ marginBottom: 8 }}>
              <Text strong>Collected Dates:</Text> {collectedDates || "[will be populated on generation]"}
            </div>
            <div style={{ marginBottom: 8 }}>
              <Text strong>Total Number of Days:</Text> {totalDays ?? "[will be populated on generation]"}
            </div>
          </Col>
        </Row>

        {/* 7) Compliance Summary */}
        <Row style={{ marginTop: 24 }} gutter={16}>
          <Col span={24}>
            <Title level={5}>Compliance Summary</Title>
            {isEditMode ? (
              <Form.Item
                name="complianceSummary"
              >
                <TextArea
                  rows={3}
                  placeholder="At least 16 days of patient data was collected via Yosa, transmitted to, and reviewed by the provider, fulfilling CPT 98978 requirements..."
                />
              </Form.Item>
            ) : (
              <div style={{ marginBottom: 8 }}>
                {complianceSummary || "—"}
              </div>
            )}
          </Col>
        </Row>

        {/* 8) Device Supply Cost */}
        <Row style={{ marginTop: 24 }} gutter={16}>
          <Col xs={24} md={12}>
            <Title level={5}>Device Supply Cost</Title>
            {isEditMode ? (
              <Form.Item name="deviceSupplyCost" label="Cost ($)">
                <Input placeholder="e.g. 50.00" />
              </Form.Item>
            ) : (
              <div style={{ marginBottom: 8 }}>
                <Text strong>Cost:</Text> {deviceSupplyCost || "—"}
              </div>
            )}
          </Col>
        </Row>

        {/* 9) Additional Notes */}
        <Row style={{ marginTop: 24 }}>
          <Col span={24}>
            <Title level={5}>Additional Notes</Title>
            {isEditMode ? (
              <Form.Item name="notes">
                <TextArea rows={4} placeholder="Any extra documentation, special circumstances, or supplemental pricing info (e.g. In Massachusetts, similar RTM codes 98976 and 98977 are reimbursed at $39.57)..." />
              </Form.Item>
            ) : (
              <div style={{ whiteSpace: "pre-line", marginBottom: 8 }}>
                {notes || "—"}
              </div>
            )}
          </Col>
        </Row>

        {/* Footer */}
        <Row style={{ marginTop: 24 }}>
          <Col span={24}>
            <Text type="secondary" style={{ fontStyle: "italic" }}>
              Data collected and verified via Yosa Inc.'s remote monitoring application.
              Visit www.yosaapp.com for more details.
            </Text>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default NewReportForm;
