import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { logos } from '../config/logos';
import signUpPic from '../assets/signup.png';
import '../styles/SignUp.css';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

const poolData = {
  UserPoolId: 'us-east-1_2Pbc3zfmr', 
  ClientId: '7q641c75ljpv2snpitel88muip', 
};

const userPool = new CognitoUserPool(poolData);

const stripePromise = loadStripe("pk_live_51QmgBbCidEoN7Kd1koHCeMXRRMvcwjC0tYOTVoSI31SZW79ik4Tgtzwc2xxDijMG9XDwIE19hhuWcUq3QPuZhMzj000YT00i3F");

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";
const currentEnv = process.env.REACT_APP_ENV || "development";

const ConfirmSignup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state.email;
  const pricingPlan = location.state?.pricingPlan;
  const organizationName = location.state?.organizationName || "default";
  const trialExpiration = location.state?.trialExpiration; // non-null if free trial was enabled
  const [errorMessage, setErrorMessage] = useState("");

  const onConfirmSignUp = async (email, confirmationCode) => {
    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(confirmationCode, true, async (err, result) => {
      if (err) {
        console.error('Confirmation Error:', err);
        setErrorMessage(err.message || 'An error occurred during confirmation.');
        return;
      }

      try {
        // If the user is on an enterprise or free plan, we do not want to direct them to Stripe.
        if (pricingPlan === "enterprise" || pricingPlan === "free") {
          await axios.post(`${api}/user-payments/`, {
            email: email,
            stripe_customer_id: "default",
            pricing_plan: pricingPlan, // "enterprise" or "free"
            organization_name: organizationName,
            payment_status: "active",
            trial_expiration: trialExpiration || null,
          });
          // Redirect to login or success page.
          navigate('/login');
        } else {
          // For standard subscriptions (e.g., "starter" or "practice"), create a user-payment record with "inactive"
          // status, request a Stripe Checkout session, and then redirect.
          await axios.post(`${api}/user-payments/`, {
            email: email,
            stripe_customer_id: "default",
            pricing_plan: pricingPlan,
            organization_name: organizationName,
            payment_status: "inactive",
          });
          const sessionRes = await axios.post(`${api}/create-checkout-session/`, {
            email: email,
            pricing_plan: pricingPlan,
          });
          const stripe = await stripePromise;
          await stripe.redirectToCheckout({ sessionId: sessionRes.data.sessionId });
        }
      } catch (paymentError) {
        console.error("Error creating UserPayment:", paymentError.response?.data || paymentError.message);
        setErrorMessage("Failed to create payment entry. Please try again.");
      }
    });
  };

  const onFinish = (values) => {
    onConfirmSignUp(email, values.confirmationCode);
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Form submission failed:', errorInfo);
  };

  return (
    <>
      <section className='signup-container'>
        <div className='signup-left'>
          <img id='signupimage' src={signUpPic} alt='Yosa Lady' />
        </div>
        <div className='signup-right'>
          <img className='signuplogo' src={logos[currentEnv]} alt="Yosa Logo" />
          <Form
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 20 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item label="Email">
              <Input value={email} disabled />
            </Form.Item>

            <Form.Item
              label="Confirmation Code"
              name="confirmationCode"
              rules={[
                { required: true, message: 'Please input your confirmation code!' },
                { max: 100, message: 'Code cannot exceed 100 characters!' },
              ]}
            >
              <Input />
            </Form.Item>

            {errorMessage && (
              <div className='error-message'>
                <p style={{ color: 'red' }}>{errorMessage}</p>
              </div>
            )}

            <div className='center'>
              <Button className='forgot-password' onClick={() => navigate('/login')}>
                Already have an account? Log in here
              </Button>
              <Form.Item>
                <Button className='submit-button' type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default ConfirmSignup;