import React, { useState, useEffect } from "react";
import { Layout, Button, Table, Card, Modal } from "antd";
import Sidebar from "../components/Sidebar"; // Import Sidebar component
import { useNavigate, useLocation } from "react-router-dom";
import { FileTextOutlined, RollbackOutlined } from "@ant-design/icons";
import "../styles/AdminDashboard.css";

const { Header, Content } = Layout;

const Billing = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const therapistEmail = location.state?.therapistEmail;
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);

  const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

  useEffect(() => {
    if (therapistEmail) {
      fetchPayments();
    }
  }, [therapistEmail]);

  const fetchPayments = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${api}/api/user-payments?email=${therapistEmail}`);
      const data = await response.json();
      const formattedData = data.map((payment) => ({
        ...payment,
        created_at: new Date(payment.created_at).toLocaleString(),
      }));
      setPayments(formattedData);
    } catch (error) {
      console.error("Error fetching payment data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Columns for the Invoice History Table
  const columns = [
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Pricing Plan", dataIndex: "pricing_plan", key: "pricing_plan" },
    { title: "Payment Status", dataIndex: "payment_status", key: "payment_status" },
    { title: "Created At", dataIndex: "created_at", key: "created_at" },
  ];

  const handleCancelSubscription = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel the subscription?",
      content: "This will also cancel any respective provider accounts.",
      okText: "Yes, Cancel Subscription",
      cancelText: "No, Keep Subscription",
      onOk: () => {
        // Add your cancellation logic here.
      },
    });
  };

  const manageSubscription = async () => {
    try {
      const response = await fetch(`${api}/create-customer-portal/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: therapistEmail }),
      });
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error("Error opening Stripe Customer Portal:", error);
    }
  };

  // Determine if the user is on the enterprise plan.
  const isEnterprise = payments.some(
    (payment) =>
      payment.pricing_plan &&
      payment.pricing_plan.toLowerCase() === "enterprise"
  );

  // Determine if the user is on the free plan.
  const userPayment = payments.find((payment) => payment.email === therapistEmail);
  const isFreeUser =
    userPayment &&
    userPayment.pricing_plan &&
    userPayment.pricing_plan.toLowerCase() === "free";

  return (
    <Layout>
      <Sidebar
        items={[
          {
            key: "dashboard",
            icon: <RollbackOutlined />,
            label: "Back",
            onClick: () => navigate("/Dashboard/"),
          },
        ]}
      />
      <Layout>
        <Header
          className="header"
          style={{
            padding: 40,
            fontWeight: "bold",
            fontSize: 25,
            fontFamily: "'Mulish', sans-serif",
            backgroundColor: "#C3D7CB",
            borderBottom: "3px solid #375945",
            color: "black",
          }}
        >
          Billing
        </Header>
        <Content className="scrollable-content">
          <div className="dashboard-content">
            <Card title="Payment Details">
              <Table
                columns={columns}
                dataSource={payments}
                loading={loading}
                rowKey="id"
                pagination={{ pageSize: 5 }}
              />
            </Card>

            {/* Conditionally render the billing management option */}
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              {isEnterprise ? (
                <p>
                  As an enterprise user, your payments are managed by the admin
                  of your organization.
                </p>
              ) : isFreeUser ? (
                <div>
                  <p>
                    You are currently using the Free Plan.
                  </p>
                  <Button
                    type="primary"
                    onClick={() => setIsPaymentModalVisible(true)}
                    style={{
                      backgroundColor: "#1C3727",
                      borderColor: "black",
                      color: "white",
                    }}
                  >
                    View Payment Plans
                  </Button>
                </div>
              ) : (
                <Button
                  type="primary"
                  onClick={manageSubscription}
                  style={{
                    backgroundColor: "#1C3727",
                    borderColor: "black",
                    color: "white",
                  }}
                >
                  Manage Billing on Stripe
                </Button>
              )}
            </div>
          </div>
        </Content>
      </Layout>

      <Modal
        open={isPaymentModalVisible}
        onCancel={() => setIsPaymentModalVisible(false)}
        footer={null}
        closable={true}
        title="Payment Plans"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <p style={{ textAlign: "center", marginBottom: "20px" }}>
            Please choose one of the following subscription options:
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginBottom: "20px",
              flexWrap: "wrap",
            }}
          >
            <Button
              type="primary"
              onClick={() =>
                (window.location.href =
                  "https://buy.stripe.com/cN229PgtScnUdNu8wx")
              }
              style={{
                backgroundColor: "#1C3727",
                borderColor: "black",
                color: "white",
              }}
            >
              Starter Level
            </Button>
            <Button
              type="primary"
              onClick={() =>
                (window.location.href =
                  "https://buy.stripe.com/bIYdSx6TidrYeRy3cf")
              }
              style={{
                backgroundColor: "#1C3727",
                borderColor: "black",
                color: "white",
              }}
            >
              Practice Level
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Billing;