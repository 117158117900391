import React from 'react';
import { Modal, Button } from 'antd';

const InfoModal = ({ visible, onClose, title, content, images }) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      {/* Display Preview Content */}
      <p>{content}</p>

      {/* Single Preview Image */}
      {images && images.length === 1 && (
        <img
          src={images[0]}
          alt={`${title} preview`}
          style={{
            width: "250px",
            height: "auto",
            marginTop: "10px",
            borderRadius: "8px",
          }}
        />
      )}

      {/* Multiple Preview Images */}
      {images && images.length > 1 && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          {images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`${title} preview ${index + 1}`}
              style={{
                width: "200px",
                height: "auto",
                borderRadius: "8px",
              }}
            />
          ))}
        </div>
      )}
    </Modal>
  );
};

export default InfoModal;
