import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Modal, Layout } from 'antd';
import { RollbackOutlined, DeleteOutlined, FileAddOutlined, CheckSquareOutlined, SmallDashOutlined, LineOutlined, BorderOutlined, PicCenterOutlined, SaveOutlined, EditOutlined, ShopOutlined } from '@ant-design/icons';
import Sidebar from '../components/Sidebar';
import '../styles/Worksheets.css';
import yellowshirtlady from '../assets/yellowshirtlady.png';
import ElementEditor from '../components/ElementEditor';
import axios from 'axios';
import { getCurrentUser } from 'aws-amplify/auth';
import AssignmentPreviewModal from '../components/AssignmentPreviewModal';

const { Header } = Layout;

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Add the Authorization header
    },
};

const getCurrentUsername = async () => {
    try {
        const { username } = await getCurrentUser();
        return username.toString();
    } catch (err) {
        console.error('Error getting current user:', err);
        throw err;
    }
};

const Worksheets = () => {
    const [selectedWorksheetKey, setSelectedWorksheetKey] = useState(null);
    const getSelectedWorksheet = () =>
        worksheets.find((worksheet) => worksheet.id === selectedWorksheetKey);
    const [worksheets, setWorksheets] = useState([]);
    const fetchWorksheets = async (username) => {
        try {
            const response = await axios.get(`${api}/CustomWorksheets/`, {
                ...axiosConfig,
                params: { username }, // Pass username as a query parameter
            });
            //console.log('Fetched worksheets:', response.data);
            setWorksheets(response.data);
        } catch (error) {
            console.error('Error fetching worksheets:', error);
        }
    };
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [titleInput, setTitleInput] = useState('');
    const [currentWorksheet, setCurrentWorksheet] = useState(null);

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [saveModalVisible, setSaveModalVisible] = useState(false);
    const [worksheetToDelete, setWorksheetToDelete] = useState(null);
    const [warningModalVisible, setWarningModalVisible] = useState(false);
    const [warningModalText, setWarningModalText] = useState('');
    const [isAssignmentModalVisible, setIsAssignmentModalVisible] = useState(false);
    const [assignmentData, setAssignmentData] = useState([]);

    const location = useLocation();

    const { Footer } = Layout;

    // * Constants * //

    const navigate = useNavigate();

    const sidebarItems = [
        {
            key: '1',
            icon: <RollbackOutlined />,
            label: 'Back',
            onClick: () => window.location.href = '/dashboard',
        },
        {
            key: '2',
            icon: <ShopOutlined />,
            label: 'Community',
            onClick: () => navigate('/community/'),
        },
    ];

    // SELECT WORKSHEET

    const [wkst, setWskt] = useState(null);

    const handleSelectWorksheet = (worksheetId) => {
        const selectedWorksheet = worksheets.find((ws) => ws.id === Number(worksheetId)); // Convert ID to number if necessary
        if (selectedWorksheet) {
            setWskt(selectedWorksheet);
        }
    };

    // Trigger handleSelectWorksheet only after worksheets are fetched
    useEffect(() => {
        if (worksheets.length > 0 && location.state?.worksheetId) {
            handleSelectWorksheet(location.state.worksheetId);
        }
    }, [worksheets, location.state]);

    // Trigger state updates for wkst
    useEffect(() => {
        if (wkst) {
            setCurrentWorksheet({
                ...wkst,
                elements: wkst.elements.map((el) => ({
                    type: el.type,
                    value: el.value || '', // Ensure `value` is defined
                })),
            });
            setSelectedWorksheetKey(wkst.id);
            setTitleInput(wkst.title);
        }
    }, [wkst]);

    // Fetch worksheets on component mount
    useEffect(() => {
        const fetchData = async () => {
            const username = await getCurrentUsername();
            await fetchWorksheets(username);
        };

        fetchData();
    }, []);

    // CREATE WORKSHEET

    const createWorksheet = async (title) => {
        const username = await getCurrentUsername();

        const newWorksheet = {
            title,
            username,
            elements: [], // Empty elements array by default
        };

        try {
            await axios.post(`${api}/CustomWorksheets/`, newWorksheet, axiosConfig);
            await fetchWorksheets(username);
        } catch (error) {
            console.error('Error creating worksheet:', error.response?.data || error.message);
        }
    };

    const handleCreateWorksheet = () => {
        const newTitle = `Assignment ${worksheets.length + 1}`;
        createWorksheet(newTitle);
    };

    // DELETE WORKSHEET

    const deleteWorksheet = async (worksheetId) => {
        try {
            const username = await getCurrentUsername();
            await axios.delete(`${api}/CustomWorksheets/${worksheetId}/`, axiosConfig);
            await fetchWorksheets(username);
        } catch (error) {
            console.error('Error deleting worksheet:', error);
        }
    };

    // Function to handle deleting a worksheet
    const handleDeleteWorksheet = (worksheetId) => {
        deleteWorksheet(worksheetId);
        if (selectedWorksheetKey === worksheetId) {
            setSelectedWorksheetKey(null);
            setCurrentWorksheet(null);
        }
    };

    const confirmDeleteWorksheet = () => {
        handleDeleteWorksheet(worksheetToDelete);
        setDeleteModalVisible(false);
        setWorksheetToDelete(null);
    };

    // ELEMENTS

    const handleAddElement = (type) => {
        if (currentWorksheet && currentWorksheet.elements?.length === 15) {
            setWarningModalText('Number of elements must not exceed 15. Consider splitting this assignment into multiple.');
            setWarningModalVisible(true);
            return;
        }
        const newElement = { type, value: '' };
        setCurrentWorksheet({
            ...currentWorksheet,
            elements: [...currentWorksheet.elements, newElement],
        });
    };

    const handleDeleteElement = (index) => {
        setCurrentWorksheet((prevWorksheet) => ({
            ...prevWorksheet,
            elements: prevWorksheet.elements.filter((_, i) => i !== index),
        }));
    };

    const handleMoveElementUp = (index) => {
        setCurrentWorksheet((prevWorksheet) => {
            const elements = [...prevWorksheet.elements];
            if (index > 0) {
                [elements[index - 1], elements[index]] = [elements[index], elements[index - 1]];
            }
            // Return a completely new object to force a re-render
            return { ...prevWorksheet, elements: [...elements] };
        });
    };

    const handleMoveElementDown = (index) => {
        setCurrentWorksheet((prevWorksheet) => {
            const elements = [...prevWorksheet.elements];
            if (index < elements.length - 1) {
                [elements[index], elements[index + 1]] = [elements[index + 1], elements[index]];
            }
            // Return a completely new object to force a re-render
            return { ...prevWorksheet, elements: [...elements] };
        });
    };

    const handleElementChange = (index, newValue) => {
        setCurrentWorksheet((prevWorksheet) => {
            const updatedElements = [...prevWorksheet.elements];
            updatedElements[index].value = newValue; // Update the value at the specific index
            return { ...prevWorksheet, elements: updatedElements }; // Return updated worksheet
        });
    };


    // SAVE AND DISCARD

    const discardChanges = () => {
        setSelectedWorksheetKey(null);
        setCurrentWorksheet(null);
    }

    const saveChanges = async () => {
        // Validation
        let incomplete = false;
        currentWorksheet?.elements.forEach((element) => {
            if (element.value === '') {
                setWarningModalText('Please fill out all text and instructions fields for this assignment.');
                setWarningModalVisible(true);
                incomplete = true;
            }
        });
        if (incomplete) return;

        try {
            const username = await getCurrentUsername();

            const isValidElements = currentWorksheet.elements.every(
                (el) => el.type && el.value !== undefined
            );

            if (!isValidElements) {
                setWarningModalText('All elements must have a type and value.');
                setWarningModalVisible(true);
                return;
            }

            const formattedElements = currentWorksheet.elements.map((element) => ({
                ...(element.id && { id: element.id }), // Only include id if it exists
                type: element.type,
                value: element.value,
            }));

            await axios.patch(`${api}/CustomWorksheets/${currentWorksheet.id}/`, {
                title: titleInput,
                elements: formattedElements,
            }, axiosConfig);

            // Update local state
            await fetchWorksheets(username);

            setSaveModalVisible(true); // Show save confirmation modal
        } catch (error) {
            console.error('Error saving worksheet:', error);
            setWarningModalText('Failed to save the assignment. Please try again.');
            setWarningModalVisible(true);
        }
    };

    // TITLE

    const handleEditTitle = () => {
        setIsEditingTitle(true);
        setTitleInput(titleInput !== '' ? titleInput : currentWorksheet.title); // Initialize input
    };

    const handleTitleSave = async () => {
        // Update local state
        setCurrentWorksheet((prevWorksheet) => ({
            ...prevWorksheet,
            title: titleInput,
        }));
        setIsEditingTitle(false);
    };

    // BUTTON AND MODAL LOGIC

    const areElementsEqual = (elements1, elements2) => {
        if (elements1.length !== elements2.length) {
            return false;
        }

        for (let i = 0; i < elements1.length; i++) {
            if (elements1[i].value !== elements2[i].value || elements1[i].type !== elements2[i].type) {
                return false;
            }
        }

        return true;
    };

    const openAssignmentModal = (custom_worksheet) => {
        setAssignmentData(custom_worksheet);
        setIsAssignmentModalVisible(true);
    };

    const closeAssignmentModal = () => {
        setIsAssignmentModalVisible(false);
    };

    return (
        <>
            <head>
                <meta charSet='UTF-8' />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <title>Yosa Assignment Builder</title>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
                </style>
            </head>
            <div className="worksheets-container">
                <Sidebar items={sidebarItems} />

                <div className="toolbox-content-outer">
                    <Header
                        className='header'
                        style={{
                            padding: 40,
                            fontWeight: 'bold',
                            fontSize: 25,
                            fontFamily: 'Mulish',
                            backgroundColor: '#C3D7CB',
                            borderBottom: '3px solid #375945',
                            color: 'black',
                            minWidth: '-moz-max-content',
                        }}
                    >
                        <div>
                            {'Assignment Builder'}
                        </div>
                    </Header>

                    <div className="toolbox-content">

                        <div className="content-sections">
                            {/* List Section */}
                            <div className="list-section">
                                <div className="worksheets-list">
                                    {worksheets.map((worksheet) => (
                                        <div
                                            key={worksheet.id} // Use a unique identifier
                                            className="worksheet-item"
                                            style={{
                                                display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                                                wordBreak: 'break-word', // Break long words to fit within the width
                                                overflowWrap: 'break-word', // Fallback for better compatibility
                                            }}
                                            onClick={() => handleSelectWorksheet(worksheet.id)}
                                        >
                                            <span>{worksheet.title}</span>
                                            <DeleteOutlined
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setWorksheetToDelete(worksheet.id);
                                                    setDeleteModalVisible(true);
                                                }}
                                                className="delete-worksheet-button"
                                            />
                                        </div>
                                    ))}
                                    <Button
                                        type="primary"
                                        icon={<FileAddOutlined />}
                                        className="add-worksheet-button"
                                        onClick={handleCreateWorksheet}
                                    >
                                        Create New Assignment
                                    </Button>
                                </div>
                            </div>

                            {/* Creation and Editing Section */}
                            <div className="creation-section">
                                <h2 className="creation-title">
                                    {currentWorksheet && (
                                        <div className="editable-title">
                                            {isEditingTitle ? (
                                                <div className="title-edit-container">
                                                    <input
                                                        type="text"
                                                        className="title-input"
                                                        value={titleInput}
                                                        maxLength={50}
                                                        showCount={true}
                                                        onChange={(e) => setTitleInput(e.target.value)}
                                                    />
                                                    <SaveOutlined
                                                        className="title-save-icon"
                                                        onClick={handleTitleSave}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className="title-display-container"
                                                    style={{
                                                        width: '500px',
                                                        wordBreak: 'break-word', // Break long words to fit within the width
                                                        overflowWrap: 'break-word', // Fallback for better compatibility
                                                    }}
                                                >
                                                    <span>{currentWorksheet.title}</span>
                                                    <EditOutlined
                                                        className="title-edit-icon"
                                                        onClick={handleEditTitle}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </h2>
                                <div className="buttons">
                                    {currentWorksheet && (
                                        <div className="action-buttons">
                                            {currentWorksheet.title === getSelectedWorksheet().title && areElementsEqual(currentWorksheet.elements, getSelectedWorksheet().elements) ?
                                                (
                                                    <div className="action-item saved" style={{ marginBottom: '10px' }} >
                                                        Saved
                                                    </div>
                                                ) : (
                                                    <div className="action-item save-action" style={{ marginBottom: '10px' }} onClick={() => saveChanges()}>
                                                        Save
                                                    </div>
                                                )
                                            }
                                            <div className="action-item preview-action" style={{ marginBottom: '10px' }} onClick={() => openAssignmentModal(currentWorksheet)}>
                                                Preview
                                            </div>
                                            <div className="action-item discard-action" style={{ marginBottom: '10px' }} onClick={() => discardChanges()}>
                                                Close
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {(currentWorksheet ? (
                                    <div className="creation-content">
                                        <div className="worksheet-elements">
                                            {/* Left Section: Current Worksheet Elements */}
                                            <div className="elements-list">
                                                {currentWorksheet?.elements.length > 0 ? (
                                                    currentWorksheet.elements.map((element, index) => (
                                                        <ElementEditor
                                                            key={`${element.type}-${index}`} // Combine type and index for a unique key
                                                            element={element}
                                                            index={index}
                                                            onDelete={handleDeleteElement}
                                                            onMoveUp={handleMoveElementUp}
                                                            onMoveDown={handleMoveElementDown}
                                                            onChange={handleElementChange}
                                                            isFirst={index === 0}
                                                            isLast={index === currentWorksheet.elements.length - 1}
                                                        />
                                                    ))
                                                ) : (
                                                    <p style={{ marginLeft: '10px' }}>This worksheet has no elements. Add elements from the menu to the right.</p>
                                                )}
                                            </div>
                                        </div>

                                        {/* Right Section: Available Elements */}
                                        <div className="available-elements">
                                            <h2 className="elements-title">Static</h2>
                                            <div className="elements-grid">
                                                <div className="element-item" onClick={() => handleAddElement('Text')}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', }}>
                                                        <PicCenterOutlined />
                                                        <p style={{ margin: 0 }}>Text</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <h2 className="elements-title-2">Response</h2>
                                            <div className="elements-grid">
                                                <div className="element-item" onClick={() => handleAddElement('Text Response')}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', }}>
                                                        <BorderOutlined />
                                                        <p style={{ margin: 0 }}>Text</p>
                                                    </div>
                                                </div>
                                                <div className="element-item" onClick={() => handleAddElement('Checkbox Response')}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', }}>
                                                        <CheckSquareOutlined />
                                                        <p style={{ margin: 0 }}>Checkbox</p>
                                                    </div>
                                                </div>
                                                <div className="element-item" onClick={() => handleAddElement('Mood Response')}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', }}>
                                                        <SmallDashOutlined />
                                                        <p style={{ margin: 0 }}>Mood</p>
                                                    </div>
                                                </div>
                                                <div className="element-item" onClick={() => handleAddElement('Slider Response 5')}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', }}>
                                                        <div>
                                                            <LineOutlined /> 5
                                                        </div>
                                                        <p style={{ margin: 0 }}>Slider</p>
                                                    </div>
                                                </div>
                                                <div className="element-item" onClick={() => handleAddElement('Slider Response 10')}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', }}>
                                                        <div>
                                                            <LineOutlined /> 10
                                                        </div>
                                                        <p style={{ margin: 0 }}>Slider</p>
                                                    </div>
                                                </div>
                                                <div className="element-item" onClick={() => handleAddElement('Slider Response 100')}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', flexDirection: 'column', }}>
                                                        <div>
                                                            <LineOutlined /> 100
                                                        </div>
                                                        <p style={{ margin: 0 }}>Slider</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="non-selected">
                                        <div className="non-selected-text">
                                            Create a new assignment or select an existing assignment to start editing!
                                        </div>
                                    </div>
                                )
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Delete Confirmation Modal */}
                <Modal
                    title="Are you sure?"
                    open={deleteModalVisible}
                    onOk={confirmDeleteWorksheet}
                    onCancel={() => setDeleteModalVisible(false)}
                    okText="Delete"
                    cancelText="Cancel"
                    okButtonProps={{ danger: true }}
                >
                    <p>This action cannot be undone.</p>
                </Modal>

                {/* Warning Modal */}
                <Modal
                    title="Warning:"
                    open={warningModalVisible}
                    onOk={() => setWarningModalVisible(false)}
                    onCancel={() => setWarningModalVisible(false)}
                    footer={[
                        <Button key="ok" type="primary" onClick={() => setWarningModalVisible(false)}>
                            OK
                        </Button>,
                    ]}
                >
                    <p>{warningModalText}</p>
                </Modal>

                {/* Assignment Preview Modal */}
                <AssignmentPreviewModal
                    title={assignmentData.title}
                    assignmentData={assignmentData.elements}
                    isVisible={isAssignmentModalVisible}
                    onClose={closeAssignmentModal}
                />

            </div>
        </>
    );
};

export default Worksheets;
