// File: src/screens/Evidence.jsx
import React from "react";
import { useState } from 'react';
import "../styles/NewHome.css"; // Import the corresponding CSS file
import "../styles/Product.css";
import "../styles/Evidence.css";
import { Collapse, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
//image imports
import { logosGreen } from '../config/logos';
import linkedinicon from '../assets/linkedinicon.png';

const currentEnv = "production";

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const FAQs = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const evidenceMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
          </Menu.Item>
        </Menu>
      );

  const aboutMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/About" style={{ textDecoration: 'none' }}>About</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
          </Menu.Item>
        </Menu>
      );

  const productMenu = (
          <Menu>
            <Menu.Item key="product">
              <a href="/Product" style={{ textDecoration: 'none' }}>Product</a>
            </Menu.Item>
            <Menu.Item key="startupguide">
              <a href="/StartUpGuide" style={{ textDecoration: 'none' }}>Getting Started</a>
            </Menu.Item>
          </Menu>
        );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch(`${api}/Waitlist/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
        //console.log("error");
      }

      alert('You successfully joined our mailing list! We will contact you soon.');
      setEmail('');
    } catch (error) {
      setError('There was a problem submitting your email. Please try again later.');
    }
  };



  return (
    <>
      <head>
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>Yosa Homework</title>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
        </style>
      </head>

      <div className="new-home">

        {/* Header */}
        <header className="header">
          <a href="/" className="logo">
            <img src={logosGreen[currentEnv]} alt="Yosa Logo" />
          </a>

          {/* Hamburger Menu Icon */}
          <button
            className={`menu-toggle ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>

          <nav className={`navigation ${menuOpen ? "open" : ""}`}>
            <ul>
              <li><a href="/">Home</a></li>
              <li>
                            <Dropdown overlay={productMenu} trigger={['hover']}>
                              <a style={{ textDecoration: 'none' }} href="/StartUpGuide">
                                Product <DownOutlined />
                              </a>
                            </Dropdown>
                            </li>
              <li>
                <Dropdown overlay={evidenceMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/Evidence">
                    Evidence <DownOutlined />
                  </a>
                </Dropdown>
              </li>
              <li><a href="/Pricing">Pricing</a></li>
              <li>
                <Dropdown overlay={aboutMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/About">
                    About <DownOutlined />
                  </a>
                </Dropdown>
              </li>
              <li><a href="/LogIn">Log In</a></li>
              <li><a href="/SignUp">Sign Up</a></li>
            </ul>
          </nav>
        </header>



        <section
          className="faq-header"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '2.5rem',
            height: '100%',
            padding: '0 1rem', // Ensures no extra spacing on small screens
            maxWidth: '100vw', // Prevents horizontal scrolling
            boxSizing: 'border-box', // Ensures padding doesn't increase the width
            overflow: 'hidden', // Ensures no content spills over
          }}
        >

          <div
            className="faq-header-text"
            style={{
              textAlign: 'center',
              fontFamily: 'Mulish',
              color: '#375945',
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
              maxWidth: '100%', // Prevents text from exceeding the screen width
              padding: '0 1rem', // Prevents text from touching edges
            }}
          >

            <h1
              style={{
                fontSize: '2.8rem', // Use a responsive font size
                margin: 0,
                lineHeight: '1.2', // Add proper line spacing
                maxWidth: '100%', // Prevents horizontal overflow
              }}
            >
              Frequently Asked Questions
            </h1>
            <div>
              <h2
                style={{
                  fontSize: '1rem',
                  marginTop: '1rem',
                  lineHeight: '1.4', // Proper spacing for readability
                  maxWidth: '100%', // Prevents overflow
                }}
              >
                If you're thinking it, chances are others have too. Find answers to
                common questions about Yosa.
              </h2>
            </div>
          </div>
        </section>



        {/* General FAQs */}
        <h2 className="faq-section-header" style={{ display: 'flex', color: '#375945', paddingLeft: '4rem', marginBottom: '0', paddingTop: '1.5rem' }}>General</h2>
        <section className="solution-section" style={{ marginTop: '0', paddingTop: '0' }}>
          <div className="solution-text">
            <div className="cbt-accordion">
              <Collapse accordion>
                <Collapse.Panel className='cbt-accordionpanel' header="Is Yosa HIPAA-compliant?" key="1">
                  <p><strong>Answer:</strong> Yes! Yosa proudly adheres to strict HIPAA standards, ensuring the privacy and security of your clients’ data. See more about our privacy and security practices in the Privacy Policy and Notice of Privacy Practices.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="What devices can I use Yosa on?" key="2">
                  <p><strong>Answer:</strong> The provider portal and tools are available through any web browser online (You'll log in by clicking 'Log In' in the header above or click 'Sign Up' if you don't have an account). The client app is compatible with most smarphones running on iOS (e.g. iPhone) or Android.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="Who do I contact for technical support?" key="3">
                  <p><strong>Answer:</strong> For any technical issues or support, you can contact the Yosa team at support@yosaapp.com.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="Why is homework important in therapy?" key="4">
                  <p><strong>Answer:</strong> Homework is a critical component of therapy because it helps you apply the skills and techniques learned in sessions to your everyday life. Research shows that completing therapy homework is significantly linked to better outcomes, including symptom reduction and improved well-being. It allows you to practice new behaviors, reflect on insights, and build long-lasting changes outside of therapy sessions.</p>
                  <p>To learn more about the evidence behind homework's impact on therapy outcomes, visit our <a href='/Evidence'>Evidence</a> and <a href='/Homework'>Homework</a> pages.</p>
                </Collapse.Panel>
              </Collapse>
            </div>
          </div>
        </section>

        {/* Provider / Therapist FAQs */}
        <h2 className="faq-section-header" style={{ display: 'flex', color: '#375945', paddingLeft: '4rem', marginBottom: '0', paddingTop: '1.5rem' }}>Providers / Therapists</h2>
        <section className="solution-section" style={{ marginTop: '0', paddingTop: '0' }}>
          <div className="solution-text">
            <div className="cbt-accordion">
              <Collapse accordion>
                <Collapse.Panel className='cbt-accordionpanel' header="How do I sign up for Yosa?" key="1">
                  <p><strong>Answer:</strong> You can sign up for Yosa by visiting our <a href='/SignUp'>Sign Up</a> page and creating an account. Simply choose your subscription tier based on the number of clients in your practice, and follow the onboarding steps to set up your account. See our <a href='/Pricing'>Pricing</a> options here or contact us at support@yosaapp.com for further inquiries.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="How do I add my clients and ensure they're signed up?" key="2">
                  <p><strong>Answer:</strong> After creating your Yosa account and logging in, you will be navigated to the provider dashboard. From there, click the "Add Client" button on the green sidebar to the left. You'll then be prompted with instructions and must input the client's Name, Email, and select the Yosa features you want this client to have access to. Click "Send Invite" at the bottom of this page once you've done so. If your client does not have Yosa, we will send them an email with instructions guiding them along. Otherwise, they will receive an in-app notification that you added them as a client. If they have not registered, a red exclamation will appear left of their name in the "My Clients" table and "Send Assignment" button will be grayed out. If this is not the case, your client is signed up and you're all set!</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="How do I upload my own worksheet or assignment?" key="3">
                  <p><strong>Answer:</strong> After logging in, you will be navigated to the provider dashboard. From here, you can upload your own custom worksheets or assignments by clicking "Assignment Builder" to build an interactive assignment from scratch or "Upload PDF" to upload PDFs. These options can be found in the green sidebar on the left side of the dashboard. Once you've successfully uploaded a PDF or built an assignment from scratch, it will appear in the "My Library" table in the dashboard. Note you can navigate between viewing Assignments (from the Assignment Builder) and PDFs by selecting the headers in the table.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="Are validated measures like the PHQ-9 available to send and monitor through Yosa?" key="4">
                  <p><strong>Answer:</strong> Yes, validated measures, including the PHQ-9, GAD-7, and PCL-5 are available on Yosa, allowing you to assign them directly to clients (which they will be notified of and complete in-app) and monitor their responses in real-time. To send one of these measures to your client, select the "Send Assignment" button in the row of the client you wish to send it to. From there, select the checkbox next to the measure you wish to send and click "Send".</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="Do I need to be a CBT therapist to use Yosa?" key="5">
                  <p><strong>Answer:</strong> No, Yosa is designed to support therapists practicing across a variety of different modalities. While homework assignments are common in CBT, a number of other modalities also utilize homework and you can use Yosa to customize tasks and tools to suit various therapeutic approaches. See more about how Yosa can be used in different modalities in the <a href='/Homework'>Homework</a> page.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="What if I don't want my client to have access to parts of the app (e.g. the safety plan)?" key="6">
                  <p><strong>Answer:</strong> Yosa is designed to be tailored to each client's specific needs and goals and allows you to control which features your clients can access. When you first add a client, you'll select what features they have access to and can edit this in the future (either adding or removing features) as treatment progresses. For example, you might not want to give access to the "Thought Record" until introducing the concept first during a session. Yosa allows you to add that access whenever you want so Yosa can support individualized needs throughout the treatment lifecycle.</p>
                </Collapse.Panel>
                <Collapse.Panel className='cbt-accordionpanel' header="I can't see my clients' data. How do they share it with me?" key="7">
                  <p><strong>Answer:</strong> Each individual client can choose what data they wish to share with their provider. The default is "not shared". To share data, clients can click the settings icon in the top right corner of their app's home screen then select 'Sharing' in the dropdown menu, navigating to 'Sharing Settings'. From here, clients can toggle on or off what features they wish to share data with providers. Note that validated measures such as PHQ-9 scores are shared with providers by default.</p>
                </Collapse.Panel>
              </Collapse>
            </div>
          </div>
        </section>

        {/* Client / Patient FAQs */}
        <h2 className="faq-section-header" style={{ display: 'flex', color: '#375945', paddingLeft: '4rem', marginBottom: '0', paddingTop: '1.5rem' }}>Clients / Patients</h2>
        <section className="solution-section" style={{ marginTop: '0', paddingTop: '0' }}>
          <div className="solution-text">
            <div className="cbt-accordion">
              <Collapse accordion>
                <Collapse.Panel className='cbt-accordionpanel' header="Does my therapist or provider need to invite me to be able to use Yosa?" key="1">
                  <p><strong>Answer:</strong> No, you can download and use Yosa without an invitation. However, Yosa is best used in conjunction with a therapist or provider, and the app's content will be limited without a therapist invite.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="Do I have to pay to use Yosa?" key="2">
                  <p><strong>Answer:</strong> No, Yosa is free to use for clients. It is free to download and use on your own. Therapists pay a subscription to use the platform and give clients premium features.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="How do I know what assignments my therapist assigned me?" key="3"> 
                <p><strong>Answer:</strong> Incomplete assignments your therapist has assigned will appear on the home screen of your Yosa app under "Your Assignments". After completing an assignment, it will disappear from this section.</p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="How do I delete my account?" key="4"> 
                <p><strong>Answer:</strong> If you wish to delete your Yosa account and associated data, please follow these steps:

                   To disable your account, click the settings icon in the top right corner of the Yosa app and select "Delete Account" from the dropdown, followed by "Disable Account". This will disable your account, but some data may still be shared with your provider per legal requirements. Alternatively, you may send an email to support@yosaapp.com with the subject line "Account Deletion Request."
                    In the email, please include:
                    The email address associated with your Yosa account and a brief confirmation that you wish to delete your account.
                    Our support team will process your request within 30 days and confirm once your account has been deleted.
                    Once your account is deleted, your personal data will be permanently removed from our systems.
                    Some anonymized data may be retained for legal purposes in compliance with our Privacy Policy.
                    If you have any questions, feel free to reach out to support@yosaapp.com. </p>
                </Collapse.Panel>

                <Collapse.Panel className='cbt-accordionpanel' header="Can I request partial data deletion without deleting my account?" key="5"> 
                <p><strong>Answer:</strong> Yes! If you would like to request the deletion of some or all of your personal data without deleting your account, you can email support@yosaapp.com with the subject line "Data Deletion Request." In the email, specify: The data you wish to have removed and the reason (optional, but helpful for improving our services).
                    Our team will review your request and respond within 30 days regarding the status of your data removal.
                    Some anonymized data may be retained for legal purposes in compliance with our Privacy Policy.
                    If you have any questions, feel free to reach out to support@yosaapp.com.</p>
                </Collapse.Panel>          
            </Collapse>
            </div>
          </div>
        </section>

        {/* Waitlist Signup */}
        <section className="newsletter">
          <h2 style={{marginBottom: '30px'}}>Get started today for free! No credit card required.</h2>
          <a href="/signup" className="free-trial-button">Sign Up</a>
        </section>

        <footer className="footer">
          <div className="footer-content">
            <p>&copy; {currentYear} Yosa Inc. All rights reserved.</p>
            <nav>
              <ul>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                <li><a href="/terms-of-service">Terms of Service</a></li>
                <li><a href="/baa">Business Associate Agreement</a></li>
              </ul>
            </nav>
            <div className="footer-bottom">
              <p>
                <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
                <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
                  <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default FAQs;
