import React, { useState } from 'react';
import Joyride from 'react-joyride';
import { Button, Tooltip } from 'antd';
import { InfoCircleOutlined, LikeFilled } from '@ant-design/icons';

const TourGuide = () => {
  const [run, setRun] = useState(false);

  // Define the tour steps with targets corresponding to your elements' class names.
  const steps = [
    {
      target: '.sidebar-add-client',
      content: 'Click here to add a new client. Your client will see an invitation to connect accounts in their Yosa mobile app, allowing you to send assignments and monitor progress. We recommend adding yourself as a client first to see how the app works. As a reminder, the web portal here is for you, the provider, while your clients will use the Yosa mobile app.',
      placement: 'right',
    },
    {
      target: '.check-registration-status-button',
      content: "Click here to check your clients' registration status of if they've accepted your invitation in their Yosa mobile app or not. If not, a red alert icon will display left of their name.",
      placement: 'right',
    },
    {
      target: '.sidebar-assignment-builder',
      content: "The assignment builder allows you to create custom interactive worksheets, which you can then send to your clients' mobile app.",
      placement: 'right',
    },
    {
      target: '.sidebar-upload-pdf',
      content: 'Click here to upload PDFs, which will then show in the "My Library" table in the "PDFs" tab. You can assign these PDFs to your clients.',
      placement: 'right',
    },
    {
      target: '.sidebar-community',
      content: "If you'd like, you may choose to share worksheets you've created with other providers on Yosa. To browse and save interactive worksheets shared by others, click here. Any saved worksheets can then be assigned to your clients.",
      placement: 'right',
    },
    {
      target: '.tour-client-name',
      content: "Click an individual client's name to navigate to a screen with more details on their history, including responses to assignments and charts of various metrics over time. You may also choose which features to enable or disable for that client in the 'Features' tab, tailoring the Yosa app to their unique needs.",
      placement: 'right',
    },
    {
      target: '.custom-client-button',
      content: 'You may also click here to navigate to the individual client screen.',
      placement: 'right',
    },
    {
      target: '.tour-send-assignment',
      content: 'Click here to send an assignment(s) to your client. You may send custom worksheets, PDFs, or anything from our assignment toolbox, including measures like the PHQ-9, thought records, and more! Once sent, the client will receive a notification in their mobile app.',
      placement: 'right',
    },
    {
      target: '.tour-expand-icon',
      content: 'In addition to navigating to the individual client screen by clicking the client name or charts button, you may click here to view a history of all assignments sent to this client, including whether it was completed or not and actions to view the response or intructions provided.',
      placement: 'right',
    },
    {
      target: '.sidebar-help',
      content: 'Click here for help resources, including videos of how to sign up and get started on Yosa, how to use the provider web portal here, and how to use the mobile app as a client. FAQs and info and images of the mobile app are also available in the "Assignment Toolbox" tab.',
      placement: 'right',
    },
    {
      target: '.sidebar-billing',
      content: 'Click here to view your billing details, including your current pricing plan.',
      placement: 'right',
    },
  ];

  return (
    <>
      {/* Button to trigger the tour */}
      <Tooltip title="Start Tour">
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => setRun(true)}
          style={{ fontSize: '16px', color: '#59846A' }}
        />
      </Tooltip>

      <Joyride
        steps={steps}
        run={run}
        continuous
        showSkipButton
        showProgress={false}
        locale={{
          next: "Next",             // Set the Next button text
          back: "Back",             // Optional: set the Back button text
          skip: "Skip",             // Optional
          close: "Close",           // Optional
          last: "Finish",           // Optional
        }}
        disableOverlayClose
        styles={{
          options: {
            zIndex: 9999,
          },
          tooltip: {
            fontFamily: 'Mulish, sans-serif',
            color: '#323232',
          },
          buttonNext: {
            backgroundColor: '#59846A',
            color: '#fff',
            border: 'none',
          },
          buttonBack: {
            color: '#666',
          },
        }}
        callback={(data) => {
          const { status } = data;
          if (status === 'finished' || status === 'skipped') {
            setRun(false);
          }
        }}
      />
    </>
  );
};

export default TourGuide;
