import React, { useState } from "react";
import "../styles/ShareWithCommunityModal.css";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Tooltip } from "antd";

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const ShareWithCommunityModal = ({ assignments, userAssignments, username, isOpen, onClose, reFetch }) => {
  const navigate = useNavigate();
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [description, setDescription] = useState("");
  const [tagsList, setTagsList] = useState([]);
  const [openSection, setOpenSection] = useState(null); // Accordion toggle state

  const openShareModal = (assignment) => {
    //console.log("Opening Share Modal for assignment:", assignment);
    setSelectedAssignment(assignment);
    setDescription(""); // Reset description
    setTagsList([]); // Reset selected tags
  };

  const closeModal = () => {
    setSelectedAssignment(null);
    setDescription("");
    setTagsList([]);
    onClose();
  };

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const handleTagChange = (tag) => {
    setTagsList((prevTags) =>
      prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
    );
  };

  // Disable submit button if description is empty AND no tags are selected
  const isSubmitDisabled = description.trim() === "" || tagsList.length === 0;

  const shareAssignment = async () => {
    if (!selectedAssignment || isSubmitDisabled) return;

    try {
      const assignmentToPost = {
        id: Date.now(),
        title: selectedAssignment.title,
        username: username,
        numberSaves: 0,
        tags: tagsList,
        description: description,
        worksheet: selectedAssignment.elements,
      };

      await axios.post(`${api}/CommunityWorksheets/`, assignmentToPost, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json',
        },
      });

      closeModal();

      // reload page
      reFetch();
    } catch (error) {
      console.error('Error sharing with community:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="share-modal-overlay" onClick={closeModal}>
      <div className="share-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="share-modal-close-button" onClick={closeModal}>
          &times;
        </button>
        <h2>Help the Yosa Community</h2>
        <p className="share-disclaimer">
          By sharing, you confirm that your assignment does not violate
          copyright or intellectual property laws. Yosa is not responsible for
          any violations. Please ensure all materials are original and
          appropriately credited.
        </p>

        {/* If no assignment is selected, show the list */}
        {!selectedAssignment ? (
          <div className="share-assignment-list">
            {userAssignments.length > 0 ? (
              userAssignments.filter((a) => a.elements.length !== 0).map((assignment) => (
                <div key={assignment.id} className="share-assignment-item">
                  <div className="share-assignment-info">
                    <p>{assignment.title}</p>
                    <button className="share-button" onClick={() => openShareModal(assignment)}>
                      Share
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No assignments created yet.</p>
            )}
          </div>
        ) : (
          /* If an assignment is selected, show the sharing form */
          <div className="share-assignment-form">
            <button className="back-button" onClick={() => setSelectedAssignment(null)}>⬅ Back</button>
            <h3>Share Assignment</h3>
            <p><strong>{selectedAssignment.title}</strong></p>

            <label>Description:</label>
            <textarea
              className="auto-resize-textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Provide a short description..."
              rows="3"
              maxLength={300}
            />

            {/* Modality Accordion */}
            <div className="accordion">
              <div className="accordion-header" onClick={() => toggleSection("modality")}>
                <h3 style={{ color: 'black' }}>Modality</h3>
                <span style={{ color: 'black' }}>{openSection === "modality" ? "-" : "+"}</span>
              </div>
              {openSection === "modality" && (
                <div className="accordion-content">
                  {["ABA", "ACT", "CBT", "DBT", "EMDR", "IFS", "MBCT", "SFBT"].map((modality) => (
                    <label key={modality} className="checkbox-label" style={{ color: 'black' }}>
                      <input
                        type="checkbox"
                        checked={tagsList.includes(modality)}
                        onChange={() => handleTagChange(modality)}
                      /> {modality}
                    </label>
                  ))}
                </div>
              )}
            </div>

            {/* Demographic Accordion */}
            <div className="accordion">
              <div className="accordion-header" onClick={() => toggleSection("demographic")}>
                <h3 style={{ color: 'black' }}>Demographic</h3>
                <span style={{ color: 'black' }}>{openSection === "demographic" ? "-" : "+"}</span>
              </div>
              {openSection === "demographic" && (
                <div className="accordion-content">
                  {["Teen", "Adult"].map((demographic) => (
                    <label key={demographic} className="checkbox-label" style={{ color: 'black' }}>
                      <input
                        type="checkbox"
                        checked={tagsList.includes(demographic)}
                        onChange={() => handleTagChange(demographic)}
                      /> {demographic}
                    </label>
                  ))}
                </div>
              )}
            </div>

            {/* Topic Accordion */}
            <div className="accordion">
              <div className="accordion-header" onClick={() => toggleSection("topic")}>
                <h3 style={{ color: 'black' }}>Topic</h3>
                <span style={{ color: 'black' }}>{openSection === "topic" ? "-" : "+"}</span>
              </div>
              {openSection === "topic" && (
                <div className="accordion-content">
                  {[
                    "Addiction", "ADHD", "Anger Management", "Anxiety", "Behavioral Issues",
                    "Bipolar Disorder", "Borderline Personality Disorder", "Coping Skills",
                    "Depression", "Dissociative Disorders (DID)", "Eating Disorders", "Grief",
                    "Mindfulness", "Obsessive-Compulsive (OCD)", "Relationships", "Self-Esteem",
                    "Self-Harm", "Sleep", "Spirituality", "Stress", "Substance Abuse",
                    "Trauma and PTSD", "Other"
                  ].map((topic) => (
                    <label key={topic} className="checkbox-label" style={{ color: 'black' }}>
                      <input
                        type="checkbox"
                        checked={tagsList.includes(topic)}
                        onChange={() => handleTagChange(topic)}
                      /> {topic}
                    </label>
                  ))}
                </div>
              )}
            </div>

            {/* Submit Button */}
            <Tooltip title={isSubmitDisabled ? description.trim() === "" ? "Please add a description before submitting." : tagsList.length === 0 ? "Please add at least one tag before submitting." : "" : ""}>
              <button
                className={`view-button ${isSubmitDisabled ? "view-button-disabled" : ""}`}
                onClick={shareAssignment}
                disabled={isSubmitDisabled}
              >
                Submit
              </button>
            </Tooltip>
          </div>
        )}

        {!selectedAssignment && (
          <button className="go-to-assignment-builder-button" onClick={() => navigate("/assignment-builder")}>
            Go to Assignment Builder
          </button>
        )}
      </div>
    </div>
  );
};

export default ShareWithCommunityModal;
