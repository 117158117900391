import React from "react";
import { useParams, useLocation } from "react-router-dom";
import "../styles/Payment.css";
import balancingGuy from "../assets/balancingguy.png";
import { logosGreen } from '../config/logos';
import { RollbackOutlined } from '@ant-design/icons';

const Payment = () => {
  const { planId } = useParams();
  const location = useLocation();

  const currentEnv = process.env.REACT_APP_ENV || "development";

  // Pricing data
  const pricingPlans = {
    1: {
      title: "Private Practice Level",
      price: 69.99,
    },
    2: {
      title: "Group Practice Level",
      price: 189.99,
    },
    3: {
      title: "Enterprise Level",
      contactUs: true,
    },
  };

  // Get the selected plan
  const selectedPlan = pricingPlans[planId];

  // Get passed discount and billing frequency
  const searchParams = new URLSearchParams(location.search);
  const isAnnual = searchParams.get("isAnnual") === "true";
  const isSale = searchParams.get("isSale") === "true";

  // Calculate price
  let finalPrice = selectedPlan?.price || 0;
  if (isSale) finalPrice = finalPrice * 0.5; // Apply sale discount
  if (isAnnual) finalPrice = finalPrice * 12 * 0.9; // Apply annual discount
  const monthlyPrice = finalPrice.toFixed(2);

  if (!selectedPlan) {
    return <div>Plan not found. Please go back to the pricing page.</div>;
  }

  return (
    <>
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Yosa Payment</title>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
        </style>
      </head>

      <div className="payment-container">
        <RollbackOutlined className="payment-back-button" onClick={() => window.history.back()} />
        <h1>Payment Details</h1>
        <div className="payment-form-container">
          {/* Left Side - Payment Form */}
          <form className="payment-form">
            <div className="payment-header">Contact Information</div>
            <label>
              Email Address
              <input type="email" placeholder="Enter your email" required />
            </label>
            <div className="payment-header">Billing Address</div>
            <label>
              Country
              <select required>
                <option value="">Select your country</option>
                <option value="US">United States</option>
              </select>
            </label>
            <label>
              Address
              <input type="text" placeholder="Enter your billing address" required />
            </label>
            <label>
              Zip Code
              <input type="text" placeholder="Enter your zip code" pattern="\d*" required />
            </label>
            <div className="payment-header">Payment Information</div>
            <label>
              Promo Code
              <input type="text" placeholder="Enter promo code (optional)" />
            </label>
            <label>
              Credit Card Number
              <input
                type="text"
                placeholder="xxxx xxxx xxxx xxxx"
                pattern="\d{16}"
                maxLength="16"
                required
              />
            </label>
            <div className="card-details-row">
              <label>
                Expiry Date
                <input
                  type="text"
                  placeholder="MM / YY"
                  pattern="\d{2}\/\d{2}"
                  maxLength="5"
                  required
                />
              </label>
              <label>
                CVV
                <input
                  type="text"
                  placeholder="xxx"
                  pattern="\d{3}"
                  maxLength="3"
                  required
                />
              </label>
            </div>
            <button type="submit" className="payment-button">
              Make Payment
            </button>
          </form>

          {/* Right Side - Plan Details */}
            <div className="payment-summary">
            <div className="payment-summary-top">
                <div className="tagline">Level up your practice today!</div>
                <img src={logosGreen[currentEnv]} alt="Yosa Logo" className="yosa-logo" />
            </div>
            <div className="plan-details">
                <h2>{selectedPlan.title}</h2>
                <p className="price-summary">
                ${monthlyPrice} <span>{isAnnual ? "/ year (billed annually)" : "/ month"}</span>
                </p>
            </div>
            <ul className="plan-features">
                {selectedPlan.features?.map((feature, index) => (
                <li key={index}>{feature}</li>
                ))}
            </ul>
            <img src={balancingGuy} alt="Balancing Guy" className="balancing-guy" />
            </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
