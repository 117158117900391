// File: src/components/ToolkitAccordion.jsx
import React, { useState } from "react";
import "../styles/Product.css";

const toolkitItems = [
  { title: "Journaling", description: "Encourage self-reflection and expression." },
  { title: "Breathing Room", description: "Practice guided deep breathing exercises." },
  { title: "PMR", description: "Guided Progressive Muscle Relaxation." },
  { title: "Symptom Log", description: "Track mood, energy, sleep, and stress over time." },
  { title: "Medication", description: "Track medication intake." },
  { title: "Substance Log", description: "Log substance use over time." },
  { title: "Worksheets", description: "Interactive custom assignments for clients." },
  { title: "Text Assignments", description: "Send text-based assignments to clients." },
  { title: "Goals", description: "Goal setting, tracking, and reflection." },
  { title: "Thought Record", description: "Identify and challenge negative thoughts." },
  { title: "Therapy Journal", description: "Support client preparation and reflection." },
  { title: "Activity Log", description: "Plan activities and reflect on their impact." },
  { title: "Exposure Therapy", description: "Log and reflect on exposure activities." },
  { title: "Value Sort", description: "Define core values, a common ACT exercise." },
  { title: "Validated Measures", description: "PHQ-9, GAD-7, and PCL-5." },
];

const ToolkitAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="toolkit-accordion">
      <div className="product-accordion">
        {toolkitItems.map((item, index) => (
          <div
            key={index}
            className={`accordion-item ${activeIndex === index ? "active" : ""}`}
            onClick={() => toggleAccordion(index)}
          >
            <div className="product-accordion-header">
              <h3>{item.title}</h3>
              <span>{activeIndex === index ? "-" : "+"}</span>
            </div>
            {activeIndex === index && (
              <div className="product-accordion-content">
                <p>{item.description}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ToolkitAccordion;
