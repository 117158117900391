import React, { useState } from 'react';
import { Button, Form, Input, Checkbox, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import UserPool from "../UserPool";
import logincover from '../assets/logincover.png';
import { RollbackOutlined } from '@ant-design/icons';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe("pk_test_51QmgBgCcWKLcVElLzxfrgZo5zwm4PXr8UMiW7fuIpM2Lj6NNQRsnNpu8CYSR6WdrxeNjiev8qASPAMcfCimD5aH900MDpDrH1l");

const SignUp = () => {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Local override endpoint
  let apiEndpoint = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

  const [pricingPlan, setPricingPlan] = useState("");

  // ─── FUNCTIONS TO VERIFY/DELETE ENTERPRISE CODE ──────────────────────────────
  const fetchCodes = async () => {
    try {
      const response = await axios.get(`${apiEndpoint}/Codes/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching codes:', error);
      setErrorMessage('Error fetching codes.');
      return [];
    }
  };

  const deleteCode = async (code) => {
    try {
      const codes = await fetchCodes();
      const codeObj = codes.find((codeObj) => codeObj.code === code);
      if (codeObj) {
        await axios.delete(`${apiEndpoint}/Codes/${codeObj.id}/`);
      } else {
        console.error('Code not found.');
      }
    } catch (error) {
      console.error('Error deleting code:', error);
    }
  };
  // ──────────────────────────────────────────────────────────────────────────────

  // onSubmit2: Pass trialExpiration as null and pricingPlan based on flow.
  const onSubmit2 = async (email, password, therapistName, selectedPlan, organizationName = "", trialExpiration = null) => {
    if (!therapistName) {
      setErrorMessage('Therapist name is required!');
      return;
    }

    const attributes = [
      {
        Name: 'custom:TherapistName', // Custom attribute for therapist name (case-sensitive)
        Value: therapistName.trim(),
      },
    ];

    UserPool.signUp(email, password, attributes, null, (err, data) => {
      if (err) {
        console.error('Error during sign-up:', err);
        setErrorMessage(err.message || 'An error occurred during sign up.');
      } else {
        navigate('/confirm-sign-up', { state: { email, pricingPlan: selectedPlan, organizationName, trialExpiration } });
      }
    });
  };

  // onFinish handler: Require that either an enterprise code or a pricing plan is provided.
  const onFinish = async (values) => {
    setErrorMessage("");
    if (!values.enterpriseCode && !values.pricingPlan) {
      setErrorMessage("Please select a pricing plan or enter an enterprise code.");
      return;
    }

    // For all flows, trialExpiration is null.
    const trialExpiration = null;

    // Enterprise flow: If an enterprise code is provided, force the plan to "enterprise".
    if (values.enterpriseCode) {
      const codes = await fetchCodes();
      const codeObj = codes.find(
        (codeObj) => codeObj.code === values.enterpriseCode.trim()
      );
      if (codeObj) {
        // Delete the code so that they won't be charged.
        await deleteCode(values.enterpriseCode.trim());
        const organizationName = codeObj.organization_name || "";
        onSubmit2(values.email, values.password, values.therapistName, "enterprise", organizationName, trialExpiration);
      } else {
        setErrorMessage("Invalid enterprise code.");
        return;
      }
    } else {
      // Standard flow: use the selected pricing plan (which could be "free", "starter", or "practice")
      onSubmit2(values.email, values.password, values.therapistName, values.pricingPlan, "", trialExpiration);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Form submission failed:', errorInfo);
  };

  const goToLogIn = () => {
    navigate('/login');
  };

  return (
    <>
      <head>
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>Yosa Home Screen</title>
        <style>
          {`@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');`}
        </style>
      </head>

      <section
        style={{
          display: "flex",
          height: "100vh",
          fontFamily: "Mulish, sans-serif",
        }}
      >
        {/* Left Section */}
        <div
          style={{
            flex: "1",
            minWidth: "300px",
            backgroundColor: "#FFEBA3",
            padding: "1rem",
          }}
          className='signup-left'
        >
          <img
            src={logincover}
            alt="Yosa Cover"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>

        {/* Right Section */}
        <div
          style={{
            flex: "1",
            maxWidth: "500px",
            overflowY: "auto",
            padding: "2.5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <RollbackOutlined className="payment-back-button" onClick={() => window.history.back()} />
          <h1 style={{ fontSize: "2rem", fontWeight: "bold" }}>Sign Up</h1>
          <p style={{ color: "#323232", marginBottom: "2.5rem" }}>
            Join the future of mental health care!
          </p>

          <Form
            form={form}
            name="signup"
            style={{ width: "100%" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please enter a valid email address!" },
                { max: 254, message: "Email cannot exceed 254 characters!" },
              ]}
            >
              <Input />
            </Form.Item>

            <p style={{ marginBottom: "0.25rem", fontSize: "0.9rem", color: "#323232" }}>
              Your therapist name will be displayed to your clients during their onboarding process.
            </p>
            <Form.Item
              label="Therapist Name"
              name="therapistName"
              rules={[
                { required: true, message: "Please input your preferred name! This will be displayed to your clients." },
                { max: 30, message: "Please shorten your name!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 8, message: "Password must be at least 8 characters long!" },
                { max: 128, message: "Password cannot exceed 128 characters!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confpassword"
              dependencies={['password']}
              rules={[
                { required: true, message: "Please confirm your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Passwords do not match!");
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            {errorMessage && (
              <div style={{ color: "red", marginBottom: "1rem" }}>{errorMessage}</div>
            )}

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject("You must agree to continue!"),
                },
              ]}
              style={{ textAlign: "left", marginBottom: "1rem" }}
            >
              <Checkbox>
                I agree to the{" "}
                <a href="/terms-of-service">Terms of Service</a>,{" "}
                <a href="/privacy-policy">Privacy Policy</a>,{" "}
                <a href="/baa">Business Associate Agreement</a>, and{" "}
                <a href="notice-of-privacy-practices">Notice of Privacy Practices</a>.
              </Checkbox>
            </Form.Item>

            {/* Conditional Rendering for Enterprise Code and Pricing Plan */}
            <Form.Item noStyle shouldUpdate={(prev, cur) => prev.enterpriseCode !== cur.enterpriseCode || prev.pricingPlan !== cur.pricingPlan}>
              {() => {
                const enterpriseCode = form.getFieldValue("enterpriseCode");
                const pricingPlanValue = form.getFieldValue("pricingPlan");
                return (
                  <>
                    {/* Enterprise Code Field: Show only if no pricing plan is selected */}
                    {!pricingPlanValue && (
                      <div style={{ marginBottom: "1rem", textAlign: "left" }}>
                        <p style={{ marginBottom: "0.25rem", fontSize: "0.9rem", color: "#323232" }}>
                          Enter an enterprise code if you have been granted special access.
                        </p>
                        <Form.Item name="enterpriseCode">
                          <Input placeholder="Enterprise Code" />
                        </Form.Item>
                      </div>
                    )}

                    {/* Pricing Plan Field: Show only if no enterprise code is provided */}
                    {!enterpriseCode && (
                      <div style={{ marginBottom: "1rem", textAlign: "left" }}>
                        <Form.Item label="Choose a pricing plan" name="pricingPlan" labelCol={{ span: 24 }}>
                          <Radio.Group
                            onChange={(e) => setPricingPlan(e.target.value)}
                            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
                          >
                            <Radio value="free">
                              <div>Free Level - No credit card required</div>
                            </Radio>
                            <Radio value="starter">
                              <div>Starter Level - $19/month</div>
                            </Radio>
                            <Radio value="practice">
                              <div>Practice Level - $39/month</div>
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    )}
                  </>
                );
              }}
            </Form.Item>

            <div style={{ marginBottom: "1rem" }}>
              <a
                href="/Pricing"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#323232", fontWeight: "bold" }}
              >
                Learn more about our pricing plans
              </a>
            </div>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{
                  backgroundColor: "#FFEBA3",
                  color: "#A68200",
                  fontWeight: "bold",
                  borderRadius: "40px",
                  border: "none",
                }}
              >
                Sign Up
              </Button>
            </Form.Item>
          </Form>

          <p style={{ marginTop: "1rem", fontSize: "0.9rem", color: "#323232" }}>
            Already have an account?{" "}
            <a
              onClick={goToLogIn}
              style={{ cursor: "pointer", color: "#323232", fontWeight: "bold" }}
            >
              Log in
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export default SignUp;