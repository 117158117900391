import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import linkedinicon from '../assets/linkedinicon.png';
import { logosGreen } from '../config/logos';
import '../styles/SignUp.css';

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";
const currentEnv = process.env.REACT_APP_ENV || "development";

const BusinessAssociateAgreement = () => {
  const navigate = useNavigate();
      const [email, setEmail] = useState('');
      const [error, setError] = useState('');
      const [menuOpen, setMenuOpen] = useState(false);
      const currentYear = new Date().getFullYear();
      
      const toggleMenu = () => {
        setMenuOpen(!menuOpen);
      };
  
      const goToSignUp = () => {
          navigate('/signup');
      };
  
      const goToLogIn = () => {
          navigate('/login');
      };
  
      const handleEmailChange = (e) => {
          setEmail(e.target.value);
      };
  
      const handleSubmit = async (e) => {
          e.preventDefault();
          setError('');
  
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(email)) {
              setError('Please enter a valid email address.');
              return;
          }
  
          try {
              const response = await fetch(`${api}/api/Waitlist/`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ email }),
              });
  
              if (!response.ok) {
                  throw new Error('Network response was not ok');
                  //console.log("error");
              }
  
              alert('You successfully joined our mailing list!');
              setEmail('');
          } catch (error) {
              setError('There was a problem submitting your email. Please try again later.');
          }
      };
  
    const evidenceMenu = (
          <Menu>
            <Menu.Item key="evidence">
              <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
            </Menu.Item>
            <Menu.Item key="homework">
              <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
            </Menu.Item>
          </Menu>
        );
  
    const aboutMenu = (
          <Menu>
            <Menu.Item key="evidence">
              <a href="/About" style={{ textDecoration: 'none' }}>About</a>
            </Menu.Item>
            <Menu.Item key="homework">
              <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
            </Menu.Item>
          </Menu>
        );

  return (
    <>
      <html>
        <head>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Business Associate Agreement - Yosa</title>
          <style>
            {`@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');`}
          </style>
        </head>
        <body>
          <div className="new-home">
            {/* Header */}
            <header className="header">
                <a href="/" className="logo">
                  <img src={logosGreen[currentEnv]} alt="Yosa Logo" />
                </a>
            
                {/* Hamburger Menu Icon */}
                <button
                  className={`menu-toggle ${menuOpen ? "open" : ""}`}
                  onClick={toggleMenu}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            
                <nav className={`navigation ${menuOpen ? "open" : ""}`}>
                  <ul>
                    <li><a className="bold-header-title" style={{ fontWeight: "900" }} href="/">Home</a></li>
                    <li><a href="/Product">Product</a></li>
                    <li>
                    <Dropdown overlay={evidenceMenu} trigger={['hover']}>
                    <a style={{ textDecoration: 'none' }} href="/Evidence">
                        Evidence <DownOutlined />
                        </a>
                    </Dropdown>
                    </li>
                    <li><a href="/Pricing">Pricing</a></li>
                    {/*<li><a href="/Admin">Admin</a></li>*/}
                    <li>
                    <Dropdown overlay={aboutMenu} trigger={['hover']}>
                      <a style={{ textDecoration: 'none' }} href="/About">
                        About <DownOutlined />
                       </a>
                    </Dropdown>
                    </li>
                  <li><a href="/LogIn">Log In</a></li>
                  <li><a href="/SignUp">Sign Up</a></li>
                </ul>
              </nav>
            </header>

            {/* Main Content */}
            <main>
              <h1 className="privacy-title">Business Associate Agreement</h1>
              <div className="content-container">
                <p>
                  This BUSINESS ASSOCIATE AGREEMENT (the “BAA”) is made and entered into at the date and time your Yosa account is created and is between you (“Covered Entity”) and Yosa Inc (“Business Associate”, in accordance with the meaning given to those terms at 45 CFR §164.501). In this BAA, Covered Entity and Business Associate are each a “Party” and, collectively, are the “Parties”.
                </p>

                <h2 className="policy-header">BACKGROUND</h2>
                <ol>
                  <li>
                    <strong>I.</strong> Covered Entity is either a “covered entity” or “business associate” of a covered entity as defined under the Health Insurance Portability and Accountability Act of 1996, Public Law 104-191, as amended by the HITECH Act (as defined below) and, as such, is required to comply with HIPAA’s provisions regarding the confidentiality and privacy of Protected Health Information (as defined below);
                  </li>
                  <li>
                    <strong>II.</strong> The Parties have entered into or will enter into one or more agreements under which Business Associate provides or will provide certain specified services to Covered Entity (collectively, the “Agreement”);
                  </li>
                  <li>
                    <strong>III.</strong> In providing services pursuant to the Agreement, Business Associate will have access to Protected Health Information;
                  </li>
                  <li>
                    <strong>IV.</strong> By providing the services pursuant to the Agreement, Business Associate will become a “business associate” of the Covered Entity as such term is defined under HIPAA;
                  </li>
                  <li>
                    <strong>V.</strong> Both Parties are committed to complying with all federal and state laws governing the confidentiality and privacy of health information, including, but not limited to, the Standards for Privacy of Individually Identifiable Health Information found at 45 CFR Part 160 and Part 164, Subparts A and E (collectively, the “Privacy Rule”);
                  </li>
                  <li>
                    <strong>VI.</strong> Both Parties intend to protect the privacy and provide for the security of Protected Health Information disclosed to Business Associate pursuant to the terms of this Agreement, HIPAA and other applicable laws.
                  </li>
                </ol>

                <h2 className="policy-header">AGREEMENT</h2>
                <p>
                  NOW, THEREFORE, in consideration of the mutual covenants and conditions contained herein and the continued provision of PHI by Covered Entity to Business Associate under the Agreement in reliance on this BAA, the Parties agree as follows:
                </p>

                <h3>1. Definitions.</h3>
                <p>
                  For purposes of this BAA, the Parties give the following meaning to each of the terms in this Section 1 below. Any capitalized term used in this BAA, but not otherwise defined, has the meaning given to that term in the Privacy Rule or pertinent law.
                </p>
                <p><strong>A.</strong> “Affiliate” means a subsidiary or affiliate of Covered Entity that is, or has been, considered a covered entity, as defined by HIPAA.</p>
                <p><strong>B.</strong> “Breach” means the acquisition, access, use, or disclosure of PHI in a manner not permitted under the Privacy Rule which compromises the security or privacy of the PHI, as defined in 45 CFR §164.402.</p>
                <p><strong>C.</strong> “Breach Notification Rule” means the portion of HIPAA set forth in Subpart D of 45 CFR Part 164.</p>
                <p><strong>D.</strong> “Data Aggregation” means, with respect to PHI created or received by Business Associate in its capacity as the “business associate” under HIPAA of Covered Entity, the combining of such PHI by Business Associate with the PHI received by Business Associate in its capacity as a business associate of one or more other “covered entity” under HIPAA, to permit data analyses that relate to the Health Care Operations of the respective covered entities. The meaning of “data aggregation” in this BAA shall be consistent with the meaning given to that term in the Privacy Rule.</p>
                <p><strong>E.</strong> “Designated Record Set” has the meaning given to such term under the Privacy Rule, including 45 CFR §164.501.B.</p>
                <p><strong>F.</strong> “De-Identify” means to alter the PHI such that the resulting information meets the requirements described in 45 CFR §§164.514(a) and (b).</p>
                <p><strong>G.</strong> “Electronic PHI” means any PHI maintained in or transmitted by electronic media as defined in 45 CFR §160.103.</p>
                <p><strong>H.</strong> “Health Care Operations” has the meaning given to that term in 45 CFR §164.501.</p>
                <p><strong>I.</strong> “HHS” means the U.S. Department of Health and Human Services.</p>
                <p><strong>J.</strong> “HITECH Act” means the Health Information Technology for Economic and Clinical Health Act, enacted as part of the American Recovery and Reinvestment Act of 2009, Public Law 111-005.</p>
                <p><strong>K.</strong> “Individual” has the same meaning given to that term in 45 CFR §§164.501 and 160.130 and includes a person who qualifies as a personal representative in accordance with 45 CFR §164.502(g).</p>
                <p><strong>L.</strong> “Privacy Rule” means that portion of HIPAA set forth in 45 CFR Part 160 and Part 164, Subparts A and E.</p>
                <p><strong>M.</strong> “Protected Health Information” or “PHI” has the meaning given to the term “protected health information” in 45 CFR §§164.501 and 160.103, limited to the information created or received by Business Associate from or on behalf of Covered Entity.</p>
                <p><strong>N.</strong> “Security Incident” means the attempted or successful unauthorized access, use, disclosure, modification, or destruction of information or interference with system operations in an information system.</p>
                <p><strong>O.</strong> “Security Rule” means the Security Standards for the Protection of Electronic Health Information provided in 45 CFR Part 160 &amp; Part 164, Subparts A and C.</p>
                <p><strong>P.</strong> “Unsecured Protected Health Information” or “Unsecured PHI” means any “protected health information” as defined in 45 CFR §§164.501 and 160.103 that is not rendered unusable, unreadable or indecipherable to unauthorized individuals through the use of a technology or methodology specified by the HHS Secretary in the guidance issued pursuant to the HITECH Act and codified at 42 USC §17932(h).</p>

                <div className="agreement-section">
                  <h3>2. Use and Disclosure of PHI.</h3>
                  <p>
                    A. Except as otherwise provided in this BAA, Business Associate may use or disclose PHI as reasonably necessary to provide the services described in the Agreement to Covered Entity, and to undertake other activities of Business Associate permitted or required of Business Associate by this BAA or as required by law.
                  </p>
                  <p>
                    B. Except as otherwise limited by this BAA or federal or state law, Covered Entity authorizes Business Associate to use the PHI in its possession for the proper management and administration of Business Associate’s business and to carry out its legal responsibilities. Business Associate may disclose PHI for its proper management and administration, provided that (i) the disclosures are required by law; or (ii) Business Associate obtains, in writing, prior to making any disclosure to a third party (a) reasonable assurances from this third party that the PHI will be held confidential as provided under this BAA and used or further disclosed only as required by law or for the purpose for which it was disclosed to this third party and (b) an agreement from this third party to notify Business Associate immediately of any breaches of the confidentiality of the PHI, to the extent it has knowledge of the breach.
                  </p>
                  <p>
                    C. Business Associate will not use or disclose PHI in a manner other than as provided in this BAA, as permitted under the Privacy Rule, or as required by law. Business Associate will use or disclose PHI, to the extent practicable, as a limited data set or limited to the minimum necessary amount of PHI to carry out the intended purpose of the use or disclosure, in accordance with Section 13405(b) of the HITECH Act (codified at 42 USC §17935(b)) and any of the act’s implementing regulations adopted by HHS, for each use or disclosure of PHI.
                  </p>
                  <p>
                    D. Upon request, Business Associate will make available to Covered Entity any of Covered Entity’s PHI that Business Associate or any of its agents or subcontractors have in their possession.
                  </p>
                  <p>
                    E. Business Associate may use PHI to report violations of law to appropriate Federal and State authorities, consistent with 45 CFR §164.502(j)(1).
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>3. Safeguards Against Misuse of PHI.</h3>
                  <p>
                    Business Associate will use appropriate safeguards to prevent the use or disclosure of PHI other than as provided by the Agreement or this BAA and Business Associate agrees to implement administrative, physical, and technical safeguards that reasonably and appropriately protect the confidentiality, integrity and availability of the Electronic PHI that it creates, receives, maintains or transmits on behalf of Covered Entity. Business Associate agrees to take reasonable steps, including providing adequate training to its employees to ensure compliance with this BAA and to ensure that the actions or omissions of its employees or agents do not cause Business Associate to breach the terms of this BAA.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>4. Reporting Disclosures of PHI and Security Incidents.</h3>
                  <p>
                    Business Associate will report to Covered Entity in writing any use or disclosure of PHI not provided for by this BAA of which it becomes aware and Business Associate agrees to report to Covered Entity any Security Incident affecting Electronic PHI of Covered Entity of which it becomes aware. Business Associate agrees to report any such event within five business days of becoming aware of the event.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>5. Reporting Breaches of Unsecured PHI.</h3>
                  <p>
                    Business Associate will notify Covered Entity in writing promptly upon the discovery of any Breach of Unsecured PHI in accordance with the requirements set forth in 45 CFR §164.410, but in no case later than 30 calendar days after discovery of a Breach. Business Associate will reimburse Covered Entity for any costs incurred by Covered Entity in complying with the requirements of Subpart D of 45 CFR §164 that are imposed on Covered Entity as a result of a Breach committed by Business Associate.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>6. Mitigation of Disclosures of PHI.</h3>
                  <p>
                    Business Associate will take reasonable measures to mitigate, to the extent practicable, any harmful effect that is known to Business Associate of any use or disclosure of PHI by Business Associate or its agents or subcontractors in violation of the requirements of this BAA.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>7. Agreements with Agents or Subcontractors.</h3>
                  <p>
                    Business Associate will ensure that any of its agents or subcontractors that have access to, or to which Business Associate provides, PHI agree in writing to the restrictions and conditions concerning uses and disclosures of PHI contained in this BAA and agree to implement reasonable and appropriate safeguards to protect any Electronic PHI that it creates, receives, maintains or transmits on behalf of Business Associate or, through Business Associate, Covered Entity. Business Associate shall notify Covered Entity, or upstream Business Associate, of all subcontracts and agreements relating to the Agreement, where the subcontractor or agent receives PHI as described in section 1.M. of this BAA. Such notification shall occur within 30 (thirty) calendar days of the execution of the subcontract by placement of such notice on the Business Associate’s primary website. Business Associate shall ensure that all subcontracts and agreements provide the same level of privacy and security as this BAA.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>8. Access to PHI by Individuals.</h3>
                  <p>
                    A. Upon request, Business Associate agrees to furnish Covered Entity with copies of the PHI maintained by Business Associate in a Designated Record Set in the time and manner designated by Covered Entity to enable Covered Entity to respond to an Individual’s request for access to PHI under 45 CFR §164.524.
                  </p>
                  <p>
                    B. In the event any Individual or personal representative requests access to the Individual’s PHI directly from Business Associate, Business Associate within ten business days will forward that request to Covered Entity. Any disclosure of, or decision not to disclose, the PHI requested by an Individual or a personal representative and compliance with the requirements applicable to an Individual’s right to obtain access to PHI shall be the sole responsibility of Covered Entity.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>9. Amendment of PHI.</h3>
                  <p>
                    A. Upon request and instruction from Covered Entity, Business Associate will amend PHI or a record about an Individual in a Designated Record Set that is maintained by, or otherwise within the possession of, Business Associate as directed by Covered Entity in accordance with procedures established by 45 CFR §164.526. Any request by Covered Entity to amend such information will be completed by Business Associate within 15 business days of Covered Entity’s request.
                  </p>
                  <p>
                    B. In the event that any Individual requests that Business Associate amend such Individual’s PHI or record in a Designated Record Set, Business Associate within ten business days will forward this request to Covered Entity. Any amendment of, or decision not to amend, the PHI or record as requested by an Individual and compliance with the requirements applicable to an Individual’s right to request an amendment of PHI will be the sole responsibility of Covered Entity.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>10. Accounting of Disclosures.</h3>
                  <p>
                    A. Business Associate will document any disclosures of PHI made by it to account for such disclosures as required by 45 CFR §164.528(a). Business Associate also will make available information related to such disclosures as would be required for Covered Entity to respond to a request for an accounting of disclosures in accordance with 45 CFR §164.528. At a minimum, Business Associate will furnish Covered Entity the following with respect to any covered disclosures by Business Associate: (i) the date of disclosure of PHI; (ii) the name of the entity or person who received PHI, and, if known, the address of such entity or person; (iii) a brief description of the PHI disclosed; and (iv) a brief statement of the purpose of the disclosure which includes the basis for such disclosure.
                  </p>
                  <p>
                    B. Business Associate will furnish to Covered Entity information collected in accordance with this Section 10, within ten business days after written request by Covered Entity, to permit Covered Entity to make an accounting of disclosures as required by 45 CFR §164.528, or in the event that Covered Entity elects to provide an Individual with a list of its business associates, Business Associate will provide an accounting of its disclosures of PHI upon request of the Individual, if and to the extent that such accounting is required under the HITECH Act or under HHS regulations adopted in connection with the HITECH Act.
                  </p>
                  <p>
                    C. In the event an Individual delivers the initial request for an accounting directly to Business Associate, Business Associate will within ten business days forward such request to Covered Entity.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>11. Responsibilities of Covered Entity.</h3>
                  <p>
                    With regard to the use and/or disclosure of Protected Health Information by Business Associate, Covered Entity agrees to:
                  </p>
                  <p>
                    A. Notify Business Associate of any limitation(s) in its notice of privacy practices in accordance with 45 CFR §164.520, to the extent that such limitation may affect Business Associate’s use or disclosure of PHI.
                  </p>
                  <p>
                    B. Notify Business Associate of any changes in, or revocation of, permission by an Individual to use or disclose Protected Health Information, to the extent that such changes may affect Business Associate’s use or disclosure of PHI.
                  </p>
                  <p>
                    C. Notify Business Associate of any restriction to the use or disclosure of PHI that Covered Entity has agreed to in accordance with 45 CFR §164.522, to the extent that such restriction may affect Business Associate’s use or disclosure of PHI.
                  </p>
                  <p>
                    D. Except for data aggregation or management and administrative activities of Business Associate, Covered Entity shall not request Business Associate to use or disclose PHI in any manner that would not be permissible under HIPAA if done by Covered Entity.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>12. Term and Termination.</h3>
                  <p>
                    A. This BAA will become effective on the date first written above, and will continue in effect until all obligations of the Parties have been met under the Agreement and under this BAA.
                  </p>
                  <p>
                    B. Covered Entity may terminate immediately this BAA, the Agreement, and any other related agreements if Covered Entity makes a determination that Business Associate has breached a material term of this BAA and Business Associate has failed to cure that material breach, to Covered Entity’s reasonable satisfaction, within 30 days after written notice from Covered Entity. Covered Entity may report the problem to the Secretary of HHS if termination is not feasible.
                  </p>
                  <p>
                    C. If Business Associate determines that Covered Entity has breached a material term of this BAA, then Business Associate will provide Covered Entity with written notice of the existence of the breach and shall provide Covered Entity with 30 days to cure the breach. Covered Entity’s failure to cure the breach within the 30-day period will be grounds for immediate termination of the Agreement and this BAA by Business Associate.
                  </p>
                  <p>
                    D. Upon termination of the Agreement or this BAA for any reason, all PHI maintained by Business Associate will be returned to Covered Entity or destroyed by Business Associate. Business Associate will not retain any copies of such information. This provision will apply to PHI in the possession of Business Associate’s agents and subcontractors. If return or destruction of the PHI is not feasible, in Business Associate’s reasonable judgment, Business Associate will furnish Covered Entity with notification, in writing, of the conditions that make return or destruction infeasible. Upon mutual agreement of the Parties that return or destruction of the PHI is infeasible, Business Associate will extend the protections of this BAA to such information for as long as Business Associate retains such information and will limit further uses and disclosures to those purposes that make the return or destruction of the information not feasible. The Parties understand that this Section 14.D. will survive any termination of this BAA.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>13. Effect of BAA.</h3>
                  <p>
                    A. This BAA is a part of and subject to the terms of the Agreement, except that to the extent any terms of this BAA conflict with any term of the Agreement, the terms of this BAA will govern.
                  </p>
                  <p>
                    B. Except as expressly stated in this BAA or as provided by law, this BAA will not create any rights in favor of any third party.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>14. Regulatory References.</h3>
                  <p>
                    A reference in this BAA to a section in HIPAA means the section as in effect or as amended at the time.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>15. Notices.</h3>
                  <p>
                    All notices to Business Associate shall be in writing, and either delivered by hand, or sent by mail, or delivered in such other manner as the parties may agree upon, to Yosa, Inc, c/o Compliance Manager, 3 Sandy Hill Rd Mansfield, MA 02048. All notices to Covered Entity shall be by email at the email address provided upon account creation.
                  </p>
                </div>

                <div className="agreement-section">
                  <h3>16. Amendments and Waiver.</h3>
                  <p>
                    This BAA may not be modified, nor will any provision be waived or amended, except in writing duly signed by authorized representatives of the Parties. A waiver with respect to one event shall not be construed as continuing, or as a bar to or waiver of any right or remedy as to subsequent events.
                  </p>
                  <p>
                    Each party reserves the right to change address for receiving notice during the term of this BAA upon written notice to the other parties.
                  </p>
                </div>
              </div>
            </main>

            {/* Footer */}
            <footer className="footer">
              <div className="footer-content">
                <p>&copy; {currentYear} Yosa Inc. All rights reserved.</p>
                <nav>
                  <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                    <li><a href="/terms-of-service">Terms of Service</a></li>
                    <li><a href="/baa">Business Associate Agreement</a></li>
                  </ul>
                </nav>
                <div className="footer-bottom">
                  <p>
                    <a href="mailto:support@yosaapp.com" className="email-link">
                      support@yosaapp.com
                    </a>
                    <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
                      <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
                    </a>
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </body>
      </html>
    </>
  );
};

export default BusinessAssociateAgreement;
