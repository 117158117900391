import React, { useState } from 'react';

const MedicationCalendar = ({
  medications,           // array of medication objects
  onDateSelect,          // optional callback when a new date is selected
  selectedMedication,    // optional external selected date/medication (not used here)
  setSelectedMedication, // optional external setter (if you want to lift state)
}) => {
  // Local state for the selected and displayed date.
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [displayedDate, setDisplayedDate] = useState(new Date());

  // Helper: normalize date to remove time parts
  const normalizeDate = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  };

  // Returns true if a medication was taken on the given day.
  const isMedicationTaken = (med, date) => {
    const normalizedDate = normalizeDate(date);
    return med.datesTaken.some((takenDate) => {
      return normalizeDate(new Date(takenDate)).toDateString() === normalizedDate.toDateString();
    });
  };

  // Filters medications to include only those active on the given date.
  // A medication is active if its addDate is on or before the date and,
  // if it has an endDate, that endDate is after the date.
  const getMedicationsForDate = (date) => {
    const normalizedDate = normalizeDate(date);
    return medications.filter((med) => {
      const addDate = normalizeDate(new Date(med.addDate));
      const endDate = med.endDate ? normalizeDate(new Date(med.endDate)) : null;
      if (addDate > normalizedDate) return false;
      if (endDate && endDate <= normalizedDate) return false;
      return true;
    });
  };

  // Calendar utility functions
  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const handleMonthChange = (offset) => {
    const newDisplayedDate = new Date(displayedDate);
    newDisplayedDate.setMonth(newDisplayedDate.getMonth() + offset);
    setDisplayedDate(newDisplayedDate);
  };

  const generateCalendar = () => {
    const year = displayedDate.getFullYear();
    const month = displayedDate.getMonth();
    const daysInCurrentMonth = daysInMonth(month, year);
    const startDay = new Date(year, month, 1).getDay();
    const calendarDays = Array(startDay).fill(null);
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      calendarDays.push(i);
    }
    return calendarDays;
  };

  const calendarDays = generateCalendar();

  // Handle date selection: update local state and call optional prop setters.
  const handleDateClick = (day) => {
    const date = new Date(displayedDate);
    date.setDate(day);
    const normalized = normalizeDate(date);
    setSelectedDate(normalized);
    if (onDateSelect) {
      onDateSelect(normalized);
    }
    if (setSelectedMedication) {
      setSelectedMedication(normalized);
    }
  };

  // Get the medications active on the selected day.
  const medsForSelectedDate = getMedicationsForDate(selectedDate);

  return (
    <div style={styles.outerContainer}>
      {/* Calendar Section */}
      <div style={styles.calendarContainer}>
        <div style={styles.calendarHeader}>
          <button style={styles.navButton} onClick={() => handleMonthChange(-1)}>
            &lt;
          </button>
          <span style={styles.headerText}>
            {displayedDate.toLocaleString('default', { month: 'long' })}{' '}
            {displayedDate.getFullYear()}
          </span>
          <button style={styles.navButton} onClick={() => handleMonthChange(1)}>
            &gt;
          </button>
        </div>
        <div style={styles.calendarGrid}>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <div key={day} style={styles.dayHeader}>
              {day}
            </div>
          ))}
          {calendarDays.map((day, index) => {
            if (day === null) return <div key={index} style={styles.emptyDay} />;
            const date = new Date(displayedDate);
            date.setDate(day);
            const normalizedDate = normalizeDate(date);
            // Determine background based on whether medications on that day were all taken.
            // We consider a day "taken" if at least one active medication is present and all of them were taken.
            const medsForDate = getMedicationsForDate(normalizedDate);
            let status = 0; // 0: no meds, 1: all taken, -1: at least one not taken
            if (medsForDate.length > 0) {
              status = medsForDate.every((med) => isMedicationTaken(med, normalizedDate)) ? 1 : -1;
            }
            const backgroundColor =
              status === -1 ? '#FF3B30' : status === 1 ? '#34C759' : 'transparent';

            return (
              <button
                key={index}
                style={{
                  ...styles.day,
                  backgroundColor,
                  outline:
                    normalizeDate(selectedDate).toDateString() === normalizedDate.toDateString()
                      ? '2px solid black'
                      : 'none',
                  color: backgroundColor !== 'transparent' ? 'white' : 'black',
                }}
                onClick={() => handleDateClick(day)}
              >
                {day}
              </button>
            );
          })}
        </div>
      </div>

      {/* My Medications List Section */}
      <div style={styles.medListContainer}>
        <h3 style={styles.medListHeader}>Medications</h3>
        {medsForSelectedDate.length === 0 ? (
          <p style={styles.noMedications}>No medications for this day.</p>
        ) : (
          medsForSelectedDate.map((med) => {
            const taken = isMedicationTaken(med, selectedDate);
            return (
              <div key={med.id} style={styles.medItem}>
                <span style={styles.medName}>{med.name}</span>
                <span
                  style={{
                    ...styles.medStatus,
                    color: taken ? '#34C759' : '#FF3B30',
                    fontWeight: 'bold',
                  }}
                >
                  {taken ? '✓' : '✗'}
                </span>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

// Inline Styles
const styles = {
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '20px',
    flexWrap: 'wrap',
    padding: '20px',
  },
  calendarContainer: {
    padding: 20,
    borderRadius: 10,
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    width: '100%',
    maxWidth: 400,
  },
  calendarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  navButton: {
    background: 'none',
    border: 'none',
    fontSize: 18,
    cursor: 'pointer',
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  calendarGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: 5,
  },
  dayHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '5px 0',
  },
  emptyDay: {
    height: 40,
  },
  day: {
    width: 40,
    height: 40,
    border: 'none',
    borderRadius: '50%',
    fontSize: 14,
    cursor: 'pointer',
    transition: '0.2s',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  medListContainer: {
    padding: 20,
    borderRadius: 10,
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: 300,
    width: '100%',
  },
  medListHeader: {
    margin: '0 0 10px',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Mulish',
  },
  noMedications: {
    textAlign: 'center',
    fontStyle: 'italic',
    color: '#555',
  },
  medItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
    padding: '8px 0',
  },
  medName: {
    fontSize: 14,
    color: '#333',
  },
  medStatus: {
    fontSize: 18,
  },
};

export default MedicationCalendar;
