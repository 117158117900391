// DownloadPdf.js
import React from 'react';
import { Button } from 'antd';
import { jsPDF } from 'jspdf';
import moment from 'moment';

// A small helper to handle page breaks
function addPageIfNeeded(doc, y, lineHeight = 6) {
  const pageHeight = doc.internal.pageSize.getHeight(); // height of page in jsPDF units
  if (y > pageHeight - 20) {
    doc.addPage();
    // Reset font/size after adding page
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(12);
    return 20; // reset y to a top margin
  }
  return y;
}

// Another helper to print multiline text with page breaks
function printWrappedText(doc, text, x, y, maxWidth = 180, lineHeight = 6) {
  const lines = doc.splitTextToSize(text, maxWidth);
  lines.forEach((line) => {
    y = addPageIfNeeded(doc, y, lineHeight);
    doc.text(line, x, y);
    y += lineHeight;
  });
  return y;
}

const DownloadPdf = ({ reportData }) => {
  const generatePdf = () => {
    const doc = new jsPDF();
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(12);

    let y = 20;   // initial vertical position
    const lineHeight = 6; // spacing between lines

    // 1) Title
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(22);
    doc.text('CPT 98978 Supporting Documentation', 14, y);
    y += 15;
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(12);

    // 2) Report #, Start Date, End Date
    doc.setFont('helvetica', 'bold');
    doc.text('Report #, Start Date, End Date', 14, y);
    y += lineHeight;
    doc.setFont('helvetica', 'normal');
    y = addPageIfNeeded(doc, y, lineHeight);

    const reportId = reportData.reportId || '—';
    const start = reportData.startDate || '—';
    const end = reportData.endDate || '—';

    y = printWrappedText(doc, `Report #: ${reportId}`, 14, y);
    y = printWrappedText(doc, `Start Date: ${start}`, 14, y);
    y = printWrappedText(doc, `End Date: ${end}`, 14, y);
    y += lineHeight;

    // 3) Patient
    doc.setFont('helvetica', 'bold');
    doc.text('Patient', 14, y);
    y += lineHeight;
    doc.setFont('helvetica', 'normal');
    y = addPageIfNeeded(doc, y, lineHeight);

    y = printWrappedText(doc, `Name: ${reportData.patientName || '—'}`, 14, y);
    y = printWrappedText(doc, `Member ID: ${reportData.patientExternal || '—'}`, 14, y);
    y = printWrappedText(doc, `DOB: ${reportData.patientDOB || '—'}`, 14, y);
    y += lineHeight;

    // 4) Provider
    doc.setFont('helvetica', 'bold');
    doc.text('Provider', 14, y);
    y += lineHeight;
    doc.setFont('helvetica', 'normal');
    y = addPageIfNeeded(doc, y, lineHeight);

    y = printWrappedText(doc, `Name: ${reportData.therapistName || '—'}`, 14, y);
    y = printWrappedText(doc, `NPI: ${reportData.therapistNPI || '—'}`, 14, y);
    y += lineHeight;

    // 5) Practice
    doc.setFont('helvetica', 'bold');
    doc.text('Practice', 14, y);
    y += lineHeight;
    doc.setFont('helvetica', 'normal');
    y = addPageIfNeeded(doc, y, lineHeight);

    y = printWrappedText(doc, `Practice Name: ${reportData.practiceName || '—'}`, 14, y);
    y = printWrappedText(doc, `Practice Address: ${reportData.practiceAddress || '—'}`, 14, y);
    y = printWrappedText(doc, `Practice Phone: ${reportData.practicePhone || '—'}`, 14, y);
    y += lineHeight;

    // 6) Clinical Justification
    doc.setFont('helvetica', 'bold');
    doc.text('Clinical Justification', 14, y);
    y += lineHeight;
    doc.setFont('helvetica', 'normal');
    y = addPageIfNeeded(doc, y, lineHeight);

    y = printWrappedText(doc, `Diagnosis: ${reportData.diagnosis || '—'}`, 14, y);
    // Wrap "Reason for RTM / Use Case"
    const reasonForRTM = `Reason for RTM / Use Case: ${reportData.reasonForRTM || '—'}`;
    y = printWrappedText(doc, reasonForRTM, 14, y);
    y += lineHeight;

    // Add "Ensuring consistent engagement..." snippet
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(10);

    const engagementText = `Ensuring consistent engagement with therapy-related tasks (often called homework) between sessions is essential for lasting progress. Research consistently shows that "homework" compliance in therapy significantly improves patient outcomes (e.g., Kazantzis et al., 2010; Kazantzis et al., 2016).`;

    y = printWrappedText(doc, engagementText, 14, y);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(12);
    y += lineHeight;

    // 7) Time Spent (Minutes)
    doc.setFont('helvetica', 'bold');
    doc.text('Time Spent (Minutes)', 14, y);
    y += lineHeight;
    doc.setFont('helvetica', 'normal');
    y = addPageIfNeeded(doc, y, lineHeight);

    y = printWrappedText(doc, reportData.timeSpent || '—', 14, y);
    y += lineHeight;

    // 8) Data Collection Details
    doc.setFont('helvetica', 'bold');
    doc.text('Data Collection Details', 14, y);
    y += lineHeight;
    doc.setFont('helvetica', 'normal');
    y = addPageIfNeeded(doc, y, lineHeight);

    y = printWrappedText(doc, `Collected Dates: ${reportData.collectedDates || '—'}`, 14, y);
    y = printWrappedText(doc, `Total Number of Days: ${reportData.totalDays ?? '—'}`, 14, y);
    y += lineHeight;

    // 9) Compliance Summary
    doc.setFont('helvetica', 'bold');
    doc.text('Compliance Summary', 14, y);
    y += lineHeight;
    doc.setFont('helvetica', 'normal');
    y = addPageIfNeeded(doc, y, lineHeight);

    const compliance = reportData.complianceSummary || '—';
    y = printWrappedText(doc, compliance, 14, y);
    y += lineHeight;

    // 10) Device Supply Cost
    doc.setFont('helvetica', 'bold');
    doc.text('Device Supply Cost', 14, y);
    y += lineHeight;
    doc.setFont('helvetica', 'normal');
    y = addPageIfNeeded(doc, y, lineHeight);

    y = printWrappedText(doc, reportData.deviceSupplyCost || '—', 14, y);
    y += lineHeight;

    // 11) Additional Notes
    doc.setFont('helvetica', 'bold');
    doc.text('Additional Notes', 14, y);
    y += lineHeight;
    doc.setFont('helvetica', 'normal');
    y = addPageIfNeeded(doc, y, lineHeight);

    if (reportData.notes) {
      y = printWrappedText(doc, reportData.notes, 14, y);
    } else {
      y = printWrappedText(doc, '—', 14, y);
    }
    y += lineHeight;

    // 12) Note about 98976 & 98977
    doc.setFont('helvetica', 'normal');
    const massNote = 'Note: In Massachusetts, similar RTM codes 98976 and 98977 are reimbursed at $39.57.';
    y = printWrappedText(doc, massNote, 14, y);
    y += lineHeight;

    // 13) Footer
    const footerLine =
      "Data collected and verified via Yosa Inc.'s remote monitoring application. Visit www.yosaapp.com for more details.";
    y = printWrappedText(doc, footerLine, 14, y);
    y += lineHeight;

    // 14) Generated On
    doc.setFontSize(10);
    const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
    // If near bottom, add page
    y = addPageIfNeeded(doc, y, lineHeight);
    doc.text(`Generated On: ${timestamp}`, 14, 285);

    // Save PDF
    const fileName = `Report_${reportData.startDate || 'start'}_to_${reportData.endDate || 'end'}.pdf`;
    doc.save(fileName);
  };

  return (
    <Button type="primary" onClick={generatePdf}>
      Download PDF
    </Button>
  );
};

export default DownloadPdf;
