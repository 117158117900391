// src/components/InfiniteTicker.jsx
import React, { useState } from "react";
import "../styles/InfiniteTicker.css";

// Icon imports
import safety from "../assets/safety.png";
import worksheets from "../assets/therapy.png";
import therapyjournal from "../assets/talk-therapy.png";
import journaling from "../assets/journaling.png";
import breathing from "../assets/meditation.png";
import pmr from "../assets/PMR.png";
import mood from "../assets/mood.png";
import medication from "../assets/medication.png";
import substance from "../assets/substance.png";
import thoughtrecord from "../assets/thoughtrecord.png";
import goals from "../assets/goals.png";
import activitylog from "../assets/activitylog.png";
import exposuretherapy from "../assets/exposuretherapy.png";
import valuesort from "../assets/valuesort.png";
import mealdonations from "../assets/meal donations.png";
import measure from "../assets/therapy.png";

const icons = [
  { src: safety, title: "Safety Plan" },
  { src: worksheets, title: "Worksheets" },
  { src: therapyjournal, title: "Therapy Journal" },
  { src: journaling, title: "Journaling" },
  { src: breathing, title: "Breathing Room" },
  { src: pmr, title: "PMR" },
  { src: measure, title: "PHQ-9" },
  { src: mood, title: "Symptom Log" },
  { src: medication, title: "Medication" },
  { src: substance, title: "Substance Log" },
  { src: thoughtrecord, title: "Thought Record" },
  { src: measure, title: "GAD-7" },
  { src: goals, title: "Goals" },
  { src: activitylog, title: "Activity Log" },
  { src: exposuretherapy, title: "Exposure Therapy" },
  { src: measure, title: "PCL-5" },
  { src: valuesort, title: "Value Sort" },
  { src: mealdonations, title: "Journey" },
];

const InteractiveTickerItem = ({ src, title }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleClick = () => {
    // Redirect to /product on click
    window.location.href = "/product";
  };

  return (
    <div
      className="ticker-item"
      style={{
        cursor: "pointer",
        transition: "transform 0.3s ease",
        transform: isHovered ? "scale(1.1)" : "scale(1)"
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <img src={src} alt={title} className="ticker-icon" />
      <p className="icon-title">{title}</p>
    </div>
  );
};

const InfiniteTicker = () => {
  return (
    <section className="infinite-ticker">
      <div className="ticker-row ticker-row-top">
        <div className="ticker-track">
          {icons.map((icon, index) => (
            <InteractiveTickerItem key={`top-${index}`} src={icon.src} title={icon.title} />
          ))}
          {icons.map((icon, index) => (
            <InteractiveTickerItem key={`top-repeat-${index}`} src={icon.src} title={icon.title} />
          ))}
        </div>
      </div>
      <div className="ticker-row ticker-row-bottom">
        <div className="ticker-track">
          {icons.map((icon, index) => (
            <InteractiveTickerItem key={`bottom-${index}`} src={icon.src} title={icon.title} />
          ))}
          {icons.map((icon, index) => (
            <InteractiveTickerItem key={`bottom-repeat-${index}`} src={icon.src} title={icon.title} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default InfiniteTicker;
