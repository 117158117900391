import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Card,
  Statistic,
} from "antd";
import {
  UserAddOutlined,
  LogoutOutlined,
  DeleteOutlined,
  DollarOutlined,
  BarChartOutlined,
  FileTextOutlined,
  TeamOutlined,
  UserOutlined,
  DollarCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import Sidebar from "../components/Sidebar";
import AnalyticsTab from "../components/AnalyticsTab";

const { Header, Content, Sider } = Layout;

const AdminDashboard = () => {
  // Inline style objects
  const headerStyle = {
    backgroundColor: "white",
    color: "#375945",
    fontFamily: "Mulish, sans-serif",
    fontSize: "1.5rem",
    fontWeight: "bold",
    padding: "2rem",
  };

  const topSectionStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    marginBottom: "1rem",
  };
  
  const cardStyle = {
    flex: "1 1 200px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  
  const iconContainerStyle = {
    fontSize: "2rem",
    color: "#375945",   // Use your preferred accent color
    marginRight: "1rem",
  };
  
  const cardTextStyle = {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Mulish, sans-serif",
  };
  
  const cardTitleStyle = {
    fontSize: "0.9rem",
    color: "#888",
    marginBottom: "0.25rem",
  };
  
  const cardValueStyle = {
    fontSize: "1.4rem",
    fontWeight: "bold",
    color: "#375945",
  };

  const providersSectionHeaderStyle = {
    fontFamily: "Mulish, sans-serif",
    color: "#375945",
    fontWeight: "bold",
    fontSize: "1.5rem",
    padding: "2rem 0 1rem 0",
  };

  const modalContentStyle = {
    borderRadius: "12px",
    padding: "1rem",
  };

  const modalTitleStyle = {
    fontFamily: "Mulish, sans-serif",
    color: "#375945",
    fontSize: "1.5rem",
    fontWeight: "bold",
  };

  const formLabelStyle = {
    fontFamily: "Mulish, sans-serif",
    fontWeight: 600,
    color: "#375945",
  };

  const inputStyle = {
    borderRadius: "6px",
  };

  // Component state and navigation
  const [activeTab, setActiveTab] = useState("dashboard");
  const [providers, setProviders] = useState([]);
  const [isAddProviderModalVisible, setIsAddProviderModalVisible] = useState(false);
  const [providerForm] = Form.useForm();
  const navigate = useNavigate();

  // Sidebar items
  const sidebarItems = [
    {
      key: "dashboard",
      icon: <FileTextOutlined />,
      label: "Dashboard",
      onClick: () => setActiveTab("dashboard"),
    },

    {
      key: "analytics",
      icon: <BarChartOutlined />,
      label: "Analytics",
      onClick: () => setActiveTab("analytics"),
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: "Log Out",
      onClick: () => navigate("/NewHome/"),
    },
  ];

  // Add provider handler
  const handleAddProvider = (values) => {
    setProviders((prev) => [
      ...prev,
      {
        id: Date.now(),
        ...values,
        clients: 0,
        status: "Active",
      },
    ]);
    setIsAddProviderModalVisible(false);
    providerForm.resetFields();
  };

  // Delete provider handler
  const handleDeleteProvider = (id) => {
    Modal.confirm({
      title: "Delete Provider",
      content: "Are you sure you want to delete this provider?",
      okText: "Delete",
      okButtonProps: { danger: true },
      onOk: () => setProviders((prev) => prev.filter((p) => p.id !== id)),
    });
  };

  // Table columns
  const providerColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span
          style={{
            color: status === "Active" ? "green" : "red",
            fontWeight: "bold",
            fontFamily: "Mulish, sans-serif",
          }}
        >
          {status}
        </span>
      ),
    },
    { title: "# of Clients", dataIndex: "clients", key: "clients" },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDeleteProvider(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  // Dashboard (overview + user management)
  const renderDashboard = () => {
    return (
      <div>
        {/* Overview Section */}
        <div style={topSectionStyle}>
          {/* Card 1: Total Providers */}
          <Card style={cardStyle}>
            <div style={iconContainerStyle}>
              <UserOutlined />
            </div>
            <div style={cardTextStyle}>
              <span style={cardTitleStyle}>Total Providers</span>
              <span style={cardValueStyle}>{providers.length}</span>
            </div>
          </Card>

          {/* Card 2: Total Active Clients */}
          <Card style={cardStyle}>
            <div style={iconContainerStyle}>
              <TeamOutlined />
            </div>
            <div style={cardTextStyle}>
              <span style={cardTitleStyle}>Total Active Clients</span>
              <span style={cardValueStyle}>{providers.reduce((total, provider) => total + provider.clients, 0)}</span>
            </div>
          </Card>

          {/* Card 3: Recent Billing Activity */}
          {/*<Card style={cardStyle}>
            <div style={iconContainerStyle}>
              <DollarCircleOutlined />
            </div>
            <div style={cardTextStyle}>
              <span style={cardTitleStyle}>Recent Billing Activity</span>
              <span style={cardValueStyle}>$189.99 / month</span>
            </div>
          </Card>*/}

          {/* Card 4: Avg. Homework Compliance */}
          <Card style={cardStyle}>
            <div style={iconContainerStyle}>
              <CheckCircleOutlined />
            </div>
            <div style={cardTextStyle}>
              <span style={cardTitleStyle}>Completion Rate</span>
              <span style={cardValueStyle}>75%</span>
            </div>
          </Card>
        </div>
        {/* User Management Table */}
        <div>
          <h2 style={providersSectionHeaderStyle}>My Providers</h2>
          <Button
            icon={<UserAddOutlined />}
            onClick={() => setIsAddProviderModalVisible(true)}
            style={{
              marginBottom: "16px",
              fontFamily: "Mulish, sans-serif",
              fontWeight: 600,
              backgroundColor: "#375945",
              color: "white",
              border: "none",
              borderRadius: "35px",
              padding: "0.5rem 1.5rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            Add Provider
          </Button>
          <Table
            style={{ backgroundColor: "white", paddingBottom: "1rem" }}
            columns={providerColumns}
            dataSource={providers.map((provider) => ({
              key: provider.id,
              ...provider,
            }))}
            pagination={{ pageSize: 5 }}
          />
        </div>
      </div>
    );
  };

  // Content based on active tab
  const renderContent = () => {
    switch (activeTab) {
      case "dashboard":
        return renderDashboard();
      case "analytics":
        return <AnalyticsTab />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* Inline <head> for font */}
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Admin Dashboard</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap"
          rel="stylesheet"
        />
      </head>

      {/* Layout with pinned Sider */}
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          width={200}
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "#2c4737", // Example darker green
            overflow: "auto",
          }}
        >
          {/* Render your custom Sidebar inside Sider */}
          <Sidebar items={sidebarItems} />
        </Sider>

        {/* Main layout offset by the Sider width */}
        <Layout style={{ marginLeft: 200 }}>
          <Header style={headerStyle}>Admin Dashboard</Header>
          <Content style={{ padding: "1rem", backgroundColor: "#ffffff", overflow: "auto" }}>
            <div style={{ margin: "1rem" }}>{renderContent()}</div>
          </Content>
        </Layout>

        {/* Add Provider Modal */}
        <Modal
          title={<div style={modalTitleStyle}>Add New Provider</div>}
          visible={isAddProviderModalVisible}
          onCancel={() => setIsAddProviderModalVisible(false)}
          onOk={() => providerForm.submit()}
          bodyStyle={modalContentStyle}
        >
          <Form form={providerForm} onFinish={handleAddProvider} layout="vertical">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Enter provider's name" }]}
              labelStyle={formLabelStyle}
            >
              <Input placeholder="Provider Name" style={inputStyle} />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Enter provider's email" },
                { type: "email", message: "Invalid email format" },
              ]}
              labelStyle={formLabelStyle}
            >
              <Input placeholder="Provider Email" style={inputStyle} />
            </Form.Item>
          </Form>
        </Modal>
      </Layout>
    </>
  );
};

export default AdminDashboard;
