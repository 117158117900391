import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Tabs, Select, Space, message, Modal, Layout } from 'antd';
import { LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Sidebar from '../components/Sidebar'; // Import the new Sidebar component
import FeaturesTab from '../components/FeaturesTab'; // Import the new FeaturesTab component
import ActivityTab from '../components/ActivityTab'; // Import the new QualitativeDataTab component
import MedicationCalendar from '../components/MedicationCalendar'; // Import the new MedicationCalendar component
import AllDataChart from '../components/AllDataChart'; // Import the new AllDataChart component
import { Menu, Checkbox } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import '../styles/ClientDetail.css';
import dayjs from 'dayjs';
import axios from 'axios';
import ReimbursementTab from '../components/ReimbursementTab';

const { TabPane } = Tabs;
const { Option } = Select;

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const gad7Questions = {
  q1: "Feeling nervous, anxious, or on edge",
  q2: "Not being able to stop or control worrying",
  q3: "Worrying too much about different things",
  q4: "Trouble relaxing",
  q5: "Being so restless that it’s hard to sit still",
  q6: "Becoming easily annoyed or irritable",
  q7: "Feeling afraid as if something awful might happen"
};

const gad7phq9Answers = {
  0: "Not at all (0)",
  1: "Several days (1)",
  2: "More than half the days (2)",
  3: "Nearly every day (3)"
}

const phq9Questions = {
  q1: "Little interest or pleasure in doing things",
  q2: "Feeling down, depressed, or hopeless",
  q3: "Trouble falling or staying asleep, or sleeping too much",
  q4: "Feeling tired or having little energy",
  q5: "Poor appetite or overeating",
  q6: "Feeling bad about yourself—or that you are a failure or have let yourself or your family down",
  q7: "Trouble concentrating on things, such as reading the newspaper or watching television",
  q8: "Moving or speaking so slowly that other people could have noticed, or being so fidgety or restless that you have been moving around a lot more than usual",
  q9: "Thoughts that you would be better off dead, or thoughts of hurting yourself in some way"
};

const pcl5Questions = {
  q1: "Repeated, disturbing, and unwanted memories of the stressful experience",
  q2: "Repeated, disturbing dreams of the stressful experience",
  q3: "Suddenly feeling or acting as if the stressful experience were actually happening again",
  q4: "Feeling very upset when something reminded you of the stressful experience",
  q5: "Having strong physical reactions (e.g., heart pounding, trouble breathing, sweating) when something reminded you of the stressful experience",
  q6: "Avoiding memories, thoughts, or feelings related to the stressful experience",
  q7: "Avoiding external reminders of the stressful experience (people, places, conversations, activities, objects, situations, etc.)",
  q8: "Trouble remembering important parts of the stressful experience",
  q9: "Having strong negative beliefs about yourself, other people, or the world",
  q10: "Blaming yourself or someone else for the stressful experience or what happened after it",
  q11: "Having strong negative feelings such as fear, horror, anger, guilt, or shame",
  q12: "Loss of interest in activities that you used to enjoy",
  q13: "Feeling distant or cut off from other people",
  q14: "Trouble experiencing positive feelings (e.g., being unable to feel happiness or have loving feelings for people close to you)",
  q15: "Irritable behavior, angry outbursts, or acting aggressively",
  q16: "Taking too many risks or doing things that could cause you harm",
  q17: "Being 'superalert' or watchful or on guard",
  q18: "Feeling jumpy or easily startled",
  q19: "Difficulty concentrating",
  q20: "Trouble falling or staying asleep"
};

const pcl5Answers = {
  0: "Not at all (0)",
  1: "A little bit (1)",
  2: "Moderately (2)",
  3: "Quite a bit (3)",
  4: "Extremely (4)"
}

const answerColors = {
  0: "#9E9E9E",
  1: "#FFA480",
  2: "#FF6352",
  3: "#FF3217",
  4: "#FF3217"
};

const ClientDetail = () => {

  const navigate = useNavigate();
  const [customWorksheets, setCustomWorksheets] = useState([]);
  const [assignments, setAssignments] = useState([]);

  const [answersModalData, setAnswersModalData] = useState(null);
  const [isAnswersModalVisible, setIsAnswersModalVisible] = useState(false);
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [isContentModalVisible, setIsContentModalVisible] = useState(false);
  const [contentContent, setContentContent] = useState("");
  const [contentTitle, setContentTitle] = useState("");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const [selectedTask, setSelectedTask] = useState(null);
  const [gad7Data, setGad7Data] = useState([]);
  const [gad7CompleteData, setGad7CompleteData] = useState([]);
  const [phq9Data, setPhq9Data] = useState([]);
  const [phq9CompleteData, setPhq9CompleteData] = useState([]);
  const [pcl5Data, setPcl5Data] = useState([]);
  const [pcl5CompleteData, setPcl5CompleteData] = useState([]);
  const [consolidatedData, setConsolidatedData] = useState([]);
  const [visibleMetrics, setVisibleMetrics] = useState(['mood', 'energy', 'stress', 'sleep', 'socialInteraction']);
  const [medications, setMedications] = useState([]);
  const [substances, setSubstances] = useState([]);
  const [selectedMedication, setSelectedMedication] = useState(null);
  const [selectedSubstance, setSelectedSubstance] = useState(null);
  const [timeSelections, setTimeSelections] = useState(['month', 'month', 'month', 'month'])
  const location = useLocation();
  const { client, tasks: tasksFromLocation, activeTab: initialActiveTab, selectedChart: initialSelectedChart } = location.state || {};
  const [activeTab, setActiveTab] = useState(() => initialActiveTab || '1');
  const [selectedChart, setSelectedChart] = useState(initialSelectedChart || 'gad7' || 'phq9' || 'pcl5');
  const [tasks, setTasks] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [thoughtRecords, setThoughtRecords] = useState(null);
  const [journals, setJournals] = useState(null);
  const [therapyJournals, setTherapyJournals] = useState(null);
  const [activityLogs, setActivityLogs] = useState(null);
  const [exposureTherapy, setExposureTherapy] = useState(null);
  const [goals, setGoals] = useState(null);
  const [safetyPlan, setSafetyPlan] = useState(null);
  const [valueSort, setValueSort] = useState(null);

  const [filterCompleted, setFilterCompleted] = useState(false);
  const [filterIncomplete, setFilterIncomplete] = useState(false);

  const [sharingFeatures, setSharingFeatures] = useState([]);

  const { Header } = Layout;

  useEffect(() => {
    setTasks(tasksFromLocation || []);
    if (client?.email) {
      fetchChartData();
      fetchSharingFeatures();
      fetchChart2Data();
      fetchMedications();
      fetchSubstances();
    }
  }, [client]);

  useEffect(() => {
    if (!selectedMedication && medications.length > 0) {
      setSelectedMedication(medications[0].id); // Set the first medication by default
    }
  }, [medications]);

  useEffect(() => {
    if (!selectedSubstance && substances.length > 0) {
      setSelectedSubstance(substances[0].id); // Set the first medication by default
    }
  }, [substances]);

  const handleViewChart = (chartType) => {
    setSelectedChart(chartType); // Set the selected chart
    setActiveTab('2'); // Immediately switch to the Charts tab
  };

  const deleteTask = async (taskId) => {
    try {
      // Optimistically remove the task from the frontend
      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));

      // Make the DELETE request to the backend
      const response = await axios.delete(`${api}/Tasks/${taskId}/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 204) {
        message.success('Task deleted successfully');
      } else {
        throw new Error('Failed to delete task from the backend');
      }
    } catch (error) {
      console.error('Error deleting task:', error);
      message.error('Failed to delete task. Please try again.');
    }
  };

  // Chart data based on selection
  const chartData =
    selectedChart === 'gad7'
      ? gad7Data
      : selectedChart === 'phq9'
        ? phq9Data
        : pcl5Data;

  const completeChartData =
    selectedChart === 'gad7'
      ? gad7CompleteData
      : selectedChart === 'phq9'
        ? phq9CompleteData
        : pcl5CompleteData;

  const filterDataByTimeRange = (data, timeSelection) => {
    if (!data || data.length === 0) return []; // Handle empty data

    const now = dayjs(); // Current date

    switch (timeSelection) {
      case 'week':
        return data.filter((entry) =>
          dayjs(entry.date, "M/D/YYYY").isAfter(now.subtract(1, 'week'))
        );
      case 'month':
        return data.filter((entry) =>
          dayjs(entry.date, "M/D/YYYY").isAfter(now.subtract(1, 'month'))
        );
      case 'year':
        return data.filter((entry) =>
          dayjs(entry.date, "M/D/YYYY").isAfter(now.subtract(1, 'year'))
        );
      case 'all':
      default:
        return data; // Return all data if 'all' is selected
    }
  };

  const fetchSharingFeatures = async () => {
    const username = client.clientUsername;

    //console.log('fetching sharing for', username);

    try {
      const response = await axios.get(`${api}/Sharing/`, {
        params: { username },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      if (response.data.length > 0) {
        setSharingFeatures(response.data[0].features || []);
        //console.log('response is', response.data[0].features);
      } else {
        console.error('No sharing data found for this user.');
        setSharingFeatures([]); // Fallback to empty
      }
    } catch (error) {
      console.error('Failed to fetch sharing features:', error.message || error);
      setSharingFeatures([]); // Fallback to empty
    }
  };

  const fetchChartData = async () => {
    const username = client.clientUsername;

    try {
      // Fetch GAD-7 data
      const gad7Url = new URL(`${api}/GAD7/`);
      gad7Url.searchParams.set('username', username);

      const gad7Response = await fetch(gad7Url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      if (!gad7Response.ok) {
        throw new Error(`Failed to fetch GAD-7 data: ${gad7Response.statusText}`);
      }

      const gad7RawData = await gad7Response.json();

      const transformedGad7Data = gad7RawData.map((entry) => {
        const totalScore =
          (entry.q1 || 0) +
          (entry.q2 || 0) +
          (entry.q3 || 0) +
          (entry.q4 || 0) +
          (entry.q5 || 0) +
          (entry.q6 || 0) +
          (entry.q7 || 0);

        return {
          date: new Date(entry.date).toLocaleDateString(),
          Score: totalScore,
        };
      });

      setGad7Data(transformedGad7Data);

      const completeGad7Data = gad7RawData.map((entry) => {
        const responses = Object.keys(entry)
          .filter(key => key.startsWith("q"))
          .map(key => ({
            question: gad7Questions[key],
            answer: gad7phq9Answers[entry[key]],
            color: answerColors[entry[key]]
          }));

        return {
          date: new Date(entry.date).toLocaleDateString(),
          responses
        }
      })

      setGad7CompleteData(completeGad7Data);

      // Fetch PHQ-9 data
      const phq9Url = new URL(`${api}/PHQ9/`);
      phq9Url.searchParams.set('username', username);

      const phq9Response = await fetch(phq9Url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      if (!phq9Response.ok) {
        throw new Error(`Failed to fetch PHQ-9 data: ${phq9Response.statusText}`);
      }

      const phq9RawData = await phq9Response.json();

      const transformedPhq9Data = phq9RawData.map((entry) => {
        const totalScore =
          (entry.q1 || 0) +
          (entry.q2 || 0) +
          (entry.q3 || 0) +
          (entry.q4 || 0) +
          (entry.q5 || 0) +
          (entry.q6 || 0) +
          (entry.q7 || 0) +
          (entry.q8 || 0) +
          (entry.q9 || 0);

        return {
          date: new Date(entry.date).toLocaleDateString(),
          Score: totalScore,
        };
      });

      setPhq9Data(transformedPhq9Data);

      const completePhq9Data = phq9RawData.map((entry) => {
        const responses = Object.keys(entry)
          .filter(key => key.startsWith("q"))
          .map(key => ({
            question: phq9Questions[key],
            answer: gad7phq9Answers[entry[key]],
            color: answerColors[entry[key]]
          }));

        return {
          date: new Date(entry.date).toLocaleDateString(),
          responses
        }
      })

      setPhq9CompleteData(completePhq9Data);

      // Fetch PCL-5 data
      const pcl5Url = new URL(`${api}/PCL5/`);
      pcl5Url.searchParams.set('username', username);

      const pcl5Response = await fetch(pcl5Url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      if (!pcl5Response.ok) {
        throw new Error(`Failed to fetch PCL-5 data: ${pcl5Response.statusText}`);
      }

      const pcl5RawData = await pcl5Response.json();

      const transformedPcl5Data = pcl5RawData.map((entry) => {
        const totalScore =
          (entry.q1 || 0) +
          (entry.q2 || 0) +
          (entry.q3 || 0) +
          (entry.q4 || 0) +
          (entry.q5 || 0) +
          (entry.q6 || 0) +
          (entry.q7 || 0) +
          (entry.q8 || 0) +
          (entry.q9 || 0) +
          (entry.q10 || 0) +
          (entry.q11 || 0) +
          (entry.q12 || 0) +
          (entry.q13 || 0) +
          (entry.q14 || 0) +
          (entry.q15 || 0) +
          (entry.q16 || 0) +
          (entry.q17 || 0) +
          (entry.q18 || 0) +
          (entry.q19 || 0) +
          (entry.q20 || 0);

        return {
          date: new Date(entry.date).toLocaleDateString(),
          Score: totalScore,
        };
      });

      setPcl5Data(transformedPcl5Data);

      const completePcl5Data = pcl5RawData.map((entry) => {
        const responses = Object.keys(entry)
          .filter(key => key.startsWith("q"))
          .map(key => ({
            question: pcl5Questions[key],
            answer: pcl5Answers[entry[key]],
            color: answerColors[entry[key]]
          }));

        return {
          date: new Date(entry.date).toLocaleDateString(),
          responses
        }
      })

      setPcl5CompleteData(completePcl5Data);
    } catch (error) {
      console.error("Error fetching chart data:", error.message || error);
      message.error("Failed to load chart data.");
    }
  };

  const fetchChart2Data = async () => {
    const username = client.clientUsername;

    try {
      // Fetch Symptom Log data
      const symptomUrl = new URL(`${api}/MoodInfo/`);
      symptomUrl.searchParams.set('username', username);

      const symptomResponse = await fetch(symptomUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      if (!symptomResponse.ok) {
        throw new Error(`Failed to fetch Symptom Log data: ${symptomResponse.statusText}`);
      }

      const symptomRawData = await symptomResponse.json();

      const consolidatedData = symptomRawData
        .map(entry => {
          return {
            date: new Date(entry?.date).toLocaleDateString(), // The unique date
            mood: entry?.mood !== null ? (((entry.mood - 1) / 4) * 100) : null,
            energy: entry?.energy || null,
            stress: entry?.stress || null,
            sleep: entry?.sleep || null,
            socialInteraction: entry?.socialInteraction || null,
          };
        })

      // Set the state with the filtered and transformed data
      setConsolidatedData(consolidatedData);
    } catch (error) {
      console.error("Error fetching chart 2 data:", error.message || error);
      message.error("Failed to load chart 2 data.");
    }
  };

  const fetchMedications = async () => {
    const username = client.clientUsername;

    try {
      const medicationsUrl = new URL(`${api}/Medications/`);
      medicationsUrl.searchParams.set('username', username);

      const medicationsResponse = await fetch(medicationsUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      if (!medicationsResponse.ok) {
        throw new Error(`Failed to fetch Medications data: ${medicationsResponse.statusText}`);
      }

      const medicationsRawData = await medicationsResponse.json();

      setMedications(medicationsRawData);
    } catch (error) {
      console.error("Error fetching medications:", error.message || error);
      message.error("Failed to load medications.");
    }
  };

  const fetchSubstances = async () => {
    const username = client.clientUsername;

    try {
      const substancesUrl = new URL(`${api}/Substances/`);
      substancesUrl.searchParams.set('username', username);

      const substancesResponse = await fetch(substancesUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      if (!substancesResponse.ok) {
        throw new Error(`Failed to fetch Substances data: ${substancesResponse.statusText}`);
      }

      const substancesRawData = await substancesResponse.json();

      setSubstances(substancesRawData);
    } catch (error) {
      console.error("Error fetching substances:", error.message || error);
      message.error("Failed to load substances.");
    }
  };

  // Fixed max for Y-axis
  const chartMaxYDomain = {
    gad7: 21,
    pcl5: 80,
    phq9: 27
  };

  const maxYDomain = chartMaxYDomain[selectedChart] || 80;

  const items = [
    {
      key: '1',
      icon: <RollbackOutlined />,
      label: 'Back',
      onClick: () => {
        navigate('/dashboard');
      }
    },
  ];

  const showContentModal = (message, title) => {
    setContentContent(message);
    setContentTitle(title);
    setIsContentModalVisible(true);
  };

  const handleContentModalClose = () => {
    setIsContentModalVisible(false);
    setContentContent("");
    setContentTitle("");
  };

  const showDeleteModal = (task) => {
    setIsDeleteModalVisible(message);
    setSelectedTask(task);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalVisible(false);
    setSelectedTask(null);
  };

  const openPdfModal = (fileUrl) => {
    setPdfUrl(fileUrl);
    setPdfModalVisible(true);
  };

  const closePdfModal = () => {
    setPdfUrl("");
    setPdfModalVisible(false);
  };


  // Table columns definition for assignments

  const columns = [
    {
      title: 'Task Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => dayjs(date).format('MMMM D, YYYY, h:mm A'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span style={{ color: status === 'Complete' ? 'green' : 'red' }}>{status}</span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, task) => (
        <Space>
          {/* View PHQ-9 Chart Button */}
          {task.type === 'PHQ-9' && task.status === 'Complete' && (
            <Button
              type="link"
              onClick={() => {
                handleViewChart('phq9');
              }}
            >
              View PHQ-9 Chart
            </Button>
          )}

          {/* View GAD-7 Chart Button */}
          {task.type === 'GAD-7' && task.status === 'Complete' && (
            <Button
              type="link"
              onClick={() => {
                handleViewChart('gad7');
              }}
            >
              View GAD-7 Chart
            </Button>
          )}

          {/* View PCL-5 Chart Button */}
          {task.type === 'PCL-5' && task.status === 'Complete' && (
            <Button
              type="link"
              onClick={() => {
                handleViewChart('pcl5');
              }}
            >
              View PCL-5 Chart
            </Button>
          )}

          {/* View Response Button for Worksheets */}
          {task.file !== null && task.status === 'Complete' && task.response && (

            <Button
              type="link"
              onClick={() => {
                openPdfModal(task.file);
              }}
            >
              View PDF
            </Button>
          )}

          {/* View Response Button for Worksheets */}
          {!['PHQ-9', 'GAD-7', 'PCL-5'].includes(task.type) && task.status === 'Complete' && task.response && (
            <Button
              type="link"
              onClick={() => {
                showContentModal(task.response, "Response");
              }}
            >
              View Response
            </Button>
          )}

          {task.type === 'See Notes' && task.message && (
            <Button
              type="link"
              onClick={() => showContentModal(task.message, "Instructions")}
            >
              View Instructions
            </Button>
          )}
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, task) => (
        <Space>
          <Button
            type="link"
            danger
            onClick={() => showDeleteModal(task)}
          >
            Delete
          </Button>
        </Space>
      ),
    }
  ];

  // Function to handle filter changes
  const handleFilterChange = (filterType) => {
    if (filterType === 'completed') {
      setFilterCompleted(!filterCompleted);
    } else if (filterType === 'incomplete') {
      setFilterIncomplete(!filterIncomplete);
    }
  };

  // Filter the assignments based on the selected filters
  const filteredTasks = tasks.filter((task) => {
    if (!filterCompleted && !filterIncomplete) return true;
    if (filterCompleted && task.status === 'Complete') return true;
    if (filterIncomplete && task.status === 'Incomplete') return true;
    return false;
  });

  // Dropdown menu for filtering
  const menu = (
    <Menu>
      <Menu.Item key="completed">
        <Checkbox
          checked={filterCompleted}
          onChange={() => handleFilterChange('completed')}
        >
          Completed
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="incomplete">
        <Checkbox
          checked={filterIncomplete}
          onChange={() => handleFilterChange('incomplete')}
        >
          Incomplete
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  // Function to generate all dates between the first and last entry
  const generateDateRange = (startDate, endDate) => {
    let dates = [];
    let current = dayjs(startDate);
    let end = dayjs(endDate);

    while (current.isBefore(end) || current.isSame(end, 'day')) {
      dates.push(current.format("M/D/YYYY"));
      current = current.add(1, "day");
    }

    return dates;
  };

  // Retrieve and process substance data
  const selectedSubstanceEntries =
    substances.find((sub) => sub.id === selectedSubstance)?.entries || [];

  const allDates =
    selectedSubstanceEntries.length > 0
      ? generateDateRange(
        dayjs(selectedSubstanceEntries[0].date).format("M/D/YYYY"),
        dayjs(selectedSubstanceEntries[selectedSubstanceEntries.length - 1].date).format("M/D/YYYY")
      )
      : [];

  const fullData = allDates.map((date) => {
    const existingEntry = selectedSubstanceEntries.find(
      (entry) => dayjs(entry.date).format("M/D/YYYY") === date
    );
    return {
      date,
      amount: existingEntry ? existingEntry.amount : 0, // Fill missing dates with 0
      money: existingEntry ? existingEntry.money : "0",
      situation: existingEntry ? existingEntry.situation : "",
    };
  });

  const CustomDot = (props) => {
    const { cx, cy, payload } = props;
    return (
      <circle
        cx={cx}
        cy={cy}
        r={9}
        fill="#a68200"
        style={{ cursor: "pointer" }}
        onClick={() => handleDataPointClick(payload)}
      />
    );
  };

  const handleDataPointClick = (dataPoint) => {
    console.log("clicked data point with date", dataPoint.date);
    const clickedDate = dataPoint.date;
    const entry = completeChartData.find(item => item.date === clickedDate);
    console.log("entry found", entry);
    setAnswersModalData(entry);
    setIsAnswersModalVisible(true);
  };

  const getCustomTicks = () => {
    if (selectedChart === 'pcl5') {
      return [0, 15, 31, 50, 80];
    } else if (selectedChart === 'gad7') {
      return [0, 5, 10, 15, 21];
    } else {
      return [0, 5, 10, 15, 20, 27];
    }
  };

  const fmtDate = (date) => dayjs(date).format("M/D/YYYY");

  function getAllOtherDataForChart(
    exposureTherapy = [],
    tasks = [],
    journals = [],
    therapyJournals = [],
    thoughtRecords = [],
    activityLogs = [],
    goals = [],
    safetyPlan = [],
    valueSort = [],
    medications = [],
    substances = []
  ) {
    // Helper: format dates consistently.
    const fmtDate = (date) => dayjs(date).format("M/D/YYYY");
  
    // Transform Tasks
    const tasksTransformed = tasks.map((task) => ({
      date: fmtDate(task.date),
      details: [`Task: ${task.name}`, `Status: ${task.status}`],
    }));
  
    // Transform Journaling entries
    const journalingTransformed = (journals || []).map((j) => ({
      date: fmtDate(j.date),
      details: [
        `Journal Entry: ${j.title || "No title"}`,
        j.entry ? `Entry: ${j.entry.slice(0, 50)}...` : "No entry",
      ],
    }));
  
    // Transform Therapy Journals
    const therapyJournalsTransformed = (therapyJournals || []).map((t) => ({
      date: fmtDate(t.date),
      details: [
        "Therapy Journal Entry",
        t.beforeMood ? `Before Mood: ${t.beforeMood}/10` : "",
        t.afterMood ? `After Mood: ${t.afterMood}/10` : "",
      ].filter(Boolean),
    }));
  
    // Transform Thought Records
    const thoughtRecordsTransformed = (thoughtRecords || []).map((tr) => ({
      date: fmtDate(tr.date),
      details: [
        `Thought Record: ${tr.title || "Untitled"}`,
        tr.entry ? `Entry: ${tr.entry.slice(0, 50)}...` : "No entry",
      ],
    }));
  
    // Transform Activity Logs
    const activityLogsTransformed = (activityLogs || []).map((a) => ({
      date: fmtDate(a.day),
      details: [
        `Activity Log: ${a.title || "Untitled"}`,
        a.expectedMood ? `Expected Mood: ${a.expectedMood}/10` : "",
        a.beforeMood ? `Before Mood: ${a.beforeMood}/10` : "",
        a.afterMood ? `After Mood: ${a.afterMood}/10` : "",
      ].filter(Boolean),
    }));
  
    // Transform Exposure Therapy entries
    const exposureTherapyTransformed = (exposureTherapy || []).map((e) => ({
      date: e.day ? fmtDate(e.day) : (e.date ? fmtDate(e.date) : "N/A"),
      details: [
        "Exposure Therapy",
        e.situation ? `Situation: ${e.situation}` : "",
        e.beginningSuds != null ? `Start SUDS: ${e.beginningSuds}/10` : "",
        e.endSuds != null ? `End SUDS: ${e.endSuds}/10` : "",
      ].filter(Boolean),
    }));
  
    // Transform Goals
    const goalsTransformed = (goals || []).map((g) => ({
      date: dayjs(g.done_by).isValid() ? fmtDate(g.done_by) : fmtDate(g.created_at),
      details: [`Goal: ${g.description || "No description"}`, g.is_completed ? "Completed" : "Not completed"],
    }));
  
    // Transform Safety Plan updates
    const safetyPlanTransformed = (safetyPlan || []).map((sp) => ({
      date: sp.created_at ? fmtDate(sp.created_at) : "N/A",
      details: [
        "Safety Plan updated",
        sp.warningSigns ? `Warning signs: ${sp.warningSigns.slice(0, 50)}...` : "",
      ].filter(Boolean),
    }));
  
    // Transform Value Sort entries
    const valueSortTransformed = (valueSort || []).map((v) => ({
      date: v.date ? fmtDate(v.date) : "N/A",
      details: ["Value Sort completed"],
    }));
  
    // Transform Medications: iterate through each medication’s entries.
    const medicationsTransformed = (medications || []).flatMap((med) => {
      if (!Array.isArray(med.entries)) return [];
      return med.entries.map((entry) => ({
        date: fmtDate(entry.date),
        details: [
          `Medication: ${med.name}`,
          entry.amount ? `Amount: ${entry.amount}` : "",
          entry.notes ? `Notes: ${entry.notes}` : "",
        ].filter(Boolean),
      }));
    });
  
    // Transform Substances: iterate through each substance’s entries.
    const substancesTransformed = (substances || []).flatMap((sub) => {
      if (!Array.isArray(sub.entries)) return [];
      return sub.entries.map((entry) => ({
        date: fmtDate(entry.date),
        details: [
          `Substance: ${sub.name}`,
          entry.amount ? `Amount: ${entry.amount}` : "",
          entry.situation ? `Situation: ${entry.situation}` : "",
          entry.money ? `Money: $${entry.money}` : "",
        ].filter(Boolean),
      }));
    });
  
    // Combine all transformed arrays.
    const allData = [
      ...tasksTransformed,
      ...journalingTransformed,
      ...therapyJournalsTransformed,
      ...thoughtRecordsTransformed,
      ...activityLogsTransformed,
      ...exposureTherapyTransformed,
      ...goalsTransformed,
      ...safetyPlanTransformed,
      ...valueSortTransformed,
      ...medicationsTransformed,
      ...substancesTransformed,
    ];
  
    // Sort all entries by date ascending.
    allData.sort(
      (a, b) =>
        dayjs(a.date, "M/D/YYYY").toDate() - dayjs(b.date, "M/D/YYYY").toDate()
    );
  
    return allData;
  }
  

  return (
    <>
      <head>
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>Yosa Client Detail</title>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
        </style>
      </head>
      <div className="client-detail">
        {/* Sidebar with a back button */}
        <Sidebar items={items} />
        {/* Content Modal */}
        <Modal
          title={contentTitle}
          visible={isContentModalVisible}
          onCancel={handleContentModalClose}
          footer={[
            <Button key="close" onClick={handleContentModalClose}>
              Close
            </Button>,
          ]}
        >
          <p>{contentContent || "No content provided."}</p>
        </Modal>

        {/* Delete Modal */}
        <Modal
          title="Delete Task"
          visible={isDeleteModalVisible}
          onCancel={handleDeleteModalClose}
          footer={[
            <Button key="cancel" onClick={() => {
              handleDeleteModalClose();
            }}>
              Cancel
            </Button>,
            <Button key="yes" onClick={() => {
              deleteTask(selectedTask.id);
              handleDeleteModalClose();
            }}>
              Confirm Delete
            </Button>,
          ]}
        >
          <p>{"Are you sure you want to delete this task?"}</p>
        </Modal>

        {/* PDF Modal */}
        <Modal
          title="View PDF"
          visible={pdfModalVisible}
          onCancel={closePdfModal}
          footer={[
            <Button key="close" onClick={closePdfModal}>
              Close
            </Button>,
          ]}
          width="80%"
          style={{ top: 20 }}
        >
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              style={{ width: '100%', height: '80vh' }}
              frameBorder="0"
            ></iframe>
          )}
        </Modal>

        {/* Main Content */}
        <div className="client-content">
          <Header
            className='header'
            style={{
              padding: 40,
              fontWeight: 'bold',
              fontSize: 25,
              fontFamily: 'Mulish',
              backgroundColor: '#C3D7CB',
              borderBottom: '3px solid #375945',
              color: 'black',
              display: 'flex',  // Make the header itself flex to contain items properly
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '17px' }}>
              <div>
                {client.name ? `${client.name}` : 'Client'}
              </div>
              <div style={{ textAlign: 'right', fontSize: 'medium' }}>
                <p><strong>Email:</strong> {client.email}</p>
              </div>
            </div>
          </Header>

          <div className="client-content-inner">
            <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={(key) => setActiveTab(key)}>

              {/* Qualitative Data Content */}
              <TabPane tab={<span style={{ color: '#375945' }}>Activity</span>} key="1">
                <ActivityTab clientUsername={client?.clientUsername} name={client?.name} />
              </TabPane>
              {/* Charts Content */}
              <TabPane tab={<span style={{ color: '#375945' }}>Charts</span>} key="2">
                <Modal
                  title={`Responses on ${answersModalData?.date ? new Date(answersModalData.date).toLocaleDateString() : 'N/A'
                    }`}
                  visible={isAnswersModalVisible}
                  onCancel={() => setIsAnswersModalVisible(false)}
                  footer={[
                    <Button key="close" onClick={() => setIsAnswersModalVisible(false)}>
                      Close
                    </Button>,
                  ]}
                >
                  {selectedChart === 'gad7' || selectedChart === 'phq9' ? (
                    <p>Over the past two weeks, how often have you been bothered by:</p>
                  ) : (
                    <p>In the past month, how much were you bothered by:</p>
                  )}
                  {answersModalData && answersModalData.responses && answersModalData.responses.length > 0 ? (
                    answersModalData.responses.map((item, index) => (
                      <div key={index} style={{ marginBottom: '1rem' }}>
                        <strong>{item.question}</strong>
                        <p style={{ color: item.color }}>{item.answer}</p>
                      </div>
                    ))
                  ) : (
                    <p>No responses available.</p>
                  )}
                </Modal>
                <div className="chart-wrapper">
                  <h3 style={{ marginTop: 20, marginBottom: 20 }}>Validated Measures</h3>
                  <div className="chart-container">
                    {/* Dropdown to select chart */}
                    <div className="chart-selector">
                      <Select
                        value={selectedChart}
                        style={{ width: 200 }}
                        onChange={value => setSelectedChart(value)}
                      >
                        <Option value="gad7">GAD-7</Option>
                        <Option value="phq9">PHQ-9</Option>
                        <Option value="pcl5">PCL-5</Option>
                      </Select>
                      <Select
                        style={{ width: '200px', marginLeft: 20 }}
                        value={timeSelections[0]}
                        onChange={(value) => {
                          setTimeSelections((prev) => {
                            const updatedSelections = [...prev];
                            updatedSelections[0] = value;
                            return updatedSelections;
                          });
                        }}
                      >
                        <Option value="week">Last Week</Option>
                        <Option value="month">Last Month</Option>
                        <Option value="year">Last Year</Option>
                        <Option value="all">All Time</Option>
                      </Select>
                    </div>
                    {(filterDataByTimeRange(chartData, timeSelections[0]).length > 0) ? (
                      <div>
                        <p style={{ marginLeft: '10px', color: '#a7a7a7' }}>Click on data points to examine responses</p>
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart data={filterDataByTimeRange(chartData, timeSelections[0])} margin={{ top: 10, right: 10 }} >
                            <CartesianGrid stroke="#ccc" />
                            <XAxis dataKey="date" interval='preserveStartEnd' tickFormatter={(tick) => dayjs(tick).format("M/D/YY")} tick={{ fontSize: 12 }} tickMargin={10} />
                            <YAxis domain={[0, maxYDomain]} ticks={getCustomTicks()} />
                            <Tooltip />
                            <Line
                              type="monotone"
                              dataKey="Score"
                              stroke="#a68200"
                              strokeWidth={3}
                              dot={<CustomDot onDotClick={handleDataPointClick} />}      // Use the custom dot with click handling
                              isAnimationActive={false}
                              activeDot={false}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    ) : (
                      <div style={{ height: 300, textAlign: 'center' }}>
                        No {selectedChart.slice(0, 3).toUpperCase()}-{selectedChart.slice(3)} data yet. Assign {selectedChart.slice(0, 3).toUpperCase()}-{selectedChart.slice(3)} to this client to start gathering data.
                      </div>
                    )}
                  </div>
                </div>

                <AllDataChart
                  symptomData={consolidatedData}
                  gad7Data={gad7Data}
                  phq9Data={phq9Data}
                  pcl5Data={pcl5Data}
                  otherData={getAllOtherDataForChart(
                      exposureTherapy,  // pass exposure therapy data
                      tasks,
                      journals,
                      therapyJournals,
                      thoughtRecords,
                      activityLogs,
                      goals,
                      safetyPlan,
                      valueSort,
                      medications,
                      substances
                  )}
                />

                { /* SYMPTOM LOG */}
                {sharingFeatures !== null && sharingFeatures.includes('symptom_log') ? (
                  <div className="chart-wrapper">
                    <h3 style={{ marginTop: 10, marginBottom: 20 }}>Symptom Log</h3>
                    <div className="chart-container">
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '500px', marginBottom: 20 }}
                        placeholder="Select metrics to display"
                        value={visibleMetrics}
                        onChange={(value) => setVisibleMetrics(value)}
                      >
                        <Option value="mood">Mood</Option>
                        <Option value="energy">Energy</Option>
                        <Option value="stress">Stress</Option>
                        <Option value="sleep">Sleep</Option>
                        <Option value="socialInteraction">Social Interaction</Option>
                      </Select>
                      <Select
                        style={{ width: '200px', marginLeft: 20 }}
                        value={timeSelections[1]}
                        onChange={(value) => {
                          setTimeSelections((prev) => {
                            const updatedSelections = [...prev];
                            updatedSelections[1] = value;
                            return updatedSelections;
                          });
                        }}
                      >
                        <Option value="week">Last Week</Option>
                        <Option value="month">Last Month</Option>
                        <Option value="year">Last Year</Option>
                        <Option value="all">All Time</Option>
                      </Select>
                      {filterDataByTimeRange(consolidatedData, timeSelections[1]).length !== 0 ? (
                        <ResponsiveContainer width="100%" height={350}>
                          <LineChart data={filterDataByTimeRange(consolidatedData, timeSelections[1])} margin={{ top: 10, right: 10 }} >
                            <CartesianGrid stroke="#ccc" />
                            <XAxis dataKey="date" interval='preserveStartEnd' tickFormatter={(tick) => dayjs(tick).format("M/D/YY")} tick={{ fontSize: 12 }} tickMargin={10} />
                            <YAxis domain={[0, 100]} />
                            <Tooltip />
                            <Legend />
                            {/* Mood Line */}
                            {visibleMetrics.includes('mood') && (
                              <Line
                                type="monotone"
                                dataKey="mood"
                                name="Mood"
                                stroke="#FF5733"
                                strokeWidth={3}
                                dot={{ r: 6 }}
                                connectNulls={false}
                                isAnimationActive={false}
                              />
                            )}
                            {/* Energy Line */}
                            {visibleMetrics.includes('energy') && (
                              <Line
                                type="monotone"
                                dataKey="energy"
                                name="Energy"
                                stroke="#33C4FF"
                                strokeWidth={3}
                                dot={{ r: 6 }}
                                connectNulls={false}
                                isAnimationActive={false}
                              />
                            )}
                            {/* Stress Line */}
                            {visibleMetrics.includes('stress') && (
                              <Line
                                type="monotone"
                                dataKey="stress"
                                name="Stress"
                                stroke="#FFC300"
                                strokeWidth={3}
                                dot={{ r: 6 }}
                                connectNulls={false}
                                isAnimationActive={false}
                              />
                            )}
                            {/* Sleep Line */}
                            {visibleMetrics.includes('sleep') && (
                              <Line
                                type="monotone"
                                dataKey="sleep"
                                name="Sleep"
                                stroke="#33FF57"
                                strokeWidth={3}
                                dot={{ r: 6 }}
                                connectNulls={false}
                                isAnimationActive={false}
                              />
                            )}
                            {/* Social Interaction Line */}
                            {visibleMetrics.includes('socialInteraction') && (
                              <Line
                                type="monotone"
                                dataKey="socialInteraction"
                                name="Social Interaction"
                                stroke="#8D33FF"
                                strokeWidth={3}
                                dot={{ r: 6 }}
                                connectNulls={false}
                                isAnimationActive={false}
                              />
                            )}
                          </LineChart>
                        </ResponsiveContainer>
                      ) : (
                        <div style={{ height: 350, textAlign: 'center' }}>
                          No Symptom Log data yet. Assign Symptom Log to this client to start gathering data.
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="chart-wrapper">
                    <h3 style={{ marginTop: 10, marginBottom: 20 }}>Symptom Log</h3>
                    <p>Data not available. Make sure this client is sharing Symptom Log data.</p>
                  </div>
                )}
                { /* MEDICATION LOG */}
                {sharingFeatures !== null && sharingFeatures.includes('medication') ? (
                  <div className="chart-wrapper">
                    <h3 style={{ marginTop: 10, marginBottom: 20 }}>Medication Log</h3>
                    <div className="chart-container">
                      {medications.length > 0 ? (
                        <>
                          <Select
                            placeholder="Select a medication"
                            style={{ width: 300, marginBottom: 20 }}
                            onChange={(value) => setSelectedMedication(value)}
                            value={selectedMedication}
                            options={medications.map((med) => ({
                              label: med.name,
                              value: med.id,
                            }))}
                          />
                          <Select
                            style={{ width: 200, marginBottom: 20, marginLeft: 20 }}
                            value={timeSelections[2]} // Example: third time selection for medications
                            onChange={(value) => {
                              setTimeSelections((prev) => {
                                const updatedSelections = [...prev];
                                updatedSelections[2] = value; // Update for medications
                                return updatedSelections;
                              });
                            }}
                          >
                            <Option value="week">Last Week</Option>
                            <Option value="month">Last Month</Option>
                            <Option value="year">Last Year</Option>
                            <Option value="all">All Time</Option>
                          </Select>
                          {selectedMedication ? (
                            <>
                              {/* Start Date and End Date */}
                              <div style={{ marginBottom: 20, fontSize: "14px" }}>
                                <strong>Start Date:</strong>{" "}
                                {dayjs(
                                  medications.find((med) => med.id === selectedMedication)?.addDate
                                ).format("M/D/YYYY")}{" "}
                                <br />
                                <strong>End Date:</strong>{" "}
                                {medications.find((med) => med.id === selectedMedication)?.endDate ===
                                  "9999-12-31T23:59:59Z"
                                  ? "Ongoing"
                                  : dayjs(
                                    medications.find((med) => med.id === selectedMedication)?.endDate
                                  ).format("M/D/YYYY")}
                                <br />
                                <strong>Frequency:</strong>{" "}
                                {medications.find((med) => med.id === selectedMedication)?.frequency || "N/A"}
                                <br />
                                <strong>Side Effects:</strong>{" "}
                                {medications.find((med) => med.id === selectedMedication)?.sideEffects || "N/A"}
                              </div>
                              <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                  data={
                                    medications
                                      .find((med) => med.id === selectedMedication)
                                      ?.datesTaken
                                      .map((date) => dayjs(date).format("M/D/YYYY")) // Format dates
                                      .filter((date) =>
                                        filterDataByTimeRange(
                                          [{ date }], // Wrap each date into an object for filtering
                                          timeSelections[2] // Use selected time range
                                        ).length > 0
                                      ) // Filter dates based on time range
                                      .sort((a, b) => new Date(a) - new Date(b)) // Sort dates before calculating daysBetween
                                      .map((date, index, arr) => ({
                                        date,
                                        daysBetween:
                                          index > 0
                                            ? dayjs(date).diff(dayjs(arr[index - 1]), "day")
                                            : 0, // First dose has 0 days since last dose
                                      })) || []
                                  }
                                  margin={{ top: 10, right: 30, left: 10 }}
                                >
                                  <CartesianGrid stroke="#ccc" />
                                  <XAxis
                                    dataKey="date"
                                    type="category"
                                    tick={{ fontSize: 12 }}
                                    tickMargin={10}
                                    interval='preserveStartEnd'
                                    tickFormatter={(tick) => dayjs(tick).format("M/D/YY")}
                                  />
                                  <YAxis
                                    dataKey="daysBetween"
                                    domain={[0, "dataMax"]}
                                    ticks={Array.from(
                                      {
                                        length: Math.max(
                                          ...(medications
                                            .find((med) => med.id === selectedMedication)
                                            ?.datesTaken
                                            ?.map((date) => dayjs(date).format("M/D/YYYY"))
                                            .filter((date) =>
                                              filterDataByTimeRange(
                                                [{ date }], // Wrap each date into an object for filtering
                                                timeSelections[2] // Use selected time range
                                              ).length > 0
                                            )
                                            .sort((a, b) => new Date(a) - new Date(b))
                                            .map((date, index, arr) =>
                                              index > 0
                                                ? dayjs(date).diff(dayjs(arr[index - 1]), "day")
                                                : 0
                                            ) || [])
                                        ) + 1,
                                      },
                                      (_, i) => i
                                    )}
                                    tick={{ fontSize: 12 }}
                                    tickMargin={10}
                                    label={{
                                      value: "Days Between Doses",
                                      angle: -90,
                                      position: "insideLeft",
                                    }}
                                  />
                                  <Tooltip
                                    labelFormatter={(value) => `Date: ${value}`}
                                    formatter={(value) => [`${value}`, `Days since last dose`]}
                                  />
                                  <Line
                                    type="monotone"
                                    dataKey="daysBetween"
                                    stroke="#375945"
                                    dot={{ r: 6 }}
                                    strokeWidth={3}
                                    isAnimationActive={false}
                                  />
                                </LineChart>
                              </ResponsiveContainer>
                            </>
                          ) : (
                            <div style={{ textAlign: "center", marginTop: 20 }}>
                              Select a medication to view its timeline.
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ height: 350, textAlign: "center" }}>No Medication data available. Assign Medication to this client to start gathering data.</div>
                      )}
                    </div>
                    <MedicationCalendar
                      medications={medications}
                      onDateSelect={(date) => console.log('Selected Date:', date)}
                    />
                  </div>
                ) : (
                  <div className="chart-wrapper">
                    <h3 style={{ marginTop: 10, marginBottom: 20 }}>Medication Log</h3>
                    <p>Data not available. Make sure this client is sharing Medication data.</p>
                  </div>
                )}



                { /* SUBSTANCE LOG */}
                {sharingFeatures !== null && sharingFeatures.includes('substance_log') ? (
                  <div className="chart-wrapper">
                    <h3 style={{ marginTop: 10, marginBottom: 20 }}>Substance Log</h3>
                    <div className="chart-container">
                      {substances.length > 0 ? (
                        <>
                          {/* Dropdown to select substance */}
                          <Select
                            placeholder="Select a substance"
                            style={{ width: 300, marginBottom: 20 }}
                            onChange={(value) => setSelectedSubstance(value)}
                            value={selectedSubstance}
                            options={substances.map((sub) => ({
                              label: sub.name,
                              value: sub.id,
                            }))}
                          />
                          {/* Dropdown for time range selection */}
                          <Select
                            style={{ width: 200, marginBottom: 20, marginLeft: 20 }}
                            value={timeSelections[3]} // Use timeSelections[3] for Substance Log
                            onChange={(value) => {
                              setTimeSelections((prev) => {
                                const updatedSelections = [...prev];
                                updatedSelections[3] = value; // Update the selection for Substance Log
                                return updatedSelections;
                              });
                            }}
                          >
                            <Option value="week">Last Week</Option>
                            <Option value="month">Last Month</Option>
                            <Option value="year">Last Year</Option>
                            <Option value="all">All Time</Option>
                          </Select>

                          {selectedSubstance ? (
                            <>
                              {/* Bar chart */}
                              <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={filterDataByTimeRange(fullData, timeSelections[3])} margin={{ top: 10, right: 30, left: 10 }}>
                                  <CartesianGrid stroke="#ccc" />
                                  <XAxis
                                    dataKey="date"
                                    tick={{ fontSize: 12 }}
                                    tickMargin={10}
                                    interval='preserveStartEnd'
                                    tickFormatter={(tick) => dayjs(tick).format("MM/DD/YY")}
                                  />
                                  <YAxis
                                    dataKey="amount"
                                    domain={[0, "dataMax"]}
                                    tick={{ fontSize: 12 }}
                                    tickMargin={10}
                                    label={{
                                      value:
                                        "Amount (" +
                                        substances.find((sub) => sub.id === selectedSubstance)?.unit +
                                        ")" || "Amount",
                                      angle: -90,
                                      position: "insideLeft",
                                    }}
                                  />
                                  <Tooltip
                                    labelFormatter={(value) => `Date: ${value}`}
                                    formatter={(value, name, props) => {
                                      const { situation, money } = props.payload;
                                      const tooltipItems = [
                                        `Amount: ${value}`,
                                        `Money: ${money || 'N/A'}`,
                                        `Situation: ${situation || 'N/A'}`,
                                      ];
                                      return tooltipItems.join(' / ');
                                    }}
                                  />
                                  <Bar dataKey="amount" fill="#375945" barSize={40} />
                                </BarChart>
                              </ResponsiveContainer>
                            </>
                          ) : (
                            <div style={{ textAlign: "center", marginTop: 20 }}>
                              Select a substance to view its timeline.
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ height: 350, textAlign: "center" }}>No Substance Log data available. Assign Substance Log to this client to start gathering data.</div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="chart-wrapper">
                    <h3 style={{ marginTop: 10, marginBottom: 20 }}>Substance Log</h3>
                    <p>Data not available. Make sure this client is sharing Substance Log data.</p>
                  </div>
                )}
              </TabPane>
              {/* Features Content */}
              <TabPane tab={<span style={{ color: '#375945' }}>Features</span>} key="3">
                <FeaturesTab clientUsername={client?.clientUsername} name={client?.name} />
              </TabPane>
              {/* Reimbursement Content */}
              {/* 
              <TabPane tab={<span style={{ color: '#375945' }}>Reimbursement</span>} key="4">
                <ReimbursementTab clientUsername={client?.clientUsername} name={client?.name} />
              </TabPane>*/}
            </Tabs>
          </div>

        </div>
      </div>
    </>
  );
};

export default ClientDetail;