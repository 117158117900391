import React from 'react';
import logo from './logo.svg';
import './styles/Home.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './screens/Home';
import SignUp from './screens/SignUp.jsx';
import ConfirmSignUp from './screens/ConfirmSignUp.jsx';
import Dashboard from './screens/Dashboard.jsx';
import LogIn from './screens/LogIn.jsx';
import PrivacyPolicy from './screens/PrivacyPolicy.jsx';
import ForgotPassword from './screens/ForgotPassword.jsx';
import ConfirmResetPassword from './screens/ConfirmResetPassword.jsx';
import TermsOfService from './screens/TermsOfService.jsx';
import NoticeOfPrivacyPractices from './screens/NoticeOfPrivacyPractices.jsx';
import ClientDetail from './screens/ClientDetail.jsx'; // New client detail component
import AssignmentToolbox from './screens/AssignmentToolbox.jsx';
import Worksheets from './screens/Worksheets.jsx';
//import NewHome from './screens/NewHome.jsx';
import Product from './screens/Product.jsx';
import Evidence from './screens/Evidence.jsx';
import Community from './screens/Community.jsx';
import Pricing from './screens/Pricing.jsx';
import About from './screens/About.jsx';
import Payment from './screens/Payment.jsx';
import Admin from './screens/AdminDashboard.jsx';
import Billing from './screens/Billing.jsx';
import Homework from './screens/Homework.jsx';
import FAQs from './screens/FAQs.jsx';
import StartUpGuide from './screens/StartUpGuide.jsx';
import BusinessAssociateAgreement from './screens/BusinessAssociateAgreement.jsx';
//import NewLogIn from './screens/NewLogIn.jsx';
//import NewSignUp from './screens/NewSignUp.jsx';
import Amplify from '@aws-amplify/core';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/signup' element={<SignUp />}></Route>
        <Route path='/dashboard' element={<Dashboard />}></Route>
        <Route path="/client/:clientId" element={<ClientDetail />} /> {/* Dynamic route for client details */}
        <Route path='/assignment-builder' element={<Worksheets />}></Route>
        <Route path='/assignment-toolbox' element={<AssignmentToolbox />}></Route>
        <Route path='/login' element={<LogIn />}></Route>
        <Route path='/confirm-sign-up' element={<ConfirmSignUp />}></Route>
        <Route path='/forgotpassword' element={<ForgotPassword />}></Route>
        <Route path='/confirmresetpassword' element={<ConfirmResetPassword />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/notice-of-privacy-practices" element={<NoticeOfPrivacyPractices />}></Route>
        {/*<Route path='/newhome' element={<NewHome />}></Route>*/}
        <Route path='/product' element={<Product />}></Route>
        <Route path='/evidence' element={<Evidence />}></Route>
        <Route path='/homework' element={<Homework />}></Route>
        <Route path='/faqs' element={<FAQs />}></Route>
        <Route path='/startupguide' element={<StartUpGuide />}></Route>
        <Route path='/community' element={<Community />}></Route>
        <Route path='/pricing' element={<Pricing />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/admin' element={<Admin />}></Route>
        <Route path='/billing' element={<Billing />}></Route>
        {/*<Route path='/newlogin' element={<NewLogIn />}></Route>*/}
        {/*<Route path='/newsignup' element={<NewSignUp />}></Route>*/}
        <Route path='/payment/:planId' element={<Payment />}></Route>
        <Route path='/baa' element={<BusinessAssociateAgreement />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;