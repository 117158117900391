import React, { useState, useMemo, useEffect } from 'react';
import { Card, Collapse, List, Tooltip, Typography, Row, Col, Dropdown, Button, Checkbox, Menu, DatePicker } from 'antd';
import {
  UnlockOutlined,
  LockOutlined,
  LineChartOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CalendarOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import dayjs from "dayjs";

const { Title } = Typography;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const valueSortValues = [
  { id: '1', title: 'Acceptance' },
  { id: '2', title: 'Accuracy' },
  { id: '3', title: 'Achievement' },
  { id: '4', title: 'Adventure' },
  { id: '5', title: 'Attractiveness' },
  { id: '6', title: 'Balance' },
  { id: '7', title: 'Beauty' },
  { id: '8', title: 'Caring' },
  { id: '9', title: 'Challenge' },
  { id: '10', title: 'Comfort' },
  { id: '11', title: 'Commitment' },
  { id: '12', title: 'Compassion' },
  { id: '13', title: 'Contribution' },
  { id: '14', title: 'Cooperation' },
  { id: '15', title: 'Courtesy' },
  { id: '16', title: 'Creativity' },
  { id: '17', title: 'Dependability' },
  { id: '18', title: 'Ecology' },
  { id: '19', title: 'Faithfulness' },
  { id: '20', title: 'Fame' },
  { id: '21', title: 'Family' },
  { id: '22', title: 'Fitness' },
  { id: '23', title: 'Flexibility' },
  { id: '24', title: 'Forgiveness' },
  { id: '25', title: 'Friendship' },
  { id: '26', title: 'Fun' },
  { id: '27', title: 'Generosity' },
  { id: '28', title: 'Genuineness' },
  { id: '29', title: 'Growth' },
  { id: '30', title: 'Health' },
  { id: '31', title: 'Honesty' },
  { id: '32', title: 'Hope' },
  { id: '33', title: 'Humility' },
  { id: '34', title: 'Humor' },
  { id: '35', title: 'Independence' },
  { id: '36', title: 'Inner Peace' },
  { id: '37', title: 'Intimacy' },
  { id: '38', title: 'Justice' },
  { id: '39', title: 'Knowledge' },
  { id: '40', title: 'Leisure' },
  { id: '41', title: 'Loved' },
  { id: '42', title: 'Loving' },
  { id: '43', title: 'Mastery' },
  { id: '44', title: 'Mindfulness' },
  { id: '45', title: 'Nurturance' },
  { id: '46', title: 'Openness' },
  { id: '47', title: 'Order' },
  { id: '48', title: 'Passion' },
  { id: '49', title: 'Pleasure' },
  { id: '50', title: 'Popularity' },
  { id: '51', title: 'Power' },
  { id: '52', title: 'Purpose' },
  { id: '53', title: 'Rationality' },
  { id: '54', title: 'Realism' },
  { id: '55', title: 'Responsibility' },
  { id: '56', title: 'Risk' },
  { id: '57', title: 'Romance' },
  { id: '58', title: 'Safety' },
  { id: '59', title: 'Self-Control' },
  { id: '60', title: 'Service' },
  { id: '61', title: 'Sexuality' },
  { id: '62', title: 'Simplicity' },
  { id: '63', title: 'Solitude' },
  { id: '64', title: 'Spirituality' },
  { id: '65', title: 'Stability' },
  { id: '66', title: 'Tolerance' },
  { id: '67', title: 'Tradition' },
  { id: '68', title: 'Virtue' },
  { id: '69', title: 'Wealth' },
  { id: '70', title: 'World Peace' }
];

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const ActivityTab = ({ clientUsername, name }) => {
  const [tasksCompleted, setTasksCompleted] = useState(0);
  const [outstandingTasks, setOutstandingTasks] = useState(0);
  const [daysUsed, setDaysUsed] = useState(0);
  const completionRate = useMemo(() => {
    if (tasksCompleted + outstandingTasks === 0) return '0%'; // Avoid division by zero
    return `${Math.round((tasksCompleted / (outstandingTasks + tasksCompleted)) * 100)}%`;
  }, [tasksCompleted, outstandingTasks]);

  const [tasks, setTasks] = useState([]);
  const [stats, setStats] = useState([]);
  const [sharing, setSharing] = useState([]);

  const [thoughtRecords, setThoughtRecords] = useState(null);
  const [journals, setJournals] = useState(null);
  const [therapyJournals, setTherapyJournals] = useState(null);
  const [activityLogs, setActivityLogs] = useState(null);
  const [exposureTherapy, setExposureTherapy] = useState(null);
  const [goals, setGoals] = useState(null);
  const [safetyPlan, setSafetyPlan] = useState(null);
  const [valueSort, setValueSort] = useState(null);

  useEffect(() => {
    fetchTasks();
    fetchStats();
    fetchSharing();
    fetchOthers();
  }, []);

  useEffect(() => {
    if (!tasks || !Array.isArray(tasks)) return; // Ensure tasks is an array

    const completedCount = tasks.filter(task => task.status === 'Complete').length;
    const incompleteCount = tasks.filter(task => task.status === 'Incomplete').length;

    setTasksCompleted(completedCount);
    setOutstandingTasks(incompleteCount);
  }, [tasks]);

  useEffect(() => {
    if (stats && stats.length > 0) {
      setDaysUsed(stats[0].daysUsed); // Assuming stats is an array and you need the first item
    }
  }, [stats]);

  const [formattedResponses, setFormattedResponses] = useState([]);

  useEffect(() => {
    if (
      tasks.length > 0 &&
      sharing.length > 0 &&
      [thoughtRecords, journals, therapyJournals, activityLogs, exposureTherapy, goals, safetyPlan, valueSort].every(arr => arr !== null)
    ) {
      const transformedTasks = transformTasks(tasks, sharing);
      const transformedOthers = transformOtherResponses(
        [...thoughtRecords, ...journals, ...therapyJournals, ...activityLogs, ...exposureTherapy, ...goals, ...safetyPlan, ...valueSort],
        sharing
      );

      const sortedResponses = [...transformedTasks, ...transformedOthers].filter((t) => t).sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      const uniqueResponses = [];
      const taskMap = new Map();
      for (const response of sortedResponses) {
        const uniqueKey = `${response.id}-${response.type}`;
        if (!taskMap.has(uniqueKey)) {
          taskMap.set(uniqueKey, response);
          uniqueResponses.push(response);
        }
      }

      console.log("unique responses ", uniqueResponses);

      setFormattedResponses(uniqueResponses);
    }
  }, [tasks, sharing, thoughtRecords, journals, therapyJournals, activityLogs, exposureTherapy, goals, safetyPlan, valueSort]);

  // Transform data

  const otherAssignmentMappings = {
    'Therapy Journal Entry (Before)': 'therapy_journal',
    'Therapy Journal Entry (After)': 'therapy_journal',
    'Journal Entry': 'journaling',
    'Breating Exercise': 'breathing_room',
    'Goals': 'goals',
    'Value Sort': 'value_sort',
    'Safety Plan': 'safety_plan',
    'Activity Log': 'activity_log',
    'Thought Record': 'thought_record',
    'Exposure Therapy': 'exposure_therapy',
  }

  const transformTasks = (tasks) => {
    //console.log('sharing', sharing[0].features)
    return tasks.filter((t) => t.status !== 'Complete' || t.file || t.worksheet).map((task) => {
      //console.log(task.type);
      return {
        id: task.id,
        type: determineTaskType(task), // Determine the correct type dynamically
        date: task.date.split("T")[0], // Extract only the date part
        responses: formatResponses(task),
        progress: task.status === "Complete" ? "Completed" : "Incomplete",
        shared: task.shared ||
          (sharing[0].features !== null && sharing[0].features.includes(otherAssignmentMappings[task.type])) ||
          (((task.file || task.worksheet) && (sharing[0].features !== null && sharing[0].features.includes('worksheets')))),
      };
    });
  };

  const determineTaskType = (task) => {
    // Handle "PDF" type tasks
    if (task.file) return `PDF: ${task.name}`;

    // Handle "Interactive Worksheet" tasks
    if (task.worksheet) return `Interactive Worksheet: ${task.name}`;

    return task.type; // Default to original task type
  };

  const formatResponses = (task) => {
    if (task.response_json && task.worksheet) {
      return Object.entries(task.response_json).filter(([key, _]) => key !== "date").map(([key, value]) => {
        // Find the corresponding worksheet entry based on the key
        const matchedWorksheet = task.worksheet.find(item => item.id.toString() === key);

        return {
          field: matchedWorksheet ? matchedWorksheet.value : key, // Use worksheet value if found, otherwise fallback to key
          value: value.toString() + (matchedWorksheet.type.endsWith('5') ? '/5' : matchedWorksheet.type.endsWith('10') ? '/10' : matchedWorksheet.type.endsWith('100') ? '/100' : ''),
        };
      });
    }

    if (task.response) {
      return [{ field: "Response", value: task.response }];
    }

    return []; // Default empty array if no responses exist
  };

  const hasKey = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

  const transformOtherResponses = (responses, sharing) => {

    if (!Array.isArray(responses)) {
      console.error("transformOtherResponses received an invalid response list:", responses);
      return [];
    }

    const sharingFeatures = sharing.length > 0 ? sharing[0].features : [];

    return responses.filter(response => response !== null && response !== undefined).map((response) => {
      let responseType = null;
      let shared = false;

      if (hasKey(response, "entry")) {
        // Journaling
        responseType = "Journal Entry";
        shared = response.shared || (sharingFeatures !== null && sharingFeatures.includes("journaling")); // Journaling has its own shared field
      } else if (hasKey(response, "expectedMood")) {
        // Activity Logs
        responseType = "Activity Log";
        shared = (sharingFeatures !== null && sharingFeatures.includes("activity_log"));
      } else if (hasKey(response, "positivesAndNegatives")) {
        // Therapy Journals
        responseType = "Therapy Journal";
        shared = (sharingFeatures !== null && sharingFeatures.includes("therapy_journal"));
      } else if (hasKey(response, "description")) {
        // Goals
        responseType = "Goal";
        shared = (sharingFeatures !== null && sharingFeatures.includes("goals"));
      } else if (hasKey(response, "automatic_thoughts")) {
        // Thought Records
        responseType = "Thought Record";
        shared = (sharingFeatures !== null && sharingFeatures.includes("thought_record"));
      } else if (hasKey(response, "worstFear")) {
        // Exposure Therapy
        responseType = "Exposure Therapy";
        shared = (sharingFeatures !== null && sharingFeatures.includes("exposure_therapy"));
      } else if (hasKey(response, "distractionPeople")) {
        // Safety Plan
        responseType = "Safety Plan";
        shared = true;
      } else if (hasKey(response, "veryImportant")) {
        // Value Sort
        responseType = "Value Sort";
        shared = (sharingFeatures !== null && sharingFeatures.includes("value_sort"));
      }

      if (!responseType) return null;

      return {
        id: response.id,
        type: responseType || "Unknown",
        date: response.date ? response.date.split("T")[0]
          : response.day ? response.day.split("T")[0]
            : null, // Extract date only
        responses: formatOtherResponses(response, responseType), // Format responses dynamically
        progress: "Completed", // Always completed
        shared, // Apply the correct shared logic
      };
    });
  };

  const formatOtherResponses = (response, responseType) => {
    if (!response) return [];

    const formatList = (list) => {
      if (!Array.isArray(list) || list.length === 0) return "N/A";

      return list
        .filter(item => item.trim() !== "") // Removes empty or whitespace-only values
        .join(', ') || "N/A"; // Ensures "N/A" if all items were empty
    };

    const formatValueSortList = (list) => {
      if (!Array.isArray(list) || list.length === 0) return "N/A";

      return list
        .map(id => {
          const match = valueSortValues.find(value => value.id === id);
          return match ? match.title : `Unknown (ID: ${id})`; // Handles invalid IDs
        })
        .join(', ');
    };

    if (responseType === "Journal Entry") {
      return [
        { field: "Title", value: response.title || "No Title" },
        { field: "Entry", value: response.entry || "No Entry" },
        { field: "How are you feeling?", value: response.feeling || "No Feeling" },
        { field: "Situation", value: response.situation || "No Situation" },
        { field: "What is your mood?", value: response.mood?.toString() + '/5' || "N/A" },
      ];
    } else if (responseType === "Therapy Journal") {
      return [
        { field: "Mood before", value: response.beforeMood?.toString() + '/10' || "N/A" },
        { field: "Mood after", value: response.afterMood?.toString() + '/10' || "N/A" },
        { field: "What problems do you want your therapist's help in solving?", value: response.problems || "N/A" },
        { field: "How have you been feeling this week compared to others?", value: response.emotions || "N/A" },
        { field: "What happened this week that your therapist should know?", value: response.positivesAndNegatives || "N/A" },
        { field: "Do you have any assigned tasks or goals from last session? If so, what did you learn? Anything you want to continue doing? Any problems getting it done?", value: response.tasks || "N/A" },
        { field: "How did therapy go?", value: response.therapyReflection || "N/A" },
        { field: "Is there anything you would have liked to talk about but didn't?", value: response.shouldHaveTalkedAbout || "N/A" },
      ];
    } else if (responseType === "Goal") {
      return [
        { field: "Goal description", value: response.description || "No Description" },
        { field: "Number of times", value: response.number_times?.toString() || "N/A" },
        { field: "Done by", value: response.done_by || "No Deadline" },
        { field: "Reflection", value: response.reflection || "Not completed yet" },
        { field: "Completed", value: response.is_completed ? "Yes" : "No" },
      ];
    } else if (responseType === "Thought Record") {
      return [
        { field: "Day", value: response.day || "N/A" },
        { field: "Time", value: response.time || "N/A" },
        { field: "Describe the situation", value: response.situation || "N/A" },
        { field: "What thoughts came to mind?", value: response.automatic_thoughts || "N/A" },
        { field: "How did you feel? What did you notice in your body? Where did you feel it?", value: response.feelings || "N/A" },
        { field: "Feelings intensity", value: response.feelings_intensity?.toString() + '/100' || "N/A" },
        { field: "What facts support this thought?", value: response.evidence_for || "N/A" },
        { field: "What facts do you have that the thoughts are NOT totally true? What advice would you give someone else? Is this really as important as it seems in the big picture?", value: response.evidence_against || "N/A" },
        { field: "How do you feel now? What's changed?", value: response.outcome || "N/A" },
        { field: "New feelings intensity", value: response.feelings_intensity_new?.toString() + '/100' || "N/A" },
      ];
    } else if (responseType === "Activity Log") {
      return [
        { field: "Day", value: response.day || "N/A" },
        { field: "Time", value: response.time || "N/A" },
        { field: "Planned activity", value: response.title || "N/A" },
        { field: "Expected mood", value: response.expectedMood?.toString() + '/10' || "N/A" },
        { field: "Mood before", value: response.beforeMood?.toString() + '/10' || "N/A" },
        { field: "Mood right after", value: response.afterMood?.toString() + '/10' || "N/A" },
        { field: "Notes", value: response.notes || "N/A" },
      ];
    } else if (responseType === "Exposure Therapy") {
      return [
        { field: "Day", value: response.day || "N/A" },
        { field: "Time", value: response.time || "N/A" },
        { field: "Situation to practice", value: response.situation || "N/A" },
        { field: "Safety behaviors to target", value: response.safetyBehaviors || "N/A" },
        { field: "What is your worst fear?", value: response.worstFear || "N/A" },
        { field: "Beginning SUDS", value: response.beginningSuds?.toString() + '/10' || "N/A" },
        { field: "Mid SUDS", value: response.midSuds?.toString() + '/10' || "N/A" },
        { field: "End SUDS", value: response.endSuds?.toString() + '/10' || "N/A" },
        { field: "Describe your feelings", value: response.feelings || "N/A" },
        { field: "Did you reach your goal?", value: response.goalReached ? "Yes" : "No" },
        { field: "What did you learn?", value: response.learnings || "N/A" },
        { field: "Next steps for future exposure", value: response.nextSteps || "N/A" },
      ];
    } else if (responseType === "Safety Plan") {
      return [
        { field: "Warning signs", value: response.warningSigns || "N/A" },
        { field: "Coping strategies", value: response.copingStrategies || "N/A" },
        { field: "People and places that provide distraction", value: formatList(response.distractionPeople) },
        { field: "People I can ask for help", value: formatList(response.askForHelp) || "N/A" },
        { field: "Professionals and resources to contact", value: formatList(response.professionalContacts) || "N/A" },
        { field: "Steps to make the environment safe", value: response.environmentSafety || "N/A" },
      ];
    } else if (responseType === "Value Sort") {
      return [
        { field: "Very Important", value: formatValueSortList(response.veryImportant) },
        { field: "A Little Important", value: formatValueSortList(response.aLittleImportant) },
        { field: "Not So Important", value: formatValueSortList(response.notSoImportant) },
        { field: "Not At All Important", value: formatValueSortList(response.notAtAllImportant) },
      ];
    }

    return []; // Default empty array if no responses exist
  };

  // Filter states
  const [filters, setFilters] = useState({
    progress: [],
    shared: [],
    dateRange: [dayjs().subtract(1, 'month'), dayjs()],
    types: [],
  });

  // Filter logic
  const allowedTypes = [
    "Thought Record",
    "Journal Entry",
    "Therapy Journal Entry (Before)",
    "Therapy Journal Entry (After)",
    "Therapy Journal",
    "Activity Log",
    "Exposure Therapy",
    "Goal",
    "Value Sort",
    "Safety Plan",
  ];

  const filteredResponses = formattedResponses.filter((worksheet) => {
    const isPDF = worksheet.type.startsWith("PDF:");
    const isInteractiveWorksheet = worksheet.type.startsWith("Interactive Worksheet:");
    const isAllowedType = allowedTypes.includes(worksheet.type);

    const matchesAllowedType = isPDF || isInteractiveWorksheet || isAllowedType;
    const matchesProgress =
      filters.progress.length === 0 || filters.progress.includes(worksheet.progress);
    const matchesShared =
      filters.shared.length === 0 || filters.shared.includes(worksheet.shared ? 'Shared' : 'Not Shared');
    const matchesDate =
      !filters.dateRange ||
      (new Date(worksheet.date) >= filters.dateRange[0] &&
        new Date(worksheet.date) <= filters.dateRange[1]);
    const matchesType =
      Array.isArray(filters.types) && filters.types.length === 0 || filters.types.includes(worksheet.type);


    return matchesAllowedType && matchesProgress && matchesShared && matchesDate && matchesType;
  });

  // Fetch data

  const fetchTasks = async () => {
    const tasksUrl = new URL(`${api}/Tasks/`);
    tasksUrl.searchParams.set('clientUsername', clientUsername);

    const tasksResponse = await fetch(tasksUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!tasksResponse.ok) {
      throw new Error(`Failed to fetch Tasks data: ${tasksResponse.statusText}`);
    }

    const tasksRawData = await tasksResponse.json();

    setTasks(tasksRawData);
  }

  const fetchStats = async () => {
    const statsUrl = new URL(`${api}/Stats/`);
    statsUrl.searchParams.set('username', clientUsername);

    const statsResponse = await fetch(statsUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!statsResponse.ok) {
      throw new Error(`Failed to fetch Stats data: ${statsResponse.statusText}`);
    }

    const statsRawData = await statsResponse.json();

    setStats(statsRawData);
  }

  const fetchSharing = async () => {
    const sharingUrl = new URL(`${api}/Sharing/`);
    sharingUrl.searchParams.set('username', clientUsername);

    const sharingResponse = await fetch(sharingUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!sharingResponse.ok) {
      throw new Error(`Failed to fetch Sharing data: ${sharingResponse.statusText}`);
    }

    const sharingRawData = await sharingResponse.json();

    //console.log('sharingrawdata', sharingRawData);

    setSharing(sharingRawData);
  }

  const fetchOthers = async () => {
    // Thought Records
    const thoughtRecordUrl = new URL(`${api}/ThoughtRecords/`);
    thoughtRecordUrl.searchParams.set('username', clientUsername);

    const thoughtRecordResponse = await fetch(thoughtRecordUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!thoughtRecordResponse.ok) {
      throw new Error(`Failed to fetch Thought Record data: ${thoughtRecordResponse.statusText}`);
    }

    const thoughtRecordRawData = await thoughtRecordResponse.json();

    setThoughtRecords(thoughtRecordRawData);

    // Journal Entries
    const journalingUrl = new URL(`${api}/Journaling/`);
    journalingUrl.searchParams.set('username', clientUsername);

    const journalingResponse = await fetch(journalingUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!journalingResponse.ok) {
      throw new Error(`Failed to fetch Journaling data: ${journalingResponse.statusText}`);
    }

    const journalingRawData = await journalingResponse.json();

    setJournals(journalingRawData);

    // Therapy Journal Entries
    const therapyJournalingUrl = new URL(`${api}/TherapyJournaling/`);
    therapyJournalingUrl.searchParams.set('username', clientUsername);

    const therapyJournalingResponse = await fetch(therapyJournalingUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!therapyJournalingResponse.ok) {
      throw new Error(`Failed to fetch Therapy Journaling data: ${therapyJournalingResponse.statusText}`);
    }

    const therapyJournalingRawData = await therapyJournalingResponse.json();

    setTherapyJournals(therapyJournalingRawData);

    // Activity Log
    const activityLogsUrl = new URL(`${api}/ActivityLogs/`);
    activityLogsUrl.searchParams.set('username', clientUsername);

    const activityLogsResponse = await fetch(activityLogsUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!activityLogsResponse.ok) {
      throw new Error(`Failed to fetch Activity Log data: ${activityLogsResponse.statusText}`);
    }

    const activityLogsRawData = await activityLogsResponse.json();

    setActivityLogs(activityLogsRawData);

    // Exposure Therapy
    const exposureTherapyUrl = new URL(`${api}/ExposureTherapy/`);
    exposureTherapyUrl.searchParams.set('username', clientUsername);

    const exposureTherapyResponse = await fetch(exposureTherapyUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!exposureTherapyResponse.ok) {
      throw new Error(`Failed to fetch Exposure Therapy data: ${exposureTherapyResponse.statusText}`);
    }

    const exposureTherapyRawData = await exposureTherapyResponse.json();

    setExposureTherapy(exposureTherapyRawData);

    // Goal Setting
    const goalsUrl = new URL(`${api}/Goals/`);
    goalsUrl.searchParams.set('username', clientUsername);

    const goalsResponse = await fetch(goalsUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!goalsResponse.ok) {
      throw new Error(`Failed to fetch Goals data: ${goalsResponse.statusText}`);
    }

    const goalsRawData = await goalsResponse.json();

    setGoals(goalsRawData);

    // Safety Plan
    const safetyPlanUrl = new URL(`${api}/SafetyPlan/`);
    safetyPlanUrl.searchParams.set('username', clientUsername);

    const safetyPlanResponse = await fetch(safetyPlanUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!safetyPlanResponse.ok) {
      throw new Error(`Failed to fetch Safety Plan data: ${safetyPlanResponse.statusText}`);
    }

    const safetyPlanRawData = await safetyPlanResponse.json();

    setSafetyPlan(safetyPlanRawData);

    // Value Sort
    const valueSortUrl = new URL(`${api}/ValueSort/`);
    valueSortUrl.searchParams.set('username', clientUsername);

    const valueSortResponse = await fetch(valueSortUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!valueSortResponse.ok) {
      throw new Error(`Failed to fetch Value Sort data: ${valueSortResponse.statusText}`);
    }

    const valueSortRawData = await valueSortResponse.json();

    setValueSort(valueSortRawData);
  }

  // Handle filter changes
  const handleMenuClick = (checkedValues, filterType) => {
    setFilters((prev) => ({ ...prev, [filterType]: checkedValues }));
  };

  const handleDateChange = (dates) => {
    setFilters((prev) => ({ ...prev, dateRange: dates }));
  };

  // Dropdown Menu
  const filterMenu = (
    <Dropdown
      trigger={['click']}
      overlay={
        <div
          style={{
            background: 'white',
            padding: '12px',
            borderRadius: '8px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            minWidth: '250px', // Ensures good spacing
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ marginBottom: '12px', fontWeight: 'bold' }}>Progress</div>
          <Checkbox.Group
            options={['Complete', 'Incomplete']}
            onChange={(checkedValues) => handleMenuClick(checkedValues, 'progress')}
          />

          <hr style={{ margin: '12px 0', border: 'none', borderTop: '1px solid #ddd' }} />

          <div style={{ marginBottom: '12px', fontWeight: 'bold' }}>Shared Status</div>
          <Checkbox.Group
            options={['Shared', 'Not Shared']}
            onChange={(checkedValues) => handleMenuClick(checkedValues, 'shared')}
          />

          <hr style={{ margin: '12px 0', border: 'none', borderTop: '1px solid #ddd' }} />

          <div style={{ marginBottom: '12px', fontWeight: 'bold' }}>Worksheet Type</div>
          <Checkbox.Group
            options={Array.from(new Set(filteredResponses.map((w) => w.type)))}
            onChange={(checkedValues) => handleMenuClick(checkedValues, 'types')}
          />

          <hr style={{ margin: '12px 0', border: 'none', borderTop: '1px solid #ddd' }} />

          <div style={{ marginBottom: '12px', fontWeight: 'bold' }}>Date Range</div>
          <RangePicker
            value={filters.dateRange}
            onChange={handleDateChange}
            onClick={(e) => e.stopPropagation()} // Prevents dropdown from closing on click
            style={{ width: '100%' }} // Ensures proper width
          />
        </div>
      }
    >
      <Button icon={<FilterOutlined />} type="primary" style={{ backgroundColor: '#59846A', borderRadius: '35px', color: '#FFEBA3' }}>
        Filters
      </Button>
    </Dropdown>
  );

  return (
    <div>
      {/* Usage Metrics */}
      <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
        <Col span={6}>
          <Card title="Tasks Completed" bordered>
            <CheckCircleOutlined style={{ fontSize: '24px', color: '#52c41a' }} />
            <Title level={2}>{tasksCompleted}</Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Outstanding Tasks" bordered>
            <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#fa541c' }} />
            <Title level={2}>{outstandingTasks}</Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Completion Rate" bordered>
            <LineChartOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
            <Title level={2}>{completionRate}</Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Days Used" bordered>
            <CalendarOutlined style={{ fontSize: '24px', color: '#faad14' }} />
            <Title level={2}>{daysUsed}</Title>
          </Card>
        </Col>
      </Row>

      <div style={{ marginBottom: '10px', marginTop: '30px', display: 'flex', justifyContent: 'flex-start' }}>
        <h3 className='activity-title'>Response Tracking</h3>
      </div>
      {/* Filter Button */}
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-start' }}>
        {filterMenu}
      </div>
      <Collapse accordion>
        {filteredResponses.filter((r) => (r.type !== 'Safety Plan' && r.type !== 'Value Sort') || r.progress !== 'Completed').map((item) => (
          <Panel
            header={
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {/* Completion Icon with Tooltip */}
                <Tooltip title={item.progress === 'Completed' ? 'Completed' : 'Incomplete Assignment'}>
                  {item.progress === 'Completed' ? (
                    <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '18px', marginLeft: item.shared && item.progress === 'Completed' ? '0px' : '23.5px' }} />
                  ) : (
                    <ExclamationCircleOutlined style={{ color: '#faad14', fontSize: '18px', marginLeft: item.shared && item.progress === 'Completed' ? '0px' : '23.5px' }} />
                  )}
                </Tooltip>

                {/* Lock or Unlock Icon with Tooltip */}
                <Tooltip title={item.shared ? 'Response Shared' : 'Response Not Shared'}>
                  {item.shared ? (
                    <UnlockOutlined style={{ color: '#1890ff', fontSize: '18px' }} />
                  ) : (
                    <LockOutlined style={{ color: '#fa541c', fontSize: '18px' }} />
                  )}
                </Tooltip>

                {/* Worksheet Title */}
                <span>
                  {item.type} ({item.date})
                </span>
              </div>
            }
            key={uuidv4()}
            showArrow={item.shared && item.progress === 'Completed'}
            collapsible={item.shared ? "header" : ""}
          >
            {item.shared ? (
              <List
                size="small"
                dataSource={[
                  // Filter out responses that are empty for the main list
                  ...item.responses.filter(response => response.value !== "N/A" && !response.value.startsWith("undefined/")),

                  // Add the special "Empty Fields" entry if needed
                  {
                    isEmptyFields: true,
                    emptyFields: item.responses
                      .filter(response => response.value === "N/A" && !response.value.startsWith("undefined/"))
                      .map(response => response.field || response.question || response.step)
                      .filter(Boolean), // Ensure no undefined values
                  },
                ]}
                renderItem={(response) => (
                  response.isEmptyFields ? (
                    response.emptyFields.length > 0 && (
                      <List.Item>
                        <div style={{ color: 'grey', fontStyle: 'italic' }}>
                          <strong>Empty Fields:</strong> {response.emptyFields.join(', ')}
                        </div>
                      </List.Item>
                    )
                  ) : (
                    <List.Item>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {response.question && (
                          <div>
                            <strong>{response.question}</strong>
                          </div>
                        )}
                        {response.field && (
                          <div>
                            <strong>{response.field}</strong>
                          </div>
                        )}
                        {response.step && (
                          <div>
                            <strong>{response.step}</strong>
                          </div>
                        )}
                        <div>{response.value}</div>
                      </div>
                    </List.Item>
                  )
                )}
              />
            ) : (
              <p>To view this response{item.progress === 'Completed' ? '' : ' when it is completed'}, ask this client to share data for {item.type.startsWith('Therapy Journal') ? 'Therapy Journal' : item.type.startsWith('PDF') || item.type.startsWith('Interactive') ? 'Worksheets, or to share this specific entry' : item.type.startsWith('Journal Entry') ? 'Journaling, or to share this specific entry' : item.type}.</p>
            )}
          </Panel>
        ))}
      </Collapse>
      <div style={{ marginBottom: '10px', marginTop: '30px', display: 'flex', justifyContent: 'flex-start' }}>
        <h3 className='activity-title'>Ongoing Responses</h3>
      </div>
      <Collapse accordion style={{ marginTop: '10px' }}>
        {formattedResponses.filter((r) => (r.type === 'Safety Plan' || r.type === 'Value Sort') && r.progress === 'Completed').map((item) => (
          <Panel
            header={
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {/* Lock or Unlock Icon with Tooltip */}
                <Tooltip title={item.shared ? 'Response Shared' : 'Response Not Shared'}>
                  {item.shared ? (
                    <UnlockOutlined style={{ color: '#1890ff', fontSize: '18px', marginLeft: item.shared && item.progress === 'Completed' ? '0px' : '23.5px' }} />
                  ) : (
                    <LockOutlined style={{ color: '#fa541c', fontSize: '18px', marginLeft: item.shared && item.progress === 'Completed' ? '0px' : '23.5px' }} />
                  )}
                </Tooltip>

                {/* Worksheet Title */}
                <span>
                  {item.type}
                </span>
              </div>
            }
            key={uuidv4()}
            showArrow={item.shared && item.progress === 'Completed'}
            collapsible={item.shared ? "header" : ""}
          >
            {item.shared ? (
              <List
                size="small"
                dataSource={[
                  // Filter out responses that are empty for the main list
                  ...item.responses.filter(response => response.value !== "N/A" && !response.value.startsWith("undefined/")),

                  // Add the special "Empty Fields" entry if needed
                  {
                    isEmptyFields: true,
                    emptyFields: item.responses
                      .filter(response => response.value === "N/A" && !response.value.startsWith("undefined/"))
                      .map(response => response.field || response.question || response.step)
                      .filter(Boolean), // Ensure no undefined values
                  },
                ]}
                renderItem={(response) => (
                  response.isEmptyFields ? (
                    response.emptyFields.length > 0 && (
                      <List.Item>
                        <div style={{ color: 'grey', fontStyle: 'italic' }}>
                          <strong>Empty Fields:</strong> {response.emptyFields.join(', ')}
                        </div>
                      </List.Item>
                    )
                  ) : (
                    <List.Item>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {response.question && (
                          <div>
                            <strong>{response.question}</strong>
                          </div>
                        )}
                        {response.field && (
                          <div>
                            <strong>{response.field}</strong>
                          </div>
                        )}
                        {response.step && (
                          <div>
                            <strong>{response.step}</strong>
                          </div>
                        )}
                        <div>{response.value}</div>
                      </div>
                    </List.Item>
                  )
                )}
              />
            ) : (
              <p>To view this response{item.progress === 'Completed' ? '' : ' when it is completed'}, ask this client to share data for {item.type.startsWith('Therapy Journal') ? 'Therapy Journal' : item.type.startsWith('PDF') || item.type.startsWith('Interactive') ? 'Worksheets, or to share this specific entry' : item.type.startsWith('Journal Entry') ? 'Journaling, or to share this specific entry' : item.type}.</p>
            )}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ActivityTab;

