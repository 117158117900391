import React from 'react';
import { Layout, Menu } from 'antd';
import '../styles/Sidebar.css'; // Import the sidebar styling
import { logosWhite } from '../config/logos';

const { Sider } = Layout;

const currentEnv = "production";

const Sidebar = ({ items, children }) => {
  const currentYear = new Date().getFullYear();
  return (
    <Sider
      className="sidebar"
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        //console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        //console.log(collapsed, type);
      }}
    >
      <a href="/" className="logo" style={{ position: 'absolute', top: '7px', left: '35px' }}>
        <img src={logosWhite[currentEnv]} alt="Yosa Logo" />
      </a>
      <div className="sidebar-container" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[]}
          style={{ marginTop: '70px', flex: '1' }}
          items={items}
        />
        <div className="sidebar-children" style={{ flex: '1', overflowY: 'auto' }}>
          {children}
        </div>
        <div style={{ color: '#1C3727', fontSize: 'small', marginLeft: '29px', marginBottom: '10px', alignSelf: 'flex-end' }}>
          <p>&copy; {currentYear} Yosa Inc.</p>
          <p>
            Questions?<br />
            <a style={{ color: '#1C3727' }} href="/StartUpGuide" target="_blank">
              Start up guide
            </a><br />
            <a style={{ color: '#1C3727' }} href="/FAQs" target="_blank">
              FAQs
            </a><br />
            Contact: <a style={{ color: '#1C3727' }} href="mailto:support@yosaapp.com">support@yosaapp.com</a>
          </p>
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
