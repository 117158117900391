import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal, Card, Button, Layout, Collapse, Tabs } from "antd";
import { RollbackOutlined } from '@ant-design/icons';
import Sidebar from '../components/Sidebar';
import '../styles/AssignmentToolbox.css';
// Feature icons
import worksheets from '../assets/therapy.png';
import therapyjournal from '../assets/talk-therapy.png';
import journaling from '../assets/journaling.png';
import breathing from '../assets/meditation.png';
import pmr from '../assets/PMR.png';
import mood from '../assets/mood.png';
import medication from '../assets/medication.png';
import substance from '../assets/substance.png';
import thoughtrecord from '../assets/thoughtrecord.png';
import goals from '../assets/goals.png';
import activitylog from '../assets/activitylog.png';
import exposuretherapy from '../assets/exposuretherapy.png';
import valuesort from '../assets/valuesort.png';
import mealdonations from '../assets/meal donations.png';
import measure from '../assets/therapy.png';
//Preview images
import tjbeforepreview from '../assets/assignmenttoolbox/tjbefore.png';
import tjafterpreview from '../assets/assignmenttoolbox/tjafter.png';
import journalpreview1 from '../assets/assignmenttoolbox/j1.png';
import journalpreview2 from '../assets/assignmenttoolbox/j2.png';
import meditation1 from '../assets/assignmenttoolbox/meditation1.png';
import brpreview1 from '../assets/assignmenttoolbox/br1.png';
import brpreview2 from '../assets/assignmenttoolbox/br2.png';
import brpreview3 from '../assets/assignmenttoolbox/br3.png';
import brpreview4 from '../assets/assignmenttoolbox/br4.png';
import brpreview5 from '../assets/assignmenttoolbox/br5.png';
import trpreview1 from '../assets/assignmenttoolbox/tr1.png';
import trpreview2 from '../assets/assignmenttoolbox/tr2.png';
import alpreview1 from '../assets/assignmenttoolbox/al1.png';
import alpreview2 from '../assets/assignmenttoolbox/al2.png';
import etpreview1 from '../assets/assignmenttoolbox/et1.png';
import etpreview2 from '../assets/assignmenttoolbox/et2.png';
import etpreview3 from '../assets/assignmenttoolbox/et3.png';
import etpreview4 from '../assets/assignmenttoolbox/et4.png';
import pmr1 from '../assets/assignmenttoolbox/pmr1.png';
import pmr2 from '../assets/assignmenttoolbox/pmr2.png';
import sym1 from '../assets/assignmenttoolbox/sym1.png';
import sym2 from '../assets/assignmenttoolbox/sym2.png';
import sym3 from '../assets/assignmenttoolbox/sym3.png';
import med1 from '../assets/assignmenttoolbox/med1.png';
import sub1 from '../assets/assignmenttoolbox/sub1.png';
import g1 from '../assets/assignmenttoolbox/g1.png';
import g2 from '../assets/assignmenttoolbox/g2.png';
import g3 from '../assets/assignmenttoolbox/g3.png';
import phq1 from '../assets/assignmenttoolbox/phq1.png';
import gad1 from '../assets/assignmenttoolbox/gad1.png';
import w1 from '../assets/assignmenttoolbox/w1.png';
import w2 from '../assets/assignmenttoolbox/w2.png';
import w3 from '../assets/assignmenttoolbox/w3.png';
import w4 from '../assets/assignmenttoolbox/w4.png';
import text2 from '../assets/assignmenttoolbox/text2.png';
import text3 from '../assets/assignmenttoolbox/text3.png';
import pcl1 from '../assets/assignmenttoolbox/pcl1.png';
import pcl2 from '../assets/assignmenttoolbox/pcl2.png';
import md1 from '../assets/assignmenttoolbox/md1.png';
import v1 from '../assets/assignmenttoolbox/v1.png';

const { Header } = Layout;
const { TabPane } = Tabs;

// List of assignable items - NEED TO DO WORKSHEETS, VALUE SORT
const assignableItems = [
  {
    key: "worksheets",
    title: "Worksheets",
    description: "Create and send interactive custom assignments to clients.",
    preview: 'Use the "Assignment Builder" on the Dashboard sidebar to create interactive custom assignments from scratch. Choose from a variety of inputs, including text fields, sliders, checkboxes, and more! Alternatively, you may upload a pdf to send to clients. Worksheets, either from the "Assignment Builder" or uploaded as a pdf can be sent to clients for completion and review, providing an accessible and interactive way to engage clients betweeen sessions.',
    image: worksheets,
    previewimages: [w1, w2, w3, w4],
  },
  {
    key: "text",
    title: "Text Assignments",
    description: "Send text-based assignments to clients, such as to call a friend or to research a topic.",
    preview: "Text Assignments allow you to send text-based assignments to clients. You can create assignments with instructions, questions, or prompts for reflection. In the example in the images below, the provider assigned this client to research emotional dependence.",
    image: worksheets,
    previewimages: [text2, text3],
  },
  {
    key: "therapy_journal_entry_before",
    title: "Therapy Journal Entry (Before)",
    description: "Support client preparation for therapy sessions.",
    preview: "The Therapy Journal (Before) helps clients prepare for their upcoming therapy session. It encourages reflection on their recent experiences, emotions, and challenges, providing a structured space to document what they want to focus on during the session. This feature ensures that sessions start with clarity and direction, saving time and maximizing productivity.",
    image: therapyjournal,
    previewimage: tjbeforepreview,
  },
  {
    key: "therapy_journal_entry_after",
    title: "Therapy Journal Entry (After)",
    description: "Client reflections after therapy sessions.",
    preview: "The Therapy Journal (After) is designed for post-session reflection. Clients can document key takeaways, insights, and actions to apply in their daily lives. This feature reinforces therapeutic lessons and promotes retention.",
    image: therapyjournal,
    previewimage: tjafterpreview,
  },
  {
    key: "journal_entry",
    title: "Journal Entry",
    description: "Encourage self-reflection and  expression.",
    preview: "Journaling provides clients with a dedicated space to freely express their thoughts, feelings, and experiences. It encourages self-reflection and emotional processing, helping clients gain clarity and insight into their mental and emotional well-being. Journaling has been shown to reduce stress, improve mood, and enhance overall self-awareness, making it a valuable tool for personal growth and mental health support.",
    image: journaling,
    previewimages: [journalpreview1, journalpreview2],
  },
  {
    key: "breathing_room",
    title: "Breathing Room",
    description: "Practice guided deep breathing exercises.",
    preview: "The Breathing Room provides a variety of meditations and guided breathing exercises designed to reduce stress, improve focus, and enhance overall well-being. Users can practice structured techniques like Loving Kindness meditation, body scans, Box Breathing, Belly Breathing, and 4-7-8 Breathing or create a fully customizable breathing cadence to suit their personal preferences. The exercises feature an interactive visual guide, making the experience intuitive and engaging.",
    image: breathing,
    previewimages: [meditation1, brpreview1, brpreview2, brpreview3, brpreview4, brpreview5],
  },
  {
    key: "pmr",
    title: "PMR",
    description: "Guided Progressive Muscle Relaxation to reduce stress.",
    preview: "Progressive Muscle Relaxation (PMR) is a relaxation technique that involves tensing and relaxing muscle groups to reduce stress and promote relaxation. The PMR feature guides users through a series of exercises to systematically tense and release muscle groups, helping them identify and release physical tension. This practice can help reduce anxiety, improve sleep, and enhance overall well-being by promoting relaxation and stress relief.",
    image: pmr,
    previewimages: [pmr1, pmr2],
  },
  {
    key: "mood_tracker",
    title: "Symptom Log",
    description: "Track mood, energy, sleep, stress, and social interaction over time.",
    preview: "The Symptom Log is a tracking tool that allows clients to log and monitor key aspects of their mental and physical well-being over time, including mood, energy, stress, sleep, and social interaction. This feature provides a visual representation of trends through charts, enabling both clients and therapists to identify patterns and gain deeper insights. This allows therapists to better understand their clients' experiences, supporting informed discussions and data-driven decisions in the therapeutic process.",
    image: mood,
    previewimages: [sym1, sym2, sym3],
  },
  {
    key: "medication",
    title: "Medication",
    description: "Track your medication intake.",
    preview: "The Medication feature helps clients stay consistent with their medication regimen by tracking doses, schedules, side effects, and adherence.",
    image: medication,
    previewimage: med1,
  },
  {
    key: "substance_log",
    title: "Substance Log",
    description: "Log substance use over time.",
    preview: "Clients can log substances they use, specify the unit of measurement (e.g., drinks), and record the quantity consumed each day. This data is visually represented in a chart, allowing clients and therapists to monitor trends over time to gain insight into consumption patterns, fostering awareness, and supporting healthier habits.",
    image: substance,
    previewimage: sub1,
  },
  {
    key: "thought_record",
    title: "Thought Record",
    description: "Identify and challenge negative thoughts.",
    preview: "A cornerstone of Cognitive Behavioral Therapy, the Thought Record guides clients through a structured process to identify, evaluate, and challenge negative thoughts. Users document situations, associated thoughts, and emotions while exploring evidence against thoughts. This reflective process helps individuals reframe unhelpful thinking patterns, leading to healthier perspectives and emotional responses. The feature includes prompts to assess feelings, consider alternative viewpoints, and record outcomes, fostering greater self-awareness and emotional regulation.",
    image: thoughtrecord,
    previewimages: [trpreview1, trpreview2],
  },
  {
    key: "goals",
    title: "Goals",
    description: "Set goals for your clients.",
    preview: "The Goals feature enables clients to set, track, and achieve goals, fostering motivation, accountability, and progress.",
    image: goals,
    previewimages: [g1, g2, g3],
  },
  {
    key: "activity_log",
    title: "Activity Log",
    description: "A.K.A. Behavioral Activation - Plan activities and reflect on their impact.",
    preview: "The Activity Log, rooted in behavioral activation, helps users plan and reflect on activities they enjoy or used to enjoy. Users record planned activities, anticipated emotions, and expected outcomes. After completing the activity, they document their mood before and after, as well as any insights or lessons learned. This structured approach encourages users to engage in meaningful activities, identify patterns, and better understand the connection between their actions and emotional states. It's a powerful tool for building positive habits and improving mental health through intentional, reflective practice.",
    image: activitylog,
    previewimages: [alpreview1, alpreview2],
  },
  {
    key: "exposure_therapy",
    title: "Exposure Therapy",
    description: "Gradual practice to confront fears and reduce distress.",
    preview: "Exposure Therapy is a structured, evidence-based practice designed to help clients confront and gradually overcome their fears and anxieties. The tool provides a step-by-step framework for planning and reflecting on exposure tasks. Before: Identify the specific fear, safety behaviors to target, and worst-case scenarios. During: Log the session's timing and record distress levels (Subjective Units of Distress Scale - SUDS) at various intervals, helping visualize emotional progress. After: Reflect on achievements, insights gained, and plan actionable next steps for continued growth. This feature empowers clients to systematically face their fears, track progress, and build confidence while reducing avoidance behaviors, all while providing valuable insights for therapists to guide treatment.",
    image: exposuretherapy,
    previewimages: [etpreview1, etpreview2, etpreview3, etpreview4],
  },
  {
    key: "value_sort",
    title: "Value Sort",
    description: "Identify important personal values.",
    preview: "The Value Sort is an interactive tool that helps clients identify and prioritize their core values. Users are presented with a series of value statements and asked to sort them based on personal importance. This activity encourages self-reflection, introspection, and the exploration of values that guide behavior and decision-making. By clarifying personal values, clients can align their actions with their beliefs, fostering a sense of purpose, meaning, and fulfillment.",
    image: valuesort,
    previewimage: v1,
  },
  {
    key: "phq9",
    title: "PHQ-9",
    description: "Patient Health Questionnaire-9.",
    preview: "PHQ-9 preview: PHQ-9 is a multipurpose instrument for screening, diagnosing, monitoring, and measuring the severity of depression. It is based directly on the diagnostic criteria for major depressive disorder in the DSM-IV.",
    image: measure,
    previewimage: phq1,
  },
  {
    key: "gad7",
    title: "GAD-7",
    description: "Generalized Anxiety Disorder-7.",
    preview: "GAD-7 preview: The GAD-7 is a self-report questionnaire designed to assess and screen for generalized anxiety disorder. It is a brief, reliable, and valid measure of anxiety severity.",
    image: measure,
    previewimage: gad1,
  },
  {
    key: "pcl5",
    title: "PCL-5",
    description: "PTSD Checklist for DSM-5.",
    preview: "PCL-5 preview: The PCL-5 is a 20-item self-report measure that assesses the presence and severity of post-traumatic stress disorder (PTSD) symptoms according to the DSM-5 criteria.",
    image: measure,
    previewimages: [pcl1, pcl2],
  },
  {
    key: "meal_donations",
    title: "Journey",
    description: "A meaningful way to encourage clients to stay engaged.",
    preview: "Yosa's Journey feature and meal donation program is designed to motivate clients to stay consistent in their mental health journey while contributing to a meaningful cause. It’s a way to inspire commitment, build accountability, and help your clients feel connected to a larger purpose. Here’s how it works: The more users engage with Yosa's features, such as journaling, breathing exercises, or completing therapeutic tasks, the more meals we will donate on their behalves. This tangible outcome provides a sense of purpose, accomplishment, and giving back, all while reinforcing positive habits. Clients can see their individual impact—how many meals they’ve donated—and the collective contributions of the Yosa community. As clients engage with the app and complete challenges, they also earn experience points (EXP) to level up along the way, with trophies celebrating their consistency and positive contributions, adding an extra layer of engagement. Clients can also complete randomized daily quests for meal donations and EXP. The meal donation program empowers clients to see their mental health efforts not just as personal growth but as an opportunity to make a real-world difference. It’s a unique way to keep them engaged, motivated, and connected to the therapeutic process. While not technically an assignment, this is a feature you can choose to opt into or out of for your clients.",
    image: mealdonations,
    previewimage: md1,
  }
];

const AssignmentToolbox = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(() => '1');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  // Handle modal open and close
  const handlePreview = (item) => setSelectedItem(item);
  const handleClose = () => setSelectedItem(null);

  const items = [
    {
      key: '1',
      icon: <RollbackOutlined />,
      label: 'Back',
      onClick: () => {
        navigate('/dashboard');
      }
    },
  ];

  const videoContainerStyle = {
    position: 'relative',
    paddingBottom: isMobile ? '56.25%' : '39.5%',
    height: 0,
    maxWidth: isMobile ? '100%' : '70%',
    margin: '0 auto 1rem',
    textAlign: 'center'
  };

  const iframeStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0
  };

  return (
    <>
      <head>
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>Yosa Help</title>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
        </style>
      </head>

      {/* Sidebar with a back button */}
      <div className="assignment-toolboxX">
        <Sidebar items={items} />
        <div className="toolbox-content-outer">
          <Header
            className='header'
            style={{
              padding: 40,
              fontWeight: 'bold',
              fontSize: 25,
              fontFamily: 'Mulish',
              backgroundColor: '#C3D7CB',
              borderBottom: '3px solid #375945',
              color: 'black',
              minWidth: '-moz-max-content',
            }}
          >
            <div>
              {'Help'}
            </div>
          </Header>

          <div className="toolbox-content">

          <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
          <TabPane tab={<span style={{ color: '#375945', fontSize: '1rem' }}>How To</span>} key="1">
            <div className="new-home">
            <section className="startup-guide-section" style={{ padding: '2rem' }}>
              <Tabs defaultActiveKey="1" centered>
                <TabPane tab={<span style={{ color: '#375945' }}>How to Sign Up</span>} key="1">
                  <div className="video-container" style={videoContainerStyle}>
                    <iframe
                      style={iframeStyle}
                      src="https://www.youtube.com/embed/Vi_kBi1iyvA"
                      title="How to Sign Up and Get Started on Yosa"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div
                    className="guide-description"
                    style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                  >
                    <h2 style={{color: '#375945'}}>Sign Up Process</h2>
                    <ol>
                      <li>
                        Navigate to the <a href="https://yosaapp.com/SignUp" target="_blank" rel="noopener noreferrer">Sign Up</a> screen.
                      </li>
                      <li>
                        Enter your information and select a pricing plan. <strong>Note:</strong> Your therapist name is what your clients will see when invited.
                      </li>
                      <li>
                        Input the confirmation code sent to your email and submit.
                      </li>
                      <li>
                        Proceed to log in with your credentials.
                      </li>
                      <li>
                        On the dashboard, click <strong>Add Client</strong> on the left sidebar.
                      </li>
                      <li>
                        Ensure you correctly enter the email your client will use to register on their mobile device.
                      </li>
                      <li>
                        If your client hasn’t installed Yosa or logged in, they will receive an email guiding them through onboarding and a link to the app.
                      </li>
                      <li>
                        Once they sign up and log in for the first time, they’ll get a notification that you’ve invited them to connect.
                      </li>
                      <li>
                        If they accept, their registration status updates to “registered” in your dashboard. (You might need to click “Check Registration Status” in the clients table to refresh.)
                      </li>
                      <li>
                        Now you’re connected to your client and ready to send assignments and provide care!
                      </li>
                    </ol>
                  </div>
                </TabPane>
                <TabPane tab={<span style={{ color: '#375945' }}>Send Assignments and Monitor Clients</span>} key="2">
                  <div className="video-container" style={videoContainerStyle}>
                    <iframe
                      style={iframeStyle}
                      src="https://www.youtube.com/embed/K42gT4lky_4"
                      title="How to Send Assignments and Monitor Your Patients on Yosa"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <Tabs defaultActiveKey="3" centered>
                    <TabPane tab={<span style={{ color: '#375945' }}>Sending Assignments</span>} key="3">
                      <div
                        className="guide-description"
                        style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                      >
                        <ol>
                          <li>
                            Log in to your provider dashboard.
                          </li>
                          <li>
                            Locate the client to whom you want to send an assignment.
                          </li>
                          <li>
                            Click the <strong>Send Assignment</strong> button next to the client's name.
                          </li>
                          <li>
                            Choose an assignment or multiple in the popup. Assignments include custom ones under "My Assignments", validated measures, and any of the Yosa features on the app. You may also choose to add instructions or send a link in the intructions field.
                          </li>
                          <li>
                            Click "Send" at the bottom of the popup to send to your client. Once sent, the assignment will appear in the client's mobile app.
                          </li>
                        </ol>
                      </div>
                    </TabPane>
                    <TabPane tab={<span style={{ color: '#375945' }}>Monitoring Client Progress</span>} key="4">
                      <div
                        className="guide-description"
                        style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                      >
                        <ol>
                          <li>
                            Log in to your provider dashboard.
                          </li>
                          <li>
                            Client responses and assignment statuses update in real-time. Note that besides measures like the PHQ-9, assignment responses and data are locked by default. Clients can choose to share responses or data with you by clicking "Sharing" in the settings on their mobile app. From there, they can toggle on or off the specific features they want to share.
                          </li>
                          <li>
                            To view responses, click the client's name in the "My Clients" table in the dashboard. 
                          </li>
                          <li>
                            You can view qualitative responses in the <b>"Activity"</b> tab (e.g. Thought Records, Goals, etc.). Here, the green check or alert icon will tell you if the assignment was completed or not. Click the assignment to view its content if shared (see step 2). 
                          </li>
                          <li>
                            You can view quantitative responses in the <b>"Charts"</b> tab (e.g. measures, symptom log, medication log, substance log).
                          </li>
                          <li>
                            You can also view client assignments directly from the dashboard by clicking the arrow directly to the left of the client's name in the "My Clients" table. This will display a list of all assignments sent to the client and their statuses, including date and time sent, completion status, sharing status, any provided instruction, and a link to view its data.
                          </li>
                        </ol>
                      </div>
                    </TabPane>
                    <TabPane tab={<span style={{ color: '#375945' }}>Creating Custom Assignments</span>} key="5">
                      <div
                        className="guide-description"
                        style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                      >
                        <ol>
                          <li>
                            Custom assignments you create or upload will appear in the "My Library" table on the dashboard. These include assignments created from the assignment builder, community assignments, and uploaded PDFs.
                          </li>
                          <li>
                            To create an interactive assignment from scratch, click the <strong>Assignment Builder</strong> button on the left sidebar. From here, you can create a new assignment, choosing the individual components and layout of the worksheet, including text fields, sliders, checkboxes and more.
                          </li>
                          <li>
                            To upload a PDF, click the <strong>Upload PDF</strong> button on the left sidebar.
                          </li>
                          <li>
                            Any custom assignments you create or upload can be sent to clients by clicking "Send Assignment" and selecting the desired custom assignment(s).
                          </li>
                        </ol>
                      </div>
                    </TabPane>
                    <TabPane tab={<span style={{ color: '#375945' }}>Choosing Client Features</span>} key="6">
                      <div
                        className="guide-description"
                        style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                      >
                        <ol>
                          <li>
                            Yosa is a one-stop-shop of tools you can utilize with your clients. However, not all will be applicable to every client and it may be overwhelming to see so many. 
                          </li>
                          <li>
                            Thus, you can choose which features to enable or disable for each client, tailoring their experience to their unique needs and goals.
                          </li>
                          <li>
                            Feature selection occurs first when adding the client. Whatever features you select will be the one's that client can view on their mobile app. Note that Journaling, the Therapy Journal, Worksheets, and Help resources are default features all users have access to. 
                          </li>
                          <li>
                            In addition to when initally adding a client, you can update their features at any time by clicking the client's name in the "My Clients" table on the dashboard and selecting the <strong>Features</strong> tab. From here choose the features you want to enable and click "Save", updating what your client can access.
                          </li>
                        </ol>
                      </div>
                    </TabPane>
                  </Tabs>
                </TabPane>
                <TabPane tab={<span style={{ color: '#375945' }}>Client Mobile App</span>} key="3">
                  <div className="video-container" style={videoContainerStyle}>
                    <iframe
                      style={iframeStyle}
                      src="https://www.youtube.com/embed/3qz0-zZravs?si=rawS7H5BIqaNZtHh"
                      title="How to Use the Client Mobile App on Yosa"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <Tabs defaultActiveKey="3" centered>
                    <TabPane tab={<span style={{ color: '#375945' }}>General</span>} key="3">
                      <div
                        className="guide-description"
                        style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                      >
                        <ol>
                          <li>
                            The Yosa mobile app can be downloaded from the App Store (for Apple devices) or Google Play Store (for Android devices).
                          </li>
                          <li>
                            While the web portal (yosaapp.com) is for providers, the mobile app is for clients, where they will complete assignments, track progress, and engage with therapuetic activities.
                          </li>
                          <li>
                            Although the mobile app is for clients, we reccomend providers add themselves as a client and download it to understand the client experience and better support them. See the video in the "How to Sign Up" tab for more information.
                          </li>
                          <li>
                            The features clients are able to access are determined by the provider. See the video in the "Send Assignments and Monitor Clients" tab for information on customizing what features your individual clients can access.
                          </li>
                          <li>
                            Through their mobile app, clients choose what information they wish to share with their provider (e.g. assignment responses, symptom log data, etc.). They can do this by clicking "Sharing" in the settings on their mobile app. From there, they can toggle on or off the specific features they want to share. See the video in the "Send Assignments and Monitor Clients" tab for more information.
                          </li>
                        </ol>
                      </div>
                    </TabPane>
                    <TabPane tab={<span style={{ color: '#375945' }}>Home Screen</span>} key="4">
                      <div
                        className="guide-description"
                        style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                      >
                        <ol>
                          <li>
                            Clients can view and complete pending assignments you send under "Your Assignments" on the home screen.
                          </li>
                          <li>
                            The "Whiteboard" on the home screen is a place clients can store any readily available information, such as goals, coming appointments, or reminders.
                          </li>
                        </ol>
                      </div>
                    </TabPane>
                    <TabPane tab={<span style={{ color: '#375945' }}>Features</span>} key="5">
                      <div
                        className="guide-description"
                        style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                      >
                        <ol>
                          <li>
                            Yosa has several features clients can access on their mobile app, which are found on the library tab. These include:
                          </li>
                          <li>
                            <b>Wellness Toolkit</b> - A collection of active practices, including journaling, breathing exercises, meditation, and progressive muscle relaxation.
                          </li>
                          <li>
                            <b>Tracking</b> - The symptom log allows clients to track their mood, sleep, energy, stress and social interaction over time. The medication log allows clients to track medication usage and adherance. The substance log allows clients to track substance use.
                          </li>
                          <li>
                            <b>Therapy</b> - Custom worksheets (PDFs or from assignment builder) you send your clients will show here to respond to, as well as various other therapy specific activities, including thought records, goal setting, value sorting, behavioral activation, exposure therapy, and a therapy journal to prepare and reflect on sessions.
                          </li>
                          <li>
                            <b>Help</b> - A collection of resources for clients to access, including crisis lines specific to different needs.
                          </li>
                        </ol>
                      </div>
                    </TabPane>
                    <TabPane tab={<span style={{ color: '#375945' }}>Journey</span>} key="6">
                      <div
                        className="guide-description"
                        style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}
                      >
                        <ol>
                          <li>
                            A core goal of Yosa is boosting engagement in therapeutic activities between sessions. Thus, the "Journey" tab is all about making the app more fun and inspiring to use by tracking their mental health journey on Yosa.
                          </li>
                          <li>
                            Clients can work towards a number of "Yosa Challenges", each with a specific goal and reward. Rewards often include earning a meal donation on their behalf, an incentive that is both motivating and makes you feel good to accomplish.
                          </li>
                          <li>
                            As clients complete challenges, they also earn exp to level up, a fun way to track progress and engage with the app.
                          </li>
                          <li>
                            The "Journey" feature also includes a "Daily Quest", a small goal to complete each day, such as taking a 5 minute stretch break. Similar to completing challenges, after completing 15 "Daily Quests", clients earn a meal donation.
                          </li>
                        </ol>
                      </div>
                    </TabPane>
                  </Tabs>
                </TabPane>
              </Tabs>
            </section>
            </div>
          </TabPane>
          {/* Qualitative Data Content */}
          <TabPane tab={<span style={{ color: '#375945', fontSize: '1rem' }}>FAQs</span>} key="2">
            <div
            className="faq-header-text"
            style={{
              textAlign: 'center',
              fontFamily: 'Mulish',
              color: '#375945',
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
              maxWidth: '100%', // Prevents text from exceeding the screen width
              padding: '0 1rem', // Prevents text from touching edges
            }}
          >

            <h1
              style={{
                fontSize: '2.8rem', // Use a responsive font size
                margin: 0,
                lineHeight: '1.2', // Add proper line spacing
                maxWidth: '100%', // Prevents horizontal overflow
                marginTop: '2rem',
              }}
            >
              Frequently Asked Questions
            </h1>
            <div>
              <h2
                style={{
                  fontSize: '1rem',
                  marginTop: '1rem',
                  lineHeight: '1.4', // Proper spacing for readability
                  maxWidth: '100%', // Prevents overflow
                }}
              >
                If you're thinking it, chances are others have too. Find answers to
                common questions about Yosa.
              </h2>
            </div>
            </div>

            {/* Provider / Therapist FAQs */}
            <h2 className="faq-section-header" style={{ display: 'flex', color: '#375945', paddingLeft: '4rem', marginBottom: '0', paddingTop: '1.5rem' }}>Providers / Therapists</h2>
            <section className="solution-section" style={{ marginTop: '0', paddingTop: '0' }}>
              <div className="solution-text">
                <div className="cbt-accordion">
                  <Collapse accordion>
                    <Collapse.Panel className='cbt-accordionpanel' header="How do I add my clients and ensure they're signed up?" key="2">
                      <p><strong>Answer:</strong> After creating your Yosa account and logging in, you will be navigated to the provider dashboard. From there, click the "Add Client" button on the green sidebar to the left. You'll then be prompted with instructions and must input the client's Name, Email, and select the Yosa features you want this client to have access to. Click "Send Invite" at the bottom of this page once you've done so. If your client does not have Yosa, we will send them an email with instructions guiding them along. Otherwise, they will receive an in-app notification that you added them as a client. If they have not registered, a red exclamation will appear left of their name in the "My Clients" table and "Send Assignment" button will be grayed out. If this is not the case, your client is signed up and you're all set!</p>
                    </Collapse.Panel>

                    <Collapse.Panel className='cbt-accordionpanel' header="How do I upload my own worksheet or assignment?" key="3">
                      <p><strong>Answer:</strong> After logging in, you will be navigated to the provider dashboard. From here, you can upload your own custom worksheets or assignments by clicking "Assignment Builder" to build an interactive assignment from scratch or "Upload PDF" to upload PDFs. These options can be found in the green sidebar on the left side of the dashboard. Once you've successfully uploaded a PDF or built an assignment from scratch, it will appear in the "My Library" table in the dashboard. Note you can navigate between viewing Assignments (from the Assignment Builder) and PDFs by selecting the headers in the table.</p>
                    </Collapse.Panel>

                    <Collapse.Panel className='cbt-accordionpanel' header="Are validated measures like the PHQ-9 available to send and monitor through Yosa?" key="4">
                      <p><strong>Answer:</strong> Yes, validated measures, including the PHQ-9, GAD-7, and PCL-5 are available on Yosa, allowing you to assign them directly to clients (which they will be notified of and complete in-app) and monitor their responses in real-time. To send one of these measures to your client, select the "Send Assignment" button in the row of the client you wish to send it to. From there, select the checkbox next to the measure you wish to send and click "Send".</p>
                    </Collapse.Panel>

                    <Collapse.Panel className='cbt-accordionpanel' header="What if I don't want my client to have access to parts of the app (e.g. the safety plan)?" key="6">
                      <p><strong>Answer:</strong> Yosa is designed to be tailored to each client's specific needs and goals and allows you to control which features your clients can access. When you first add a client, you'll select what features they have access to and can edit this in the future (either adding or removing features) as treatment progresses. For example, you might not want to give access to the "Thought Record" until introducing the concept first during a session. Yosa allows you to add that access whenever you want so Yosa can support individualized needs throughout the treatment lifecycle.</p>
                    </Collapse.Panel>

                    <Collapse.Panel className='cbt-accordionpanel' header="How do I track my clients' responses and data?" key="7">
                      <p><strong>Answer:</strong> On the provider dashboard in the "My Clients" table, click on the name of the client you wish view. From here, the "Activity" tab will display responses to text-based assignments and entries. The "Charts" tab displays quantitative visuals for validated measures, symptom tracking, medication intake, and substance use tracking. Additionally, in the "My Clients" table in the dashboard, you may click the chevron directly left of a clients name to display their assignments, as well as buttons to view intructions and responses.</p>
                    </Collapse.Panel>

                    <Collapse.Panel className='cbt-accordionpanel' header="I can't see my clients' data. How do they share it with me?" key="8">
                      <p><strong>Answer:</strong> Each individual client can choose what data they wish to share with their provider. The default is "not shared". To share data, clients can click the settings icon in the top right corner of their app's home screen then select 'Sharing' in the dropdown menu, navigating to 'Sharing Settings'. From here, clients can toggle on or off what features they wish to share data with providers. Note that validated measures such as PHQ-9 scores are shared with providers by default.</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </section>

            {/* Client / Patient FAQs */}
            <h2 className="faq-section-header" style={{ display: 'flex', color: '#375945', paddingLeft: '4rem', marginBottom: '0', paddingTop: '1.5rem' }}>Clients / Patients</h2>
            <section className="solution-section" style={{ marginTop: '0', paddingTop: '0' }}>
              <div className="solution-text">
                <div className="cbt-accordion">
                  <Collapse accordion>
                    <Collapse.Panel className='cbt-accordionpanel' header="How do I know what assignments my therapist assigned me?" key="3"> 
                    <p><strong>Answer:</strong> Incomplete assignments your therapist has assigned will appear on the home screen of your Yosa app under "Your Assignments". After completing an assignment, it will disappear from this section.</p>
                    </Collapse.Panel>

                    <Collapse.Panel className='cbt-accordionpanel' header="How do I delete my account?" key="4"> 
                    <p><strong>Answer:</strong> If you wish to delete your Yosa account and associated data, please follow these steps:

                        To disable your account, click the settings icon in the top right corner of the Yosa app and select "Delete Account" from the dropdown, followed by "Disable Account". This will disable your account, but some data may still be shared with your provider per legal requirements. Alternatively, you may send an email to support@yosaapp.com with the subject line "Account Deletion Request."
                        In the email, please include:
                        The email address associated with your Yosa account and a brief confirmation that you wish to delete your account.
                        Our support team will process your request within 30 days and confirm once your account has been deleted.
                        Once your account is deleted, your personal data will be permanently removed from our systems.
                        Some anonymized data may be retained for legal purposes in compliance with our Privacy Policy.
                        If you have any questions, feel free to reach out to support@yosaapp.com. </p>
                    </Collapse.Panel>

                    <Collapse.Panel className='cbt-accordionpanel' header="Can I request partial data deletion without deleting my account?" key="5"> 
                    <p><strong>Answer:</strong> Yes! If you would like to request the deletion of some or all of your personal data without deleting your account, you can email support@yosaapp.com with the subject line "Data Deletion Request." In the email, specify: The data you wish to have removed and the reason (optional, but helpful for improving our services).
                        Our team will review your request and respond within 30 days regarding the status of your data removal.
                        Some anonymized data may be retained for legal purposes in compliance with our Privacy Policy.
                        If you have any questions, feel free to reach out to support@yosaapp.com.</p>
                    </Collapse.Panel>          
                </Collapse>
                </div>
              </div>
            </section>

            {/* General FAQs */}
            <h2 className="faq-section-header" style={{ display: 'flex', color: '#375945', paddingLeft: '4rem', marginBottom: '0', paddingTop: '1.5rem' }}>General</h2>
            <section className="solution-section" style={{ marginTop: '0', paddingTop: '0' }}>
              <div className="solution-text">
                <div className="cbt-accordion">
                  <Collapse accordion>
                    <Collapse.Panel className='cbt-accordionpanel' header="Is Yosa HIPAA-compliant?" key="1">
                      <p><strong>Answer:</strong> Yes! Yosa proudly adheres to strict HIPAA standards, ensuring the privacy and security of your clients’ data. See more about our privacy and security practices in the Privacy Policy and Notice of Privacy Practices.</p>
                    </Collapse.Panel>

                    <Collapse.Panel className='cbt-accordionpanel' header="What devices can I use Yosa on?" key="2">
                      <p><strong>Answer:</strong> The provider portal and tools are available through any web browser online (You'll log in by clicking 'Log In' in the header above or click 'Sign Up' if you don't have an account). The client app is compatible with most smarphones running on iOS (e.g. iPhone) or Android.</p>
                    </Collapse.Panel>

                    <Collapse.Panel className='cbt-accordionpanel' header="Who do I contact for technical support?" key="3">
                      <p><strong>Answer:</strong> For any technical issues or support, you can contact the Yosa team at support@yosaapp.com.</p>
                    </Collapse.Panel>

                    <Collapse.Panel className='cbt-accordionpanel' header="Why is homework important in therapy?" key="4">
                      <p><strong>Answer:</strong> Homework is a critical component of therapy because it helps you apply the skills and techniques learned in sessions to your everyday life. Research shows that completing therapy homework is significantly linked to better outcomes, including symptom reduction and improved well-being. It allows you to practice new behaviors, reflect on insights, and build long-lasting changes outside of therapy sessions.</p>
                      <p>To learn more about the evidence behind homework's impact on therapy outcomes, visit our <a href='/Evidence'>Evidence</a> and <a href='/Homework'>Homework</a> pages.</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </section>
            
            {/* Troubleshooting FAQs */}
            <div style={{fontSize: '1.5rem', fontFamily: 'Mulish', display: 'flex', textAlign: 'center'}}>
              <div>If you have other questions, please don't hesitate to reach out to our support team at support@yosaapp.com and a team member will assist you as soon as possible!</div>
            </div>
          </TabPane>

          {/* Quantitative Data Content */}
          <TabPane tab={<span style={{ color: '#375945', fontSize: '1rem' }}>Assignment Toolbox</span>} key="3">
            <h1 style={{fontSize: '1.5rem', fontFamily: 'Mulish', marginTop: '1.5rem', marginBottom: '0'}}>What you can assign on Yosa:</h1>
            <div className="assignments-grid" style={{ marginTop: '2rem' }}>
              {assignableItems.map((item) => (
                <Card key={item.key} className="assignment-toolbox-card" bordered>
                  <div className="assignment-content">
                    <div className="assignment-text">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                      <Button className="assignment-button" type="primary" onClick={() => handlePreview(item)}>
                        Preview
                      </Button>
                    </div>
                    <img alt={item.title} src={item.image} className="assignment-toolbox-image" />
                  </div>
                </Card>
              ))}
            </div>

            {/* Modal for item previews */}
            {selectedItem && (
              <Modal
                title={selectedItem.title}
                visible={!!selectedItem}
                onCancel={handleClose}
                footer={[
                  <Button key="close" onClick={handleClose}>
                    Close
                  </Button>,
                ]}
              >
                {/* Display Preview Content */}
                <p>{selectedItem.preview}</p>

                {/* Single Preview Image */}
                {selectedItem.previewimage && (
                  <img
                    src={selectedItem.previewimage}
                    alt={`${selectedItem.title} preview`}
                    style={{
                      width: "250px",
                      height: "auto",
                      marginTop: "10px",
                      borderRadius: "8px",
                    }}
                  />
                )}

                {/* Multiple Preview Images */}
                {selectedItem.previewimages && (
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "10px" }}>
                    {selectedItem.previewimages.map((img, index) => (
                      <img
                        key={index}
                        src={img}
                        alt={`${selectedItem.title} preview ${index + 1}`}
                        style={{
                          width: "200px", // Adjust width as needed
                          height: "auto",
                          borderRadius: "8px",
                        }}
                      />
                    ))}
                  </div>
                )}
              </Modal>
            )}
          </TabPane>
        </Tabs>

          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentToolbox;
