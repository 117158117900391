import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Pricing.css";
import { Menu, Dropdown, Button, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { logosGreen } from '../config/logos';
import linkedinicon from '../assets/linkedinicon.png';

const Pricing = () => {
  const [isAnnual, setIsAnnual] = useState(false);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const currentEnv = process.env.REACT_APP_ENV || "development";

  // Pricing data with original and discounted prices
  const pricingPlans = [
    {
      id: 1,
      title: "Free Level",
      monthlyPrice: 0,
      features: [
        "Access to complete Yosa Assignment Toolbox",
        "Assignment Builder and Community",
        "Analytics dashboard to manage and monitor clients",
      ],
      clientAccounts: "Up to 4 client accounts",
    },
    {
      id: 2,
      title: "Starter Level",
      originalMonthlyPrice: 39,
      monthlyPrice: 19, // 50% off
      features: [
        "All features in Entry Level",
        "Allows the onboarding of up to 11 additional clients",
      ],
      clientAccounts: "Up to 15 client accounts",
    },
    {
      id: 3,
      title: "Practice Level",
      originalMonthlyPrice: 79,
      monthlyPrice: 39, // 50% off
      features: [
        "All features in Starter Level",
        "Allows the onboarding of up to 85 additional clients",
      ],
      clientAccounts: "Up to 100 client accounts",
    },
    {
      id: 4,
      title: "Enterprise Level",
      originalMonthlyPrice: 0, // Contact Us pricing
      monthlyPrice: 0, // No discount applies
      features: [
        "All features in Practice Level",
        "Manage multiple clinicians",
        "Custom branding and integration",
        "Dedicated account manager",
      ],
      clientAccounts: "Unlimited client accounts",
      contactUs: true,
    },
  ];

  // Toggle between monthly and annual pricing
  const handleToggle = () => {
    setIsAnnual(!isAnnual);
  };

  const evidenceMenu = (
    <Menu>
      <Menu.Item key="evidence">
        <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
      </Menu.Item>
      <Menu.Item key="homework">
        <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
      </Menu.Item>
    </Menu>
  );

  const aboutMenu = (
    <Menu>
      <Menu.Item key="evidence">
        <a href="/About" style={{ textDecoration: 'none' }}>About</a>
      </Menu.Item>
      <Menu.Item key="homework">
        <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
      </Menu.Item>
    </Menu>
  );

  const productMenu = (
          <Menu>
            <Menu.Item key="product">
              <a href="/Product" style={{ textDecoration: 'none' }}>Product</a>
            </Menu.Item>
            <Menu.Item key="startupguide">
              <a href="/StartUpGuide" style={{ textDecoration: 'none' }}>Getting Started</a>
            </Menu.Item>
          </Menu>
        );

  return (
    <>
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Yosa Pricing</title>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
        </style>
      </head>

      <div className="new-home">
        {/* Header */}
        <header className="header">
          <a href="/" className="logo">
            <img src={logosGreen[currentEnv]} alt="Yosa Logo" />
          </a>

          {/* Hamburger Menu Icon */}
          <button
            className={`menu-toggle ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>

          <nav className={`navigation ${menuOpen ? "open" : ""}`}>
            <ul>
              <li><a href="/">Home</a></li>
              <li>
                <Dropdown overlay={productMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/Product">
                    Product <DownOutlined />
                  </a>
                </Dropdown></li>
              <li>
                <Dropdown overlay={evidenceMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/Evidence">
                    Evidence <DownOutlined />
                  </a>
                </Dropdown>
              </li>
              <li><a className="bold-header-title" style={{ fontWeight: "900" }} href="/Pricing">Pricing</a></li>
              <li>
                <Dropdown overlay={aboutMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/About">
                    About <DownOutlined />
                  </a>
                </Dropdown>
              </li>              <li><a href="/LogIn">Log In</a></li>
              <li><a href="/SignUp">Sign Up</a></li>
            </ul>
          </nav>
        </header>

        <div className="pricing-container">
          {/* Header Section */}
          <header className="pricing-header">
            <h1>Pricing Plans</h1>
            <p>
              Launch Sale: All prices are 50% off.
              {/*Save an additional 10% with annual billing.*/}
            </p>
            {/*<div className="billing-toggle">
              {<span>Bill Monthly</span>
              <label className="switch">
                <input type="checkbox" checked={isAnnual} onChange={handleToggle} />
                <span className="slider"></span>
              </label>
              <span>Bill Annually (Extra 10% Off)</span>
            </div>*/}
          </header>

          {/* Pricing Cards */}
          <div className="pricing-cards">
            {pricingPlans.map((plan) => (
              <div key={plan.id} className="pricing-card">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'first baseline', padding: '0 2rem' }}>
                  <div style={{ height: '30px', marginTop: '35px' }}>
                    <h2>{plan.title}</h2>
                  </div>
                  <p className="pricing-price">
                    {plan.contactUs ? (
                      "Contact us"
                    ) : (
                      <>
                        {plan.originalMonthlyPrice && (
                          <span className="original-price">
                            ${plan.originalMonthlyPrice.toFixed(0)}
                          </span>
                        )}
                        {" "}
                        {plan.monthlyPrice === 0 ? (
                          <div style={{ display: 'flex', flexDirection: 'row', gap: '15px'}}>
                            <span className="discounted-price">
                              Free
                            </span>
                            <span className="credit-card-text">(no credit card required)</span>
                          </div>
                        ) : (
                          <span className="discounted-price">
                            ${plan.monthlyPrice.toFixed(0)} / month
                          </span>
                        )}
                      </>
                    )}
                  </p>
                  <p className="pricing-details">{plan.clientAccounts}</p>
                  <ul className="pricing-features">
                    {plan.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </div>
                <button
                  className="pricing-button"
                  onClick={() => {
                    if (plan.contactUs) {
                      window.location.href = "mailto:support@yosaapp.com?subject=Inquiry%20about%20Yosa%20Pricing&body=Hello%20Yosa%20Support,%0D%0A%0D%0AI%20am%20interested%20in%20learning%20more%20about%20your%20pricing%20plans.%20Please%20provide%20more%20details.%0D%0A%0D%0AThank%20you.";
                    } else {
                      navigate(`/SignUp`);
                    }
                  }}
                  style={{ margin: '0 2rem', marginBottom: '2rem' }}
                >
                  {plan.contactUs ? "Contact Us" : "Get Started"}
                </button>
              </div>
            ))}
          </div>
        </div>
        <footer className="footer">
          <div className="footer-content">
            <p>&copy; {currentYear} Yosa Inc. All rights reserved.</p>
            <nav>
              <ul>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                <li><a href="/terms-of-service">Terms of Service</a></li>
                <li><a href="/baa">Business Associate Agreement</a></li>
              </ul>
            </nav>
            <div className="footer-bottom">
              <p>
                <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
                <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
                  <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Pricing;
