// File: src/screens/Evidence.jsx
import React from "react";
import { useState } from 'react';
import "../styles/NewHome.css"; // Import the corresponding CSS file
import "../styles/Product.css";
import "../styles/Evidence.css";
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
//image imports
import { logosGreen } from '../config/logos';
import linkedinicon from '../assets/linkedinicon.png';
import bannerimage from '../assets/bannerimage.jpg';
import homeworkicon from '../assets/homeworkicon.png';
import limitation from '../assets/exposuretherapy.png';
import solution from '../assets/balancingguy.png';

const Evidence = () => {
    const currentYear = new Date().getFullYear();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    
        const toggleMenu = () => {
          setMenuOpen(!menuOpen);
        };

const handleEmailChange = (e) => {
    setEmail(e.target.value);
};

const currentEnv = "production";

const api = process.env.REACT_APP_API_URL || "https://admin.yosaapp.com/api";

const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch(`${api}/Waitlist/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
                //console.log("error");
            }

            alert('You successfully joined our mailing list! We will contact you soon.');
            setEmail('');
        } catch (error) {
            setError('There was a problem submitting your email. Please try again later.');
        }
    };

  const evidenceMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/Evidence" style={{ textDecoration: 'none' }}>Evidence</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/Homework" style={{ textDecoration: 'none' }}>Homework</a>
          </Menu.Item>
        </Menu>
      );

  const aboutMenu = (
        <Menu>
          <Menu.Item key="evidence">
            <a href="/About" style={{ textDecoration: 'none' }}>About</a>
          </Menu.Item>
          <Menu.Item key="homework">
            <a href="/FAQs" style={{ textDecoration: 'none' }}>FAQs</a>
          </Menu.Item>
        </Menu>
      );

  const productMenu = (
          <Menu>
            <Menu.Item key="product">
              <a href="/Product" style={{ textDecoration: 'none' }}>Product</a>
            </Menu.Item>
            <Menu.Item key="startupguide">
              <a href="/StartUpGuide" style={{ textDecoration: 'none' }}>Getting Started</a>
            </Menu.Item>
          </Menu>
        );

  return (
    <>
    <head>
      <meta charSet='UTF-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <title>Yosa Evidence</title>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Mulish:wght@400;700&display=swap');
      </style>
    </head>

    <div className="new-home">

    {/* Header */}
    <header className="header">
        <a href="/" className="logo">
          <img src={logosGreen[currentEnv]} alt="Yosa Logo" />
        </a>
        
        {/* Hamburger Menu Icon */}
        <button
            className={`menu-toggle ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>

        <nav className={`navigation ${menuOpen ? "open" : ""}`}>
            <ul>
            <li><a href="/">Home</a></li>
            <li>
              <Dropdown overlay={productMenu} trigger={['hover']}>
                <a style={{ textDecoration: 'none' }} href="/Product">
                  Product <DownOutlined />
                </a>
              </Dropdown></li>
            <li>
                <Dropdown overlay={evidenceMenu} trigger={['hover']}>
                    <a style={{ textDecoration: 'none' }} href="/Evidence">
                    Evidence <DownOutlined />
                    </a>
                </Dropdown>
            </li>
            <li><a href="/Pricing">Pricing</a></li>
            <li>
              <Dropdown overlay={aboutMenu} trigger={['hover']}>
                  <a style={{ textDecoration: 'none' }} href="/About">
                      About <DownOutlined />
                  </a>
              </Dropdown>
            </li>
            <li><a href="/LogIn">Log In</a></li>
            <li><a href="/SignUp">Sign Up</a></li>
            </ul>
        </nav>
    </header>

      {/* Hero Section */}
      <section className="hero-research">
        <div className="evidence-header">
          <h1>Grounded in research, designed for impact: Yosa bridges the gap between therapy and everyday life</h1>
        </div>
        <img src={bannerimage} alt="Banner" />
        <div className="evidence-subheader">
          <h2>Discover the science and evidence that makes Yosa a game-changer in therapy</h2>
        </div>
      </section>

      {/* Why Homework Matters */}
      <section className="limitations-section">
        <div className="limitations-text">
          <h2>Why Homework Matters</h2>
          <p>
            Therapeutic homework (often called something else to avoid negative association) bridges the gap between therapy sessions and everyday life by having patients engage with what they learned <i>in</i> therapy, <i>outside</i> of therapy hours as well. Examples include self-monitoring, psychoeducational homework, and behavioral practices.
          </p>
          <p>
            As individuals might only go to therapy weekly or biweekly, continuous monitoring between sessions provides a more representative picture of a patient's week.
          </p>
          <p><a href="/Homework" className="link-to-evidence">Click here to learn more about homework and its roots in therapy</a></p>
          <h3>Did you know homework is causally linked with better patient outcomes?</h3>
          <p>
            (<a href="https://psycnet.apa.org/doiLanding?doi=10.1111%2Fj.1468-2850.2010.01204.x" className="link-to-evidence">Kazantzis et al., 2010</a>; <a href="https://www.sciencedirect.com/science/article/abs/pii/S0005789416300296?via%3Dihub" className="link-to-evidence">Kazantzis et al., 2016</a>)
          </p>
          <h3>Yet, just 50% of assignments are completed.</h3>
          <p>
            (<a href="https://www.sciencedirect.com/science/article/abs/pii/S0005789416300296?via%3Dihub" className="link-to-evidence">Kazantzis et al., 2016</a>; <a href="https://journals.sagepub.com/doi/10.1177/0145445504272979" className="link-to-evidence">Gaynor et al., 2006</a>; <a href="https://www.cambridge.org/core/journals/behavioural-and-cognitive-psychotherapy/article/abs/problems-with-homework-in-cbt-rare-exception-or-rather-frequent/0C47B89190ED53495A5223D38216B45A" className="link-to-evidence">Helbig & Fehm, 2004</a>)
          </p>
        </div>
        <div className="limitations-image">
          <img src={homeworkicon} alt="Why Homework Matters Illustration" />
        </div>
      </section>

      {/* Current Limitations */}
      <section className="limitations-section">
        <div className="limitations-text">
          <h2>Current Limitations</h2>
          <p>
          Traditional methods of delivering therapy assignments—such as paper handouts, emails, or verbal instructions—often face challenges that contribute to low completion rates, with less than 50% of assignments being completed, which can hinder patient outcomes and disrupt the continuity of therapy <a className="link-to-evidence" href="https://pmc.ncbi.nlm.nih.gov/articles/PMC8183976/">(Bunnell et al., 2022;</a> <a className="link-to-evidence" href="https://onlinelibrary.wiley.com/doi/10.1002/jclp.10027">Garland & Scott, 2002)</a>.
          </p>
          <h3 className="custom-h3">Provider Barriers</h3>
          <ul>
            <li>Difficulty engaging patients or discouraged by low engagement</li>
            <li>Don’t know what to assign / lack resources</li>
          </ul>

          <h3 className="custom-h3">Patient Barriers</h3>
          <ul>
            <li>Assignment lost or forgotten, low accessibility</li>
            <li>Lack of reward or reinforcement</li>
            <li>Homework being an aversive term</li>
            <li>Difficulty understanding assignment purpose</li>
            <li>Lack of engagement and interactivity</li>
          </ul>
        </div>
        <div className="limitations-image">
          <img src={limitation} alt="Current Limitations Illustration" />
        </div>
      </section>

      {/* How Yosa is Better */}
      <section className="solution-section">
        <div className="solution-text">
          <h2>How Yosa is Better: Evidence-Informed Solutions</h2>
          <p>
            Yosa addresses the challenges of traditional therapeutic homework with a modern, engaging, and research-driven platform. Our design integrates findings and recommendations from peer-reviewed research to improve homework completion and patient outcomes, with features that make therapy a seamless part of daily life. <a className="link-to-evidence" href="https://mental.jmir.org/2017/2/e20/">(Tang & Kreindler, 2017;</a> <a className="link-to-evidence" href="https://www.jmir.org/2022/3/e27791">Denecke et al., 2022;</a> <a className="link-to-evidence" href="https://pmc.ncbi.nlm.nih.gov/articles/PMC8183976/">Bunnell et al., 2022)</a>.
          </p>
          <ul>
            <li><strong>Increased Accessibility:</strong> Patients can access their assignments and tools anytime, anywhere.</li>
            <li><strong>Stimulating Experience:</strong> A user-friendly patient-centered interface, with interactive, gamified features like badges, progress tracking, and reminders promote enjoyable participation.</li>
            <li><strong>Customization:</strong> By allowing therapists to choose what features each client can access (and change this over time), Yosa ensures app content aligns with therapy goals and dynamically adapts therapy progresses.</li>
            <li><strong>Fostering Learning:</strong> Reducing inconvenience is key to maintaining a patient’s motivation to complete homework. Yosa is designed to be simple and clear to reduce barriers.</li>
            <li><strong>Behavior and Symptom Tracking:</strong> Unlike traditional paper-based methods, Yosa's digital platform allows for real-time self-assessment, reducing recall bias and enhancing data accuracy and accessibility over time, offering valuable insights to therapists and clients.</li>
            <li><strong>Provider Insights:</strong> Intuitive charts and actionable data to track progress, uncover trends, and tailor treatment to individual needs.</li>
            <li><strong>One Stop Shop:</strong> It can be hard to know what to assign or where to find resources. Between our comprehensive library of tools and assignments, and your ability to create custom assignments, Yosa makes it easy as a one stop shop to support your clients between sessions.</li>
          </ul>
        </div>
        <div className="solution-image">
          <img src={solution} alt="How Yosa is Better Illustration" />
        </div>
      </section>

      {/* Assignment Builder */}
      <section className="assignment-builder">
        <div className="assignment-builder-text">
          <h2>Bridging the Gap Between Sessions</h2>
          <p className="bridging-text">
          Therapy doesn’t stop at the office—it’s a daily journey. Yosa empowers patients to stay engaged, motivated, and on track between sessions by providing the tools they need to succeed.
          </p>
          <p className="bridging-text">
          With Yosa, therapists can trust that their patients are consistently supported, and patients can take ownership of their mental health journey—making every session more impactful.          
          </p>
        </div>
      </section>

      <h3 className="learn-more"><a href="/Homework">Click here to learn more about the evidence behind Yosa!</a></h3>

      {/* Waitlist Signup */}
      <section className="newsletter">
        <h2 style={{marginBottom: '30px'}}>Get started today for free! No credit card required.</h2>
        <a href="/signup" className="free-trial-button">Sign Up</a>
      </section>

      <footer className="footer">
                <div className="footer-content">
                    <p>&copy; {currentYear} Yosa Inc. All rights reserved.</p>
                    <nav>
                    <ul>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="/notice-of-privacy-practices">Notice of Privacy Practices</a></li>
                        <li><a href="/terms-of-service">Terms of Service</a></li>
                        <li><a href="/baa">Business Associate Agreement</a></li>
                    </ul>
                    </nav>
                    <div className="footer-bottom">
                    <p>
                        <a href="mailto:support@yosaapp.com" className="email-link">support@yosaapp.com</a>
                        <a href="https://www.linkedin.com/company/yosa-app/" target="_blank" rel="noopener noreferrer">
                        <img src={linkedinicon} alt="LinkedIn" className="linkedin-icon" />
                        </a>
                    </p>
                    </div>
                </div>
            </footer>
    </div>
    </>
  );
};

export default Evidence;
